<div
  class="container col-xl-12 mx-0 px-2 mx-sm-auto mx-md-0 px-xl-5 mb-2"
>
  <div class="d-flex flex-column mb-0 pb-2">
    <h3 class="text-info text-uppercase text-center mt-3 mb-2">employees</h3>
    <div class="d-flex justify-content-center justify-content-md-start px-md-2">
      <a class="text-info" routerLink="all">
        <button
          class="btn btn-outline-info my-2 ml-1 mx-sm-1 shadow"
          tooltip="All Employees"
        >
          <i class="fas fa-users"></i> All
        </button>
      </a>
      <a class="text-info" routerLink="logged-in">
        <button
          class="btn btn-outline-info my-2 ml-1 mx-sm-1 shadow"
          tooltip="Logged In Employees"
        >
          <i class="fas fa-user-check"></i> Logged In
        </button>
      </a>
      <a class="text-info" routerLink="approve">
        <button
          class="btn btn-outline-info my-2 ml-1 mx-sm-1 shadow"
          tooltip="Approve Employees"
        >
          <i class="fas fa-user-plus"></i> Newly Registered
        </button>
      </a>
      <a class="text-info" routerLink="deactivated">
        <button
          class="btn btn-outline-info my-2 ml-1 mx-sm-1 shadow"
          tooltip="Deactivated Employees"
        >
          <i class="fas fa-user-lock"></i> Deactivated
        </button>
      </a>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>
