import { Component, OnInit } from "@angular/core";
import firebase from "firebase";

// eslint-disable-next-line max-len
export interface TradingProductCatalog {
  tradingProductId: string /*Generated*/;
  tradingProductName: string;
  tradingProductCategory: string;
  /*Stockable=Stockable items are physical objects whose quantity should be tracked,
  composite=Serialized products are objects that have a unique serial number on each unit.,
  non-stoked=Non-stocked products are not tracked by quantity,
  but you can assign costs and prices to them,
  service=Services are not tracked by quantity,
  but you can assign costs and prices to them.*/
  tradingProductType: string;
  /*Standard=This item has one variant which is itself,
  Variant=This item has different attributes, like size or color.
  Each variant can be uniquely tracked,Composite=A composite contains one or more standard items.
  It can be tracked as one item for all the standard items linked to it*/
  tradingProductDescription: string /**/;
  tradingProductUnitOfMeasure: string;
  /*Cases,Packs,Pieces,Each,Weight={kilogram(kg),gram(g),milligram(mg)},Height={metre(m),centimetre (cm),millimetre (mm), inch (in),
  the foot (ft), the yard (yd)},Width={metre(m),centimetre (cm),millimetre (mm), inch (in), the foot (ft),
  the yard (yd)},Length{metre(m),centimetre (cm),millimetre (mm), inch (in), the foot (ft), the yard (yd),volume={litres,millitres}}*/
  tradingProductAverageCostPrice: number;
  tradingProductAverageMarkUp: number;
  tradingProductAverageSellingPrice: number;
  tradingProductInventoryStatus: string /*Available,Not Available*/;
  inventoryColRef: string;
  inventoryDocRef: string;
  supplierColRef: string;
  supplierDocRef: string;
  employeeColRefCB: string /*Created by*/;
  employeeDocRefCB: string /*Created by*/;
  employeeColRefFA: string /*First Approved by*/;
  employeeDocRefCBSA: string /*First Approved*/;
  employeeColRefSA: string /*Second Approved by*/;
  employeeDocRefSA: string /*Second Approved*/;
  tradingProductVariants: any;
  createdAt: firebase.firestore.FieldValue;
  updatedAt: firebase.firestore.FieldValue;
}

export interface TradingProductCatalogWithVId extends TradingProductCatalog {
  variantId: string /*Unique id representing the variant in variants*/;
}

export interface TradingProductVariants {
  variantAttributesValues: string /*Value of the attribute like Red,Blue*/;
  variantCostPrice: number;
  variantMarkUp: number;
}

export interface TradingProductComposites {
  compositeId: string;
  tradingProductsColRefs: string;
  tradingProductsDocRefs: string;
}

export interface UomData {
  uomAbbreviation: string;
  uomName: string;
}

export interface PType {
  pTypeName: string;
}

export interface PCat {
  pCatName: string;
}

@Component({
  selector: "app-create-catalog-item",
  templateUrl: "./create-catalog-item.component.html",
  styleUrls: ["./create-catalog-item.component.scss"],
})
export class CreateCatalogItemComponent implements OnInit {
  status: boolean;
  constructor() {}

  ngOnInit(): void {
    this.status = false;
  }
}
