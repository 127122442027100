import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { forkJoin, Observable, of } from "rxjs";
import { AdminService } from "../services/admin.service";
import { catchError, map } from "rxjs/operators";
import { ApprovalItem, Company } from "../../shared/models/admin";
import { EmployeeData } from "../../shared/models/employee";
import { Branch } from "../../shared/models/user-auth";

export interface DashboardResolvedData {
  company: Company;
  approvals: Array<ApprovalItem>;
  loggedIn: Array<EmployeeData>;
  branches: Array<Branch>;
}

@Injectable({
  providedIn: "root",
})
export class DashboardResolver implements Resolve<Observable<any>> {
  constructor(private adminService: AdminService) {}

  resolve(): Observable<any> {
    return <Observable<any>>forkJoin([
      this.adminService.getCompany(),
      this.adminService.getCreateApprovals(),
      this.adminService.getUpdateApprovals(),
      this.adminService.getLoggedInEmployees(),
      this.adminService.getAllBranches(),
    ]).pipe(
      map(
        (
          results: [
            Company,
            Array<ApprovalItem>,
            Array<ApprovalItem>,
            Array<EmployeeData>,
            Array<Branch>
          ]
        ) => ({
          details: {
            company: results[0],
            approvals: [
              ...(results[1].length > 3 ? results[1].slice(1, 3) : results[1]),
              ...(results[2].length > 3 ? results[2].slice(1, 3) : results[2]),
            ],
            loggedIn:
              results[3].length > 5 ? results[3].slice(1, 5) : results[3],
            branches:
              results[4].length > 5
                ? results[4].slice(1, 5)
                : results[4].slice(1, 5),
          },
        })
      ),
      catchError((error) => {
        return of({ details: null, error: error });
      })
    );
  }
}
