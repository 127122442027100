
export const environment = {
   production: 'false',
   apiUrl: 'https://www.fikasalama.ug:3078',
    firebaseConfig : {
     apiKey: 'AIzaSyDjM5LDBAkthxyO6bMjh-_dJvrP5kVPSag',
     authDomain: 'brimasleaddev.firebaseapp.com',
     databaseURL: 'https://brimaslead-test.firebaseapp.com',
     projectId: 'brimasleaddev',
     storageBucket: 'brimasleaddev.appspot.com',
     messagingSenderId: '406867042256',
     appId: '1:406867042256:web:db0dedef4af021d95c23d5',
   }
 };
