import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { forkJoin, Observable, of } from "rxjs";
import { AdminService } from "../services/admin.service";
import { catchError, map } from "rxjs/operators";
import { AuthService } from "../../auth/services/auth.service";
import { EmployeeData, EmployeeRole } from "../../shared/models/employee";

export interface EmployeeResolvedData {
  employees: Array<EmployeeData>;
  roles: Array<EmployeeRole>;
}

@Injectable({
  providedIn: "root",
})
export class AllEmployeesResolver implements Resolve<Observable<any>> {
  constructor(
    private adminService: AdminService,
    private authService: AuthService
  ) {}

  resolve(): Observable<any> {
    return <Observable<any>>forkJoin([
      this.adminService.getAllEmployees(),
      this.authService.getEmployeeRoles(),
    ]).pipe(
      map((results: [Array<EmployeeData>, Array<EmployeeRole>]) => ({
        details: {
          employees: results[0],
          roles: results[1],
        },
      })),
      catchError((error) => {
        return of({ details: null, error: error });
      })
    );
  }
}
