import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { showToasterMessage } from "../../utils/functions";
import { ToastrService } from "ngx-toastr";
import { AdministrationService } from "../../../administration/services/administration.service";

export interface SupplierDetail {
  DateApproved: string | null;
  DateCreated: string | null;
  DateUpdateApproved: string | null;
  DateUpdated: string | null;
  TimeApproved: string | null;
  TimeCreated: string | null;
  TimeUpdateApproved: string | null;
  TimeUpdated: string | null;
  branchId: number;
  branchName: string | null;
  relationshipManagerContact: string | null;
  relationshipManagerEmail: string | null;
  relationshipManagerId: number;
  relationshipManagerName: string | null;
  supplierApprovedBy: string | null;
  supplierCertificateOfRegistration: string | null;
  supplierCreatedBy: string | null;
  supplierEmail: string | null;
  supplierEmailVerify: number;
  supplierEmailVerifyDecoded: string | null;
  supplierEngagementType: number;
  supplierEngagementTypeDecoded: string | null;
  supplierId: number;
  supplierName: string | null;
  supplierPhone1: string | null;
  supplierPhone2: string | null;
  supplierPhotoUrl: string | null;
  supplierStatus: number;
  supplierStatusDecoded: string | null;
  supplierTinNumber: string | null;
  supplierType: number;
  supplierTypeDecoded: string | null;
  supplierUpdatedApprovalBy: string | null;
  supplierUpdatedBy: string | null;
  supplierVatCompliant: number;
  supplierVatCompliantDecoded: string | null;
}

@Component({
  selector: "app-supplier-details",
  templateUrl: "./supplier-details.component.html",
  styleUrls: ["./supplier-details.component.scss"],
})
export class SupplierDetailsComponent implements OnInit {
  @Input() supplierDetail: SupplierDetail;
  @Output() emitEditSupplier = new EventEmitter<any>();
  @Output() emitShippingAddress = new EventEmitter<any>();
  @Output() zoomImage = new EventEmitter<string>();
  imageUrl: string;

  constructor(
    private toaster: ToastrService,
    private administrationService: AdministrationService
  ) {}

  ngOnInit(): void {
    const supplierPhotoUrl = this.supplierDetail.supplierPhotoUrl;
    this.imageUrl = supplierPhotoUrl ? supplierPhotoUrl : "assets/img/man.svg";
  }

  emitEditAction(): void {
    this.emitEditSupplier.emit(this.supplierDetail);
  }

  emitOpenBankingDetailsModal() {}

  emitOpenShippingAddressModal() {
    this.administrationService
      .getShippingAddress(this.supplierDetail.supplierId, "SUPPLIER")
      .subscribe(
        (res) => {
          this.emitShippingAddress.emit(res);
        },
        (err) => {
          showToasterMessage(this.toaster, "warning", err);
        }
      );
  }

  emitOpenContactPersonModal() {}

  emitPhotoUrl() {
    this.zoomImage.emit(this.imageUrl);
  }
}
