<div class="container col-xl-12 mx-0 px-2 px-xl-5 h-100">
<div class="flex w-100 flex-column justify-content-center pb-2">
  <h4 class="text-info text-uppercase text-center my-3">
    company setup
  </h4>
  <form
    (ngSubmit)="createCompany()"
    [formGroup]="companyForm"
    class="col-12 col-xl-8 mx-auto bg-white p-4 mb-4 mb-xl-5"
  >
    <div class="row mb-4">
      <div class="col-12 d-flex flex-column flex-md-row justify-content-center">
        <img
          [src]="logoUrl || noLogo"
          alt="company-logo"
          class="rounded-circle border m-auto m-md-0"
          data-holder-rendered="true"
          height="200"
          width="200"
        />
      </div>
      <div class="col-12 d-flex justify-content-center mt-3">
              <div class="align-self-md-end">
          <input
            (change)="onFileSelected($event)"
            hidden
            id="companyLogo"
            src="image/*"
            type="file"
          />
          <button (click)="handleUploadNewLogo()" class="btn btn-outline-info shadow">
            Upload Logo
          </button>
          <div
            [hidden]="!((fval.companyLogo.touched || fval.companyLogo.dirty) && fval.companyLogo.errors?.required)"
            class="text-danger pt-4 m-auto"
          >
            The logo is required
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group text-secondary">
          <label
            [ngClass]="fval.companyName.valid ? 'text-info' : ''"
            for="companyName"
          >Name<i
            *ngIf="!isEditing && fval.companyName.valid"
            class="material-icons"
          >check_circle</i
          ></label
          >
          <div class="input-block mb-0">
            <i
              class="start fas fa-tag text-secondary mx-auto"
              style="size: 50px"
            >
            </i>
            <input
              [ngClass]="{
                        'is-invalid':
                          (fval.companyName.touched ||
                            fval.companyName.dirty) &&
                          fval.companyName.errors
                      }"
              autocomplete="off"
              class="form-control shadow"
              formControlName="companyName"
              id="companyName"
              placeholder="Enter company name"
              type="text"
            />
          </div>
          <div
            *ngIf="
                      (fval.companyName.touched || fval.companyName.dirty) &&
                      fval.companyName.errors?.required
                    "
            class="text-danger col"
          >
            Company name is required
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group text-secondary">
          <label
            [ngClass]="fval.companyEmail1.valid ? 'text-info' : ''"
            for="companyEmail1"
          >Email<i
            *ngIf="!isEditing && fval.companyEmail1.valid"
            class="material-icons"
          >check_circle</i
          ></label
          >
          <div class="input-block mb-0">
            <i
              class="start fas fa-envelope text-secondary mx-auto"
              style="size: 50px"
            ></i>
            <input
              [ngClass]="{
                        'is-invalid':
                          (fval.companyEmail1.touched ||
                            fval.companyEmail1.dirty) &&
                          fval.companyEmail1.errors
                      }"
              autocomplete="off"
              class="form-control shadow"
              formControlName="companyEmail1"
              id="companyEmail1"
              placeholder="Enter email address"
              type="text"
            />
          </div>
          <div
            *ngIf="
                      (fval.companyEmail1.touched ||
                        fval.companyEmail1.dirty) &&
                      fval.companyEmail1.errors?.required
                    "
            class="text-danger col"
          >
            The email address is required
          </div>
          <div
            *ngIf="
                      (fval.companyEmail1.touched ||
                        fval.companyEmail1.dirty) &&
                      fval.companyEmail1.errors?.pattern
                    "
            class="text-danger col"
          >
            Please enter valid email address
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group text-secondary">
          <label
            [ngClass]="
                      fval.companyPhoneContact1.valid ? 'text-info' : ''
                    "
            for="companyPhoneContact1"
          >Phone contact<i
            *ngIf="!isEditing && fval.companyPhoneContact1.valid"
            class="material-icons"
          >check_circle</i
          ></label
          >
          <div class="input-block mb-0">
            <i
              class="start fas fa-phone text-secondary mx-auto"
              style="size: 50px"
            ></i>
            <input
              [ngClass]="{
                        'is-invalid':
                          (fval.companyPhoneContact1.touched ||
                            fval.companyPhoneContact1.dirty) &&
                          fval.companyPhoneContact1.errors
                      }"
              autocomplete="off"
              class="form-control shadow"
              formControlName="companyPhoneContact1"
              id="companyPhoneContact1"
              placeholder="e.g. 07XXXXXXXX"
              type="text"
            />
          </div>
          <div
            *ngIf="
                      (fval.companyPhoneContact1.touched ||
                        fval.companyPhoneContact1.dirty) &&
                      fval.companyPhoneContact1.errors?.required
                    "
            class="text-danger col"
          >
            Company phone contact is required
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group text-secondary">
          <label
            [ngClass]="
                      fval.companyPhoneContact2.valid ? 'text-info' : ''
                    "
            for="companyPhoneContact2"
          >Alternative Phone Contact<i
            *ngIf="!isEditing && fval.companyPhoneContact2.valid"
            class="material-icons"
          >check_circle</i
          ></label
          >
          <div class="input-block mb-0">
            <i
              class="start fas fa-phone text-secondary mx-auto"
              style="size: 50px"
            ></i>
            <input
              [ngClass]="{
                        'is-invalid':
                          (fval.companyPhoneContact2.touched ||
                            fval.companyPhoneContact2.dirty) &&
                          fval.companyPhoneContact2.errors
                      }"
              autocomplete="off"
              class="form-control shadow"
              formControlName="companyPhoneContact2"
              id="companyPhoneContact2"
              placeholder="e.g. 07XXXXXXXX (optional)"
              type="text"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group text-secondary">
          <label
            [ngClass]="fval.companyOfficeFloor.valid ? 'text-info' : ''"
            for="companyOfficeFloor"
          >Office floor<i
            *ngIf="!isEditing && fval.companyOfficeFloor.valid"
            class="material-icons"
          >check_circle</i
          ></label
          >
          <div class="input-block mb-0">
            <input
              [ngClass]="{
                        'is-invalid':
                          (fval.companyOfficeFloor.touched ||
                            fval.companyOfficeFloor.dirty) &&
                          fval.companyOfficeFloor.errors
                      }"
              autocomplete="off"
              class="form-control shadow"
              formControlName="companyOfficeFloor"
              id="companyOfficeFloor"
              placeholder="Enter office floor"
              type="text"
            />
          </div>
          <div
            *ngIf="
                      (fval.companyOfficeFloor.touched ||
                        fval.companyOfficeFloor.dirty) &&
                      fval.companyOfficeFloor.errors?.required
                    "
            class="text-danger col"
          >
            The company office floor is required
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group text-secondary">
          <label
            [ngClass]="
                      fval.companyStreetBuilding.valid ? 'text-info' : ''
                    "
            for="companyStreetBuilding"
          >Street Building<i
            *ngIf="!isEditing && fval.companyStreetBuilding.valid"
            class="material-icons"
          >check_circle</i
          ></label
          >
          <div class="input-block mb-0">
            <i
              class="start fas fa-building text-secondary mx-auto"
              style="size: 50px"
            >
            </i>
            <input
              [ngClass]="{
                        'is-invalid':
                          (fval.companyStreetBuilding.touched ||
                            fval.companyStreetBuilding.dirty) &&
                          fval.companyStreetBuilding.errors
                      }"
              autocomplete="off"
              class="form-control shadow"
              formControlName="companyStreetBuilding"
              id="companyStreetBuilding"
              placeholder="Enter building name"
              type="text"
            />
          </div>
          <div
            *ngIf="
                      (fval.companyStreetBuilding.touched ||
                        fval.companyStreetBuilding.dirty) &&
                      fval.companyStreetBuilding.errors?.required
                    "
            class="text-danger col"
          >
            The building name is required
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group text-secondary">
          <label
            [ngClass]="fval.companyPlotNumber.valid ? 'text-info' : ''"
            for="companyPlotNumber"
          >Plot number<i
            *ngIf="!isEditing && fval.companyPlotNumber.valid"
            class="material-icons"
          >check_circle</i
          ></label
          >
          <div class="input-block mb-0">
            <i
              class="start fas fa-location-arrow text-secondary mx-auto"
              style="size: 50px"
            >
            </i>
            <input
              [ngClass]="{
                        'is-invalid':
                          (fval.companyPlotNumber.touched ||
                            fval.companyPlotNumber.dirty) &&
                          fval.companyPlotNumber.errors
                      }"
              autocomplete="off"
              class="form-control shadow"
              formControlName="companyPlotNumber"
              id="companyPlotNumber"
              placeholder="Enter plot number"
              type="text"
            />
          </div>
          <div
            *ngIf="
                      (fval.companyPlotNumber.touched ||
                        fval.companyPlotNumber.dirty) &&
                      fval.companyPlotNumber.errors?.required
                    "
            class="text-danger col"
          >
            The plot number is required
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group text-secondary">
          <label
            [ngClass]="fval.companyStreetName.valid ? 'text-info' : ''"
            for="companyStreetName"
          >Street name<i
            *ngIf="!isEditing && fval.companyStreetName.valid"
            class="material-icons"
          >check_circle</i
          ></label
          >
          <div class="input-block mb-0">
            <i
              class="start fas fa-location-arrow text-secondary mx-auto"
              style="size: 50px"
            ></i>
            <input
              [ngClass]="{
                        'is-invalid':
                          (fval.companyStreetName.touched ||
                            fval.companyStreetName.dirty) &&
                          fval.companyStreetName.errors
                      }"
              autocomplete="off"
              class="form-control shadow"
              formControlName="companyStreetName"
              id="companyStreetName"
              placeholder="Enter street name"
              type="text"
            />
          </div>
          <div
            *ngIf="
                      (fval.companyStreetName.touched ||
                        fval.companyStreetName.dirty) &&
                      fval.companyStreetName.errors?.required
                    "
            class="text-danger col"
          >
            The street name is required
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group text-secondary">
          <label
            [ngClass]="fval.companyBoxNumber.valid ? 'text-info' : ''"
            for="companyBoxNumber"
          >Box Number<i
            *ngIf="!isEditing && fval.companyBoxNumber.valid"
            class="material-icons"
          >check_circle</i
          ></label
          >
          <div class="input-block mb-0">
            <i
              class="start fas fa-envelope text-secondary mx-auto"
              style="size: 50px"
            ></i>
            <input
              [ngClass]="{
                        'is-invalid':
                          (fval.companyBoxNumber.touched ||
                            fval.companyBoxNumber.dirty) &&
                          fval.companyBoxNumber.errors
                      }"
              autocomplete="off"
              class="form-control shadow"
              formControlName="companyBoxNumber"
              id="companyBoxNumber"
              placeholder="Enter Post Box number"
              type="text"
            />
          </div>
          <div
            *ngIf="
                      (fval.companyBoxNumber.touched ||
                        fval.companyBoxNumber.dirty) &&
                      fval.companyBoxNumber.errors?.required
                    "
            class="text-danger col"
          >
            The company post box number is required
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group text-secondary">
          <label
            [ngClass]="
                      fval.companyCityLocation.valid ? 'text-info' : ''
                    "
            for="companyCityLocation"
          >City<i
            *ngIf="!isEditing && fval.companyCityLocation.valid"
            class="material-icons"
          >check_circle</i
          ></label
          >
          <div class="input-block mb-0">
            <i
              class="start fas fa-map-marker-alt text-secondary mx-auto"
              style="size: 50px"
            >
            </i>
            <input
              [ngClass]="{
                        'is-invalid':
                          (fval.companyCityLocation.touched ||
                            fval.companyCityLocation.dirty) &&
                          fval.companyCityLocation.errors
                      }"
              autocomplete="off"
              class="form-control shadow"
              formControlName="companyCityLocation"
              id="companyCityLocation"
              placeholder="Enter city name"
              type="text"
            >
            <datalist>
              <option>
                <!-- {{branch.branchName}} -->
              </option>
            </datalist>
          </div>
          <div
            *ngIf="
                      (fval.companyCityLocation.touched ||
                        fval.companyCityLocation.dirty) &&
                      fval.companyCityLocation.errors?.required
                    "
            class="text-danger col"
          >
            The city name is required
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group text-secondary">
          <label
            [ngClass]="
                      fval.companyRegionLocation.valid ? 'text-info' : ''
                    "
            for="companyRegionLocation"
          >Region<i
            *ngIf="!isEditing && fval.companyRegionLocation.valid"
            class="material-icons"
          >check_circle</i
          ></label
          >
          <div class="input-block mb-0">
            <i
              class="start fas fa-map-marker-alt text-secondary mx-auto"
              style="size: 50px"
            >
            </i>
            <input
              [ngClass]="{
                        'is-invalid':
                          (fval.companyRegionLocation.touched ||
                            fval.companyRegionLocation.dirty) &&
                          fval.companyRegionLocation.errors
                      }"
              autocomplete="off"
              class="form-control shadow"
              formControlName="companyRegionLocation"
              id="companyRegionLocation"
              placeholder="Enter region name"
              type="text"
            >
            <datalist>
              <option>
                <!-- {{branch.branchName}} -->
              </option>
            </datalist>
          </div>
          <div
            *ngIf="
                      (fval.companyRegionLocation.touched ||
                        fval.companyRegionLocation.dirty) &&
                      fval.companyRegionLocation.errors?.required
                    "
            class="text-danger col"
          >
            The region name is required
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group text-secondary">
          <label
            [ngClass]="
                      fval.companyCountryLocation.valid ? 'text-info' : ''
                    "
            for="companyCountryLocation"
          >Country<i
            *ngIf="!isEditing && fval.companyCountryLocation.valid"
            class="material-icons"
          >check_circle</i
          ></label
          >
          <div class="input-block mb-0">
            <i
              class="start fas fa-map-marker text-secondary mx-auto"
              style="size: 50px"
            >
            </i>
            <input
              [ngClass]="{
                        'is-invalid':
                          (fval.companyCountryLocation.touched ||
                            fval.companyCountryLocation.dirty) &&
                          fval.companyCountryLocation.errors
                      }"
              autocomplete="off"
              class="form-control shadow"
              formControlName="companyCountryLocation"
              id="companyCountryLocation"
              placeholder="Enter country name"
              type="text"
            >
            <datalist>
              <option>
                <!-- {{branch.branchName}} -->
              </option>
            </datalist>
          </div>
          <div
            *ngIf="
                      (fval.companyCountryLocation.touched ||
                        fval.companyCountryLocation.dirty) &&
                      fval.companyCountryLocation.errors?.required
                    "
            class="text-danger col"
          >
            The country name is required
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex my-1 pt-1 pb-2 mx-4 px-md-5">
      <button
        [disabled]="!companyForm.valid || companyForm.pristine || loading"
        class="btn btn-primary mx-auto px-4 px-md-5 shadow"
        type="submit"
      ><i class="fas fa-save" style="size: 50px"></i>
        Save
      </button>
      <button
        class="btn btn-danger mx-auto px-3 px-md-5 shadow"
        routerLink="/admin/dashboard"
        type="button"
      >
        <i class="fas fa-times" style="size: 50px"></i>
        Cancel
      </button>
    </div>
  </form>
</div>
</div>
