import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-design-implementation",
  templateUrl: "./design-implementation.component.html",
  styleUrls: ["./design-implementation.component.sass"],
})
export class DesignImplementationComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
