import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-statement",
  templateUrl: "./statement.component.html",
  styleUrls: ["./statement.component.sass"],
})
export class StatementComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
