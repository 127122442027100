<div
  class="container col-xl-12 mx-0 px-0 mx-sm-auto mx-md-0 px-md-2 px-xl-5 h-100"
>
  <div class="d-flex flex-column">
    <h4 class="text-info text-uppercase text-center my-3">approvals setup</h4>
    <div class="big-card bg-white mb-4 mx-2 py-3 pb-md-0">
      <div class="d-flex flex-column flex-md-row justify-content-between">
        <div class="row px-3 px-md-4">
          <div class="col-12 my-2">
            <input
              (input)="getValue($event)"
              class="form-control shadow px-2 px-md-3"
              placeholder="Search"
              style="width: inherit"
              type="text"
            />
          </div>
        </div>
          <div
            class="d-flex flex-row justify-content-center justify-content-md-end px-md-5"
          >
            <mat-paginator
              #paginator
              (page)="getPaginatorData($event)"
              [length]="approvals?.length"
              [pageSizeOptions]="pageSizeOptions()"
              [pageSize]="pageSize"
              goToFirstPage="true"
              showFirstLastButtons="false"
            >
            </mat-paginator>
          </div>
        </div>
            <div class="mx-2 mx-md-0 mx-xl-4 pb-0 py-md-2">
            <form [formGroup]="approvalForm" class="">
              <div class="mt-1 mb-2" formArrayName="approvalItems">
                  <div class="d-flex flex-row justify-content-around justify-content-md-center px-md-4">
                    <h4 class="col-5 col-md-6 px-md-5">Item Name</h4>
                    <h4 class="col-4 px-md-4">Approval Status</h4>
                    <h4 class="col-3 col-md-2 pl-3 pr-0 px-md-3">Action</h4>
                  </div>
        <div class="records my-1 py-0 mx-0 px-0 px-md-2">
                <div
                  *ngFor="
                    let items of fval.approvalItems['controls']
                      | slice: lowValue:highValue;
                    let idx = index
                  "class="my-0 py-0">
                  <div
                    [formGroupName]="idx"
                    class="card my-2 py-2 mx-0 mx-md-2 px-2 shadow"
                  >
            <div class="card-body my-0 py-0 pl-3 pr-1 px-md-4">
                <div
                    class="record-1 d-flex row flex-row justify-content-around px-0"
                      >
                        <div class="col-5 col-md-6 py-md-2">
                        <span class="">
                          {{
                            fval.approvalItems["controls"][idx]["controls"]
                              .itemName.value
                          }}
                        </span>
</div>
                        <div class="col-4 mx-0 px-0 px-md-2">
                        <select
                          [ngClass]="{
                            'is-invalid':
                              (fval.approvalItems['controls'][idx]['controls']
                                .approvalStatus.touched ||
                                fval.approvalItems['controls'][idx]['controls']
                                  .approvalStatus.dirty) &&
                              fval.approvalItems['controls'][idx]['controls']
                                .approvalStatus.errors
                          }"
                          autocomplete="off"
                          class="form-control px-2"
                          formControlName="approvalStatus"
                          id="approvalStatus"
                          required
                          type="number"
                        >
                          <option
                            class="place-holder-select"
                            disabled
                            hidden
                            selected
                            value=""
                          >
                            Select approval status
                          </option>
                          <option value="0">NO APPROVAL</option>
                          <option value="1">TO BE APPROVED</option>
                        </select>
</div>
                        <div class="col-3 col-md-2 px-md-4">
                        <button
                          (click)="saveStatus(idx)"
                          class="btn btn-outline-info shadow mx-1 mx-md-0"
                        >
                          <i class="fas fa-save"></i>
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
</div>
            </div>
            </form>
          </div>
        </div>
      </div>
    </div>
