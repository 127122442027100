import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
} from "@angular/core";
import { EmployeeData } from "../../models/employee";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-employee-details",
  templateUrl: "./employee-details.component.html",
  styleUrls: ["./employee-details.component.scss"],
})
export class EmployeeDetailsComponent implements OnInit {
  @Input() employeeDetail: EmployeeData;
  @Input() btnValue1: string;
  @Input() btnValue2: string;
  @Input() showButtons: boolean = false;
  @Input() isModal: boolean = false;
  @Output() emitBtn1 = new EventEmitter<EmployeeData>();
  @Output() emitBtn2 = new EventEmitter<EmployeeData>();
  @Output() zoomImage = new EventEmitter<string>();
  imageUrl: string;
  employee: EmployeeData;

  constructor(
    @Optional() public dialogRef: MatDialogRef<EmployeeDetailsComponent>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: { isModal: boolean; employee: EmployeeData }
  ) {}

  ngOnInit(): void {
    this.employee =
      this.data?.isModal || this.isModal
        ? this.data.employee
        : this.employeeDetail;
  }

  emitButton1() {
    this.emitBtn1.emit(this.employee);
  }

  emitButton2() {
    this.emitBtn2.emit(this.employee);
  }
}
