<div class="d-flex flex-column">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div
          class="tickets-card2 card shadow-lg mt-2 mb-0 pt-2 pb-1 px-4 px-md-5"
        >
          <div class="row d-flex justify-content-center">
            <div class="col-12 pt-0">
              <h4 class="text-info text-uppercase font-weight-bold pull-right">
                <hr class="top mt-1 mb-0" />
                rfq preparation
                <hr class="bottom my-0" />
              </h4>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="rfqs-card card shadow my-2">
          <div class="card-header d-flex justify-content-between pt-1 pb-2">
            <h5 class="text-info font-weight-bold m-0">
              <hr class="top mt-1 mb-0" />
              CREATE RFQ
              <hr class="bottom my-0" />
            </h5>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <!-- Main content -->
                <div class="card">
                  <div class="card-body pt-2 mx-0 px-0">
                    <form [formGroup]="rfqForm">
                      <div class="row mx-2">
                        <div class="col-12 col-md-4">
                          <div class="form-group">
                            <label
                              for="clientName"
                              [ngClass]="
                                fval.clientName.valid ? 'text-success' : ''
                              "
                              >{{ clientLabel
                              }}<i
                                *ngIf="fval.clientName.valid"
                                class="material-icons"
                                >check_circle</i
                              >
                            </label>

                            <button
                              type="button"
                              class="btn btn-info form-control"
                              (click)="openModal(template)"
                              *ngIf="!clientAdded"
                            >
                              <i class="fas fa-user-plus"></i>&nbsp; Select
                              Client
                            </button>

                            <input
                              *ngIf="clientAdded"
                              class="form-control"
                              formControlName="clientName"
                              [value]="fval.clientName.value"
                            />

                            <div
                              class="text-danger col"
                              *ngIf="
                                (fval.clientName.touched ||
                                  fval.clientName.dirty) &&
                                fval.clientName.errors?.required
                              "
                            >
                              Client is required
                            </div>
                          </div>
                        </div>

                        <div class="rfq-source col-12 col-md-5 col-lg-4">
                          <div class="form-group">
                            <label
                              for="rfqSource"
                              [ngClass]="
                                fval.rfqSource.valid ? 'text-success' : ''
                              "
                              >RFQ source<i
                                *ngIf="fval.rfqSource.valid"
                                class="material-icons"
                                >check_circle</i
                              ></label
                            >
                            <select
                              class="form-control"
                              formControlName="rfqSource"
                              placeholder="Select RFQ source"
                              (change)="setSelectedChanges($event)"
                            >
                              <option selected>Select RFQ source</option>
                              <option *ngFor="let rfq_source of rfq_sources">
                                {{ rfq_source.rfqSource }}
                              </option>
                            </select>
                            <div
                              class="text-danger col"
                              *ngIf="
                                (fval.rfqSource.touched ||
                                  fval.rfqSource.dirty) &&
                                fval.rfqSource.errors?.required
                              "
                            >
                              RFQ source is required
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 mx-auto">
                        <button
                          type="submit"
                          class="btn btn-primary col-5 col-md-2 mx-1"
                          (click)="createRFQ(rfqForm)"
                          [disabled]="!rfqForm.valid"
                        >
                          <!-- <ngx-spinner
                                  class="bg-warning bg-success"
                                  size="medium"
                                  type="line-scale-party"
                                >
                                  <p class="text-light">
                                    Please Wait...
                                  </p> </ngx-spinner
                                > -->
                          <i class="fas fa-plus"></i>
                          Create RFQ
                        </button>
                        <button
                          class="btn btn-danger col-5 col-md-2 mx-1"
                          (click)="resetForm()"
                        >
                          <i class="fas fa-times"></i> Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="rfqs card shadow mb-4 mx-md-0 px-md-0">
          <div class="card-body pt-2 mx-auto mx-md-1 px-1">
            <tabset>
              <tab heading="RFQ Listing" class="rfq-listing text-info">
                <div class="pt-3 px-2">
                  <input
                    type="text"
                    class="form-control col-md-7 col-xl-4"
                    [(ngModel)]="userFilter.clientName"
                    placeholder="Enter Client Name"
                  />

                  <!--record container-->
                  <!--to be displayed if no rfqs to work on-->
                  <!-- <div
                      class="invest-card card my-2 py-2 mx-auto px-2 shadow-lg"
                    >
                      <div class="col-12 my-5 py-5 justify-content-between">
                        <h4 class="text-center text-info">
                          No Received Loans yet
                        </h4>
                      </div>
                    </div> -->
                  <div
                    class="invest-card card mt-1 py-2 mb-0 mx-auto px-2 shadow-lg"
                  >
                    <div class="iteration">
                      <div class="item-container-1 mt-1">
                        <div
                          class="list-card card mt-1 pt-2 mb-0 mx-auto px-0 pb-1 shadow-lg"
                          *ngFor="
                            let summu of rfqSummury$
                              | async
                              | filterBy: userFilter;
                            let i = index;
                            let odd = odd;
                            let first = first;
                            let last = last
                          "
                          [class.text-secondary]="odd"
                          [class.text-success]="!odd"
                        >
                          <div class="list-card-body card-body my-0 py-0 mx-0">
                            <div
                              class="row my-0 pt-md-0 pb-md-1 mx-md-0 px-md-0"
                            >
                              <div
                                class="col-1 pl-1 pr-0 mx-md-0 px-md-0 d-flex justify-content-center"
                              >
                                <div class="dropdown">
                                  <button
                                    class="btn btn-outline-primary dropdown-toggle"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    tooltip="Options"
                                  >
                                    <i
                                      class="fas fa-ellipsis-v fa-2x text-info"
                                    ></i>
                                  </button>
                                  <div
                                    class="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <a
                                      class="dropdown-item text-primo pointerCursor"
                                      (click)="openModal(newItem)"
                                      ><i class="fas fa-plus"></i>
                                      Add Items
                                    </a>
                                    <a
                                      class="dropdown-item text-primo pointerCursor"
                                      (click)="openModal(rfqItems)"
                                      ><i class="fas fa-eye"></i>
                                      View RFQ
                                    </a>
                                    <!-- <a
                                  class="dropdown-item text-primo-1 pointerCursor"
                                  ><i class="fas fa-chevron-right"></i>
                                  Approve
                                </a> -->
                                  </div>
                                </div>
                              </div>
                              <div class="col-11 mx-lg-0 px-lg-0">
                                <div class="row mx-md-0 px-md-0">
                                  <div
                                    class="col-12 col-md-6 col-xl-3 mx-md-0 px-md-0"
                                  >
                                    <div class="row">
                                      <div
                                        class="col-5 col-md-4 col-lg-3 col-xl-4 d-flex justify-content-start mx-md-0 px-md-0"
                                      >
                                        <span class="">Serial No.:</span>
                                      </div>
                                      <div
                                        class="col-7 col-md-8 col-lg-9 col-xl-8 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                      >
                                        <span class="itemCreated"
                                          >{{ summu.rfqSerialNumber }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-12 col-md-6 col-xl-5 mx-md-0 px-md-0"
                                  >
                                    <div class="row">
                                      <div
                                        class="col-4 col-md-3 col-xl-2 d-flex justify-content-start mx-md-0 px-md-0"
                                      >
                                        <span class="">Client:</span>
                                      </div>
                                      <div
                                        class="col-8 col-md-9 col-xl-10 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                      >
                                        <span class="itemCreated"
                                          >{{ summu.clientColRef?.clientName }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0"
                                  >
                                    <div class="row">
                                      <div
                                        class="col-5 col-md-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                                      >
                                        <span class="">Source:</span>
                                      </div>
                                      <div
                                        class="col-7 col-md-8 col-lg-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                      >
                                        <span class="itemCreated"
                                          >{{ summu.rfqSource }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0"
                                  >
                                    <div class="row">
                                      <div
                                        class="col-6 col-md-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                                      >
                                        <span class="">No. Of Items:</span>
                                      </div>
                                      <div
                                        class="col-6 col-md-8 col-lg-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                      >
                                        <span class="itemCreated"
                                          >{{ summu.noOfItems }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0"
                                  >
                                    <div class="row">
                                      <div
                                        class="col-6 col-md-4 col-lg-3 col-xl-4 d-flex justify-content-start mx-md-0 px-md-0"
                                      >
                                        <span class="">Total Amount:</span>
                                      </div>
                                      <div
                                        class="col-6 col-md-8 col-lg-9 col-xl-8 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                      >
                                        <span class="itemCreated"
                                          >{{ summu.totalAmount }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0"
                                  >
                                    <div class="row">
                                      <div
                                        class="col-6 col-md-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                                      >
                                        <span class="">Department:</span>
                                      </div>
                                      <div
                                        class="col-6 col-md-8 col-lg-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                      >
                                        <span class="itemCreated"
                                          >{{ summu.empColRef?.department }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0"
                                  >
                                    <div class="row">
                                      <div
                                        class="col-5 col-md-4 col-lg-3 col-xl-4 d-flex justify-content-start mx-md-0 px-md-0"
                                      >
                                        <span class="">Username:</span>
                                      </div>
                                      <div
                                        class="col-7 col-md-8 col-lg-9 col-xl-8 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                      >
                                        <span class="itemCreated">
                                          {{ summu.empColRef?.userName }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--/ end of record container-->
                </div>
              </tab>
              <tab heading="RFQ Status" class="rfq-status text-info">
                <div class="pt-3 px-2">
                  <!-- <input
                    type="text"
                    class="form-control col-md-7 col-xl-4"
                    [(ngModel)]="userFilter.clientName"
                    placeholder="Enter Client Name"
                  />
 -->
                  <!--record container-->
                  <!--to be displayed if no rfqs to work on-->
                  <!-- <div
                      class="invest-card card my-2 py-2 mx-auto px-2 shadow-lg"
                    >
                      <div class="col-12 my-5 py-5 justify-content-between">
                        <h4 class="text-center text-info">
                          No Received Loans yet
                        </h4>
                      </div>
                    </div> -->
                  <div
                    class="invest-card card mt-1 py-2 mb-2 mx-auto px-2 shadow-lg"
                  >
                    <div class="iteration">
                      <div class="item-container-1 mt-1">
                        <div
                          class="list-card card mt-1 pt-2 mb-0 mx-auto px-0 pb-1 shadow-lg"
                          *ngFor="
                            let summu of rfqSummury$
                              | async
                              | filterBy: userFilter;
                            let i = index;
                            let odd = odd;
                            let first = first;
                            let last = last
                          "
                          [class.text-secondary]="odd"
                          [class.text-success]="!odd"
                        >
                          <div class="list-card-body card-body my-0 py-0 mx-0">
                            <div
                              class="row my-0 pt-md-0 pb-md-1 mx-md-0 px-md-0"
                            >
                              <div class="col-12 mx-lg-0 px-lg-0">
                                <div class="row mx-md-0 px-md-0">
                                  <div
                                    class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0"
                                  >
                                    <div class="row">
                                      <div
                                        class="col-6 col-md-5 col-lg-3 col-xl-4 d-flex justify-content-start mx-md-0 px-md-0"
                                      >
                                        <span class="">Serial No.:</span>
                                      </div>
                                      <div
                                        class="col-6 col-md-7 col-lg-9 col-xl-8 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                      >
                                        <span class="itemCreated"
                                          >{{ summu.rfqSerialNumber }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0"
                                  >
                                    <div class="row">
                                      <div
                                        class="col-6 col-md-5 col-lg-3 col-xl-4 d-flex justify-content-start mx-md-0 px-md-0"
                                      >
                                        <span class="">Mov't Status:</span>
                                      </div>
                                      <div
                                        class="col-6 col-md-7 col-lg-9 col-xl-8 d-flex justify-content-start font-weight-bold mx-0 px-0 mb-xl-1"
                                      >
                                        <span class="badge badge-info">
                                          {{ summu.movementStatus }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0"
                                  >
                                    <div class="row">
                                      <div
                                        class="col-6 col-md-5 col-lg-3 col-xl-4 d-flex justify-content-start mx-md-0 px-md-0"
                                      >
                                        <span class="">Mov't Date:</span>
                                      </div>
                                      <div
                                        class="col-6 col-md-7 col-lg-9 col-xl-8 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                      >
                                        <span class="itemCreated"
                                          >{{ summu.movementTimeStamp }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0"
                                  >
                                    <div class="row">
                                      <div
                                        class="col-6 col-md-5 col-lg-3 col-xl-4 d-flex justify-content-start mx-md-0 px-md-0"
                                      >
                                        <span class="">Mov't Time:</span>
                                      </div>
                                      <div
                                        class="col-6 col-md-7 col-lg-9 col-xl-8 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                      >
                                        <span class="itemCreated"
                                          >{{ summu.movementTimeStamp }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0"
                                  >
                                    <div class="row">
                                      <div
                                        class="col-7 col-md-5 col-lg-3 col-xl-4 d-flex justify-content-start mx-md-0 px-md-0"
                                      >
                                        <span class="">Processing Status:</span>
                                      </div>
                                      <div
                                        class="col-5 col-md-7 col-lg-9 col-xl-8 d-flex justify-content-start font-weight-bold mx-0 px-0 mt-xl-1"
                                      >
                                        <span class="badge badge-danger"
                                          >{{ summu.processingStatus }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0"
                                  >
                                    <div class="row">
                                      <div
                                        class="col-6 col-md-5 col-lg-3 col-xl-4 d-flex justify-content-start mx-md-0 px-md-0"
                                      >
                                        <span class="">Processing Date:</span>
                                      </div>
                                      <div
                                        class="col-6 col-md-7 col-lg-9 col-xl-8 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                      >
                                        <span class="itemCreated"
                                          >{{ summu.processingDate }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0"
                                  >
                                    <div class="row">
                                      <div
                                        class="col-6 col-md-5 col-lg-3 col-xl-4 d-flex justify-content-start mx-md-0 px-md-0"
                                      >
                                        <span class="">Processing Time:</span>
                                      </div>
                                      <div
                                        class="col-6 col-md-7 col-lg-9 col-xl-8 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                      >
                                        <span class="itemCreated">
                                          {{ summu.processingTime }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--/ end of record container-->
                </div>
              </tab>
            </tabset>
          </div>
          <!-- </div> -->
          <!-- /.card-body -->
        </div>
      </div>
      <!-- </div> -->

      <ng-template #template>
        <div class="modal-header bg-info text-white close">
          <h4 class="modal-title">List Of Clients</h4>
          <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="modalRef.hide()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <input
              type="text"
              class="form-control col-md-5"
              [(ngModel)]="userFilter.clientName"
              placeholder="Enter Client Phone Number"
            />
          </div>
          <div class="table-responsive my-2">
            <!--record container-->
            <div class="invest-card card mt-1 py-2 mb-2 mx-auto px-2 shadow-lg">
              <div class="item-container-1 mt-1">
                <div
                  class="list-card-1 card mt-1 pt-2 mb-0 mx-auto px-0 pb-1 shadow-lg"
                  *ngFor="let client of clients$ | async | filterBy: userFilter"
                >
                  <div class="list-card-body card-body my-0 py-0 mx-0">
                    <div class="row my-0 py-0">
                      <div
                        class="col-2 col-md-1 mx-md-0 px-md-0 d-flex justify-content-center"
                      >
                        <img
                          class="img-circle mx-auto mt-2 mt-md-0 mt-xl-1 mb-1 pb-0"
                          (click)="openModal(template)"
                          src="../../../../../assets/img/man.svg"
                        />
                      </div>
                      <div class="col-10 col-md-11 mx-lg-0 px-lg-0">
                        <div class="row mx-md-0 px-md-0">
                          <div class="col-12 col-md-6 col-xl-5 mx-xl-0 px-xl-0">
                            <div class="row">
                              <div
                                class="col-2 d-flex justify-content-start mx-0 px-0"
                              >
                                <span class="">Name:</span>
                              </div>
                              <div
                                class="col-10 d-flex justify-content-start font-weight-bold"
                              >
                                <span class="itemCreated">{{
                                  client.clientName
                                }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-md-4 col-xl-3 mx-md-0 px-md-0">
                            <div class="row">
                              <div
                                class="col-4 col-xl-3 d-flex justify-content-start mx-0 px-0"
                              >
                                <span class="">Phone No.:</span>
                              </div>
                              <div
                                class="col-8 col-xl-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                              >
                                <span class="itemCreated">
                                  {{ client.phoneNumber }}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-md-6 col-xl-4 mx-xl-0 px-xl-0">
                            <div class="row">
                              <div
                                class="col-2 d-flex justify-content-start mx-0 px-0"
                              >
                                <span class="">Email:</span>
                              </div>
                              <div
                                class="col-10 d-flex justify-content-start font-weight-bold"
                              >
                                <span class="itemCreated"
                                  >{{ client.email }}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-md-4 col-xl-8 mx-md-0 px-md-0">
                            <div class="row">
                              <div
                                class="col-6 col-md-6 col-lg-4 col-xl-2 d-flex justify-content-start mx-0 px-0"
                              >
                                <span class="">Approval Status:</span>
                              </div>
                              <div
                                class="col-6 col-md-6 col-lg-8 col-xl-10 d-flex justify-content-start font-weight-bold mx-0 px-0"
                              >
                                <span class="itemCreated"
                                  >{{ client.approvalStatus }}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-md-2">
                            <div class="row">
                              <div class="col d-flex justify-content-end">
                                <button
                                  class="btn btn-info mt-1"
                                  tooltip="Add Customer"
                                  (click)="modalRef.hide()"
                                  (click)="
                                    updateName(client.clientName, client.id)
                                  "
                                >
                                  <i class="fas fa-plus"></i> Add
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--/ end of record container-->
          </div>
        </div>
      </ng-template>
      <ng-template #newItem>
        <div class="modal-header bg-info text-white close">
          <h4 class="modal-title">Add RFQ Items</h4>
          <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="modalRef.hide()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <!--record container-->
          <div
            class="invest-card card mt-1 py-3 mb-2 mx-auto px-2 px-lg-4 shadow-lg"
          >
            <div class="row d-flex justify-content-center px-md-3 pb-md-2">
              <div class="col-12 col-md-5">
                <div class="row">
                  <div
                    class="col-5 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                  >
                    <span class="">Serial No.:</span>
                  </div>
                  <div
                    class="col-7 col-lg-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                  >
                    <span class="itemCreated"></span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-7">
                <div class="row">
                  <div
                    class="col-4 col-xl-3 d-flex justify-content-start mx-md-0 px-md-0"
                  >
                    <span class="">Client:</span>
                  </div>
                  <div
                    class="col-8 col-xl-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                  >
                    <span class="itemCreated"> </span>
                  </div>
                </div>
              </div>
            </div>
            <fieldset
              name="item"
              class="border border-secondary px-4 pt-0 pb-3 mb-1"
            >
              <legend class="text-info">Add Item</legend>

              <form [formGroup]="rfqForm">
                <div class="row">
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="form-group text-primary">
                      <label for="rfq_number">Select Item</label>
                      <select
                        class="form-control"
                        formControlName="itemName"
                        placeholder="Select Item"
                      >
                        <option selected>Select an item to add</option>
                        <option>books</option>
                        <option>bags</option>
                        <option>mugs</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="form-group text-primary">
                      <label for="clientName">Unit</label>
                      <input
                        class="form-control"
                        formControlName="clientName"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="form-group text-primary">
                      <label for="clientName">Unit Cost</label>
                      <input
                        class="form-control"
                        formControlName="clientName"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="form-group text-primary">
                      <label for="clientName">Quantity</label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="total_cost"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="form-group text-primary">
                      <label for="clientName">Total Cost</label>
                      <input
                        class="form-control"
                        formControlName="clientName"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div
                      class="d-flex justify-content-start mt-0 pt-1 mx-auto pull-left"
                    >
                      <button type="button" class="btn btn-info mx-auto">
                        <i class="fas fa-plus"></i> Add
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </fieldset>
            <div class="col-12">
              <div class="d-flex justify-content-center mt-2 pt-1 mx-auto">
                <button
                  type="button"
                  class="btn btn-primary col-5 col-md-3 col-xl-2 mx-auto"
                >
                  <i class="fas fa-save"></i> Save
                </button>
                <button
                  type="button"
                  class="btn btn-danger col-5 col-md-3 col-xl-2 mx-auto"
                >
                  <i class="fas fa-times"></i>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <!--/ end of record container-->
      </ng-template>
      <ng-template #rfqItems>
        <div class="modal-header bg-info text-white close">
          <h4 class="modal-title">RFQ Details</h4>
          <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="modalRef.hide()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <!-- <div class="form-group">
            <input
              type="text"
              class="form-control col-md-5"
              [(ngModel)]="userFilter.clientName"
              placeholder="Enter Client Phone Number"
            />
          </div> -->
          <div class="table-responsive my-2">
            <!--record container-->
            <div class="invest-card card mt-1 py-2 mb-2 mx-auto px-2 shadow-lg">
              <div class="item-container-1 mt-1">
                <div
                  class="list-card-1 card mt-1 pt-2 mb-0 mx-auto px-0 pb-1 shadow-lg"
                >
                  <div class="list-card-body card-body my-0 py-0">
                    <div class="row my-0 py-0">
                      <div class="col-12">
                        <div class="row mx-md-0">
                          <div class="col-12 col-md-6 col-xl-4 mx-xl-0 px-xl-0">
                            <div class="row">
                              <div
                                class="col-3 col-lg-2 d-flex justify-content-start mx-0 px-0"
                              >
                                <span class="">Item ID:</span>
                              </div>
                              <div
                                class="col-9 col-lg-10 d-flex justify-content-start font-weight-bold mx-md-0 px-md-0"
                              >
                                <span class="itemCreated"></span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0">
                            <div class="row">
                              <div
                                class="col-3 col-xl-2 d-flex justify-content-start mx-0 px-0"
                              >
                                <span class="">Item:</span>
                              </div>
                              <div
                                class="col-9 col-xl-10 d-flex justify-content-start font-weight-bold mx-0 px-0"
                              >
                                <span class="itemCreated"> </span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-md-6 col-xl-4 mx-xl-0 px-xl-0">
                            <div class="row">
                              <div
                                class="col-3 col-lg-2 d-flex justify-content-start mx-0 px-0"
                              >
                                <span class="">Unit:</span>
                              </div>
                              <div
                                class="col-9 col-lg-10 d-flex justify-content-start font-weight-bold mx-md-0 px-md-0"
                              >
                                <span class="itemCreated"> </span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0">
                            <div class="row">
                              <div
                                class="col-4 col-lg-3 col-xl-2 d-flex justify-content-start mx-0 px-0"
                              >
                                <span class="">Unit Cost:</span>
                              </div>
                              <div
                                class="col-8 col-lg-9 col-xl-10 d-flex justify-content-start font-weight-bold mx-0 px-0"
                              >
                                <span class="itemCreated"> </span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-md-6 col-xl-4 mx-xl-0 px-xl-0">
                            <div class="row">
                              <div
                                class="col-4 col-lg-4 col-xl-2 d-flex justify-content-start mx-0 px-0"
                              >
                                <span class="">Quantity:</span>
                              </div>
                              <div
                                class="col-8 col-lg-8 col-xl-10 d-flex justify-content-start font-weight-bold mx-0 px-0"
                              >
                                <span class="itemCreated"> </span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0">
                            <div class="row">
                              <div
                                class="col-4 col-lg-4 col-xl-2 d-flex justify-content-start mx-0 px-0"
                              >
                                <span class="">Total Cost:</span>
                              </div>
                              <div
                                class="col-8 col-lg-8 col-xl-10 d-flex justify-content-start font-weight-bold mx-0 px-0"
                              >
                                <span class="itemCreated"> </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--/ end of record container-->
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
