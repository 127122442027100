import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-posting",
  templateUrl: "./posting.component.html",
  styleUrls: ["./posting.component.sass"],
})
export class PostingComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
