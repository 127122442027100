import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { forkJoin, Observable, of } from "rxjs";
import { AdminService } from "../services/admin.service";
import { catchError, map } from "rxjs/operators";
import { ApprovalItem } from "../../shared/models/admin";

@Injectable({
  providedIn: "root",
})
export class ApprovalsResolver implements Resolve<Observable<any>> {
  constructor(private adminService: AdminService) {}

  resolve(): Observable<any> {
    return <Observable<any>>forkJoin([
      this.adminService.getCreateApprovals(),
      this.adminService.getUpdateApprovals(),
    ]).pipe(
      map((results: [Array<ApprovalItem>, Array<ApprovalItem>]) => ({
        details: {
          approvals: [...results[0], ...results[1]],
        },
      })),
      catchError((error) => {
        return of({ details: null, error: error });
      })
    );
  }
}
