<div class="pb-4">
  <div
    class="
      col-12 col-md-11 col-lg-11 mx-md-auto mx-lg-auto
      d-flex flex-column justify-content-between mt-4 flex-md-row flex-lg-row
    "
  >
    <h4 class="text-info text-uppercase font-weight-bold">
      Profile
    </h4>
    <div class="d-flex justify-content-start">
      <button
        (click)="openProfileDialog()"
        class="btn btn-outline-info my-2 ml-1 mx-sm-1 shadow"
        tooltip="Edit Profile"
      >
        <i class="fas fa-edit"></i> Edit Profile
      </button>
      <button
        (click)="openChangePasswordDialog()"
        class="btn btn-outline-info my-2 ml-1 mx-sm-1 shadow"
        tooltip="Change Password"
      >
        Change Password
      </button>
      <button
        (click)="openEditEmailDialog()"
        class="btn btn-outline-info my-2 ml-1 mx-sm-1 shadow"
        tooltip="Edit Email"
      >
        Edit Email
      </button>
    </div>
  </div>
  <div class="d-flex flex-column col-md-11 col-lg-11 mx-md-auto mx-lg-auto">
    <div class="list-card card px-3 py-5 shadow d-flex flex-md-row flex-lg-row flex-column">
      <div class="col-12 col-md-2 col-lg-2 d-flex flex-row justify-content-between flex-md-column flex-lg-column">
        <div class="d-flex flex-column text-center">
          <img
            [src]="profile.employeePhotoUrl || 'assets/img/man.svg'"
            class="rounded-circle border m-auto m-md-0 m-lg-0 align-self-center"
            height="70"
            width="70"
          />
          <h5>Photo</h5>
        </div>
        <div class="d-flex flex-column justify-content-center text-center">
          <img
            [src]="profile.employeeIdPhotoUrl || 'assets/img/man.svg'"
            class="border m-auto m-md-0 m-lg-0  align-self-center"
            height="70"
            width="70"
          />
          <h5>ID</h5>
        </div>
      </div>
      <div class="break-text col-12 col-md-11 col-lg-11">
        <div class="row">
          <div class="col-12 col-md-6 mx-xl-0 px-xl-0">
            <div class="row">
              <div class="col-3 col-lg-4 d-flex justify-content-start mx-0 px-0">
                <span class="">Name:</span>
              </div>
              <div class="col-9 col-lg-8 d-flex justify-content-start font-weight-bold mx-md-0 px-md-0">
                <span class="itemCreated">{{profile.employeeName}}</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 mx-xl-0 px-xl-0">
            <div class="row">
              <div class="col-4 d-flex justify-content-start mx-0 px-0">
                <span class="">D.O.B:</span>
              </div>
              <div class="col-8 d-flex justify-content-start font-weight-bold mx-md-0 px-md-0">
                <span class="itemCreated">{{showDate(profile.employeeDateOfBirth)}}</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 mx-xl-0 px-xl-0">
            <div class="row">
              <div class="col-4 d-flex justify-content-start mx-0 px-0">
                <span class="">Gender:</span>
              </div>
              <div class="col-8 d-flex justify-content-start font-weight-bold mx-md-0 px-md-0">
                <span class="itemCreated">{{profile.employeeGender}} </span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 mx-xl-0 px-xl-0">
            <div class="row">
              <div class="col-5 col-xl-4 d-flex justify-content-start mx-0 px-0">
                <span class="">ID Type:</span>
              </div>
              <div class="col-7 col-xl-8 d-flex justify-content-start font-weight-bold mx-md-0 px-md-0">
                <span class="itemCreated">{{showIdType(profile.employeeIdType)}} </span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 mx-xl-0 px-xl-0">
            <div class="row">
              <div class="col-4 d-flex justify-content-start mx-0 px-0">
                <span class="">ID Number:</span>
              </div>
              <div class="col-8 d-flex justify-content-start font-weight-bold mx-md-0 px-md-0">
                <span class="itemCreated"> {{profile.employeeIdNumber}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row mx-md-0 px-md-0">
          <div class="col-12">
            <h5 class="text-info text-left font-weight-bold py-1 pb-md-2">
              Work Details
            </h5>
            <hr class="length"/>
          </div>
          <div class="col-12 col-md-6 mx-xl-0 px-xl-0">
            <div class="row">
              <div class="col-5 col-xl-4 d-flex justify-content-start mx-0 px-0">
                <span class="">Employee Number:</span>
              </div>
              <div class="col-7 col-xl-8 d-flex justify-content-start font-weight-bold mx-md-0 px-md-0">
                <span class="itemCreated">{{profile.employmentNumber}}</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 mx-xl-0 px-xl-0">
            <div class="row">
              <div class="col-5 col-xl-4 d-flex justify-content-start mx-0 px-0">
                <span class="">Job Title:</span>
              </div>
              <div class="col-7 col-xl-8 d-flex justify-content-start font-weight-bold mx-md-0 px-md-0">
                <span class="itemCreated">{{profile.jobTitle}}</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 mx-xl-0 px-xl-0">
            <div class="row">
              <div class="col-5 col-xl-4 d-flex justify-content-start mx-0 px-0">
                <span class="">Department:</span>
              </div>
              <div class="col-7 col-xl-8 d-flex justify-content-start font-weight-bold mx-md-0 px-md-0">
                <span class="itemCreated">{{profile.department}}</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 mx-xl-0 px-xl-0">
            <div class="row">
              <div class="col-5 col-xl-4 d-flex justify-content-start mx-0 px-0">
                <span class="">Recruitment Date:</span>
              </div>
              <div class="col-7 col-xl-8 d-flex justify-content-start font-weight-bold mx-md-0 px-md-0">
                <span class="itemCreated">{{showDate(profile.employeeRecruitmentDate)}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row mx-md-0 px-md-0">
          <div class="col-12">
            <h5 class="text-info text-left font-weight-bold py-1 pb-md-2">
              Contact Details
            </h5>
            <hr class="length"/>
          </div>
          <div class="col-12 col-md-6 mx-xl-0 px-xl-0">
            <div class="row">
              <div class="col-6 col-xl-4 d-flex justify-content-start mx-0 px-0">
                <span class="">Home Address:</span>
              </div>
              <div class="col-6 col-xl-8 d-flex justify-content-start font-weight-bold mx-md-0 px-md-0">
                <span class="itemCreated">{{profile.employeeHomeAreaDetails}} </span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 mx-xl-0 px-xl-0">
            <div class="row">
              <div class="col-5 col-xl-4 d-flex justify-content-start mx-0 px-0">
                <span class="">Phone No. 1:</span>
              </div>
              <div class="col-7 col-xl-8 d-flex justify-content-start font-weight-bold mx-md-0 px-md-0">
                <span class="itemCreated">{{profile.employeePhone1}} </span>
              </div>
            </div>
          </div>
          <div *ngIf="profile.employeePhone2" class="col-12 col-md-6 mx-xl-0 px-xl-0">
            <div class="row">
              <div class="col-5 col-xl-4 d-flex justify-content-start mx-0 px-0">
                <span class="">Phone No. 2:</span>
              </div>
              <div class="col-7 col-xl-8 d-flex justify-content-start font-weight-bold mx-md-0 px-md-0">
                <span class="itemCreated">{{profile.employeePhone2}} </span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 mx-xl-0 px-xl-0">
            <div class="row">
              <div class="col-4 d-flex justify-content-start mx-0 px-0">
                <span class="">Email:</span>
              </div>
              <div class="col-8 d-flex justify-content-start font-weight-bold mx-0 px-0">
                <span class="itemCreated"> {{profile.employeeEmail}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 d-flex flex-row justify-content-between align-items-center p-2"
         style="background-color: ghostwhite;">
      <h5 class="text-info font-weight-bold">
        Next Of Kin
      </h5>
      <button
        (click)="openNextOfKinDialog()"
        class="btn btn-outline-info"
        mat-button
        style="background-color: #0babe0; color: white"
        tooltip="Add Next of Kin"
      >
        <i class="fas fa-user"></i> Add New
      </button>
    </div>
    <hr class="length-1"/>
    <div class="mt-4 pt-4 shadow" style="background-color: ghostwhite;">
      <pagination-controls
        (pageBoundsCorrection)="pageChanged($event)"
        (pageChange)="pageChanged($event)"
        autoHide="true"
        directionLinks="true"
        maxSize="9"
        nextLabel="Next"
        previousLabel="Previous"
        responsive="true"
        screenReaderCurrentLabel="You're on page"
        screenReaderPageLabel="page"
        screenReaderPaginationLabel="Pagination"
      >
      </pagination-controls>
      <div
        *ngIf="!nextOfKins.length"
        class="bg-white">
        There are no next of kins added yet
      </div>
      <div *ngIf="nextOfKins.length">
        <div
          *ngFor="
              let kin of nextOfKins
              | paginate
              : {
              itemsPerPage: pageSize,
              currentPage: currentPage,
              totalItems: totalItems
              };
              let i = index
              "
          class="bg-white py-4 d-flex flex-md-row flex-lg-row flex-column"
        >

          <div class="col-12 col-md-2 col-lg-2 d-flex flex-row justify-content-between flex-md-column flex-lg-column">
            <div class="d-flex flex-column text-center">
              <img
                [src]="kin.employeeNextOfKinPhotoUrl || 'assets/img/man.svg'"
                class="rounded-circle border m-auto m-md-0 m-lg-0 align-self-center"
                height="70"
                width="70"
              />
              <h5>Photo</h5>
            </div>
            <div class="d-flex flex-column justify-content-center text-center">
              <img
                [src]="kin.employeeNextOfKinIdPhotoUrl || 'assets/img/man.svg'"
                class="border m-auto m-md-0 m-lg-0  align-self-center"
                height="70"
                width="70"
              />
              <h5>ID</h5>
            </div>
          </div>
          <div class="break-text col-12 col-md-11 col-lg-11">
            <div class="row mt-4x mx-auto">
              <div class="col-12 col-md-6 mx-xl-0 px-xl-0">
                <div class="row">
                  <div class="col-3 col-lg-4 d-flex justify-content-start mx-0 px-0">
                    <span class="">Name:</span>
                  </div>
                  <div class="col-9 col-lg-8 d-flex justify-content-start font-weight-bold mx-md-0 px-md-0">
                    <span class="itemCreated">{{kin.employeeNextOfKinName}} </span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 mx-xl-0 px-xl-0">
                <div class="row">
                  <div class="col-3 d-flex justify-content-start mx-0 px-0">
                    <span class="">D.O.B:</span>
                  </div>
                  <div class="col-9 d-flex justify-content-start font-weight-bold mx-md-0 px-md-0">
                    <span class="itemCreated">{{showDate(kin.employeeNextOfKinDateOfBirth)}}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 mx-xl-0 px-xl-0">
                <div class="row">
                  <div class="col-4 d-flex justify-content-start mx-0 px-0">
                    <span class="">Gender:</span>
                  </div>
                  <div class="col-8 d-flex justify-content-start font-weight-bold mx-md-0 px-md-0">
                    <span class="itemCreated"> {{kin.employeeNextOfKinGender}}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 mx-xl-0 px-xl-0">
                <div class="row">
                  <div class="col-5 col-xl-4 d-flex justify-content-start mx-0 px-0">
                    <span class="">Relationship:</span>
                  </div>
                  <div class="col-7 col-xl-8 d-flex justify-content-start font-weight-bold mx-md-0 px-md-0">
                    <span class="itemCreated">{{kin.employeeNextOfKinRelationship}} </span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 mx-xl-0 px-xl-0">
                <div class="row">
                  <div class="col-4 d-flex justify-content-start mx-0 px-0">
                    <span class="">ID Type:</span>
                  </div>
                  <div class="col-8 d-flex justify-content-start font-weight-bold mx-md-0 px-md-0">
                    <span class="itemCreated">{{showIdType(kin.employeeNextOfKinIdType)}}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 mx-xl-0 px-xl-0">
                <div class="row">
                  <div class="col-5 col-xl-4 d-flex justify-content-start mx-0 px-0">
                    <span class="">ID Number:</span>
                  </div>
                  <div class="col-7 col-xl-8 d-flex justify-content-start font-weight-bold mx-md-0 px-md-0">
                    <span class="itemCreated">{{kin.employeeNextOfKinIdNumber}}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 mx-xl-0 px-xl-0">
                <div class="row">
                  <div class="col-6 col-xl-4 d-flex justify-content-start mx-0 px-0">
                    <span class="">Home Address:</span>
                  </div>
                  <div class="col-6 col-xl-8 d-flex justify-content-start font-weight-bold mx-md-0 px-md-0">
                    <span class="itemCreated">{{kin.employeeNextOfKinHomeAreaDetails}} </span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 mx-xl-0 px-xl-0">
                <div class="row">
                  <div class="col-5 col-xl-4 d-flex justify-content-start mx-0 px-0">
                    <span class="">Contact 1:</span>
                  </div>
                  <div class="col-7 col-xl-8 d-flex justify-content-start font-weight-bold mx-md-0 px-md-0">
                    <span class="itemCreated">{{kin.employeeNextOfKinPhone1}} </span>
                  </div>
                </div>
              </div>
              <div *ngIf="kin.employeeNextOfKinPhone2" class="col-12 col-md-6 mx-xl-0 px-xl-0">
                <div class="row">
                  <div class="col-5 col-xl-4 d-flex justify-content-start mx-0 px-0">
                    <span class="">Contact 2:</span>
                  </div>
                  <div class="col-7 col-xl-8 d-flex justify-content-start font-weight-bold mx-md-0 px-md-0">
                    <span class="itemCreated">{{kin.employeeNextOfKinPhone2}} </span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 mx-xl-0 px-xl-0">
                <div class="row">
                  <div class="col-3 d-flex justify-content-start mx-0 px-0">
                    <span class="">Email:</span>
                  </div>
                  <div class="col-9 d-flex justify-content-start font-weight-bold mx-0 px-0">
                    <span class="itemCreated">{{kin.employeeNextOfKinEmail}} </span>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-row mt-2">
                <button
                  (click)="openNextOfKinDialog(kin)"
                  class="btn btn-outline-info mr-2"
                  mat-button
                  style="background-color: #0babe0; color: white; margin-left: 0"
                  tooltip="Add Next of Kin"
                >
                  Edit
                </button>
                <button
                  (click)="deleteNextOfKin(kin)"
                  class="btn btn-outline-info"
                  mat-button
                  style="background-color: red; color: white"
                  tooltip="Add Next of Kin"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
