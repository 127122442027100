import { Component, Input, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { AuthService } from "src/app/modules/auth/services/auth.service";
import { LayoutService } from "src/app/modules/shared/layout/services/layout.service";
import { checkUserType, showToasterMessage } from "../../utils/functions";
import { ToastrService } from "ngx-toastr";
import { EmployeeData } from "../../models/employee";
import { ProfilesService } from "../../services/profiles.service";
import { Company } from "../../models/admin";
import { AdminService } from "../../../admin/services/admin.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @Input() navLayout: string;
  @Input() defaultNavbar: string;
  @Input() toggleNavbar: string;
  @Input() toggleStatus: boolean;
  @Input() navbarEffect: string;
  @Input() deviceType: string;
  @Input() headerColorTheme: string;
  @Input() leftHeaderColorTheme: string;
  @Input() navbarColorTheme: string;
  @Input() activeNavColorTheme: string;
  @Input() headerHeight: number;
  @Input() collapsedLeftHeader: boolean;
  @Input() moduleName: string;
  User: EmployeeData;
  companyLogo: string;
  companyName: string;
  dummyCompanyLogo: string = "../../../assets/Nologo.svg";
  profilePhoto = "../../../assets/img/man.svg";
  serviceErrors: any;

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private layoutService: LayoutService,
    private authService: AuthService,
    private toaster: ToastrService,
    private profilesService: ProfilesService,
    private adminService: AdminService
  ) {
    this.profilesService.userProfile.subscribe((profile: EmployeeData) => {
      this.User = profile;
    });
    this.adminService.company.subscribe((company: Company) => {
      this.companyLogo = company?.companyLogoUrl;
      this.companyName = company?.companyName;
    });
  }

  ngOnInit() {}

  changeTheToggleStatus() {
    this.layoutService.getToggleStatus();
  }

  logoutUser() {
    if (checkUserType(this.authService.loggedInUserInfo()) === "employee") {
      this.router.navigate(["auth/employee-login"]).then();
      this.adminService
        .logoutEmployee(this.authService.loggedInUserInfo().employeeId)
        .subscribe();
    } else if (
      checkUserType(this.authService.loggedInUserInfo()) === "customer"
    ) {
      this.router.navigate(["auth/customer-login"]).then();
    } else if (
      checkUserType(this.authService.loggedInUserInfo()) === "supplier"
    ) {
      this.router.navigate(["auth/supplier-login"]).then();
    }
    this.authService.logoutUser();
    showToasterMessage(this.toaster, "success", "Logout Successfully!!");
  }
}
