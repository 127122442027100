import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { API_URLS } from "../../shared/utils/constants";

@Injectable({
  providedIn: "root",
})
export class ManagementService {
  constructor(private http: HttpClient) {}

  getCustomerSectorsForApproval(endPoint: string): any {
    return this.http.get(API_URLS.USERS.CUSTOMER.SECTORS[endPoint]);
  }

  approveRejectCustomerSectors(
    postData: Array<{
      customerSectorId: number;
      employeeId: number;
    }>,
    endPoint
  ): any {
    return this.http.put(API_URLS.USERS.CUSTOMER.SECTORS[endPoint], postData);
  }

  getCustomersForApproval(endPoint: string): any {
    return this.http.get(API_URLS.USERS.CUSTOMER[endPoint]);
  }

  approveRejectCreatedCustomer(postData: Array<any>, endPoint): any {
    return this.http.put(API_URLS.USERS.CUSTOMER[endPoint], postData);
  }

  getSuppliersForApproval(endPoint: string): any {
    return this.http.get(API_URLS.USERS.SUPPLIER[endPoint]);
  }

  approveRejectCreatedSupplier(postData: Array<any>, endPoint): any {
    return this.http.put(API_URLS.USERS.SUPPLIER[endPoint], postData);
  }
}
