import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CustomValidator } from "../../validators/custom-validator";
import { AuthService } from "../../../auth/services/auth.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { formatFormFields, showToasterMessage } from "../../utils/functions";
import { AdministrationService } from "../../../administration/services/administration.service";
import { API_URLS } from "../../utils/constants";

export interface ShippingAddress {
  customerBoxNumber?: string;
  customerCityLocation?: string;
  customerCountryLocation?: string;
  customerRegionLocation?: string;
  customerOfficeFloor?: string;
  customerPlotNumber?: string;
  customerStreetBuilding?: string;
  customerStreetName?: string;
  customerEmail1?: string;
  customerEmail2?: string;
  customerPhoneContact1?: string;
  customerPhoneContact2?: string;
  customerId?: number;
  customerName?: string;
  customerShippinAdressId?: number;
  supplierBoxNumber?: string;
  supplierCityLocation?: string;
  supplierCountryLocation?: string;
  supplierEmail1?: string;
  supplierEmail2?: string;
  supplierId?: number;
  supplierName?: string;
  suppliershippingadressId?: number;
  supplierOfficeFloor?: string;
  supplierPhoneContact1?: string;
  supplierPhoneContact2?: string;
  supplierPlotNumber?: string;
  supplierRegionLocation?: string;
  supplierStreetBuilding?: string;
  supplierStreetName?: string;
}

@Component({
  selector: "app-shipping-address",
  templateUrl: "./shipping-address.component.html",
  styleUrls: ["./shipping-address.component.sass"],
})
export class ShippingAddressComponent implements OnInit {
  @Input() address: ShippingAddress[];
  @Input() userType: number;
  @Input() ownerId: number;
  @Output() closeEditor = new EventEmitter();
  userForm: FormGroup;
  OWNER_ID: string;
  SHIPPING_ADDRESS_ID: string;
  BOX_NUMBER: string;
  CITY_LOCATION: string;
  COUNTRY_LOCATION: string;
  REGION_LOCATION: string;
  OFFICE_FLOOR: string;
  PLOT_NUMBER: string;
  STREET_BUILDING: string;
  STREET_NAME: string;
  EMAIL: string;
  EMAIL2: string;
  PHONE_CONTACT: string;
  PHONE_CONTACT2: string;
  isEditing: boolean = false;
  loading: boolean = false;

  constructor(
    private authService: AuthService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private _formBuilder: FormBuilder,
    private administrationService: AdministrationService
  ) {}

  ngOnInit(): void {
    this.BOX_NUMBER =
      this.userType === 100 ? "customerBoxNumber" : "supplierBoxNumber";
    this.CITY_LOCATION =
      this.userType === 100 ? "customerCityLocation" : "supplierCityLocation";
    this.COUNTRY_LOCATION =
      this.userType === 100
        ? "customerCountryLocation"
        : "supplierCountryLocation";
    this.REGION_LOCATION =
      this.userType === 100
        ? "customerRegionLocation"
        : "supplierRegionLocation";
    this.OFFICE_FLOOR =
      this.userType === 100 ? "customerOfficeFloor" : "supplierOfficeFloor";
    this.PLOT_NUMBER =
      this.userType === 100 ? "customerPlotNumber" : "supplierPlotNumber";
    this.STREET_BUILDING =
      this.userType === 100
        ? "customerStreetBuilding"
        : "supplierStreetBuilding";
    this.STREET_NAME =
      this.userType === 100 ? "customerStreetName" : "supplierStreetName";
    this.EMAIL = this.userType === 100 ? "customerEmail1" : "supplierEmail1";
    this.EMAIL2 = this.userType === 100 ? "customerEmail2" : "supplierEmail2";
    this.PHONE_CONTACT =
      this.userType === 100 ? "customerPhoneContact1" : "supplierPhoneContact1";
    this.PHONE_CONTACT2 =
      this.userType === 100 ? "customerPhoneContact2" : "supplierPhoneContact2";
    this.SHIPPING_ADDRESS_ID =
      this.userType === 100
        ? "customerShippinAdressId"
        : "suppliershippingadressId";
    this.OWNER_ID = this.userType === 100 ? "customerId" : "supplierId";
    this.userForm = this.createFormGroup();
    this.isEditing = false;
  }

  createFormGroup(): FormGroup {
    return new FormGroup({
      [this.BOX_NUMBER]: this._formBuilder.control(
        "",
        Validators.compose([Validators.required])
      ),
      [this.CITY_LOCATION]: this._formBuilder.control(
        "",
        Validators.compose([Validators.required])
      ),
      [this.COUNTRY_LOCATION]: this._formBuilder.control(
        "",
        Validators.compose([Validators.required])
      ),
      [this.REGION_LOCATION]: this._formBuilder.control(
        "",
        Validators.compose([Validators.required])
      ),
      [this.OFFICE_FLOOR]: this._formBuilder.control(
        "",
        Validators.compose([Validators.required])
      ),
      [this.PLOT_NUMBER]: this._formBuilder.control(
        "",
        Validators.compose([Validators.required])
      ),
      [this.STREET_NAME]: this._formBuilder.control(
        "",
        Validators.compose([Validators.required])
      ),
      [this.STREET_BUILDING]: this._formBuilder.control(
        "",
        Validators.compose([Validators.required])
      ),
      [this.PHONE_CONTACT]: this._formBuilder.control(
        "",
        Validators.compose([
          Validators.required,
          CustomValidator.patternValidator(
            /^(([0])([1-9])([0-9])([0-9])([0-9])([0-9])([0-9])([0-9])([0-9])([0-9]))$/,
            { hasNumber: true }
          ),
        ])
      ),
      [this.PHONE_CONTACT2]: this._formBuilder.control(
        "",
        Validators.compose([
          CustomValidator.patternValidator(
            /^(([0])([1-9])([0-9])([0-9])([0-9])([0-9])([0-9])([0-9])([0-9])([0-9]))$/,
            { hasNumber: true }
          ),
        ])
      ),
      [this.EMAIL]: this._formBuilder.control(
        "",
        Validators.compose([
          Validators.required,
          CustomValidator.patternValidator(/\S+@\S+\.\S+/, {
            validEmail: true,
          }),
        ])
      ),
      [this.EMAIL2]: this._formBuilder.control(
        "",
        Validators.compose([
          CustomValidator.patternValidator(/\S+@\S+\.\S+/, {
            validEmail: true,
          }),
        ])
      ),
    });
  }

  setIsEditing() {
    const initialValue = { ...this.address[0] };
    delete initialValue[this.OWNER_ID];
    delete initialValue[this.SHIPPING_ADDRESS_ID];
    delete initialValue.customerName;
    delete initialValue.supplierName;

    this.userForm.setValue({ ...initialValue });
    this.isEditing = true;
  }

  createUpdateShippingAddress(): void {
    if (!this.userForm.valid) return;
    this.loading = true;
    const data = { ...formatFormFields(this.userForm.value) };

    if (this.isEditing)
      data[this.SHIPPING_ADDRESS_ID] =
        this.address[0][this.SHIPPING_ADDRESS_ID];
    else data[this.OWNER_ID] = this.ownerId;

    if (!data[this.EMAIL2])
      data[this.EMAIL2] = this.userForm.controls[this.EMAIL].value;
    if (!data[this.PHONE_CONTACT2])
      data[this.PHONE_CONTACT2] =
        this.userForm.controls[this.PHONE_CONTACT].value;

    const endpoint =
      API_URLS.USERS[this.userType === 100 ? "CUSTOMER" : "SUPPLIER"][
        !this.isEditing ? "ADD_SHIPPING_ADDRESS" : "EDIT_SHIPPING_ADDRESS"
      ];

    this.administrationService
      .postUpdateShippingAddress(
        data,
        this.isEditing ? "put" : "post",
        endpoint
      )
      .subscribe(
        (res) => {
          this.loading = false;
          this.isEditing = false;
          this.address[0] = { ...this.address[0], ...data };
          this.userForm = this.createFormGroup();
          showToasterMessage(this.toaster, "success", res);
        },
        (error) => {
          this.loading = false;
          showToasterMessage(this.toaster, "warning", error);
        }
      );
  }

  deleteShippingAddress() {
    this.administrationService
      .deleteShippingAddress(
        this.address[0][this.SHIPPING_ADDRESS_ID],
        this.userType === 100 ? "CUSTOMER" : "SUPPLIER"
      )
      .subscribe(
        (res) => {
          this.loading = false;
          this.isEditing = false;
          this.address = [];
          this.userForm = this.createFormGroup();
          showToasterMessage(this.toaster, "success", res);
        },
        (error) => {
          this.loading = false;
          showToasterMessage(this.toaster, "warning", error);
        }
      );
  }
}
