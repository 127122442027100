<div
  [attr.active-Nav-theme]="activeNavColorTheme"
  [attr.device-type]="deviceType"
  [attr.effect-type]="navbarEffect"
  [attr.nav-layout]="navLayout"
  [attr.navbar-theme]="navbarColorTheme"
  [attr.navbar-type]="toggleStatus ? defaultNavbar : toggleNavbar"
  [ngClass]="{
    hideNav:
      toggleStatus && navLayout === 'horizontal' && deviceType === 'mobile'
  }"
  [ngStyle]="{ 'height.px': asideBarHeight }"
  class="flux-left-panel"
>
  <div class="flux-wrapper">
    <nav class="flux-navbar">
      <ul class="menu-list">
        <li *ngFor="let ml of menuList;  let i = index;" [ngClass]="{active: isActive(ml.name), 'hasMenu':ml.subMenu}"
            appMenudropdown
            class="menu-item" routerLinkActive="active">
          <a (click)="onItemSelect(ml.name);" *ngIf="!(ml.subMenu)" [routerLink]="ml.url">
            <span class="flux-micon"><i class="{{ml.icon}}"></i></span>
            <span class="flux-mtext">{{ml.name}}</span>
            <span *ngIf="(ml.badge)" class="flux-badge {{ml.badgeBg}}">{{ml.badge}}</span>
          </a>
          <a *ngIf="ml.subMenu" href="javascript:void(0)">
            <span class="flux-micon"><i class="{{ml.icon}}"></i></span>
            <span class="flux-mtext">{{ml.name}}</span>
            <span *ngIf="(ml.badge)" class="flux-badge {{ml.badgeBg}}">{{ml.badge}}</span>
            <span class="flux-mcaret"></span>
          </a>
          <ul *ngIf="ml.subMenu">
            <li (click)="onSubItemSelect(ml.name);" *ngFor="let child of ml.subMenu" routerLinkActive="active">
              <a [routerLink]="child.url">
                <span class="flux-micon"><i class="{{child.icon}}"></i> </span>
                <span class="flux-mtext">{{child.name}}</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</div>
