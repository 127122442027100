<div class="card mt-0 mb-2 pt-0 shadow mx-0 mx-md-1 mx-lg-3">
  <div class="card-title">
    <h4
      class="text-info text-right text-uppercase pt-1 pb-0 px-3"
    >
      create process
    </h4>
  </div>
  <div class="card-body d-flex flex-row justify-content-center mx-2 mx-md-5 px-1 px-sm-5 px-lg-4">
    <div class="form-card mt-1 mb-3 pt-3 pb-4 px-3 mx-md-5 shadow">
          <form [formGroup]="userForm">
            <div class="form-group text-primary">
              <label
                for="processType"
                [ngClass]="fval.processType.valid ? 'text-info' : ''"
                ><i *ngIf="fval.processType.valid" class="material-icons"
                  >check_circle</i
                >Process Type</label
              >
              <div class="input-block mb-0">
                <input
                  type="text"
                  class="form-control shadow"
                  list="processType"
                  formControlName="processType"
                  [ngClass]="{
                    'is-invalid':
                      (fval.processType.touched || fval.processType.dirty) &&
                      fval.processType.errors
                  }"
                  placeholder="Select process type"
                  autocomplete="off"
                  required
                />
                <datalist>
                  <option>
                    <!-- {{branch.processName}} -->
                  </option>
                </datalist>
              </div>
              <small
                class="text-danger col"
                *ngIf="
                  (fval.processType.touched || fval.processType.dirty) &&
                  fval.processType.errors?.required
                "
              >
                The process type is required
              </small>
            </div>
            <div class="form-group text-primary">
              <label
                for="processName"
                [ngClass]="fval.processName.valid ? 'text-info' : ''"
                >Process Name<i
                  *ngIf="fval.processName.valid"
                  class="material-icons"
                  >check_circle</i
                ></label
              >
              <div class="input-block mb-0">
                <input
                  id="processName"
                  class="form-control shadow"
                  formControlName="processName"
                  type="text"
                  [ngClass]="{
                    'is-invalid':
                      (fval.processName.touched || fval.processName.dirty) &&
                      fval.processName.errors
                  }"
                  placeholder="Enter process name"
                  autocomplete="off"
                  required
                />
                <!-- <i
                  class="start fas fa-envelope text-primary mx-auto"
                ></i> -->
              </div>
              <small
                class="text-danger col"
                *ngIf="
                  (fval.processName.touched || fval.processName.dirty) &&
                  fval.processName.errors?.required
                "
              >
                The process name is required
              </small>
            </div>
            <div class="d-flex mt-1 mb-2 pt-0 pb-2 mx-auto">
              <button
                type="submit"
                class="btn btn-primary col-5 mx-auto shadow"
              >
                <!-- [disabled]="!userForm.valid" -->
                Create
              </button>

              <!-- <ngx-spinner bdColor="#175b6f86" size="medium" color="#27ceaa" type="ball-clip-rotate-multiple" [fullScreen] = "true">
            <h4 class="text-light justify-content-between align-items-center mt-4 pt-4 mt-md-4 pt-md-2">Please wait...</h4>
          </ngx-spinner>

          <label class="col" *ngIf="errored" class="text-danger">
            <i class="material-icons">Cancel</i>

             <ngx-alerts></ngx-alerts>
          </label>

          <label class="col" *ngIf="posted" class="text-info">
            <i class="material-icons">check_circle</i>
            <ngx-alerts></ngx-alerts>
          </label>
        -->

              <button type="button" class="btn btn-danger col-5 mx-auto shadow">
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
