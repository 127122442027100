import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-suppliers-service-providers",
  templateUrl: "./suppliers-service-providers.component.html",
  styleUrls: ["./suppliers-service-providers.component.sass"],
})
export class SuppliersServiceProvidersComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
