import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from "src/app/modules/auth/services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { JwtHelperService } from "@auth0/angular-jwt";
import {
  routeToDashboard,
  showToasterMessage,
} from "src/app/modules/shared/utils/functions";
import { API_URLS } from "../../shared/utils/constants";
import { CustomValidator } from "src/app/modules/shared/validators/custom-validator";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loading = false;
  userForm: FormGroup;
  fieldType: boolean;
  showNavBar: boolean;
  whoIsLoggingIn: string;
  EMAIL_FORM_FIELD_NAME: string;
  PASSWORD_FORM_FIELD_NAME: string;
  CHANGE_PASSWORD_PATH: string;
  REGISTER_PATH: string;
  LOGIN_ENDPOINT: string;
  DASHBOARD_ROUTE: string;

  constructor(
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toaster: ToastrService,
    private jwtHelper: JwtHelperService,
    private route: ActivatedRoute
  ) {
    this.showNavBar = true;
  }

  get fval(): any {
    return this.userForm.controls;
  }

  ngOnInit(): void {
    this.authService.pleaseLogin.subscribe((status: boolean) => {
      if (status) {
        showToasterMessage(
          this.toaster,
          "warning",
          "Session expired, please login",
          "Unauthorized"
        );
      }
    });
    this.route.url.subscribe((val) => {
      if (val[0].path === "employee-login") {
        this.showNavBar = false;
        this.whoIsLoggingIn = "employee";
        this.EMAIL_FORM_FIELD_NAME = "employeeEmail";
        this.PASSWORD_FORM_FIELD_NAME = "employeePassword";
        this.REGISTER_PATH = "/auth/register-employee";
        this.CHANGE_PASSWORD_PATH = "/auth/employee-change-password";
        this.LOGIN_ENDPOINT = API_URLS.AUTH.EMPLOYEE.LOGIN;
      } else if (val[0].path === "customer-login") {
        this.showNavBar = true;
        this.whoIsLoggingIn = "customer";
        this.EMAIL_FORM_FIELD_NAME = "customerEmail";
        this.PASSWORD_FORM_FIELD_NAME = "customerPassword";
        this.REGISTER_PATH = "/auth/register-customer";
        this.CHANGE_PASSWORD_PATH = "/auth/customer-change-password";
        this.LOGIN_ENDPOINT = API_URLS.AUTH.CUSTOMER.LOGIN;
        this.DASHBOARD_ROUTE = "/customer";
      } else if (val[0].path === "supplier-login") {
        this.showNavBar = true;
        this.whoIsLoggingIn = "supplier";
        this.EMAIL_FORM_FIELD_NAME = "supplierEmail";
        this.PASSWORD_FORM_FIELD_NAME = "supplierPassword";
        this.REGISTER_PATH = "/auth/register-supplier";
        this.CHANGE_PASSWORD_PATH = "/auth/supplier-change-password";
        this.LOGIN_ENDPOINT = API_URLS.AUTH.SUPPLIER.LOGIN;
        this.DASHBOARD_ROUTE = "/supplier";
      }
    });
    if (this.authService.isLoggedIn()) {
      routeToDashboard({
        router: this.router,
        whichUser: this.whoIsLoggingIn,
        userDetails: this.authService.loggedInUserInfo(),
        DASHBOARD_ROUTE: this.DASHBOARD_ROUTE,
      });
    }
    this.userForm = this.createFormGroup();
  }

  createFormGroup(): FormGroup {
    return new FormGroup({
      [this.EMAIL_FORM_FIELD_NAME]: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          CustomValidator.patternValidator(/\S+@\S+\.\S+/, {
            validEmail: true,
          }),
        ])
      ),
      [this.PASSWORD_FORM_FIELD_NAME]: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
    });
  }

  revert(): void {
    this.userForm.reset();
  }

  // toggle visibility of password field
  toggleFieldType(): void {
    this.fieldType = !this.fieldType;
  }

  login(): void {
    const data = { ...this.userForm.value };
    this.authService.loginHandler(data, this.LOGIN_ENDPOINT).subscribe(
      () => {
        routeToDashboard({
          router: this.router,
          whichUser: this.whoIsLoggingIn,
          userDetails: this.authService.loggedInUserInfo(),
          DASHBOARD_ROUTE: this.DASHBOARD_ROUTE,
        });
        showToasterMessage(
          this.toaster,
          "success",
          "Welcome to your Dashboard",
          "Log in successful"
        );
      },
      (error: string) => {
        this.loading = false;
        showToasterMessage(this.toaster, "warning", error, "Login failed!!!");
      }
    );
  }
}
