<div class="px-2">
  <h4 class="text-center text-uppercase text-info">Edit employee Role</h4>
  <form (ngSubmit)="data.isApproval ? closeEditorDialog(true) : updateEmployeeRole()" [formGroup]="userForm">
    <div class="form-group text-primary">
      <label
        [ngClass]="userForm.controls.employeeName.valid ? 'text-info' : ''"
        for="employeeName"
      >
        Employee Name
        <i *ngIf="userForm.controls.employeeName.valid" class="material-icons">check_circle</i>
      </label>
      <div class="input-block mb-0">
        <i class="start fas fa-user text-primary mx-auto" style="size: 50px"></i>
        <input
          [ngClass]="{
                'is-invalid':
                  (userForm.controls.employeeName.touched ||
                    userForm.controls.employeeName.dirty) &&
                  userForm.controls.employeeName.errors
              }"
          autocomplete="off"
          class="form-control shadow"
          formControlName="employeeName"
          id="employeeName"
          placeholder="Enter employee name"
          type="text"
        />
      </div>
      <div
        *ngIf="
              (userForm.controls.employeeName.touched ||
                userForm.controls.employeeName.dirty) &&
              userForm.controls.employeeName.errors?.required
            "
        class="text-danger col"
      >
        The employee name is required
      </div>
    </div>
    <div class="form-group text-primary">
      <label [ngClass]="userForm.controls.role.valid ? 'text-info' : ''" for="role">
        Employee Role
        <i *ngIf="userForm.controls.role.valid" class="material-icons">check_circle</i>
      </label>
      <div class="input-block mb-0">
        <i class="start fas fa-user-cog text-primary mx-auto" style="size: 50px"></i>
        <select
          class="form-control shadow"
          formControlName="role"
          id="role"
        >
          <option
            class="place-holder-select"
            disabled
            hidden
            selected
            value=""
          >
            Select a role
          </option>
          <option *ngFor="let role of data.roles" [ngValue]="role.roleId">
            {{ role.roleName | removeDashes }}
          </option>
        </select>
      </div>
      <div
        *ngIf="
              (userForm.controls.role.touched ||
                userForm.controls.role.dirty) &&
              userForm.controls.role.errors?.required
            "
        class="text-danger col"
      >
        The employee role is required
      </div>
    </div>
    <div class="d-flex mt-3 mb-0 pt-2 pb-3 mx-auto">
      <button
        [disabled]="!userForm.valid || userForm.pristine || loading"
        class="btn btn-primary col-5 mx-auto shadow"
        type="submit"
      >
        {{ data.isApproval ? "Approved" : "Save" }}
      </button>
      <button
        (click)="data.isApproval ? closeEditorDialog(false) : closeEditorDialog()"
        class="btn btn-danger col-5 mx-auto shadow"
        type="button"
      >
        {{ data.isApproval ? "Reject" : "Cancel" }}
      </button>
    </div>
  </form>
</div>
