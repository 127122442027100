import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-buy-items",
  templateUrl: "./buy-items.component.html",
  styleUrls: ["./buy-items.component.sass"],
})
export class BuyItemsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
