import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";

const routes: Routes = [
  // TODO when deploying for employees change home to employeeLogin
  {path: "auth", redirectTo: "auth/employee-login", pathMatch: "full"},
  {path: "", redirectTo: "auth/employee-login", pathMatch: "full"},
  {
    path: "admin",
    loadChildren: () =>
      import("./modules/admin/admin.module").then((m) => m.AdminModule),
  },
  // {
  //   path: "management",
  //   loadChildren: () =>
  //     import("./modules/management/management.module").then(
  //       (m) => m.ManagementModule
  //     ),
  // },
  // {
  //   path: "administration",
  //   loadChildren: () =>
  //     import("./modules/administration/administration.module").then(
  //       (m) => m.AdministrationModule
  //     ),
  // },
  // {
  //   path: "customer",
  //   loadChildren: () =>
  //     import("./modules/customer/customer.module").then(
  //       (m) => m.CustomerModule
  //     ),
  // },
  // {
  //   path: "finance",
  //   loadChildren: () =>
  //     import("./modules/finance/finance.module").then((m) => m.FinanceModule),
  // },
  // {
  //   path: "front-desk",
  //   loadChildren: () =>
  //     import("./modules/front-desk/front-desk.module").then(
  //       (m) => m.FrontDeskModule
  //     ),
  // },
  // {
  //   path: "production",
  //
  //   loadChildren: () =>
  //     import("./modules/production/production-dashboard.module").then(
  //       (m) => m.ProductionDashboardModule
  //     ),
  // },
  // {
  //   path: "quality-assurance",
  //
  //   loadChildren: () =>
  //     import("./modules/qa/qa-dashboard.module").then(
  //       (m) => m.QaDashboardModule
  //     ),
  // },
  // {
  //   path: "sales-market",
  //
  //   loadChildren: () =>
  //     import("./modules/sales-market/sales-market-dashboard.module").then(
  //       (m) => m.SalesMarketDashboardModule
  //     ),
  // },
  // {
  //   path: "supplier",
  //
  //   loadChildren: () =>
  //     import("./modules/supplier/supplier.module").then(
  //       (m) => m.SupplierModule
  //     ),
  // },
  // {
  //   path: "transport-and-logistics",
  //
  //   loadChildren: () =>
  //     import("./modules/trans-logistics/trans-logistics-dashboard.module").then(
  //       (m) => m.TransLogisticsDashboardModule
  //     ),
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
