import { Component, OnInit, TemplateRef } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { noRecords, showToasterMessage } from "../../../shared/utils/functions";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ApprovalItem, Company } from "src/app/modules/shared/models/admin";
import { DashboardResolvedData } from "../../resolvers/dasboard.resolver";
import { EmployeeData } from "src/app/modules/shared/models/employee";
import { Branch } from "src/app/modules/shared/models/user-auth";
import { AdminService } from "../../services/admin.service";

@Component({
  selector: "app-admin-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  public modalRef: BsModalRef;
  companyCreated: boolean = false;
  company: Company;
  approvals: Array<ApprovalItem>;
  branches: Array<Branch>;
  loggedIn: Array<EmployeeData>;
  serviceErrors: string;
  noLogo = "../../../../assets/Nologo.svg";

  constructor(
    private modalService: BsModalService,
    private adminService: AdminService,
    private activateRoute: ActivatedRoute,
    private toaster: ToastrService
  ) {}

  ngOnInit(): void {
    const resolvedData: DashboardResolvedData =
      this.activateRoute.snapshot.data[0]?.details;
    this.serviceErrors = this.activateRoute.snapshot.data[0]?.error;
    if (resolvedData) {
      this.company = resolvedData.company[0];
      this.companyCreated = !!this.company.companyId;
      this.branches = resolvedData.branches;
      this.approvals = resolvedData.approvals;
      this.loggedIn = noRecords(resolvedData.loggedIn)
        ? []
        : resolvedData.loggedIn;
      if (this.loggedIn.length > 5) this.loggedIn = this.loggedIn.slice(1, 5);
    } else showToasterMessage(this.toaster, "warning", this.serviceErrors);
  }

  //modal for displaying rfqs list
  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "modal-dialog-center modal-lg" })
    );
  }
}
