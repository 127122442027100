import { Component, Input, OnInit } from "@angular/core";

export interface ContactPerson {
  customerContactPersonsEmail?: string;
  customerContactPersonsName?: string;
  customerContactPersonsPhone1?: string;
  customerContactPersonsPhone2?: string;
  customerContactPersonsPhotoUrl?: string;
  customerId?: number;
  customerContactPersonsId?: number;
  supplierContactPersonsEmail?: string;
  supplierContactPersonsId?: number;
  supplierContactPersonsName?: string;
  supplierContactPersonsPhone1?: string;
  supplierContactPersonsPhone2?: string;
  supplierContactPersonsPhotoUrl?: string;
  supplierId?: number;
}

@Component({
  selector: "app-contact-person",
  templateUrl: "./contact-person.component.html",
  styleUrls: ["./contact-person.component.sass"],
})
export class ContactPersonComponent implements OnInit {
  CONTACT_PERSON_EMAIL: string;
  CONTACT_PERSON_NAME: string;
  CONTACT_PERSON_PHONE1: string;
  CONTACT_PERSON_PHONE2: string;
  CONTACT_PERSON_PHOTO_URL: string;
  CONTACT_PERSON_OWNER_ID: string;
  @Input() contactPersons: ContactPerson[];
  @Input() ownerId: number;

  constructor() {}

  ngOnInit(): void {}
}
