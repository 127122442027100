import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "src/app/modules/auth/services/auth.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from "@angular/router";
import { CustomValidator } from "src/app/modules/shared/validators/custom-validator";
import { ToastrService } from "ngx-toastr";
import { AdminService } from "../../services/admin.service";
import { showToasterMessage } from "../../../shared/utils/functions";
import { Company, CreateCompany } from "src/app/modules/shared/models/admin";
import { AngularFireStorage } from "@angular/fire/storage";
import { finalize } from "rxjs/operators";
import { COMPANY_TOKEN } from "../../../shared/utils/constants";
import { LoaderService } from "../../../shared/services/loader.service";

@Component({
  selector: "app-company-setup",
  templateUrl: "./company-setup.component.html",
  styleUrls: ["./company-setup.component.scss"],
})
export class CompanySetupComponent implements OnInit {
  loading = false;
  companyForm: FormGroup;
  companyDetails: Array<Company>;
  fieldType: boolean;
  User = this.authService.loggedInUserInfo();
  serviceErrors: any;
  logoUrl: string;
  isEditing: boolean;
  noLogo = "../../../../assets/Nologo.svg";

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private toaster: ToastrService,
    private authService: AuthService,
    private adminService: AdminService,
    private activateRoute: ActivatedRoute,
    private storage: AngularFireStorage,
    private loaderService: LoaderService
  ) {}

  get fval(): any {
    return this.companyForm.controls;
  }

  ngOnInit(): void {
    this.isEditing = false;
    this.companyDetails = this.activateRoute.snapshot.data[0]?.details;
    this.serviceErrors = this.activateRoute.snapshot.data[0]?.error;
    if (this.companyDetails && this.companyDetails[0].companyId) {
      this.isEditing = true;
      this.companyForm = this.createFormGroup();
      this.setCompanyValues(this.companyDetails[0]);
    } else {
      this.companyForm = this.createFormGroup();
      showToasterMessage(this.toaster, "warning", this.serviceErrors);
    }
  }

  createFormGroup(): any {
    return new FormGroup({
      companyName: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      companyLogo: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      companyEmail1: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ])
      ),
      companyEmail2: new FormControl(
        "",
        Validators.compose([
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ])
      ),
      companyBoxNumber: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      companyCityLocation: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      companyCountryLocation: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      companyRegionLocation: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      companyOfficeFloor: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      companyPlotNumber: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      companyStreetBuilding: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      companyPhoneContact1: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          CustomValidator.patternValidator(
            /^(([0-9])([0-9])([0-9])([0-9])([0-9])([0-9])([0-9])([0-9])([0-9])([0-9]))$/,
            { hasNumber: true }
          ),
        ])
      ),
      companyPhoneContact2: new FormControl(
        "",
        Validators.compose([
          CustomValidator.patternValidator(
            /^(([0-9])([0-9])([0-9])([0-9])([0-9])([0-9])([0-9])([0-9])([0-9])([0-9]))$/,
            { hasNumber: true }
          ),
        ])
      ),
      companyStreetName: new FormControl(
        "",
        Validators.compose([
          // Validators.required
        ])
      ),
    });
  }

  revert(): any {
    this.companyForm.reset();
  }

  // toggle visibility of password field
  toggleFieldType(): any {
    this.fieldType = !this.fieldType;
  }

  onFileSelected(event): void {
    const file = event.target.files[0];
    this.fval.companyLogo.setValue(file);
    const filePath = `Company/companyLogo`;
    this.loaderService.isLoading.next(true);
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          let downloadURL = fileRef.getDownloadURL();
          downloadURL.subscribe((url) => {
            if (url) {
              this.logoUrl = url;
              this.isEditing && this.createCompany(this.isEditing);
            }
          });
        })
      )
      .subscribe();
  }

  handleUploadNewLogo() {
    document.getElementById("companyLogo").click();
    this.fval.companyLogo.markAsTouched();
    this.fval.companyLogo.markAsDirty();
  }

  setCompanyValues(company: Company): void {
    this.fval.companyName.setValue(company.companyName);
    this.fval.companyBoxNumber.setValue(company.companyBoxNumber);
    this.fval.companyCityLocation.setValue(company.companyCityLocation);
    this.fval.companyCountryLocation.setValue(company.companyCountryLocation);
    this.fval.companyRegionLocation.setValue(company.companyRegionLocation);
    this.fval.companyOfficeFloor.setValue(company.companyOfficeFloor);
    this.fval.companyPlotNumber.setValue(company.companyPlotNumber);
    this.fval.companyStreetBuilding.setValue(company.companyStreetBuilding);
    this.fval.companyEmail1.setValue(company.companyEmail1);
    this.fval.companyEmail2.setValue(company.companyEmail2);
    this.fval.companyPhoneContact1.setValue(company.companyPhoneContact1);
    this.fval.companyPhoneContact2.setValue(company.companyPhoneContact2);
    this.fval.companyStreetName.setValue(company.companyStreetName);
    if (company.companyLogoUrl) {
      this.logoUrl = company.companyLogoUrl;
      this.fval.companyLogo.setValidators([]);
      this.fval.companyLogo.updateValueAndValidity();
    }
  }

  createCompany(updatedLogo?: boolean): void {
    if (!this.companyForm.valid) return;
    this.loading = true;
    const companyDetails: CreateCompany = {
      companyName: this.fval.companyName.value.toUpperCase(),
      companyBoxNumber: this.fval.companyBoxNumber.value.toUpperCase(),
      companyCityLocation: this.fval.companyCityLocation.value.toUpperCase(),
      companyCountryLocation:
        this.fval.companyCountryLocation.value.toUpperCase(),
      companyRegionLocation:
        this.fval.companyRegionLocation.value.toUpperCase(),
      companyOfficeFloor: this.fval.companyOfficeFloor.value.toUpperCase(),
      companyPlotNumber: this.fval.companyPlotNumber.value.toUpperCase(),
      companyStreetBuilding:
        this.fval.companyStreetBuilding.value.toUpperCase(),
      companyStreetName: this.fval.companyStreetName.value.toUpperCase(),
      companyEmail1: this.fval.companyEmail1.value,
      companyEmail2:
        this.fval.companyEmail2.value || this.fval.companyEmail1.value,
      companyPhoneContact1: this.fval.companyPhoneContact1.value,
      companyPhoneContact2:
        this.fval.companyPhoneContact2.value ||
        this.fval.companyPhoneContact1.value,
      employeeId: this.User.employeeId,
      companyLogoUrl: this.logoUrl,
    };
    this.adminService.createCompany(companyDetails).subscribe(
      () => {
        this.loading = false;
        const action: string = this.isEditing ? "update" : "setup";
        localStorage.setItem(
          COMPANY_TOKEN,
          JSON.stringify({
            ...JSON.parse(localStorage.getItem(COMPANY_TOKEN)),
            ...companyDetails,
          })
        );
        this.adminService.company.next(
          JSON.parse(localStorage.getItem(COMPANY_TOKEN))
        );
        showToasterMessage(
          this.toaster,
          "success",
          "Company " +
            `${updatedLogo ? "Logo " : ""}` +
            action +
            " was Successful"
        );
      },

      (error: string) => {
        this.loading = false;
        showToasterMessage(this.toaster, "warning", error);
      }
    );
  }
}
