<div class="d-flex flex-column justify-content-between align-items-center"
     style="height: 100vh; background-color: lightpink">
  <div class="mt-5"></div>
  <div class="card mt-3">
    <h2 class="d-flex flex-row justify-content-center mt-3">
      <i class="fas fa-sign-in-alt text-info mx-auto"></i>
    </h2>
    <h3 class="auth-head card-title text-info d-flex justify-content-center">
      <span>SIGN UP</span>
    </h3>
    <div class="card-body py-2 mb-0">
      <form (ngSubmit)="onSubmit()" [formGroup]="userForm">
        <div class="form-group text-primary">
          <label [ngClass]="fval.employeeName.valid ? 'text-info' : ''"
                 for="employeeName"
          >Name<i *ngIf="fval.employeeName.valid" class="material-icons"
          >check_circle</i
          ></label
          >
          <div class="input-block mb-0">
            <input
              [ngClass]="{
                'is-invalid':
                  (fval.employeeName.touched || fval.employeeName.dirty) &&
                  fval.employeeName.errors
              }"
              autocomplete="off"
              class="form-control shadow" formControlName="employeeName"
              id="employeeName"
              placeholder="Enter client name"
              required type="text"
            >
            <i class="start fas fa-user text-primary mx-auto">
            </i>
          </div>
          <small
            *ngIf="
              (fval.employeeName.touched || fval.employeeName.dirty) &&
              fval.employeeName.errors?.required
            "
            class="text-danger col"
          >
            Your name is required
          </small>
        </div>
        <div class="form-group text-primary">
          <label [ngClass]="fval.employeePhone1.valid ? 'text-info' : ''"
                 for="employeePhone1"
          >Phone number<i
            *ngIf="fval.employeePhone1.valid"
            class="material-icons"
          >check_circle</i
          ></label
          >
          <div class="input-block mb-0">
            <input
              [ngClass]="{
                'is-invalid':
                  (fval.employeePhone1.touched ||
                    fval.employeePhone1.dirty) &&
                  fval.employeePhone1.errors
              }"
              autocomplete="off"
              class="form-control shadow"
              formControlName="employeePhone1"
              id="employeePhone1"
              placeholder="Enter phone number"
              required type="text"
            >
            <i
              class="start fas fa-phone text-primary mx-auto"
            ></i>
            <small
              *ngIf="
                (fval.employeePhone1.touched ||
                fval.employeePhone1.dirty) &&
                fval.employeePhone1.errors?.required
                "
              class="text-danger col"
            >
              Your phone number is required
            </small>
            <!-- <small
            class="text-secondary col"
            >
            The phone number should be equal to 10 "digits"
          </small> -->
          </div>
        </div>
        <div class="form-group text-primary">
          <label [ngClass]="{'text-info': fval.employeeEmail.valid }" for="employeeEmail"
          >Email<i *ngIf="fval.employeeEmail.valid" class="material-icons"
          >check_circle</i
          ></label
          >
          <div class="input-block mb-0">
            <input
              [ngClass]="{
                'is-invalid':
                  (fval.employeeEmail.touched || fval.employeeEmail.dirty) && fval.employeeEmail.errors
              }"
              autocomplete="off"
              class="form-control shadow"
              formControlName="employeeEmail"
              id="employeeEmail"
              placeholder="Enter email"
              required type="text"
            >
            <i
              class="start fas fa-envelope text-primary mx-auto"
            ></i>
          </div>
          <small
            *ngIf="
              (fval.employeeEmail.touched || fval.employeeEmail.dirty) &&
              fval.employeeEmail.errors?.required
            "
            class="text-danger col"
          >
            Your email address is required
          </small>
          <small
            *ngIf="
              (fval.employeeEmail.touched || fval.employeeEmail.dirty) &&
              fval.employeeEmail.errors?.validEmail
            "
            class="text-danger col"
          >
            Please enter a valid email address
          </small>
        </div>
        <div class="form-group text-primary">
          <label [ngClass]="fval.employeeBranchId.valid ? 'text-info' : ''">
            <i *ngIf="fval.employeeBranchId.valid" class="material-icons">check_circle</i>
            Project
          </label>
          <div class="input-block mb-0">
            <select
              [ngClass]="{
              'is-invalid':
                (fval.employeeBranchId.touched || fval.employeeBranchId.dirty) && fval.employeeBranchId.errors
            }"
              autocomplete="off"
              class="form-control shadow"
              formControlName="employeeBranchId"
              required type="text"
            >
              <option class="place-holder-select" disabled hidden selected value="">Select a project</option>
              <option *ngFor="let branch of branches" [value]="branch.branchId">{{branch.branchName}}</option>
            </select>
          </div>
          <small
            *ngIf="
              (fval.employeeBranchId.touched || fval.employeeBranchId.dirty) && fval.employeeBranchId.errors?.required
            "
            class="text-danger col"
          >
            The branch name is required
          </small>
        </div>
        <div class="form-group text-primary">
          <label
            [ngClass]="fval.password.valid ? 'text-info' : ''"
            for="password"
          >Password<i *ngIf="fval.password.valid" class="material-icons"
          >check_circle</i
          ></label
          >

          <div class="input-block mb-0">
            <i
              class="start fas fa-lock text-primary mx-auto"
              style="size: 50px"
            ></i>
            <input
              [ngClass]="{
                'is-invalid':
                  (fval.password.touched || fval.password.dirty) &&
                  fval.password.errors
              }"
              [type]="fieldType ? 'text' : 'password'"
              autocomplete="off"
              class="form-control shadow"
              formControlName="password"
              id="password"
              placeholder="Enter Password"
            />
            <i
              (click)="toggleFieldType()"
              [ngClass]="{
                  'fa-eye-slash': !fieldType,
                  'fa-eye': fieldType
                }"
              class="end fas"
            ></i>
          </div>
          <div>
            <small
              *ngIf="
              (fval.password.touched || fval.password.dirty) &&
              fval.password.errors?.required
            "
              class="text-danger col"
            >
              Your Password is Required
            </small>
          </div>
          <div>
            <small
              *ngIf="
              (fval.password.touched || fval.password.dirty) &&
              fval.password.errors?.minlength
            "
              class="text-danger col"
            >
              Password should have a minimum of 8 characters
            </small>
          </div>
          <div>
            <small
              *ngIf="
              (fval.password.touched || fval.password.dirty) &&
              fval.password.errors?.hasCapitalCase
            "
              class="text-danger col"
            >
              Password should contain a capital letter
            </small>
          </div>
          <div>
            <small
              *ngIf="
              (fval.password.touched || fval.password.dirty) &&
              fval.password.errors?.hasSmallCase
            "
              class="text-danger col"
            >
              Password should contain a small letter
            </small>
          </div>
          <div>
            <small
              *ngIf="
              (fval.password.touched || fval.password.dirty) &&
              fval.password.errors?.hasSpecialCharacters
            "
              class="text-danger col"
            >
              Password should contain a special character
            </small>
          </div>
        </div>
        <div class="form-group text-primary">
          <label [ngClass]="fval.confirmPassword.valid ? 'text-info' : ''"
                 for="confirmPassword"
          >Confirm Password<i
            *ngIf="fval.confirmPassword.valid"
            class="material-icons"
          >check_circle</i
          ></label
          >
          <div class="input-block mb-0">
            <i
              class="start fas fa-lock text-primary mx-auto"
            ></i>
            <input
              [ngClass]="{
                  'is-invalid':
                    (fval.confirmPassword.touched ||
                      fval.confirmPassword.dirty) &&
                    fval.confirmPassword.errors
                }"
              [type]="fieldType ? 'text' : 'password'"
              autocomplete="off"
              class="form-control shadow"
              formControlName="confirmPassword"
              id="confirmPassword"
              placeholder="Confirm Password" required
            >
            <i
              (click)="toggleFieldType()"
              [ngClass]="{
                      'fa-eye-slash': !fieldType,
                      'fa-eye': fieldType
                    }"
              class="end fas"
            ></i>
          </div>

          <small
            *ngIf="fval.confirmPassword.errors?.NoPasswordMatch"
            class="text-danger col"
          >
            Passwords do not match
          </small>
          <small
            *ngIf="
                fval.confirmPassword.errors?.required &&
                (fval.confirmPassword.touched || fval.confirmPassword.dirty)
              "
            class="text-danger col"
          >
            Please confirm your password
          </small>
        </div>
        <div class="d-flex flex-row justify-content-between mb-4">
          <button
            [disabled]="loading || !userForm.valid"
            class="btn btn-primary col-5 shadow col-5"
            type="submit"
          >
            REGISTER
          </button>
          <button
            (click)="userForm.reset()"
            class="btn btn-primary col-5 shadow"
            routerLink="/auth/employee-login"
            type="button"
          >
            LOGIN
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="small text-center text-info mb-5">
    Copyright &copy; {{2022}} - stat-solutions Ltd
  </div>
</div>
