<div class="container">
  <div class="card mt-0 mb-3 pt-0 shadow mx-lg-3">
    <div class="card-title mt-0">
      <h4
        class="text-info text-right text-uppercase font-weight-bold pt-1 pb-0 px-3"
      >
        customer approval
      </h4>
    </div>
    <div class="card-body pt-0 pb-1 mx-0 px-2">
      <div class="row d-flex flex-row justify-content-center">
        <div
          class="col-12 col-md-4 pt-0 px-0 px-md-1 d-flex justify-content-start"
        >
          <pagination-controls
            (pageBoundsCorrection)="pageChanged($event)"
            (pageChange)="pageChanged($event)"
            autoHide="true"
            class="mt-1"
            directionLinks="true"
            maxSize="9"
            nextLabel="Next"
            previousLabel="Previous"
            responsive="true"
            screenReaderCurrentLabel="Page"
            screenReaderPageLabel="page"
            screenReaderPaginationLabel="Pagination"
          >
          </pagination-controls>
        </div>
        <div class="col-6 col-md-4">
          <div class="mt-1 mb-2 mb-md-3 mx-0 mx-md-2 pull-right">
            <select
              (change)="onChangeKind($event)"
              autocomplete="off"
              class="form-control px-2 px-lg-3 shadow"
              id="customerSectorKind"
              type="text"
            >
              <option [value]="1" selected>Newly Created</option>
              <option [value]="2">Updated Customers</option>
            </select>
          </div>
        </div>
        <div class="col-6 col-md-4">
          <div class="mt-1 mb-2 mb-md-3 mx-0 mx-md-2 mr-xl-3 pull-right">
            <input
              (input)="getValue($event)"
              autocomplete="on"
              class="form-control px-3 shadow"
              id="search"
              name=""
              placeholder="Search"
              type="text"
            />
          </div>
        </div>
      </div>
      <div class="invest-card mt-2 py-4 mb-5 mx-0 mx-sm-auto px-1 px-lg-auto">
        <div *ngIf="!customers.length" class="row">
          <div class="col-12">
            <h5 class="text-center font-weight-bold py-1">
              {{
                loading
                  ? "Loading ......."
                  : "There are no customers to approve or reject"
              }}
            </h5>
          </div>
        </div>
      </div>
      <div *ngIf="userForm && customers.length && !loading"
       class="invest-card mt-0 mb-3 pt-3 pt-md-2 pb-4 pb-md-3 px-2">
        <form [formGroup]="userForm">
          <div class="row mb-2">
            <!--select all items-->
            <div class="col-12 ml-0 pl-0 mx-sm-auto px-sm-auto pt-1">
              <input
                (click)="checkAllItems(!fval.selectAll.value)"
                class="ml-4 text-info"
                formControlName="selectAll"
                id="allSelected"
                tooltip="Select All Customers"
                type="checkbox"
                value=""
              />
              Select All
            </div>
          </div>
          <!-- record container-->
          <div class="iteration mb-1" formArrayName="approvalItems"
          *ngFor="
            let itemForm of approvalItemsVal.controls
              | paginate
                : {
                    itemsPerPage: pageSize,
                    currentPage: currentPage,
                    totalItems: totalItems
                  };
            let idx = index
          "
          [formGroupName]="idx"
          >
            <div class="item-container my-1">
              <div class="list-card card mt-1 py-1 mb-0 mx-0 px-0 shadow">
                <div class="list-card-body card-body my-0 py-0 mx-0 mx-lg-2">
                  <div class="row my-0 py-0">
                    <input
                    (click)="deselectAll(idx)"
                      class="mt-3 ml-1 ml-xl-3 text-info"
                      formControlName="approved"
                      type="checkbox"
                      value=""
                    />
                    <div class="col-2 col-md-1 mx-0 px-0">
                      <img
                      alt="customer photo"
                      class="img-circle mt-2 mt-xl-1 mb-1 pb-0 mx-2 mx-sm-4"
                      [src]="
                        itemForm.controls.customerPhotoUrl.value ||
                        'assets/img/man.svg'
                      "
                      />
                    </div>
                    <div class="break-text col-9 col-md-10 mx-xl-0 px-xl-0">
                      <div class="row">
                        <div class="col-6 col-md-4">
                          <div class="font-weight-bold text-red">
                            <span class="">
                              {{ itemForm.controls.customerId.value }}
                            </span>
                          </div>
                          <div class="text-primary">
                            <span class="">Customer ID</span>
                          </div>
                        </div>
                        <div class="col-6 col-md-4 mr-xl-0 pr-xl-0">
                          <div
                            class="d-flex justify-content-end font-weight-bold text-red"
                          >
                            <span class="">
                              {{ itemForm.controls.customerPhone1.value }}
                            </span>
                          </div>
                          <div class="d-flex justify-content-end text-primary">
                            <span class="">Phone No.</span>
                          </div>
                        </div>
                        <div class="col-12 col-md-4 mr-md-0 pr-md-0">
                          <div
                            class="d-flex justify-content-md-end font-weight-bold text-red"
                          >
                            <span class="">
                              {{ itemForm.controls.customerName.value }}
                            </span>
                          </div>
                          <div
                            class="d-flex justify-content-md-end text-primary"
                          >
                            <span class="">Customer</span>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-xl-4">
                          <div class="font-weight-bold text-red">
                            <span class="">
                              {{ itemForm.controls.customerEmail.value }}
                            </span>
                          </div>
                          <div class="text-primary">
                            <span class="">Email</span>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-xl-4 mr-md-0 pr-md-0">
                          <div
                            class="d-flex justify-content-md-end font-weight-bold text-red"
                          >
                            <span class="">
                              {{itemForm.controls.branchName.value}}
                            </span>
                          </div>
                          <div
                            class="d-flex justify-content-md-end text-primary"
                          >
                            <span class="">Customer Branch</span>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-xl-4 mr-md-0 pr-md-0">
                          <div
                            class="d-flex justify-content-lg-end font-weight-bold text-red"
                          >
                            <span class="">
                              {{itemForm.controls.relationshipManagerName.value}}
                            </span>
                          </div>
                          <div
                            class="d-flex justify-content-lg-end text-primary"
                          >
                            <span class="">Relations Manager</span>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-xl-12 mr-md-0 pr-md-0">
                          <div
                            class="d-flex justify-content-md-end font-weight-bold text-red"
                          >
                            <span class="">
                              {{itemForm.controls.relationshipManagerContact.value}}
                            </span>
                          </div>
                          <div
                            class="d-flex justify-content-md-end text-primary"
                          >
                            <span class="">Relations Manager Contact</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--/ records container-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div *ngIf="customers.length && !loading"
        class="d-flex justify-content-center mt-3 mb-0 py-2">
          <button
            (click)="approveRejectItems(true)"
            [disabled]="loading"
            class="btn btn-primary col-4 col-md-2 mr-2 shadow"
          >
            <i class="fas fa-check"></i> Approve
          </button>
          <button
            (click)="approveRejectItems(false)"
            *ngIf="whichKind == 1"
            class="btn btn-danger col-4 col-md-2 shadow"
          >
            <i class="fas fa-times"></i> Reject
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
