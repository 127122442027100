<div class="d-flex flex-column">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div
          class="tickets-card2 card shadow-lg mt-2 mb-0 pt-2 pb-1 px-4 px-md-5"
        >
          <div class="row d-flex justify-content-center">
            <div class="col-12 pt-0">
              <h4 class="text-info text-uppercase font-weight-bold pull-right">
                <hr class="top mt-1 mb-0" />
                cost jobs
                <hr class="bottom my-0" />
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="rfqs card shadow mt-2 mb-4 mx-md-0 px-md-0">
          <div class="card-body pt-2 mx-auto mx-md-1 px-1">
            <tabset>
              <tab heading="Pending" class="rfq-listing text-info">
                <div class="pt-3 px-2">
                  <input
                    type="text"
                    class="form-control col-md-7 col-xl-4"
                    [(ngModel)]="userFilter.clientName"
                    placeholder="Search"
                  />

                  <!--record container-->
                  <!--to be displayed if no rfqs to work on-->
                  <!-- <div
                      class="invest-card card my-2 py-2 mx-auto px-2 shadow-lg"
                    >
                      <div class="col-12 my-5 py-5 justify-content-between">
                        <h4 class="text-center text-info">
                          No Received Loans yet
                        </h4>
                      </div>
                    </div> -->
                  <div
                    class="invest-card card mt-1 py-2 mb-0 mx-auto px-2 shadow-lg"
                  >
                    <div class="iteration">
                      <div class="item-container-1 mt-1">
                        <div
                          class="list-card card mt-1 pt-2 mb-0 mx-auto px-0 pb-1 shadow-lg"
                          *ngFor="
                            let summu of rfqSummury$
                              | async
                              | filterBy: userFilter;
                            let i = index;
                            let odd = odd;
                            let first = first;
                            let last = last
                          "
                          [class.text-secondary]="odd"
                          [class.text-success]="!odd"
                        >
                          <div class="list-card-body card-body my-0 py-0 mx-0">
                            <div
                              class="row my-0 pt-md-0 pb-md-1 mx-md-0 px-md-0"
                            >
                              <div
                                class="col-1 pl-1 pr-0 mx-md-0 px-md-0 d-flex justify-content-center"
                              >
                                <div class="dropdown">
                                  <button
                                    class="btn btn-outline-primary dropdown-toggle"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    tooltip="Options"
                                  >
                                    <i
                                      class="fas fa-ellipsis-v fa-2x text-info"
                                    ></i>
                                  </button>
                                  <div
                                    class="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <a
                                      class="dropdown-item text-primo pointerCursor"
                                      (click)="openModal(costjob)"
                                      ><i class="fas fa-dollar"></i>
                                      Cost
                                    </a>
                                    <!-- <a
                                  class="dropdown-item text-primo-1 pointerCursor"
                                  ><i class="fas fa-chevron-right"></i>
                                  Approve
                                </a> -->
                                  </div>
                                </div>
                              </div>
                              <div class="col-11 mx-lg-0 px-lg-0">
                                <div class="row mx-md-0 px-md-0">
                                  <div
                                    class="col-12 col-md-6 col-xl-3 mx-md-0 px-md-0"
                                  >
                                    <div class="row">
                                      <div
                                        class="col-5 col-md-4 col-lg-3 col-xl-4 d-flex justify-content-start mx-md-0 px-md-0"
                                      >
                                        <span class="">Serial No.:</span>
                                      </div>
                                      <div
                                        class="col-7 col-md-8 col-lg-9 col-xl-8 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                      >
                                        <span class="itemCreated"
                                          >{{ summu.rfqSerialNumber }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-12 col-md-6 col-xl-5 mx-md-0 px-md-0"
                                  >
                                    <div class="row">
                                      <div
                                        class="col-4 col-md-3 col-xl-2 d-flex justify-content-start mx-md-0 px-md-0"
                                      >
                                        <span class="">Client:</span>
                                      </div>
                                      <div
                                        class="col-8 col-md-9 col-xl-10 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                      >
                                        <span class="itemCreated"
                                          >{{ summu.clientColRef?.clientName }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0"
                                  >
                                    <div class="row">
                                      <div
                                        class="col-5 col-md-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                                      >
                                        <span class="">Source:</span>
                                      </div>
                                      <div
                                        class="col-7 col-md-8 col-lg-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                      >
                                        <span class="itemCreated"
                                          >{{ summu.rfqSource }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0"
                                  >
                                    <div class="row">
                                      <div
                                        class="col-6 col-md-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                                      >
                                        <span class="">No. Of Items:</span>
                                      </div>
                                      <div
                                        class="col-6 col-md-8 col-lg-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                      >
                                        <span class="itemCreated"
                                          >{{ summu.noOfItems }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0"
                                  >
                                    <div class="row">
                                      <div
                                        class="col-6 col-md-4 col-lg-3 col-xl-4 d-flex justify-content-start mx-md-0 px-md-0"
                                      >
                                        <span class="">Total Amount:</span>
                                      </div>
                                      <div
                                        class="col-6 col-md-8 col-lg-9 col-xl-8 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                      >
                                        <span class="itemCreated"
                                          >{{ summu.totalAmount }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0"
                                  >
                                    <div class="row">
                                      <div
                                        class="col-6 col-md-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                                      >
                                        <span class="">Department:</span>
                                      </div>
                                      <div
                                        class="col-6 col-md-8 col-lg-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                      >
                                        <span class="itemCreated"
                                          >{{ summu.empColRef?.department }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0"
                                  >
                                    <div class="row">
                                      <div
                                        class="col-5 col-md-4 col-lg-3 col-xl-4 d-flex justify-content-start mx-md-0 px-md-0"
                                      >
                                        <span class="">Username:</span>
                                      </div>
                                      <div
                                        class="col-7 col-md-8 col-lg-9 col-xl-8 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                      >
                                        <span class="itemCreated">
                                          {{ summu.empColRef?.userName }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--/ end of record container-->
                </div>
              </tab>
              <tab heading="Costed" class="rfq-status text-info">
                <div class="pt-3 px-2">
                  <input
                    type="text"
                    class="form-control col-md-7 col-xl-4"
                    [(ngModel)]="userFilter.clientName"
                    placeholder="Search"
                  />

                  <!--record container-->
                  <!--to be displayed if no rfqs to work on-->
                  <!-- <div
                      class="invest-card card my-2 py-2 mx-auto px-2 shadow-lg"
                    >
                      <div class="col-12 my-5 py-5 justify-content-between">
                        <h4 class="text-center text-info">
                          No Received Loans yet
                        </h4>
                      </div>
                    </div> -->
                  <div
                    class="invest-card card mt-1 py-2 mb-0 mx-auto px-2 shadow-lg"
                  >
                    <div class="iteration">
                      <div class="item-container-1 mt-1">
                        <div
                          class="list-card card mt-1 pt-2 mb-0 mx-auto px-0 pb-1 shadow-lg"
                          *ngFor="
                            let summu of rfqSummury$
                              | async
                              | filterBy: userFilter;
                            let i = index;
                            let odd = odd;
                            let first = first;
                            let last = last
                          "
                          [class.text-secondary]="odd"
                          [class.text-success]="!odd"
                        >
                          <div class="list-card-body card-body my-0 py-0 mx-0">
                            <div
                              class="row my-0 pt-md-0 pb-md-1 mx-md-0 px-md-0"
                            >
                              <div
                                class="col-1 pl-1 pr-0 mx-md-0 px-md-0 d-flex justify-content-center"
                              >
                                <div class="dropdown">
                                  <button
                                    class="btn btn-outline-primary dropdown-toggle"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    tooltip="Options"
                                  >
                                    <i
                                      class="fas fa-ellipsis-v fa-2x text-info"
                                    ></i>
                                  </button>
                                  <div
                                    class="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <a
                                      class="dropdown-item text-primo pointerCursor"
                                      (click)="openModal(costjob)"
                                      ><i class="fas fa-chevron-right"></i>
                                      Forward
                                    </a>
                                    <!-- <a
                                  class="dropdown-item text-primo-1 pointerCursor"
                                  ><i class="fas fa-chevron-right"></i>
                                  Approve
                                </a> -->
                                  </div>
                                </div>
                              </div>
                              <div class="col-11 mx-lg-0 px-lg-0">
                                <div class="row mx-md-0 px-md-0">
                                  <div
                                    class="col-12 col-md-6 col-xl-3 mx-md-0 px-md-0"
                                  >
                                    <div class="row">
                                      <div
                                        class="col-5 col-md-4 col-lg-3 col-xl-4 d-flex justify-content-start mx-md-0 px-md-0"
                                      >
                                        <span class="">Serial No.:</span>
                                      </div>
                                      <div
                                        class="col-7 col-md-8 col-lg-9 col-xl-8 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                      >
                                        <span class="itemCreated"
                                          >{{ summu.rfqSerialNumber }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-12 col-md-6 col-xl-5 mx-md-0 px-md-0"
                                  >
                                    <div class="row">
                                      <div
                                        class="col-4 col-md-3 col-xl-2 d-flex justify-content-start mx-md-0 px-md-0"
                                      >
                                        <span class="">Client:</span>
                                      </div>
                                      <div
                                        class="col-8 col-md-9 col-xl-10 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                      >
                                        <span class="itemCreated"
                                          >{{ summu.clientColRef?.clientName }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0"
                                  >
                                    <div class="row">
                                      <div
                                        class="col-5 col-md-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                                      >
                                        <span class="">Source:</span>
                                      </div>
                                      <div
                                        class="col-7 col-md-8 col-lg-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                      >
                                        <span class="itemCreated"
                                          >{{ summu.rfqSource }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0"
                                  >
                                    <div class="row">
                                      <div
                                        class="col-6 col-md-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                                      >
                                        <span class="">No. Of Items:</span>
                                      </div>
                                      <div
                                        class="col-6 col-md-8 col-lg-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                      >
                                        <span class="itemCreated"
                                          >{{ summu.noOfItems }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0"
                                  >
                                    <div class="row">
                                      <div
                                        class="col-6 col-md-4 col-lg-3 col-xl-4 d-flex justify-content-start mx-md-0 px-md-0"
                                      >
                                        <span class="">Total Amount:</span>
                                      </div>
                                      <div
                                        class="col-6 col-md-8 col-lg-9 col-xl-8 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                      >
                                        <span class="itemCreated"
                                          >{{ summu.totalAmount }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0"
                                  >
                                    <div class="row">
                                      <div
                                        class="col-6 col-md-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                                      >
                                        <span class="">Department:</span>
                                      </div>
                                      <div
                                        class="col-6 col-md-8 col-lg-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                      >
                                        <span class="itemCreated"
                                          >{{ summu.empColRef?.department }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0"
                                  >
                                    <div class="row">
                                      <div
                                        class="col-5 col-md-4 col-lg-3 col-xl-4 d-flex justify-content-start mx-md-0 px-md-0"
                                      >
                                        <span class="">Username:</span>
                                      </div>
                                      <div
                                        class="col-7 col-md-8 col-lg-9 col-xl-8 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                      >
                                        <span class="itemCreated">
                                          {{ summu.empColRef?.userName }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--/ end of record container-->
                </div>
              </tab>
            </tabset>
          </div>
          <!-- </div> -->
          <!-- /.card-body -->
        </div>
      </div>
      <!-- </div> -->

      <ng-template #costjob>
        <div class="modal-header bg-info text-white close">
          <h4 class="modal-title">Cost Job</h4>
          <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="modalRef.hide()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <!-- <div class="form-group">
            <input
              type="text"
              class="form-control col-md-5"
              [(ngModel)]="userFilter.clientName"
              placeholder="Enter Client Phone Number"
            />
          </div> -->
          <div class="table-responsive my-2">
            <!--record container-->
            <div class="invest-card card mt-1 py-2 mb-2 mx-auto px-2 shadow-lg">
              <fieldset
                name="item"
                class="border border-secondary px-1 px-lg-4 px-xl-2 pt-0 pt-md-2 pb-3 mb-1"
              >
                <!-- <legend class="text-info">Cost</legend> -->
                <div class="item-container-1 mt-1">
                  <div
                    class="list-card-1 card mt-1 py-2 mb-0 mx-auto px-0 shadow-lg"
                  >
                    <div class="list-card-body card-body my-0 py-0">
                      <div class="row my-0 py-0">
                        <div class="col-10 col-md-11">
                          <div class="row mx-md-0">
                            <div
                              class="col-12 col-md-6 col-xl-4 mx-xl-0 px-xl-0"
                            >
                              <div class="row">
                                <div
                                  class="col-3 col-lg-2 d-flex justify-content-start mx-0 px-0"
                                >
                                  <span class="">Item ID:</span>
                                </div>
                                <div
                                  class="col-9 col-lg-10 d-flex justify-content-start font-weight-bold mx-md-0 px-md-0"
                                >
                                  <span class="itemCreated"></span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-3 col-xl-2 d-flex justify-content-start mx-0 px-0"
                                >
                                  <span class="">Item:</span>
                                </div>
                                <div
                                  class="col-9 col-xl-10 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"> </span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-6 col-xl-4 mx-xl-0 px-xl-0"
                            >
                              <div class="row">
                                <div
                                  class="col-3 col-lg-2 d-flex justify-content-start mx-0 px-0"
                                >
                                  <span class="">Unit:</span>
                                </div>
                                <div
                                  class="col-9 col-lg-10 d-flex justify-content-start font-weight-bold mx-md-0 px-md-0"
                                >
                                  <span class="itemCreated"> </span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-4 col-lg-3 d-flex justify-content-start mx-0 px-0"
                                >
                                  <span class="">Unit Cost:</span>
                                </div>
                                <div
                                  class="col-8 col-lg-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"> </span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-6 col-xl-4 mx-xl-0 px-xl-0"
                            >
                              <div class="row">
                                <div
                                  class="col-4 col-xl-2 d-flex justify-content-start mx-0 px-0"
                                >
                                  <span class="">Quantity:</span>
                                </div>
                                <div
                                  class="col-8 col-xl-10 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"> </span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-4 col-xl-3 d-flex justify-content-start mx-0 px-0"
                                >
                                  <span class="">Total Cost:</span>
                                </div>
                                <div
                                  class="col-8 col-xl-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"> </span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-6 col-xl-5 mx-xl-0 px-xl-0"
                            >
                              <div class="row">
                                <div
                                  class="col-4 col-md-3 d-flex justify-content-start mx-0 px-0"
                                >
                                  <span class="">Unit Price:</span>
                                </div>
                                <div
                                  class="col-8 col-md-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated">
                                    <input
                                      class="form-control form-control-sm"
                                      formControlName="clientName"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-6 col-xl-5 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-4 col-xl-3 d-flex justify-content-start mx-0 px-0"
                                >
                                  <span class="">Total Price:</span>
                                </div>
                                <div
                                  class="col-8 col-xl-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"> </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-2 col-md-1">
                          <div
                            class="d-flex justify-content-start mt-0 pt-1 mx-auto"
                          >
                            <button
                              type="button"
                              class="btn btn-secondary mx-auto"
                              tooltip="Edit"
                            >
                              <i class="fas fa-edit"></i>
                            </button>
                          </div>
                          <div
                            class="d-flex justify-content-start mt-0 pt-1 mx-auto"
                          >
                            <button
                              type="button"
                              class="btn btn-secondary mx-auto"
                              tooltip="Edit"
                            >
                              <i class="fas fa-save" tooltip="Save"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
              <div class="col-12">
                <div class="d-flex justify-content-center mt-2 pt-1 mx-auto">
                  <button type="button" class="btn btn-primary mx-auto">
                    Complete Costing
                  </button>
                  <!-- <button
                  type="button"
                  class="btn btn-danger col-5 col-md-3 col-xl-2 mx-auto"
                >
                  <i class="fas fa-times"></i>
                  Cancel
                </button> -->
                </div>
              </div>
            </div>
            <!--/ end of record container-->
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
