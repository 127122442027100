import { Component, OnInit } from "@angular/core";
import { MenuItem } from "../../shared/layout/left-panel/left-panel.component";

@Component({
  selector: "app-supplier-index",
  templateUrl: "./supplier-index.component.html",
  styleUrls: ["./supplier-index.component.scss"],
})
export class SupplierIndexComponent implements OnInit {
  menuList: MenuItem[];
  moduleName: string;
  constructor() {}

  ngOnInit(): void {
    this.moduleName = "SUPPLIER";
    this.menuList = [
      {
        name: "Dashboard",
        icon: "fas fa-tachometer-alt",
        url: "/supplier/dashboard",
      },
      {
        name: "Register Suppliers",
        icon: "fas fa-employees",
        url: "/supplier/profile",
      },
      {
        name: "Reports",
        icon: "fas fa-chart-line",
        url: "/supplier/reports",
      },
      {
        name: "Notifications",
        icon: "fas fa-envelope",
        url: "/supplier/notifications",
        badge: "8",
        badgeBg: "bg-warning",
      },
    ];
  }
}
