<div class="d-flex flex-column">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="title-card shadow mt-2 mb-0 pt-2 pb-0 px-4 px-md-5 mx-lg-3">
          <div class="row d-flex justify-content-between">
            <div class="col-12 pt-0">
              <h3 class="text-info text-uppercase font-weight-bold pull-right">
                processes
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="col-12 col-md-7 col-lg-8 mx-0 mx-lg-3 pt-0 px-4 px-md-3 d-flex justify-content-start"
      >
        <a class="text-info mb-1" routerLink="list">
          <button
            class="btn btn-primary my-2 ml-1 mx-sm-1 shadow"
          >
            <i class="fas fa-eye"></i> View All
          </button>
        </a>
        <a class="text-info mb-1" routerLink="create">
          <button
            class="btn btn-outline-info my-2 ml-1 mx-sm-1 shadow"
            tooltip="Create Process"
          >
            <i class="fas fa-edit"></i> Create
          </button>
        </a>
      </div>
    </div>
    <router-outlet>

    </router-outlet>
  </div>
</div>
