<div class="container">
  <div class="card mt-0 mb-3 pt-0 shadow mx-lg-3">
    <div class="card-title mt-0">
      <h4
        class="text-info text-right text-uppercase font-weight-bold pt-1 pb-0 px-3"
      >
        customer sector approval
      </h4>
    </div>
    <div class="card-body pt-0 pb-1 mx-0 px-2">
      <div class="row d-flex flex-row justify-content-center">
        <div
          class="col-12 col-md-4 pt-0 px-0 px-md-1 d-flex justify-content-start"
        >
          <pagination-controls
            (pageBoundsCorrection)="pageChanged($event)"
            (pageChange)="pageChanged($event)"
            autoHide="true"
            class="mt-1"
            directionLinks="true"
            maxSize="9"
            nextLabel="Next"
            previousLabel="Previous"
            responsive="true"
            screenReaderCurrentLabel="Page"
            screenReaderPageLabel="page"
            screenReaderPaginationLabel="Pagination"
          >
          </pagination-controls>
        </div>
        <div class="col-6 col-md-4">
          <div class="mt-1 mb-2 mb-md-3 mx-0 pull-right">
            <select
              id="customerSectorKind"
              autocomplete="off"
              class="form-control px-2 px-lg-3 shadow"
              type="text"
              (change)="onChangeKind($event)"
            >
              <option [value]="1" selected>Newly Created</option>
              <option [value]="2">Updated Sectors</option>
            </select>
          </div>
        </div>
        <div class="col-6 col-md-4">
          <div class="mt-1 mb-2 mb-md-3 mx-0 mr-md-2 mr-xl-3 pull-right">
            <input
              autocomplete="on"
              class="form-control px-3 shadow"
              id="search"
              (input)="getValue($event)"
              name=""
              placeholder="Search"
              type="text"
            />
          </div>
        </div>
      </div>
      <div
        class="invest-card mt-2 py-4 mb-5 mx-0 mx-sm-auto px-1 px-lg-auto"
        *ngIf="!approvals.length && !loading"
      >
        <div class="row" *ngIf="!approvals.length">
          <div class="col-12">
            <h5 class="text-center font-weight-bold py-1">
              There are no sectors to approve or reject
            </h5>
          </div>
        </div>
      </div>
      <div
       *ngIf="approvals.length && !loading"
       class="invest-card mt-0 mb-3 pt-3 pt-md-2 mx-auto pb-4 pb-md-3 px-2">
        <form [formGroup]="userForm">
          <div class="item-header mb-2">
            <div class="row">
              <div class="col-12 ml-0 pl-0 mx-sm-auto px-sm-auto pt-1">
                <input
                  id="allSelected"
                  formControlName="selectAll"
                  (click)="checkAllItems(!fval.selectAll.value)"
                  type="checkbox"
                  value=""
                  class="ml-4 text-info"
                  tooltip="Select All Loans"
                />
                Select All
              </div>
            </div>
          </div>
          <!--record container-->
          <div class="iteration mb-2" formArrayName="sectors">
            <div
            *ngFor="
              let item of sectorFormItmes.controls
              | paginate
                : {
                    itemsPerPage: pageSize,
                    currentPage: currentPage,
                    totalItems: totalItems
                  };
              let idx = index
            "
            [formGroupName]="idx"
             class="item-container my-1">
              <div
                class="list-card card mt-1 py-1 pt-md-1 pb-md-2 mb-0 mx-0 px-0 shadow"
              >
                <div class="list-card-body card-body my-0 py-0 mx-0 mx-md-2">
                  <div class="row my-0 py-0">
                    <div class="col-1 pt-2 mx-0 px-0">
                      <input
                      formControlName="approved"
                      type="checkbox"
                      value=""
                      class="ml-1 ml-xl-3 text-info"
                      (click)="deselectAll(idx)"
                      />
                    </div>
                    <div class="break-text col-11">
                      <div class="row mx-md-1">
                        <div class="col-6 col-lg-2">
                          <div class="font-weight-bold text-red">
                            <span class="">
                              {{item.controls.customerSectorId.value}}
                            </span>
                          </div>
                          <div class="text-primary">
                            <span class="">Sector ID</span>
                          </div>
                        </div>
                        <div class="col-6 col-lg-4">
                          <div
                            class="d-flex justify-content-end font-weight-bold text-red"
                          >
                            <span class="">
                              {{item.controls.customerSectorName.value}}
                            </span>
                          </div>
                          <div class="d-flex justify-content-end text-primary">
                            <span class="">Sector</span>
                          </div>
                        </div>
                        <div class="col-12 col-md-8 col-lg-4">
                          <div class="font-weight-bold text-red">
                            <span class="">
                              {{item.controls.customerSectorDescription.value}}
                            </span>
                          </div>
                          <div class="text-primary">
                            <span class="">Description</span>
                          </div>
                        </div>
                        <div class="col-12 col-md-4 col-lg-2">
                          <div
                            class="d-flex justify-content-md-end font-weight-bold text-red"
                          >
                            <span class="">
                              {{item.controls.customerSectorCreatedBy.value}}
                            </span>
                          </div>
                          <div
                            class="d-flex justify-content-md-end text-primary"
                          >
                            <span class="">Created By</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--/ records container-->
                </div>
              </div>
            </div>
          </div>
        </form>
        <div *ngIf="approvals.length && !loading"
        class="d-flex justify-content-center mt-3 mb-0 py-2">
          <button
            [disabled]="loadingAction"
            (click)="approveRejectItems(true)"
            class="btn btn-primary col-4 col-md-2 mr-2 shadow"
          >
            <i class="fas fa-check"></i> Approve
          </button>
          <button
            class="btn btn-danger col-4 col-md-2 shadow"
            *ngIf="whichKind == 1"
            [disabled]="loadingAction"
            (click)="approveRejectItems(false)"
          >
            <i class="fas fa-times"></i> Reject
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
