import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rp-suppliers',
  templateUrl: './rp-suppliers.component.html',
  styleUrls: ['./rp-suppliers.component.sass']
})
export class RpSuppliersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
