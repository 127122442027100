import { Component, OnInit } from '@angular/core';

@Component({
  selector: "app-approvals",
  templateUrl: "./approvals.component.html",
  styleUrls: ["./approvals.component.scss"],
})
export class ApprovalsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
