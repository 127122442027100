import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { CostJobsComponent } from "./components/cost-jobs/cost-jobs.component";
import { PayServiceProvidersComponent } from "./components/pay-service-providers/pay-service-providers.component";
import { ReceivablesPayablesComponent } from "./components/receivables-payables/receivables-payables.component";
import { RpClientsComponent } from "./components/receivables-payables/rp-clients/rp-clients.component";
import { RpSuppliersComponent } from "./components/receivables-payables/rp-suppliers/rp-suppliers.component";
import { NotificationsComponent } from "./components/notifications/notifications.component";
import { ReportsComponent } from "./components/reports/reports.component";
import { FinanceIndexComponent } from "./finance-index/finance-index.component";
import { ProfileComponent } from "../shared/components/profile/profile.component";
import { EmployeeProfileResolver } from "../shared/resolvers/employee-profile";
import { AccountsComponent } from "./components/accounts/accounts.component";
import { ListComponent } from "./components/accounts/c_o_accounts/list/list.component";
import { StatementComponent } from "./components/accounts/statement/statement.component";
import { PostingComponent } from "./components/accounts/posting/posting.component";
import { ChartOfAccountsComponent } from "./components/accounts/c_o_accounts/accounts.component";
import { MajorHeadersComponent } from "./components/accounts/c_o_accounts/major-headers/major-headers.component";
import { HeadersComponent } from "./components/accounts/c_o_accounts/headers/headers.component";

const routes: Routes = [
  {
    path: "",
    component: FinanceIndexComponent,
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: "dashboard",
      },
      {
        path: "dashboard",
        component: DashboardComponent,
      },
      {
        path: "accounts",
        component: AccountsComponent,
        children: [
          {
            path: "",
            pathMatch: "full",
            redirectTo: "c_o_accounts",
          },
          {
            path: "c_o_accounts",
            component: ChartOfAccountsComponent,
            children: [
              {
                path: "",
                pathMatch: "full",
                redirectTo: "list",
              },
              {
                path: "list",
                component: ListComponent,
              },
              {
                path: "major_headers",
                component: MajorHeadersComponent,
              },
              {
                path: "headers",
                component: HeadersComponent,
              },
            ],
          },
          {
            path: "postings",
            component: PostingComponent,
          },
          {
            path: "statements",
            component: StatementComponent,
          },
        ],
      },
      {
        path: "costjobs",
        component: CostJobsComponent,
      },
      {
        path: "paybills",
        component: PayServiceProvidersComponent,
      },
      {
        path: "R-and-Ps",
        component: ReceivablesPayablesComponent,
        children: [
          {
            path: "suppliers",
            component: RpSuppliersComponent,
          },
          {
            path: "clients",
            component: RpClientsComponent,
          },
        ],
      },
      {
        path: "reports",
        component: ReportsComponent,
      },
      {
        path: "profile",
        component: ProfileComponent,
        resolve: [EmployeeProfileResolver],
      },
      {
        path: "notifications",
        component: NotificationsComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FinanceRoutingModule {}
