import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from "src/app/modules/auth/services/auth.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import firebase from "firebase";
import { showToasterMessage } from "../../../utils/functions";
import { CustomValidator } from "../../../validators/custom-validator";
import { API_URLS } from "../../../utils/constants";
import { MatDialogRef } from "@angular/material/dialog";

export interface AccessRights {
  accessCategory: string;
  accessType: string;
  createdAt: firebase.firestore.FieldValue;
  updatedAt: firebase.firestore.FieldValue;
}

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit {
  loading = false;
  userForm: FormGroup;
  value: string;
  fieldType: boolean;
  serviceErrors: any = {};
  employeeEmail: string;
  PASSWORD_CODE_ENDPOINT = API_URLS.AUTH.EMPLOYEE.GET_PASSWORD_CHANGE_CODE;
  CHANGE_PASSWORD_ENDPOINT = API_URLS.AUTH.EMPLOYEE.POST_CHANGE_PASSWORD;

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private toaster: ToastrService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<ChangePasswordComponent>
  ) {}

  get fval() {
    return this.userForm.controls;
  }

  ngOnInit() {
    this.userForm = this.createFormGroup();
    this.employeeEmail = this.authService.loggedInUserInfo().employeeEmail;
    this.getPasswordResetCode();
  }

  getPasswordResetCode(): any {
    this.authService
      .passwordChangeCodeHandler(
        { employeeEmail: this.employeeEmail },
        this.PASSWORD_CODE_ENDPOINT
      )
      .subscribe(
        () => {
          this.loading = false;
          showToasterMessage(
            this.toaster,
            "success",
            "Check your email for a password reset code"
          );
        },
        (error: string) => {
          this.loading = false;
          showToasterMessage(this.toaster, "warning", error);
        }
      );
  }

  createFormGroup() {
    return new FormGroup(
      {
        code: new FormControl(
          "",
          Validators.compose([
            // 1. Password Field is Required
            CustomValidator.patternValidator(
              /^(([0-9])([0-9])([0-9])([0-9])([0-9]))$/,
              {
                hasNumber: true,
              }
            ),
            Validators.required,
            Validators.minLength(5),
            Validators.maxLength(5),
          ])
        ),
        password: new FormControl(
          "",
          Validators.compose([
            // 1. Password Field is Required

            Validators.required,

            // 2. check whether the entered password has a number
            //CustomValidatorInitialCompanySetup.patternValidator(/^(([1-9])([1-9])([1-9])([0-9]))$/, { hasNumber: true }),
            // 6. Has a minimum length of 8 characters
            Validators.minLength(8),
            //Validators.maxLength(4),
            // 3. check whether the entered password has upper case letter
            CustomValidator.patternValidator(/[A-Z]/, {
              hasCapitalCase: true,
            }),
            // 4. check whether the entered password has a lower-case letter
            CustomValidator.patternValidator(/[a-z]/, {
              hasSmallCase: true,
            }),
            // 5. check whether the entered password has a special character
            CustomValidator.patternValidator(/[!@#$%^&*_+-=;':"|,.<>/?]/, {
              hasSpecialCharacters: true,
            }),
          ])
        ),
        confirmPassword: new FormControl(
          "",
          Validators.compose([
            // 1. Password Field is Required
            Validators.required,
          ])
        ),
      },
      { validators: CustomValidator.passwordMatchValidator }
    );
  }

  revert() {
    this.userForm.reset();
  }

  cancel(): void {
    this.userForm.reset();
    this.dialogRef.close();
  }

  // toggle visibility of password field
  toggleFieldType(): any {
    this.fieldType = !this.fieldType;
  }

  changePassword() {
    this.loading = true;
    if (!this.userForm.valid) return;
    const data = {
      employeeEmail: this.employeeEmail,
      theChangeCode: Number(this.fval.code.value),
      employeePassword: this.fval.password.value,
    };
    this.authService
      .changePasswordHandler(data, this.CHANGE_PASSWORD_ENDPOINT)
      .subscribe(
        (data: string) => {
          this.loading = false;
          showToasterMessage(
            this.toaster,
            "success",
            "Password was changed Successfully"
          );
          this.userForm.reset();
          this.router.navigate(["employee/login"]).then();
        },
        (error: string) => {
          this.loading = false;
          showToasterMessage(
            this.toaster,
            "warning",
            error,
            "Password change failed!"
          );
        }
      );
  }
}
