import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ItemStock } from "src/app/modules/shared/models/other-models/rfqRelatedModels/item_stock.model";
import { RfqData } from "src/app/modules/shared/models/other-models/rfqRelatedModels/rfq-data.model";
import { CustomValidator } from "src/app/modules/shared/validators/custom-validator";

@Component({
  selector: "app-review-rfq",
  templateUrl: "./review-rfq.component.html",
  styleUrls: ["./review-rfq.component.scss"],
})
export class ReviewRfqComponent implements OnInit {
  @Input() rfqData: RfqData[];

  itemsForm: FormGroup;
  itemsStock: ItemStock[];
  isEditable = false;
  edited = false;
  removEditTable = true;

  constructor(private _formbuilder: FormBuilder, private router: Router) {}

  //get function for itemsForm form controls
  get fcont() {
    return this.itemsForm.controls;
  }

  ngOnInit() {
    this.itemsForm = this.createFormGroup();
  }

  createFormGroup() {
    return this._formbuilder.group({
      stockId: [
        "",
        Validators.compose([Validators.required, Validators.minLength(5)]),
      ],

      itemName: ["", Validators.compose([Validators.required])],

      unit: [
        { value: "", disabled: false },
        Validators.compose([Validators.required]),
      ],

      qtyRequired: [
        "",
        Validators.compose([
          Validators.required,
          CustomValidator.patternValidator(/^([0-9][0-9][0-9][0-9][0-9])$/, {
            hasNumber: true,
          }),
        ]),
      ],

      unitCost: [
        { value: "", disabled: false },
        Validators.compose([Validators.required]),
      ],

      totalCost: [
        { value: "", disabled: false },
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          CustomValidator.patternValidator(
            /^([0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9])$/,
            { hasNumber: true }
          ),
        ]),
      ],
    });
  }

  //method for editing rfq items
  edit(record: any) {
    record.isEditable = !record.isEditable;
    this.edited = !this.edited;
    if (!this.edited) {
      console.log(record.value);
    }
  }

  //method for saving rfq
  onSubmit() {
    console.log(this.itemsForm.value);
    this.removEditTable = !this.removEditTable;
  }
}
