import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-receivables-payables',
  templateUrl: './receivables-payables.component.html',
  styleUrls: ['./receivables-payables.component.sass']
})
export class ReceivablesPayablesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
