<div class="d-flex flex-column">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="tickets-card2 card shadow-lg mt-2 mb-0 pt-2 pb-1 px-4 px-md-5">
          <div class="row d-flex justify-content-center">
            <div class="col-12 pt-0">
              <h4 class="text-info text-uppercase font-weight-bold pull-right">
                <hr class="top mt-1 mb-0" />
                create clients
                <hr class="bottom mt-0 mb-0" />
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between align-self-center">
      <div
        class="form-card card shadow mt-2 mb-4 d-flex flex-column col-offset-lg-6"
      >
        <div class="card-body p-3">
              <form
                [formGroup]="personalFormGroup"
                (ngSubmit)="form1()"
                #formone="ngForm"
              >
                <fieldset
                  name="bioData"
                  class="border border-secondary mt-3 px-4 py-2 mb-4"
                >
                  <legend class="text-info pl-2">Bio-data</legend>
                  <div class="row">
                    <div class="col-xs-12 col-md-6">
                      <mat-form-field appearance="fill">
                        <mat-label for="userName">Name</mat-label>
                        <mat-icon matPrefix>person_outline</mat-icon>
                        <input
                          matInput
                          formControlName="userName"
                          placeholder="Enter your name"
                        />
                        <mat-error
                          *ngIf="
                            this.personalFormGroup
                              .get('userName')
                              .hasError('required')
                          "
                        >
                          Name is required
                        </mat-error>
                        <mat-error
                          *ngIf="
                            !this.personalFormGroup
                              .get('userName')
                              .hasError('required') &&
                            this.personalFormGroup
                              .get('userName')
                              .hasError('minLength')
                          "
                        >
                          Name should not be less than two characters
                        </mat-error>
                        <mat-error
                          *ngIf="
                            !this.personalFormGroup
                              .get('userName')
                              .hasError('required') &&
                            this.personalFormGroup
                              .get('userName')
                              .hasError('maxLength')
                          "
                        >
                          Name should have a maximum of 128 characters
                        </mat-error>
                        <mat-error
                          *ngIf="
                            !this.personalFormGroup
                              .get('userName')
                              .hasError('required') &&
                            this.personalFormGroup
                              .get('userName')
                              .hasError('hasCharacters')
                          "
                        >
                          Name should not include digits
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-md-6">
                      <mat-form-field appearance="fill">
                        <mat-label>National IdNo.</mat-label>
                        <mat-icon matPrefix>perm_identity</mat-icon>
                        <input
                          matInput
                          formControlName="national_id"
                          placeholder="Enter national Id number"
                        />
                        <mat-error
                          *ngIf="
                            this.personalFormGroup
                              .get('national_id')
                              .hasError('required')
                          "
                          >National Id number is required
                        </mat-error>
                        <mat-error
                          *ngIf="
                            !this.personalFormGroup
                              .get('national_id')
                              .hasError('required') &&
                            this.personalFormGroup
                              .get('national_id')
                              .hasError('nationalIdCheck')
                          "
                          >National Id number is not valid
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xs-12 col-md-6">
                      <mat-form-field appearance="fill">
                        <mat-label>Date of Birth</mat-label>
                        <mat-icon matPrefix>hourglass_empty</mat-icon>
                        <input
                          matInput
                          formControlName="date_of_birth"
                          [matDatepicker]="picker"
                          placeholder="Enter date of birth"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="picker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error
                          *ngIf="
                            this.personalFormGroup
                              .get('date_of_birth')
                              .hasError('required')
                          "
                          >Date of birth is required
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-md-6">
                      <mat-form-field appearance="fill">
                        <mat-label>Gender</mat-label>
                        <mat-icon matPrefix>label</mat-icon>
                        <select
                          matNativeControl
                          formControlName="gender"
                          placeholder="Select gender"
                        >
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </select>
                        <mat-error
                          *ngIf="
                            this.personalFormGroup
                              .get('gender')
                              .hasError('required')
                          "
                          >Gender is required
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </fieldset>
                <fieldset
                  name="contactDetails"
                  class="border border-secondary px-4 py-2 mb-4"
                >
                  <legend class="text-info pl-2">Contact Details</legend>
                  <div class="row">
                    <div class="col-xs-12 col-md-6">
                      <mat-form-field appearance="fill">
                        <mat-label>Phone number</mat-label>
                        <mat-icon matPrefix>phone_android</mat-icon>
                        <input
                          matInput
                          formControlName="phoneNumber"
                          placeholder="Enter phone number"
                        />
                        <mat-error
                          *ngIf="
                            this.personalFormGroup
                              .get('phoneNumber')
                              .hasError('required')
                          "
                          >Phone number is required
                        </mat-error>
                        <mat-error
                          *ngIf="
                            !this.personalFormGroup
                              .get('phoneNumber')
                              .hasError('required') &&
                            this.personalFormGroup
                              .get('phoneNumber')
                              .hasError('hasNumber')
                          "
                          >Phone number is invalid
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-md-6">
                      <mat-form-field appearance="fill">
                        <mat-label>Address</mat-label>
                        <mat-icon matPrefix>my_location</mat-icon>
                        <input
                          matInput
                          formControlName="address"
                          placeholder="Enter your address"
                        />
                        <mat-error
                          *ngIf="
                            this.personalFormGroup
                              .get('address')
                              .hasError('required')
                          "
                          >Address is required
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xs-12 col-md-9">
                      <mat-form-field appearance="fill">
                        <mat-label>Email</mat-label>
                        <mat-icon matPrefix>mail</mat-icon>
                        <input
                          matInput
                          formControlName="email"
                          placeholder="Enter your email address"
                        />
                        <mat-error
                          *ngIf="
                            this.personalFormGroup
                              .get('email')
                              .hasError('email')
                          "
                          >Email is required
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <fieldset
                    name="emergencyContact"
                    class="innerFieldset border border-secondary px-4 py-2 mb-4"
                  >
                    <legend class="text-info pl-2">Emergency Contact</legend>
                    <div class="row">
                      <div class="col-xs-12 col-md-9">
                        <mat-form-field appearance="fill">
                          <mat-label>Name</mat-label>
                          <mat-icon matPrefix>person_outline</mat-icon>
                          <input
                            matInput
                            formControlName="emergencyName"
                            placeholder="Enter name of Emergency contact"
                          />
                          <mat-error
                            *ngIf="
                              this.personalFormGroup
                                .get('emergencyName')
                                .hasError('required')
                            "
                            >Name of Emergency contact is required
                          </mat-error>
                          <mat-error
                            *ngIf="
                              !this.personalFormGroup
                                .get('emergencyName')
                                .hasError('required') &&
                              this.personalFormGroup
                                .get('emergencyName')
                                .hasError('hasCharacter')
                            "
                            >Name of Emergency contact is invalid
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xs-12 col-md-6">
                        <mat-form-field appearance="fill">
                          <mat-label>Relation</mat-label>
                          <mat-icon matPrefix>phone_android</mat-icon>
                          <input
                            matInput
                            formControlName="emergencyRelation"
                            placeholder="Enter your relation"
                          />
                          <mat-error
                            *ngIf="
                              this.personalFormGroup
                                .get('emergencyRelation')
                                .hasError('required')
                            "
                            >Relation to Emergency contact is required
                          </mat-error>
                          <mat-error
                            *ngIf="
                              !this.personalFormGroup
                                .get('emergencyRelation')
                                .hasError('required') &&
                              this.personalFormGroup
                                .get('emergencyRelation')
                                .hasError('hasCharacter')
                            "
                            >Relation to Emergency contact is invalid
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-xs-12 col-md-6">
                        <mat-form-field appearance="fill">
                          <mat-label>Phone Number</mat-label>
                          <mat-icon matPrefix>phone_android</mat-icon>
                          <input
                            matInput
                            formControlName="emergencyNumber"
                            placeholder="Enter Emergency phone number"
                          />
                          <mat-error
                            *ngIf="
                              this.personalFormGroup
                                .get('emergencyNumber')
                                .hasError('required')
                            "
                            >Emergency phone number is required
                          </mat-error>
                          <mat-error
                            *ngIf="
                              !this.personalFormGroup
                                .get('emergencyNumber')
                                .hasError('required') &&
                              this.personalFormGroup
                                .get('emergencyNumber')
                                .hasError('hasNumber')
                            "
                            >Emergency phone number is invalid
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </fieldset>
                </fieldset>
                <fieldset
                  name="kinDetails"
                  class="border border-secondary px-4 py-2 mb-4"
                >
                  <legend class="text-info pl-2">Next of Kin</legend>
                  <div class="row">
                    <div class="col-xs-12 col-md-5">
                      <mat-form-field appearance="fill">
                        <mat-label>Name</mat-label>
                        <mat-icon matPrefix>person_outline</mat-icon>
                        <input
                          matInput
                          formControlName="kinName"
                          placeholder="Enter name of Next of Kin"
                        />
                        <mat-error
                          *ngIf="
                            this.personalFormGroup
                              .get('kinName')
                              .hasError('required')
                          "
                          >Next of Kin name is required
                        </mat-error>
                        <mat-error
                          *ngIf="
                            !this.personalFormGroup
                              .get('kinName')
                              .hasError('required') &&
                            this.personalFormGroup
                              .get('kinName')
                              .hasError('hasCharacter')
                          "
                          >Next of Kin name is invalid
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-md-3">
                      <mat-form-field appearance="fill">
                        <mat-label>Relation</mat-label>
                        <mat-icon matPrefix>phone_android</mat-icon>
                        <input
                          matInput
                          formControlName="kinRelation"
                          placeholder="Enter your relation"
                        />
                        <mat-error
                          *ngIf="
                            this.personalFormGroup
                              .get('kinRelation')
                              .hasError('required')
                          "
                          >Relation to Next of kin is required
                        </mat-error>
                        <mat-error
                          *ngIf="
                            !this.personalFormGroup
                              .get('kinRelation')
                              .hasError('required') &&
                            this.personalFormGroup
                              .get('kinRelation')
                              .hasError('hasCharacter')
                          "
                          >Relation to Next of kin is invalid
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-md-4">
                      <mat-form-field appearance="fill">
                        <mat-label>Date of Birth</mat-label>
                        <mat-icon matPrefix>hourglass_empty</mat-icon>
                        <input
                          matInput
                          formControlName="dateOfBirth2"
                          [matDatepicker]="picker2"
                          placeholder="Enter date of birth"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="picker2"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                        <mat-error
                          *ngIf="
                            this.personalFormGroup
                              .get('dateOfBirth2')
                              .hasError('required')
                          "
                          >Date of birth is required
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </fieldset>
                <!-- <div
                  class="form-group col-xs-12 col-lg-8 align-items-center px-auto"
                >
                  <mat-label
                    ><i class="fas fa-portrait"></i>&nbsp;Passport-size
                    Photo</mat-label
                  >
                  <input
                    type="file"
                    class="form-control"
                    formControlName="photo"
                    placeholder="Upload your Passport-size photo"
                    (change)="onFileChange($event)"
                  />
                  <div class="form-group">
                    <button
                      type="button"
                      (click)="togglePhotoPreview()"
                      class="btn btn-sm btn-primary"
                      *ngIf="personalFormGroup.get('photo') !== null"
                    >
                      {{ previewPhoto ? "Hide" : "Show" }} Preview
                    </button>
                  </div>
                  <img
                    [src]="imageSrc"
                    *ngIf="
                      previewPhoto && personalFormGroup.get('photo') !== null
                    "
                    style="height: 120px; width:120px"
                  />
                </div> -->
                <div class="d-flex mt-2 pt-1 mx-auto">
                  <button type="submit" class="btn btn-primary col-5 col-md-3 mx-auto">
                    Save
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger col-5 col-md-3 mx-auto"
                  >
                    Cancel
                  </button>
                </div>
              </form>
        </div>
      </div>
    </div>
  </div>
</div>
