import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rp-clients',
  templateUrl: './rp-clients.component.html',
  styleUrls: ['./rp-clients.component.sass']
})
export class RpClientsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
