import { Component, OnInit } from "@angular/core";
// import * as jwt_decode from 'jwt-decode';
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import {
  noRecords,
  searchItems,
  showToasterMessage,
} from "src/app/modules/shared/utils/functions";
import { ToastrService } from "ngx-toastr";
import { ManagementService } from "../../../services/management.service";
import { AuthService } from "src/app/modules/auth/services/auth.service";

@Component({
  selector: "app-manage-supplier-approval",
  templateUrl: "./manage-supplier-approval.component.html",
  styleUrls: ["./manage-supplier-approval.component.scss"],
})
export class ManageSupplierApprovalComponent implements OnInit {
  userForm: FormGroup;
  approvalItems = [];
  serviceErrors: string;
  employees = [];
  suppliers = [];
  suppliersCopy = [];
  index: number;
  totalItems = 0;
  currentPage = 1;
  pageSize = 4;
  loading = false;
  loadingAction = false;
  User = this.authService.loggedInUserInfo();
  whichKind: number = 1;

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private managementService: ManagementService,
    private authService: AuthService,
    private toaster: ToastrService
  ) {}

  get approvalItemsVal(): FormArray {
    return this.userForm.get("approvalItems") as FormArray;
  }

  get fval(): any {
    return this.userForm.controls;
  }

  ngOnInit(): any {
    this.whichKind = 1;
    this.fetchSetForm();
  }

  createFormGroup(): any {
    return this.fb.group({
      approvalItems: new FormArray([]),
      selectAll: new FormControl(""),
    });
  }

  fetchSetForm() {
    this.loading = true;
    const endPoint =
      this.whichKind == 1
        ? "GET_CREATED_FOR_APPROVAL"
        : "GET_UPDATED_FOR_APPROVAL";
    this.managementService
      .getSuppliersForApproval(endPoint)
      .subscribe((res) => {
        this.suppliers = noRecords(res) ? [] : res;
        this.suppliersCopy = this.suppliers;
        this.totalItems = this.suppliers.length;
        this.userForm = this.createFormGroup();
        this.fval.selectAll.setValue(false);
        this.initialiseForm();
      });
    this.loading = false;
  }

  onChangeKind(event): void {
    this.whichKind = event.target.value;
    this.fetchSetForm();
  }

  addItem(item: FormGroup): any {
    this.approvalItemsVal.push(item);
  }

  initialiseForm(): any {
    this.suppliers.forEach((item, i) => {
      const formItem = new FormGroup({
        branchName: new FormControl(item.branchName),
        supplierEmail: new FormControl(item.supplierEmail),
        supplierId: new FormControl(item.supplierId),
        supplierName: new FormControl(item.supplierName),
        supplierBranchId: new FormControl(item.supplierBranchId),
        supplierPhotoUrl: new FormControl(item.supplierPhotoUrl),
        relationshipManagerContact: new FormControl(
          item.relationshipManagerContact
        ),
        relationshipManagerEmail: new FormControl(
          item.relationshipManagerEmail
        ),
        supplierPhone1: new FormControl(item.supplierPhone1),
        relationshipManagerName: new FormControl(item.relationshipManagerName),
        relationshipManagerId: new FormControl(item.relationshipManagerId),
        supplierCertificateOfRegistration: new FormControl(
          item.supplierCertificateOfRegistration
        ),
        approved: new FormControl(""),
      });
      this.addItem(formItem);
    });
  }

  checkAllItems(val: boolean): any {
    if (val === true) {
      this.suppliers.forEach((item, i) => {
        this.fval.approvalItems.controls[i].controls.approved.setValue(val);
      });
    } else {
      this.suppliers.forEach((item, i) => {
        this.fval.approvalItems.controls[i].controls.approved.setValue(false);
      });
    }
  }

  deselectAll(val: number): any {
    if (
      this.fval.approvalItems.controls[val].controls.approved.value === true
    ) {
      this.fval.selectAll.setValue(false);
    }
  }

  getValue(event): void {
    const results = searchItems(
      event.target.value,
      this.suppliers,
      "suppliers"
    );
    this.suppliers = results.length ? results : this.suppliersCopy;
    this.totalItems = this.suppliers.length;
  }

  revert(): any {
    this.userForm.reset();
  }

  approveRejectItems(isApprovalAction: boolean): void {
    const postData = [];
    let filteredApprovals = this.suppliers;
    this.fval.approvalItems.controls.forEach((item, i) => {
      if (isApprovalAction) {
        if (item.controls.approved.value) {
          postData.push({
            supplierId: Number(item.controls.supplierId.value),
            employeeIdApprover: this.User.employeeId,
          });
          filteredApprovals = filteredApprovals.filter(
            (k) => k.supplierId != item.controls.supplierId.value
          );
        }
      } else {
        if (item.controls.approved.value) {
          postData.push({ supplierId: Number(item.controls.supplierId.value) });
          filteredApprovals = filteredApprovals.filter(
            (k) => k.supplierId != item.controls.supplierId.value
          );
        }
      }
    });
    if (postData.length > 0) {
      this.loadingAction = true;
      this.managementService
        .approveRejectCreatedSupplier(
          postData,
          this.whichKind == 2
            ? "APPROVE_UPDATED"
            : isApprovalAction
            ? "APPROVE_CREATED"
            : "REJECT_CREATED"
        )
        .subscribe(
          (res) => {
            if (res) {
              this.fval.selectAll.setValue(false);
              this.suppliersCopy = this.suppliers = filteredApprovals;
              this.userForm = this.createFormGroup();
              this.initialiseForm();
              filteredApprovals = [];
              this.loadingAction = false;
              showToasterMessage(
                this.toaster,
                "success",
                `
              ${postData.length === 1 ? "supplier was " : "suppliers were "}
              ${isApprovalAction ? "approved" : "rejected"} successfully`
              );
            }
          },
          (err) => {
            this.loadingAction = false;
            showToasterMessage(this.toaster, "warning", err);
          }
        );
    } else {
      return;
    }
  }

  pageChanged(event): any {
    this.currentPage = event;
  }
}
