import { Component, OnInit } from "@angular/core";
import { MenuItem } from "../../shared/layout/left-panel/left-panel.component";

@Component({
  selector: "app-finance-index",
  templateUrl: "./finance-index.component.html",
  styleUrls: ["./finance-index.component.scss"],
})
export class FinanceIndexComponent implements OnInit {
  menuList: MenuItem[];
  moduleName: string;

  constructor() {}

  ngOnInit(): void {
    this.moduleName = "FINANCE";
    this.menuList = [
      {
        name: "Dashboard",
        icon: "fas fa-tachometer-alt",
        url: "/finance/dashboard",
      },
      {
        name: "Accounts",
        icon: "fas fa-dollar",
        url: "/finance/accounts",
      },
      {
        name: "Receivables and Payables",
        icon: "fas fa-dollar",
        url: "/finance/R-and-Ps",
        subMenu: [
          {
            name: "Suppliers",
            icon: "fas fa-warehouse",
            url: "/finance/R-and-Ps/suppliers",
          },
          {
            name: "Clients",
            icon: "fas fa-employees",
            url: "/finance/R-and-Ps/clients",
          },
        ],
      },
      {
        name: "Cost Jobs",
        icon: "fas fa-tools",
        url: "/finance/costjobs",
      },
      {
        name: "Pay Service Providers",
        icon: "fas fa-money-bill",
        url: "/finance/paybills",
      },
      {
        name: "Reports",
        icon: "fas fa-chart-line",
        url: "/finance/reports",
      },
      {
        name: "Profile",
        icon: "fas fa-user-cog",
        url: "/finance/profile",
      },
      {
        name: "Notifications",
        icon: "fas fa-envelope",
        url: "/finance/notifications",
        badge: "8",
        badgeBg: "bg-warning",
      },
    ];
  }
}
