import { NgxSpinnerService } from "ngx-spinner";
import { Component, OnInit } from "@angular/core";
import { AuthService } from "./modules/auth/services/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "Client1";

  constructor(
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    // const user = this.jwtHelper.decodeToken(this.authService.getJwtToken())
    const loginStatus = this.authService.isLoggedIn();
    // if (!loginStatus) return false; else this.router.navigate(checkRouteTo(user, loginStatus))
    /** spinner starts on init */
    setTimeout(() => {
      /** spinner ends after 5 seconds */
    }, 5000);
  }
}
