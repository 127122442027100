import { Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import {
  noRecords,
  searchItems,
  showToasterMessage,
} from "../../../../shared/utils/functions";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/modules/auth/services/auth.service";
import { ManagementService } from "../../../services/management.service";

@Component({
  selector: "app-manage-customer-sector",
  templateUrl: "./manage-customer-sector.component.html",
  styleUrls: ["./manage-customer-sector.component.scss"],
})
export class ManageCustomerSectorComponent implements OnInit {
  public modalRef: BsModalRef;
  userForm: FormGroup;
  approvals = [];
  approvalsCopy = [];
  loading = false;
  loadingAction = false;
  index: number;
  totalItems = 0;
  currentPage = 1;
  pageSize = 6;
  User = this.authService.loggedInUserInfo();
  whichKind: number = 1;

  constructor(
    private router: Router,
    private modalService: BsModalService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private managementService: ManagementService,
    private authService: AuthService,
    private toaster: ToastrService
  ) {}

  get sectorFormItmes() {
    return this.fval.sectors as FormArray;
  }

  get fval(): any {
    return this.userForm.controls;
  }

  ngOnInit(): any {
    this.fetchSetForm();
  }

  fetchSetForm() {
    this.loading = true;
    const endPoint =
      this.whichKind == 1
        ? "GET_SECTORS_FOR_APPROVAL"
        : "GET_UPDATED_SECTORS_FOR_APPROVAL";
    this.managementService
      .getCustomerSectorsForApproval(endPoint)
      .subscribe((res) => {
        this.approvals = noRecords(res) ? [] : res;
        this.approvalsCopy = this.approvals;
        this.totalItems = this.approvals.length;
        this.userForm = this.createFormGroup();
        this.fval.selectAll.setValue(false);
        this.initialiseForm();
        this.loading = false;
      });
  }

  onChangeKind(event): void {
    this.whichKind = event.target.value;
    this.fetchSetForm();
  }

  createFormGroup(): FormGroup {
    return this.fb.group({
      sectors: this.fb.array([]),
      selectAll: this.fb.control(""),
    });
  }

  addItem(item: FormGroup): any {
    (this.fval.sectors as FormArray).push(item);
  }

  removeItem(index: number): any {
    (this.fval.sectors as FormArray).removeAt(index);
  }

  initialiseForm(): void {
    this.approvals.forEach((item) => {
      const formItem = this.fb.group({
        customerSectorCreatedBy: new FormControl(item.customerSectorCreatedBy),
        customerSectorDescription: new FormControl(
          item.customerSectorDescription
        ),
        customerSectorId: new FormControl(item.customerSectorId),
        customerSectorName: new FormControl(item.customerSectorName),
        approved: new FormControl(""),
      });
      this.addItem(formItem);
    });
  }

  checkAllItems(val: boolean): any {
    if (val === true) {
      this.approvals.forEach((item, i) => {
        this.fval.sectors.controls[i].controls.approved.setValue(val);
      });
    } else {
      this.approvals.forEach((item, i) => {
        this.fval.sectors.controls[i].controls.approved.setValue(false);
      });
    }
  }

  deselectAll(val: number): any {
    if (this.fval.sectors.controls[val].controls.approved.value === true) {
      this.fval.selectAll.setValue(false);
    }
  }

  getValue(event): void {
    const results = searchItems(
      event.target.value,
      this.approvals,
      "customers"
    );
    this.approvals = results.length ? results : this.approvalsCopy;
    this.totalItems = this.approvals.length;
  }

  approveRejectItems(isApprovalAction: boolean): void {
    const postData = [];
    let filteredApprovals = this.approvals;
    this.fval.sectors.controls.forEach((item, i) => {
      if (isApprovalAction) {
        if (item.controls.approved.value) {
          postData.push({
            customerSectorId: Number(item.controls.customerSectorId.value),
            employeeId: this.User.employeeId,
          });
          filteredApprovals = filteredApprovals.filter(
            (k) => k.customerSectorId != item.controls.customerSectorId.value
          );
        }
      } else {
        if (item.controls.approved.value) {
          postData.push({
            customerSectorId: Number(item.controls.customerSectorId.value),
            employeeId: this.User.employeeId,
          });
          filteredApprovals = filteredApprovals.filter(
            (k) => k.customerSectorId != item.controls.customerSectorId.value
          );
        }
      }
    });
    if (postData.length > 0) {
      this.loadingAction = true;
      this.managementService
        .approveRejectCustomerSectors(
          postData,
          isApprovalAction
            ? this.whichKind == 1
              ? "APPROVE_SECTOR"
              : "APPROVE_UPDATED_SECTOR"
            : "REJECT_SECTOR"
        )
        .subscribe(
          (res) => {
            if (res) {
              this.fval.selectAll.setValue(false);
              this.approvalsCopy = this.approvals = filteredApprovals;
              this.userForm = this.createFormGroup();
              this.initialiseForm();
              filteredApprovals = [];
              this.loadingAction = false;
              showToasterMessage(
                this.toaster,
                "success",
                `
               ${
                 postData.length === 1
                   ? "customer sector was "
                   : "customer sectors were "
               }
              ${isApprovalAction ? "approved" : "rejected"} successfully`
              );
            }
          },
          (err) => {
            this.loadingAction = false;
            showToasterMessage(this.toaster, "warning", err);
          }
        );
    } else {
      return;
    }
  }

  pageChanged(event): any {
    this.currentPage = event;
  }
}
