import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { EmployeeNextOfKin } from "src/app/modules/shared/models/employee";
import { AdminService } from "src/app/modules/admin/services/admin.service";
import { AuthService } from "src/app/modules/auth/services/auth.service";
import { formatFormFields, showToasterMessage } from "../../../utils/functions";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CustomValidator } from "../../../validators/custom-validator";
import { finalize } from "rxjs/operators";
import { NgxSpinnerService } from "ngx-spinner";
import { ProfilesService } from "../../../services/profiles.service";
import { AngularFireStorage } from "@angular/fire/storage";
import { LoaderService } from "../../../services/loader.service";

@Component({
  selector: "app-edit-branch",
  templateUrl: "./next-of-kin-editor.component.html",
  styleUrls: ["./next-of-kin-editor.component.scss"],
})
export class NextOfKinEditorComponent implements OnInit {
  loading = false;
  nextOfKinForm: FormGroup;
  User = this.authService.loggedInUserInfo();
  serviceErrors: any;
  fieldType: any;
  gender = ["MALE", "FEMALE"];
  idTypes = [
    { name: "NATIONAL ID", id: 1 },
    { name: "VILLAGE ID", id: 2 },
    { name: "PASSPORT", id: 3 },
  ];
  relationshipTypes = [
    "MOTHER",
    "FATHER",
    "SON",
    "DAUGHTER",
    "WIFE",
    "HUSBAND",
    "UNCLE",
    "AUNT",
    "NIECE",
    "NEPHEW",
    "FRIEND",
    "RELATIVE",
    "NEIGHBOUR",
    "GRAND FATHER",
    "GRAND SON",
    "GRAND MATHER",
    "GRAND DAUGHTER",
    "EMPLOYER",
    "TRUSTEE",
    "LAWYER",
  ];
  nextOfKinPhotoUrl: string;
  nextOfKinIdPhotoUrl: string;
  nextOfKin: EmployeeNextOfKin;
  isEditingNextOfKin: boolean;

  constructor(
    private router: Router,
    private toaster: ToastrService,
    private authService: AuthService,
    private adminService: AdminService,
    public dialogRef: MatDialogRef<NextOfKinEditorComponent>,
    private spinner: NgxSpinnerService,
    private profilesService: ProfilesService,
    private activateRoute: ActivatedRoute,
    private storage: AngularFireStorage,
    private loaderService: LoaderService,
    @Inject(MAT_DIALOG_DATA)
    public data: { isEditing: boolean; nextOfKin?: EmployeeNextOfKin }
  ) {}

  get kval(): any {
    return this.nextOfKinForm.controls;
  }

  ngOnInit(): void {
    this.nextOfKinForm = this.createNextOfKinFormGroup();
    if (this.data.isEditing) {
      this.nextOfKin = this.data.nextOfKin;
      this.isEditingNextOfKin = this.data.isEditing;
      this.setNextOfKinValues(this.nextOfKin);
    }
  }

  createNextOfKinFormGroup(): any {
    return new FormGroup({
      employeeNextOfKinName: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      employeeNextOfKinEmail: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ])
      ),
      employeeNextOfKinIdType: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      employeeNextOfKinIdNumber: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      employeeNextOfKinGender: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      employeeNextOfKinPhotoUrl: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      employeeNextOfKinIdPhotoUrl: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      employeeNextOfKinHomeAreaDetails: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      employeeNextOfKinRelationship: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      employeeNextOfKinDateOfBirth: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      employeeNextOfKinPhone1: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          CustomValidator.patternValidator(
            /^(([0-9])([0-9])([0-9])([0-9])([0-9])([0-9])([0-9])([0-9])([0-9])([0-9]))$/,
            { hasNumber: true }
          ),
        ])
      ),
      employeeNextOfKinPhone2: new FormControl(
        "",
        Validators.compose([
          CustomValidator.patternValidator(
            /^(([0-9])([0-9])([0-9])([0-9])([0-9])([0-9])([0-9])([0-9])([0-9])([0-9]))$/,
            { hasNumber: true }
          ),
        ])
      ),
    });
  }

  setNextOfKinValues(nextOfKin: EmployeeNextOfKin): void {
    this.nextOfKin = nextOfKin;
    this.isEditingNextOfKin = true;
    this.kval.employeeNextOfKinName.setValue(nextOfKin.employeeNextOfKinName);
    this.kval.employeeNextOfKinEmail.setValue(nextOfKin.employeeNextOfKinEmail);
    this.kval.employeeNextOfKinIdType.setValue(
      nextOfKin.employeeNextOfKinIdType
    );
    this.kval.employeeNextOfKinIdNumber.setValue(
      nextOfKin.employeeNextOfKinIdNumber
    );
    this.kval.employeeNextOfKinGender.setValue(
      nextOfKin.employeeNextOfKinGender
    );
    this.kval.employeeNextOfKinHomeAreaDetails.setValue(
      nextOfKin.employeeNextOfKinHomeAreaDetails
    );
    this.kval.employeeNextOfKinRelationship.setValue(
      nextOfKin.employeeNextOfKinRelationship
    );
    this.kval.employeeNextOfKinDateOfBirth.setValue(
      new Date(nextOfKin.employeeNextOfKinDateOfBirth)
    );
    this.kval.employeeNextOfKinPhone1.setValue(
      nextOfKin.employeeNextOfKinPhone1
    );
    this.kval.employeeNextOfKinPhone2.setValue(
      nextOfKin.employeeNextOfKinPhone2
    );
    if (nextOfKin.employeeNextOfKinPhotoUrl) {
      this.nextOfKinPhotoUrl = nextOfKin?.employeeNextOfKinPhotoUrl;
      this.kval.employeeNextOfKinPhotoUrl.setValidators([]);
      this.kval.employeeNextOfKinPhotoUrl.updateValueAndValidity();
    }
    if (nextOfKin.employeeNextOfKinIdPhotoUrl) {
      this.nextOfKinIdPhotoUrl = nextOfKin?.employeeNextOfKinIdPhotoUrl;
      this.kval.employeeNextOfKinIdPhotoUrl.setValidators([]);
      this.kval.employeeNextOfKinIdPhotoUrl.updateValueAndValidity();
    }
  }

  onFileSelected(event, propertyUrl): void {
    const file = event.target.files[0];
    const filePath = `Users/Employees/NextOfKins/${
      this.User.employeeId + propertyUrl
    }`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          let downloadURL = fileRef.getDownloadURL();
          downloadURL.subscribe((url) => {
            if (url) {
              this[propertyUrl] = url;
            }
          });
        })
      )
      .subscribe();
  }

  cancel(): void {
    this.dialogRef.close();
  }

  saveNextOfKin(): void {
    if (!this.nextOfKinForm.valid) return;
    this.loaderService.isLoading.next(true);
    this.loading = true;
    setTimeout(() => {
      const data = {
        ...formatFormFields(this.nextOfKinForm.value),
        employeeNextOfKinIdNumber: this.kval.employeeNextOfKinIdNumber.value,
        employeeId: this.User.employeeId,
        employeeNextOfKinPhotoUrl: this.nextOfKinPhotoUrl,
        employeeNextOfKinIdPhotoUrl: this.nextOfKinIdPhotoUrl,
        employeeNextOfKinPhone2:
          this.kval.employeeNextOfKinPhone2.value ||
          this.kval.employeeNextOfKinPhone1.value,
      };

      if (this.isEditingNextOfKin) {
        data.employeeNextOfKinId = this.nextOfKin.employeeNextOfKinId;
        delete data.employeeId;
      }

      const method = this.isEditingNextOfKin ? "put" : "post";
      const endPoint = this.isEditingNextOfKin
        ? "EDIT_NEXT_OF_KIN"
        : "ADD_NEXT_OF_KIN";
      const resMessage = this.isEditingNextOfKin ? "updated" : "added";

      this.profilesService
        .addOrEditNextOfKinEmployee(
          this.isEditingNextOfKin ? data : [data],
          method,
          endPoint
        )
        .subscribe(
          async () => {
            this.profilesService
              .getNextOfKinEmployee(this.User.employeeId)
              .subscribe(
                (res) => {
                  this.loading = false;
                  this.dialogRef.close(res);
                },
                (error) => {
                  this.loading = false;
                  showToasterMessage(this.toaster, "warning", error);
                }
              );
            showToasterMessage(
              this.toaster,
              "success",
              `Next of kin was ${resMessage} successfully`
            );
            this.isEditingNextOfKin = false;
            this.nextOfKin = null;
          },
          (error) => {
            this.loading = false;
            showToasterMessage(this.toaster, "warning", error);
          }
        );
    }, 4000);
  }
}
