import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {AuthComponent} from "./auth.component";
import {LoginComponent} from "./login/login.component";
import {RegisterEmployeeComponent} from "./registration/register-employee/register-employee.component";
import {ChangePasswordComponent} from "./change-password/change-password.component";

const routes: Routes = [
  {
    path: "auth",
    component: AuthComponent,
    children: [
      // { path: "home", component: HomeComponent },
      {path: "employee-login", component: LoginComponent},
      // { path: "customer-login", component: LoginComponent },
      // { path: "supplier-login", component: LoginComponent },
      {
        path: "employee-change-password/:userEmail",
        component: ChangePasswordComponent,
      },
      // {
      //   path: "customer-change-password/:userEmail",
      //   component: ChangePasswordComponent,
      // },
      // {
      //   path: "supplier-change-password/:userEmail",
      //   component: ChangePasswordComponent,
      // },
      {path: "register-employee", component: RegisterEmployeeComponent},
      // { path: "register-customer", component: RegisterCustomerComponent },
      // { path: "register-supplier", component: RegisterSupplierComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {
}
