<div class="d-flex flex-column">
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-lg-12">
        <div class="card shadow-lg mt-2 mb-2 pt-0 pb-1">
          <div class="row">
            <div class="col-xs-12 col-lg-5 justify-content-left ml-3 mt-3 pt-2">
              <a class="btn btn-info btn-sm mb-1" role="button" routerLink="authpage/register-client"><i
                  class="fas fa-briefcase text-white"></i>&nbsp;Register
                Client</a>
              &nbsp;
              <a class="btn btn-info btn-sm mb-1" role="button" routerLink="/createCatalogItem"><i
                  class="fas fa-cookie-bite text-white"></i>&nbsp;Create
                Catalog Item</a>
            </div>
            <div
              class="tickets-card2 col-xs-12 col-md-12 col-offset-lg-6 col-lg-6 justify-content-end align-items-end ml-2 mt-3 pt-0 px-2">
              <h5 class="text-info font-weight-bold mt-0 pt-0 pl-2 mx-0 ">
                <hr class="top mt-1 mb-0" />
                REQUEST FOR QUOTATION(RFQ)
                <hr class="bottom mt-1 mb-0" />
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div class="row mx-0">
        <div class=" col-xs-12 col-lg-12 mx-0">
          <div class="rfqs-card card shadow mb-4 mx-0">
            <div class="card-header d-flex justify-content-between align-items-center">
              <h6 class="text-info pull-right font-weight-bold m-0">
                <hr class="top mt-1 mb-0" />
                CREATE RFQ
                <hr class="bottom mt-1 mb-0" />
              </h6>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12 col-xs-12">
                  <!-- Main content -->
                  <!-- <section class="content"> -->
                  <div class="form-card card card-primary">
                    <div class="card-body mx-0 px-0">
                      <form [formGroup]="rfqForm">
                        <div class="row mx-2">
                          <div class="col-xs-12 col-md-4 col-lg-4">
                            <div class="form-group">
                              <label for="clientName" class="text-info" [ngClass]="
                                    fval.clientName.valid ? 'text-success' : ''
                                  ">{{ clientLabel
                                }}<i *ngIf="fval.clientName.valid" class="material-icons">check_circle</i>
                              </label>

                              <button type="button" class="btn btn-info form-control" (click)="openModal(template)"
                                *ngIf="!clientAdded">
                                <i class="fas fa-user-plus"></i>&nbsp; Select
                                Client
                              </button>

                              <input *ngIf="clientAdded" class="form-control" formControlName="clientName"
                                [value]="fval.clientName.value" />

                              <div class="text-danger col" *ngIf="
                                    (fval.clientName.touched ||
                                      fval.clientName.dirty) &&
                                    fval.clientName.errors?.required
                                  ">
                                Client is required
                              </div>
                            </div>
                          </div>

                          <div class="rfq-source col-xs-12 col-md-5 col-lg-4">
                            <div class="form-group">
                              <label for="rfqSource" class="text-info" [ngClass]="
                                    fval.rfqSource.valid ? 'text-success' : ''
                                  ">RFQ source<i *ngIf="fval.rfqSource.valid"
                                  class="material-icons">check_circle</i></label>
                              <select class="form-control" formControlName="rfqSource" placeholder="Select RFQ source"
                                (change)="setSelectedChanges($event)">
                                <option selected>Select RFQ source</option>
                                <option *ngFor="let rfq_source of rfq_sources">
                                  {{ rfq_source.rfqSource }}
                                </option>
                              </select>
                              <div class="text-danger col" *ngIf="
                                    (fval.rfqSource.touched ||
                                      fval.rfqSource.dirty) &&
                                    fval.rfqSource.errors?.required
                                  ">
                                RFQ source is required
                              </div>
                            </div>
                          </div>
                          <div class="col-xs-12 col-md-3 col-lg-4">
                            <div class="custom-control custom-switch p-2 mx-5 my-3" *ngIf="setApprovalStatus">
                              <input type="checkbox" class="custom-control-input" id="customSwitch1"
                                (change)="onCheckChange($event)" />
                              <label class="custom-control-label text-info" for="customSwitch1">Skip Approval</label>
                            </div>
                          </div>
                        </div>

                        <div class="post-buttons col-12 col-offset-md-6 col-md-12 mx-3">
                          <a class="btn btn-danger col-5" (click)="resetForm()"><i
                              class="material-icons">remove_circle</i>Cancel</a>&nbsp;
                          <button type="submit" class="btn btn-info col-5" (click)="createRFQ(rfqForm)"
                            [disabled]="!rfqForm.valid">
                            <ngx-spinner class="bg-warning bg-success" size="medium" type="line-scale-party">
                              <p class="text-light">
                                Wait...
                              </p>
                            </ngx-spinner><i class="material-icons">create</i>
                            Create RFQ
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <!-- <app-add-rfq-items
      [clientNames]="fval.clientName"
      ></app-add-rfq-items> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rfq-tabs row mt-0 mx-auto">
        <div class=" col-xs-12 col-lg-12">
          <div class="rfqs card shadow mb-4 mx-auto">
            <div class="card-body p-2">
              <div class=" mx-auto">
                <tabset>
                  <tab heading="RFQ Listing" class="rfq-listing text-info">
                    <div class="table-responsive pt-3 px-2">
                      <input type="text" class="client-name form-control" [(ngModel)]="userFilter.clientName"
                        placeholder="Enter Client Name" />
                      <table class="table table-striped thead-dark table-hover table-sm mt-3">
                        <thead>
                          <tr class="text-info align-items-center">
                            <th>Action</th>
                            <th>#</th>
                            <th>RFQ Serial No.</th>
                            <th>Client Name</th>
                            <th>RFQ Source</th>
                            <th>No. Of Items</th>
                            <th>Total Amount</th>
                            <th>Department</th>
                            <th>User Queue</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="
                                                  let summu of rfqSummury$
                                                    | async
                                                    | filterBy: userFilter;
                                                  let i = index;
                                                  let odd = odd;
                                                  let first = first;
                                                  let last = last
                                                " [class.text-secondary]="odd" [class.text-success]="!odd">
                            <td class="dropdown">
                              <a class="text-info" data-toggle="dropdown" role="button" aria-haspopup="true"
                                aria-expanded="false"><i class="material-icons">more_vert</i></a>

                              <div class="dropdown-menu position-absolute">
                                <a data-toggle="modal" data-target="#comment"
                                  class="dropdown-item d-flex align-items-center text-info btn btn-info"
                                  routerLink="/addRfqItems">
                                  <i class="material-icons">add_circle</i>Add Items
                                </a>

                                <a data-toggle="modal" data-target="#defer"
                                  class="dropdown-item d-flex align-items-center text-primo">
                                  <i class="material-icons">visibility</i>View RFQ
                                </a>
                                <a data-toggle="modal" data-target="#comment"
                                  class="dropdown-item d-flex align-items-center text-info">
                                  <i class="material-icons">fast_rewind</i>Receive RFQ
                                </a>

                                <a data-toggle="modal" data-target="#comment"
                                  class="dropdown-item d-flex align-items-center text-success">
                                  <i class="material-icons">fast_forward</i>Forward RFQ
                                </a>
                              </div>
                            </td>
                            <td>{{ i + 1 }}</td>
                            <td>
                              <a routerLink="/requestforqoute">{{
                                summu.rfqSerialNumber
                                }}</a>
                            </td>
                            <td>
                              <a routerLink="/detailquote">{{
                                (
                                summu.clientColRef

                                | async
                                )?.clientName
                                }}</a>
                            </td>
                            <td>{{ summu.rfqSource }}</td>
                            <td>{{ summu.noOfItems }}</td>
                            <td>{{ summu.totalAmount }}</td>
                            <td>
                              {{
                              (
                              summu.empColRef

                              | async
                              )?.department
                              }}
                            </td>
                            <td>
                              {{
                              (
                              summu.empColRef

                              | async
                              )?.userName
                              }}
                            </td>
                            <td>
                              <span class="badge badge-info">{{
                                summu.movementStatus
                                }}</span>
                            </td>
                            <td>
                              {{
                              summu.movementTimestamp
                              | dbDate
                              | date: "dd/MM/yyyy"
                              }}
                            </td>
                            <td>
                              {{
                              summu.movementTimestamp
                              | dbDate
                              | timevalue
                              }}
                            </td>
                            <td>
                              <span class="badge badge-danger">{{
                                summu.processingStatus
                                }}</span>
                            </td>
                            <td>
                              {{
                              summu.movementTimestamp
                              | dbDate
                              | date: "dd/MM/yyyy"
                              }}
                            </td>
                            <td>
                              {{
                              summu.movementTimestamp
                              | dbDate
                              | timevalue
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </tab>
                  <tab heading="RFQ Status" class="rfq-status text-info">
                    <div class="table-responsive pt-3 px-2">
                      <table class="table table-striped table-hover table-sm">
                        <thead class="text-info">
                          <tr>
                            <th style="width: 2%">
                              #
                            </th>
                            <th style="width: 13%">
                              RFQ Serial No.
                            </th>
                            <th style="width: 15%">
                              User Queue
                            </th>
                            <th style="width: 10%">
                              Movement Status
                            </th>
                            <th style="width: 15%">
                              Movement Date
                            </th>
                            <th style="width: 15%">
                              Movement Time
                            </th>
                            <th style="width: 10%">
                              Processing Status
                            </th>
                            <th style="width: 15%">
                              Processing Date
                            </th>
                            <th style="width: 15%">
                              Processing Time
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="
                                                  let summu of rfqSummury$
                                                    | async
                                                    | filterBy: userFilter;
                                                  let i = index;
                                                  let odd = odd;
                                                  let first = first;
                                                  let last = last
                                                " [class.text-secondary]="odd" [class.text-success]="!odd">
                            <td>{{ i + 1 }}</td>
                            <td>
                              <a routerLink="/requestforqoute">{{
                                summu.rfqSerialNumber
                                }}</a>
                            </td>
                            <td>
                              {{
                              (
                              summu.empColRef

                              | async
                              )?.userName
                              }}
                            </td>
                            <td>
                              <span class="badge badge-info">{{
                                summu.movementStatus
                                }}</span>
                            </td>
                            <td>
                              {{
                              summu.movementTimestamp
                              | dbDate
                              | date: "dd/MM/yyyy"
                              }}
                            </td>
                            <td>
                              {{
                              summu.movementTimestamp
                              | dbDate
                              | timevalue
                              }}
                            </td>
                            <td>
                              <span class="badge badge-danger">{{
                                summu.processingStatus
                                }}</span>
                            </td>
                            <td>
                              {{
                              summu.movementTimestamp
                              | dbDate
                              | date: "dd/MM/yyyy"
                              }}
                            </td>
                            <td>
                              {{
                              summu.movementTimestamp
                              | dbDate
                              | timevalue
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </tab>
                </tabset>
              </div>
            </div>
            <!-- /.card-body -->
          </div>
        </div>
      </div>

      <ng-template #template>
        <div class="modal-header bg-info close pull-right modal-lg">
          <h4 class="modal-title pull-left">List Of Clients</h4>

          <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
      </button> -->
        </div>
        <div class="modal-body">
          <div class="form-group">
            <input type="text" class="form-control" [(ngModel)]="userFilter.clientName"
              placeholder="Enter Client Name" />
          </div>
          <div class="jumbotron-fluid table-responsive my-2">
            <table class="table-striped table-hover mx-0">
              <thead class="text-info">
                <th>Action</th>
                <th>Client Name</th>
                <th>Phone Number</th>
                <th>Email</th>
                <th>Approval Status</th>
              </thead>
              <tbody>
                <tr *ngFor="let client of clients$ | async | filterBy: userFilter">
                  <td>
                    <button class="primary" (click)="modalRef.hide()"
                      (click)="updateName(client.clientName, client.id)">
                      Add
                    </button>
                  </td>

                  <!-- <td > {{client.id }} </td> -->

                  <td>{{ client.clientName }}</td>
                  <td>{{ client.phoneNumber }}</td>
                  <td>{{ client.email }}</td>
                  <td>{{ client.approvalStatus }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <!--footer -->
  <div class="container mt-1 mb-2 pb-2">
    <div class="col-xs-12 col-lg-12">
      <div class="small text-center text-danger">
        Copyright &copy; 2020 - Brimasmedia Ltd
      </div>
    </div>
  </div>
  <!--/ footer -->
</div>