import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { API_URLS, USER_TOKEN } from "../utils/constants";
import { EmployeeNextOfKin, EpmloyeeProfile } from "../models/employee";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ProfilesService {
  public userProfile = new BehaviorSubject(
    JSON.parse(localStorage.getItem(USER_TOKEN))
  );

  constructor(private http: HttpClient) {}

  postEditEmployeeProfile(postData: EpmloyeeProfile): any {
    return this.http.put<any>(API_URLS.USERS.EMPLOYEE.EDIT_PROFILE, postData);
  }

  getActiveEmployee(employeeId: number): any {
    return this.http.get(
      API_URLS.USERS.EMPLOYEE.GET_ACTIVE + `?id=${employeeId}`
    );
  }

  addOrEditNextOfKinEmployee(
    postDate: EmployeeNextOfKin | EmployeeNextOfKin[],
    method,
    endPoint
  ): any {
    return this.http[method](API_URLS.USERS.EMPLOYEE[endPoint], postDate);
  }

  getNextOfKinEmployee(employeeId: number): any {
    return this.http.get(
      API_URLS.USERS.EMPLOYEE.GET_NEXT_OF_KIN + `?employeeId=${employeeId}`
    );
  }

  deleteNextOfKinEmployee(employeeNextOfKinId: number): any {
    return this.http.put(
      API_URLS.USERS.EMPLOYEE.DELETE_NEXT_OF_KIN +
        `?employeeNextOfKinId=${employeeNextOfKinId}`,
      {}
    );
  }

  postUpdateUserEmail(postData: any, endpoint: string): any {
    return this.http.put(endpoint, postData);
  }

  getActiveCustomers(): any {
    return this.http.get(API_URLS.USERS.CUSTOMER.GET_ALL_ACTIVE);
  }

  getActiveSuppliers(): any {
    return this.http.get(API_URLS.USERS.SUPPLIER.GET_ALL_ACTIVE);
  }

  getAllContactPersons(endPoint: string): Observable<any> {
    return this.http.get(endPoint);
  }
}
