import { TagInputModule } from "ngx-chips";
import { SharedModule } from "./modules/shared/shared.module";
import { TransLogisticsDashboardModule } from "./modules/trans-logistics/trans-logistics-dashboard.module";
import { SupplierModule } from "./modules/supplier/supplier.module";
import { SalesMarketDashboardModule } from "./modules/sales-market/sales-market-dashboard.module";
import { ProcurementDashboardModule } from "./modules/procurement/procurement-dashboard.module";
import { ProductionDashboardModule } from "./modules/production/production-dashboard.module";
import { ManagementModule } from "./modules/management/management.module";
import { FinanceModule } from "./modules/finance/finance.module";
import { CustomerModule } from "./modules/customer/customer.module";
import { AdminModule } from "./modules/admin/admin.module";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthModule } from "./modules/auth/auth.module";
import { FrontDeskModule } from "./modules/front-desk/front-desk.module";
import { NgxSpinnerModule } from "ngx-spinner";
import { BsDatepickerModule, DatepickerModule } from "ngx-bootstrap/datepicker";
import { InterceptorService } from "./modules/shared/services/interceptors/request-interceptor.service";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatStepperModule } from "@angular/material/stepper";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatNativeDateModule, MatRippleModule } from "@angular/material/core";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSliderModule } from "@angular/material/slider";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTreeModule } from "@angular/material/tree";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { ToastrModule } from "ngx-toastr";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AngularFireModule } from "@angular/fire";
import { environment } from "src/environments/environment";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireFunctionsModule } from "@angular/fire/functions";
import { JwtModule } from "@auth0/angular-jwt";

function jwtTokenGetter(): any {
  return localStorage.getItem("ACCESS TOKEN");
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    TagInputModule,
    BrowserModule,
    AppRoutingModule,
    AuthModule,
    AdminModule,
    CustomerModule,
    FinanceModule,
    FrontDeskModule,
    SharedModule,
    NgxSpinnerModule,
    ManagementModule,
    ProductionDashboardModule,
    SalesMarketDashboardModule,
    SupplierModule,
    ProcurementDashboardModule,
    TransLogisticsDashboardModule,
    BrowserAnimationsModule,
    HttpClientModule,
    BsDatepickerModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatRippleModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    NgbModule,
    DatepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TooltipModule.forRoot(),
    ToastrModule.forRoot(), // ToasterModule added
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireModule.initializeApp(environment.firebaseConfig, "cloud"),
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
        //   allowedDomains: ["localhost:4200/"],
        //   disallowedRoutes: ["http://example.com/examplebadroute/"],
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
  ],
  // providers: [
  //   {
  //     provide: HTTP_INTERCEPTORS,
  //     useClass: RequestInterceptorServiceService,
  //     multi: true,
  //   },
  //   { provide: StorageBucket, useValue: "gs://brimaslead/" },
  //   AngularFirestore,
  // ],

  bootstrap: [AppComponent],
})
export class AppModule {}
