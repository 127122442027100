<div class="mx-auto">
  <h4 class="text-info text-uppercase text-center font-weight-bold pt-1 pb-0 px-3">
    {{ isEditingNextOfKin ? "Edit" : "Add"}} Next of Kin
  </h4>
  <div class="my-4">
    <form (ngSubmit)="saveNextOfKin()" [formGroup]="nextOfKinForm">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group text-secondary">
            <label [ngClass]="
                          kval.employeeNextOfKinName.valid ? 'text-info' : ''
                        " for="employeeNextOfKinName">Name<i *ngIf="kval.employeeNextOfKinName.valid"
                                                             class="material-icons">check_circle</i></label>
            <div class="input-block mb-0">
              <i class="start fas fa-user text-secondary mx-auto" style="size: 50px"></i>
              <input [ngClass]="{
                            'is-invalid':
                              (kval.employeeNextOfKinName.touched ||
                                kval.employeeNextOfKinName.dirty) &&
                              kval.employeeNextOfKinName.errors
                          }" autocomplete="off" class="form-control shadow"
                     formControlName="employeeNextOfKinName"
                     id="employeeNextOfKinName" placeholder="Next of Kin name" type="text"/>
            </div>
            <div *ngIf="
                          (kval.employeeNextOfKinName.touched ||
                            kval.employeeNextOfKinName.dirty) &&
                          kval.employeeNextOfKinName.errors?.required
                        " class="text-danger col">
              The next of kin name is required
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group text-secondary">
            <label for="employeeNextOfKinDateOfBirth">Date Of Birth</label>
            <div class="input-block mb-0">
              <i class="start fas fa-calendar-alt text-primary mx-auto" style="size: 50px">
              </i>
              <input [bsConfig]="{
                            dateInputFormat: 'MM/DD/YYYY',
                            isAnimated: true
                          }" [ngClass]="{
                            'is-invalid':
                              (kval.employeeNextOfKinDateOfBirth.touched ||
                                kval.employeeNextOfKinDateOfBirth.dirty) &&
                              kval.employeeNextOfKinDateOfBirth.errors
                          }" autocomplete="off" bsDatepicker class="form-control shadow"
                     formControlName="employeeNextOfKinDateOfBirth" id="employeeNextOfKinDateOfBirth"
                     placeholder="Click to select Date Of Birth"
                     placement="bottom" type="text"/>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group text-secondary">
            <label [ngClass]="kval.employeeNextOfKinPhotoUrl.valid ? 'text-info' : ''"
                   for="employeeNextOfKinPhotoUrl">Next of Kin Photo<i
              *ngIf="kval.employeeNextOfKinPhotoUrl.valid" class="material-icons">check_circle</i></label>
            <div class="input-block mb-0">
              <i class="start fas fa-image text-secondary mx-auto" style="size: 50px">
              </i>
              <input
                (change)="onFileSelected($event, 'nextOfKinPhotoUrl')"
                [ngClass]="{
                          'is-invalid':
                            (kval.employeeNextOfKinPhotoUrl.touched ||
                              kval.employeeNextOfKinPhotoUrl.dirty) &&
                            kval.employeeNextOfKinPhotoUrl.errors
                        }"
                class="form-control shadow"
                formControlName="employeeNextOfKinPhotoUrl"
                id="employeeNextOfKinPhotoUrl" src="image/*"
                type="file"/>
            </div>
            <div *ngIf="
                        (kval.employeeNextOfKinPhotoUrl.touched || kval.employeeNextOfKinPhotoUrl.dirty) &&
                        kval.employeeNextOfKinPhotoUrl.errors?.required
                      " class="text-danger col">
              Next of kin photo is required
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group text-secondary">
            <label [ngClass]="kval.employeeNextOfKinIdPhotoUrl.valid ? 'text-info' : ''"
                   for="employeeNextOfKinIdPhotoUrl">ID Photo<i
              *ngIf="kval.employeeNextOfKinIdPhotoUrl.valid" class="material-icons">check_circle</i></label>
            <div class="input-block mb-0">
              <i class="start fas fa-image text-secondary mx-auto" style="size: 50px">
              </i>
              <input
                (change)="onFileSelected($event, 'nextOfKinIdPhotoUrl')"
                [ngClass]="{
                          'is-invalid':
                            (kval.employeeNextOfKinIdPhotoUrl.touched ||
                              kval.employeeNextOfKinIdPhotoUrl.dirty) &&
                            kval.employeeNextOfKinIdPhotoUrl.errors
                        }"
                class="form-control shadow" formControlName="employeeNextOfKinIdPhotoUrl"
                id="employeeNextOfKinIdPhotoUrl" src="image/*"
                type="file"/>
            </div>
            <div *ngIf="
                        (kval.employeeNextOfKinIdPhotoUrl.touched || kval.employeeNextOfKinIdPhotoUrl.dirty) &&
                        kval.employeeNextOfKinIdPhotoUrl.errors?.required
                      " class="text-danger col">
              Next of Kin ID photo is required
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group text-primary">
            <label for="employeeNextOfKinGender">Gender</label>
            <div class="input-block mb-0">
              <select
                [ngClass]="{
                'is-invalid':
                  (kval.employeeNextOfKinGender.touched || kval.employeeNextOfKinGender.dirty) &&
                  kval.employeeNextOfKinGender.errors
              }"
                autocomplete="off"
                class="form-control shadow"
                formControlName="employeeNextOfKinGender"
                id="employeeNextOfKinGender"
                required
                type="text"
              >
                <option
                  class="place-holder-select"
                  disabled
                  hidden
                  selected
                  value=""
                >
                  Select an Gender
                </option>
                <option *ngFor="let type of gender" [value]="type">
                  {{ type }}
                </option>
              </select>
              <div *ngIf="
                        (kval.employeeNextOfKinGender.touched || kval.employeeNextOfKinGender.dirty) &&
                        kval.employeeNextOfKinGender.errors?.required
                      " class="text-danger col">
                Next of Kin Gender is required
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group text-primary">
            <label for="employeeNextOfKinRelationship">Relationship</label>
            <div class="input-block mb-0">
              <select
                [ngClass]="{
                'is-invalid':
                  (kval.employeeNextOfKinRelationship.touched || kval.employeeNextOfKinRelationship.dirty) &&
                  kval.employeeNextOfKinRelationship.errors
              }"
                autocomplete="off"
                class="form-control shadow"
                formControlName="employeeNextOfKinRelationship"
                id="employeeNextOfKinRelationship"
                required
                type="text"
              >
                <option
                  class="place-holder-select"
                  disabled
                  hidden
                  selected
                  value=""
                >
                  Select Relationship type
                </option>
                <option *ngFor="let type of relationshipTypes" [value]="type">
                  {{ type.toUpperCase() }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group text-primary">
            <label for="employeeNextOfKinIdType">ID Type</label>
            <div class="input-block mb-0">
              <select
                [ngClass]="{
                'is-invalid':
                  (kval.employeeNextOfKinIdType.touched || kval.employeeNextOfKinIdType.dirty) &&
                  kval.employeeNextOfKinIdType.errors
              }"
                autocomplete="off"
                class="form-control shadow"
                formControlName="employeeNextOfKinIdType"
                id="employeeNextOfKinIdType"
                required
                type="text"
              >
                <option
                  class="place-holder-select"
                  disabled
                  hidden
                  selected
                  value=""
                >
                  Select an ID Type
                </option>
                <option *ngFor="let type of idTypes" [value]="type.id">
                  {{ type.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group text-secondary">
            <label [ngClass]="kval.employeeNextOfKinIdNumber.valid ? 'text-info' : ''"
                   for="employeeNextOfKinIdNumber">ID Number<i
              *ngIf="kval.employeeNextOfKinIdNumber.valid" class="material-icons">check_circle</i></label>
            <div class="input-block mb-0">
              <i class="start fas fa-tag text-secondary mx-auto" style="size: 50px"></i>
              <input
                [ngClass]="{
                          'is-invalid':
                            (kval.employeeNextOfKinIdNumber.touched ||
                              kval.employeeNextOfKinIdNumber.dirty) &&
                            kval.employeeNextOfKinIdNumber.errors
                        }"
                autocomplete="off"
                class="form-control shadow"
                formControlName="employeeNextOfKinIdNumber"
                id="employeeNextOfKinIdNumber"
                placeholder="Type your ID number"
                type="text"
              />
            </div>
            <div *ngIf="
                        (kval.employeeNextOfKinIdNumber.touched ||
                          kval.employeeNextOfKinIdNumber.dirty) &&
                        kval.employeeNextOfKinIdNumber.errors?.required
                      " class="text-danger col">
              The ID number is required
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group text-secondary">
            <label [ngClass]="
                          kval.employeeNextOfKinHomeAreaDetails.valid ? 'text-info' : ''
                        " for="employeeNextOfKinHomeAreaDetails">Home Address
              <i *ngIf="kval.employeeNextOfKinHomeAreaDetails.valid" class="material-icons">check_circle</i>
            </label>
            <div class="input-block mb-0">
              <i class="start fas fa-envelope text-secondary mx-auto" style="size: 50px"></i>
              <input [ngClass]="{
                            'is-invalid':
                              (kval.employeeNextOfKinHomeAreaDetails.touched ||
                                kval.employeeNextOfKinHomeAreaDetails.dirty) &&
                              kval.employeeNextOfKinHomeAreaDetails.errors
                          }" autocomplete="off" class="form-control shadow"
                     formControlName="employeeNextOfKinHomeAreaDetails"
                     id="employeeNextOfKinHomeAreaDetails" placeholder="Next of Kin home address"
                     type="text"/>
            </div>
            <div *ngIf="
                          (kval.employeeNextOfKinHomeAreaDetails.touched ||
                            kval.employeeNextOfKinHomeAreaDetails.dirty) &&
                          kval.employeeNextOfKinHomeAreaDetails.errors?.required
                        " class="text-danger col">
              Next of Kin Home Address is required
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group text-secondary">
            <label [ngClass]="kval.employeeNextOfKinEmail.valid ? 'text-info' : ''"
                   for="employeeNextOfKinEmail">Email<i
              *ngIf="kval.employeeNextOfKinEmail.valid" class="material-icons">check_circle</i></label>
            <div class="input-block mb-0">
              <i class="start fas fa-envelope text-secondary mx-auto" style="size: 50px"></i>
              <input [ngClass]="{
                            'is-invalid':
                              (kval.employeeNextOfKinEmail.touched ||
                                kval.employeeNextOfKinEmail.dirty) &&
                              kval.employeeNextOfKinEmail.errors
                          }" autocomplete="off" class="form-control shadow" formControlName="employeeNextOfKinEmail"
                     id="employeeNextOfKinEmail" placeholder="Enter email address" type="text"/>
            </div>
            <div *ngIf="
                          (kval.employeeNextOfKinEmail.touched ||
                            kval.employeeNextOfKinEmail.dirty) &&
                          kval.employeeNextOfKinEmail.errors?.required
                        " class="text-danger col">
              The email address is required
            </div>
            <div *ngIf="
                          (kval.employeeNextOfKinEmail.touched ||
                            kval.employeeNextOfKinEmail.dirty) &&
                          kval.employeeNextOfKinEmail.errors?.pattern
                        " class="text-danger col">
              Please enter valid email address
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group text-secondary">
            <label [ngClass]="
                        kval.employeeNextOfKinPhone1.valid ? 'text-info' : ''
                      " for="employeeNextOfKinPhone1">Phone contact 1<i *ngIf="kval.employeeNextOfKinPhone1.valid"
                                                                        class="material-icons">check_circle</i></label>
            <div class="input-block mb-0">
              <i class="start fas fa-phone text-secondary mx-auto" style="size: 50px"></i>
              <input [ngClass]="{
                          'is-invalid':
                            (kval.employeeNextOfKinPhone1.touched ||
                              kval.employeeNextOfKinPhone1.dirty) &&
                            kval.employeeNextOfKinPhone1.errors
                        }" autocomplete="off" class="form-control shadow"
                     formControlName="employeeNextOfKinPhone1"
                     id="employeeNextOfKinPhone1" placeholder="e.g. 07XXXXXXXX" type="text"/>
            </div>
            <div *ngIf="
                        (kval.employeeNextOfKinPhone1.touched ||
                          kval.employeeNextOfKinPhone1.dirty) &&
                        kval.employeeNextOfKinPhone1.errors?.required
                      " class="text-danger col">
              Your phone contact is required
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group text-secondary">
            <label [ngClass]="
                        kval.employeeNextOfKinPhone2.valid ? 'text-info' : ''
                      " for="employeeNextOfKinPhone2">Phone contact 2</label>
            <div class="input-block mb-0">
              <i class="start fas fa-phone text-secondary mx-auto" style="size: 50px"></i>
              <input [ngClass]="{
                          'is-invalid':
                            (kval.employeeNextOfKinPhone2.touched ||
                              kval.employeeNextOfKinPhone2.dirty) &&
                            kval.employeeNextOfKinPhone2.errors
                        }" autocomplete="off" class="form-control shadow"
                     formControlName="employeeNextOfKinPhone2"
                     id="employeeNextOfKinPhone2" placeholder="e.g. 07XXXXXXXX (optional)" type="text"/>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex my-1 pt-0 pb-2 pt-md-2 pt-xl-1 mx-auto mx-md-4 px-md-5">
        <button
          [disabled]="!nextOfKinForm.valid || nextOfKinForm.pristine || loading"
          class="btn btn-primary mx-auto px-4 px-md-5 shadow"
          type="submit"
        >
          <i class="fas fa-save mx-auto" style="size: 50px"></i>
          {{isEditingNextOfKin ? "Save" : "Add"}}
        </button>
        <button
          (click)="cancel()"
          [disabled]="loading"
          class="btn btn-danger mx-auto px-4 px-md-5 shadow"
          type="button"
        >
          <i class="fas fa-times mx-auto" style="size: 50px"></i>
          Cancel
        </button>
      </div>
    </form>
  </div>
</div>
