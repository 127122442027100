import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { API_URLS } from "../../shared/utils/constants";
import {
  AdminCreateUpdateCustomer,
  AdminCreateUpdateSector,
} from "../../shared/models/customer";
import { ShippingAddress } from "../../shared/components/shipping-address/shipping-address.component";

@Injectable({
  providedIn: "root",
})
export class AdministrationService {
  constructor(private http: HttpClient) {}

  postUpdateSector(postData: AdminCreateUpdateSector, method, endPoint): any {
    return this.http[method](
      API_URLS.USERS.CUSTOMER.SECTORS[endPoint],
      postData
    );
  }

  getCustomerSectors(): any {
    return this.http.get(API_URLS.USERS.CUSTOMER.SECTORS.GET_SECTORS);
  }

  deleteSector(id: number): any {
    return this.http.get(API_URLS.USERS.CUSTOMER.SECTORS[id]);
  }

  postUpdateCustomer(
    postData: AdminCreateUpdateCustomer,
    method,
    endPoint
  ): any {
    return this.http[method](API_URLS.USERS.CUSTOMER[endPoint], postData);
  }

  getRegisteredCustomersForApproval(): any {
    return this.http.get(API_URLS.USERS.CUSTOMER.GET_REGISTERED_FOR_APPROVAL);
  }

  approveRejectRegisteredCustomer(postData: Array<any>, endPoint): any {
    return this.http.put(API_URLS.USERS.CUSTOMER[endPoint], postData);
  }

  postUpdateSupplier(
    postData: AdminCreateUpdateCustomer,
    method,
    endPoint
  ): any {
    return this.http[method](API_URLS.USERS.SUPPLIER[endPoint], postData);
  }

  getRegisteredSuppliersForApproval(): any {
    return this.http.get(API_URLS.USERS.SUPPLIER.GET_REGISTERED_FOR_APPROVAL);
  }

  approveRejectRegisteredSupplier(postData: Array<any>, endPoint): any {
    return this.http.put(API_URLS.USERS.SUPPLIER[endPoint], postData);
  }

  getShippingAddress(id: number, endPoint: string): any {
    return this.http.get(API_URLS.USERS[endPoint].GET_SHIPPING_ADDRESS(id));
  }

  postUpdateShippingAddress(postData: ShippingAddress, method, endPoint): any {
    return this.http[method](endPoint, postData);
  }

  deleteShippingAddress(id: number, endPoint: string): any {
    return this.http.put(
      API_URLS.USERS[endPoint].DELETE_SHIPPING_ADDRESS(id),
      {}
    );
  }
}
