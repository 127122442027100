<div class="card mt-0 mb-3 mx-auto pt-0 shadow mx-0 mx-lg-3">
  <div class="card-title mt-0">
    <h4
      class="text-info text-right text-uppercase font-weight-bold pt-1 pb-0 px-3"
    >
      process list
    </h4>
  </div>
  <div class="card-body pt-0 pb-1 mx-0 px-2">
    <div class="row">
      <div class="col-12">
        <div class="mt-1 mb-2 mb-md-3 mx-0 pull-right">
          <!-- (input)="getValue($event)" -->
          <input
            autocomplete="on"
            class="form-control"
            id="name"
            name=""
            placeholder="Search"
            type="text"
          />
        </div>
      </div>
    </div>
    <!-- record container-->
    <div class="invest-card mt-1 mt-md-0 mb-2 py-2 mx-0 px-2 shadow">
      <div class="item-container-1 mt-1">
        <div class="list-card card mt-1 py-1 mb-0 mx-auto px-1 px-md-0 shadow">
          <div class="list-card-body card-body my-0 py-0 px-xl-4">
            <div class="row my-0 py-0">
              <div class="break-text col-12 col-md-11">
                <div class="row mx-md-0 px-md-0">
                  <div class="col-12 col-md-6 col-xl-4 mx-xl-0 px-xl-0">
                    <div class="row">
                      <div class="col-5 d-flex justify-content-start mx-0 px-0">
                        <span class="">Process ID:</span>
                      </div>
                      <div
                        class="col-7 d-flex justify-content-start font-weight-bold mx-md-0 px-md-0"
                      >
                        <span class="itemCreated"></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4 mx-xl-0 px-xl-0">
                    <div class="row">
                      <div class="col-5 d-flex justify-content-start mx-0 px-0">
                        <span class="">Process Type:</span>
                      </div>
                      <div
                        class="col-7 d-flex justify-content-start font-weight-bold mx-md-0 px-md-0"
                      >
                        <span class="itemCreated"></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4 mx-xl-0 px-xl-0">
                    <div class="row">
                      <div class="col-5 d-flex justify-content-start mx-0 px-0">
                        <span class="">Process Name:</span>
                      </div>
                      <div
                        class="col-7 d-flex justify-content-start font-weight-bold mx-md-0 px-md-0"
                      >
                        <span class="itemCreated"> </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- *ngIf="showButtons" -->
              <div class="col-3 col-md-1">
                <div class="row">
                  <div class="d-flex justify-content-end mx-lg-2">
                    <!-- (click)="emitButton1()"
              [tooltip]="btnValue1" -->
                    <button class="btn btn-outline-info my-1 shadow">
                      <i class="fas fa-edit"></i>Edit
                      <!-- {{btnValue1}} -->
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
