import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FinanceRoutingModule } from "./finance-routing.module";
import { SharedModule } from "../shared/shared.module";
import { FilterPipeModule } from "ngx-filter-pipe";
import { AlertModule } from "ngx-bootstrap/alert";
import { TabsModule } from "ngx-bootstrap/tabs";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxSpinnerModule } from "ngx-spinner";
import { ModalModule } from "ngx-bootstrap/modal";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatStepperModule } from "@angular/material/stepper";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatNativeDateModule, MatRippleModule } from "@angular/material/core";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSliderModule } from "@angular/material/slider";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTreeModule } from "@angular/material/tree";
import { BsDatepickerModule, DatepickerModule } from "ngx-bootstrap/datepicker";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { NotificationsComponent } from "./components/notifications/notifications.component";
import { PayServiceProvidersComponent } from "./components/pay-service-providers/pay-service-providers.component";
import { CostJobsComponent } from "./components/cost-jobs/cost-jobs.component";
import { ReceivablesPayablesComponent } from "./components/receivables-payables/receivables-payables.component";
import { RpSuppliersComponent } from "./components/receivables-payables/rp-suppliers/rp-suppliers.component";
import { RpClientsComponent } from "./components/receivables-payables/rp-clients/rp-clients.component";
import { ReportsComponent } from "./components/reports/reports.component";
import { FinanceIndexComponent } from "./finance-index/finance-index.component";
import { AccountsComponent } from "./components/accounts/accounts.component";
import { PostingComponent } from "./components/accounts/posting/posting.component";
import { StatementComponent } from "./components/accounts/statement/statement.component";
import { EditorComponent } from "./components/accounts/c_o_accounts/editor/editor.component";
import { ListComponent } from "./components/accounts/c_o_accounts/list/list.component";
import { CurrencyComponent } from "./components/accounts/c_o_accounts/currency/currency.component";
import { ChartOfAccountsComponent } from "./components/accounts/c_o_accounts/accounts.component";
import { HeadersComponent } from "./components/accounts/c_o_accounts/headers/headers.component";
import { MajorHeadersComponent } from "./components/accounts/c_o_accounts/major-headers/major-headers.component";

@NgModule({
  declarations: [
    FinanceIndexComponent,
    DashboardComponent,
    NotificationsComponent,
    PayServiceProvidersComponent,
    CostJobsComponent,
    ReceivablesPayablesComponent,
    RpSuppliersComponent,
    RpClientsComponent,
    ReportsComponent,
    AccountsComponent,
    PostingComponent,
    StatementComponent,
    EditorComponent,
    ListComponent,
    ChartOfAccountsComponent,
    CurrencyComponent,
    HeadersComponent,
    MajorHeadersComponent,
  ],
  imports: [
    CommonModule,
    FinanceRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    ModalModule.forRoot(),
    NgxSpinnerModule,
    DatepickerModule,
    BsDatepickerModule,
    NgbModule,
    AlertModule.forRoot(),
    TabsModule.forRoot(),
    FilterPipeModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatRippleModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
  ],
})
export class FinanceModule {}
