import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import {
  noRecords,
  searchItems,
  showToasterMessage,
} from "../../../../shared/utils/functions";
import { EmployeeData } from "../../../../shared/models/employee";
import { AdminService } from "src/app/modules/admin/services/admin.service";
import { MatDialog } from "@angular/material/dialog";
import { EmployeeDetailsComponent } from "../../../../shared/components/employee-details/employee-details.component";
import { PageEvent } from "@angular/material/paginator";

@Component({
  selector: "app-employees-list",
  templateUrl: "./deactivated-employee.component.html",
  styleUrls: ["./deactivated-employee.component.scss"],
})
export class DeactivatedEmployeeComponent implements OnInit {
  loading = false;
  employees: Array<EmployeeData> = [];
  employeesCopy: Array<EmployeeData> = [];
  roles: Array<{ roleName: string; roleId: number }>;
  imageUrl: string;
  serviceErrors: string;
  totalItems = 0;
  pageSize = 10;
  lowValue: number = 0;
  highValue: number = 10;
  displayedColumns: string[] = [
    "employeeIdPhotoUrl",
    "employeeId",
    "employeeName",
    "roleName",
    "branchName",
    "Department",
    "employeePhone1",
    "action",
  ];
  @ViewChild("exportTable") element: ElementRef;

  constructor(
    private activateRoute: ActivatedRoute,
    private toaster: ToastrService,
    private router: Router,
    private adminService: AdminService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    const resolvedData: Array<EmployeeData> =
      this.activateRoute.snapshot.data[0]?.details;
    this.serviceErrors = this.activateRoute.snapshot.data[0]?.error;
    if (resolvedData) {
      this.employeesCopy = this.employees = noRecords(resolvedData)
        ? []
        : resolvedData.map(
            (employee): EmployeeData => ({
              ...employee,
              employeePhotoUrl:
                employee.employeePhotoUrl
                  .toLowerCase()
                  .indexOf("firebasestorage") !== -1
                  ? employee.employeePhotoUrl
                  : "",
            })
          );
      this.totalItems = this.employees.length;
    } else showToasterMessage(this.toaster, "warning", this.serviceErrors);
  }

  getValue(event): any {
    const results = searchItems(
      event.target.value,
      this.employees,
      "employees"
    );
    this.employees = results.length ? results : this.employeesCopy;
    this.totalItems = this.employees.length;
  }

  openDetailsModal(employee: EmployeeData): void {
    const dialogRef = this.dialog.open(EmployeeDetailsComponent, {
      minWidth: "30%",
      data: { employee, isModal: true },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.employeesCopy = this.employees = this.employees.map(
          (employee) => ({
            ...employee,
            roleId:
              employee.employeeId === result.employeeId
                ? result.roleId
                : employee.roleId,
            roleName:
              employee.employeeId === result.employeeId
                ? result.roleName
                : employee.roleName,
          })
        );
      }
    });
  }

  public getPaginatorData(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    return event;
  }

  pageSizeOptions() {
    return [5, 10, 20, 30, 40, 50, this.employees?.length].filter(
      (n) => n <= this.employees?.length
    );
  }

  // export to excel
  exportToExcel(): any {
    // this.exportService.exportExcel(this.user, "employees");
  }

  activateEmployee(employeeId: number): any {
    this.adminService.activateEmployee(employeeId).subscribe(
      () => {
        this.loading = false;
        this.employeesCopy = this.employees = this.employees.filter(
          (employee) => employee.employeeId !== employeeId
        );
        showToasterMessage(
          this.toaster,
          "success",
          "user was activated successfully"
        );
      },
      (error: string) => {
        showToasterMessage(this.toaster, "warning", error);
      }
    );
  }
}
