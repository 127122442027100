import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { forkJoin, Observable, of } from "rxjs";
import { AuthService } from "../../auth/services/auth.service";
import { catchError, map } from "rxjs/operators";
import { ProfilesService } from "../services/profiles.service";
import {
  EmployeeData,
  EmployeeNextOfKin,
  EmployeeRole,
} from "../models/employee";

export interface EmployeeResolvedData {
  users: Array<EmployeeData>;
  roles: Array<EmployeeRole>;
}

@Injectable({
  providedIn: "root",
})
export class EmployeeProfileResolver implements Resolve<Observable<any>> {
  employeeId = this.authService.loggedInUserInfo().employeeId;

  constructor(
    private profilesService: ProfilesService,
    private authService: AuthService
  ) {}

  resolve(): Observable<any> {
    return <Observable<any>>forkJoin([
      this.profilesService.getActiveEmployee(this.employeeId),
      this.profilesService.getNextOfKinEmployee(this.employeeId),
    ]).pipe(
      map((results: [Array<EmployeeData>, Array<EmployeeNextOfKin>]) => ({
        details: {
          profile: results[0],
          nextOfKins: results[1],
        },
      })),
      catchError((error) => {
        return of({ details: null, error: error });
      })
    );
  }
}
