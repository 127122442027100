import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/modules/auth/services/auth.service";
import {
  noRecords,
  searchItems,
  showToasterMessage,
} from "../../../../shared/utils/functions";
import { EmployeeData, EmployeeRole } from "../../../../shared/models/employee";
import { AdminService } from "src/app/modules/admin/services/admin.service";
import { EmployeeResolvedData } from "../../../resolvers/all-employees.resolver";
import { PageEvent } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { EmployeeRoleEditorComponent } from "../employee-role-editor/employee-role-editor.component";
import { EmployeeDetailsComponent } from "../../../../shared/components/employee-details/employee-details.component";

@Component({
  selector: "app-employees-list",
  templateUrl: "./employee-list.component.html",
  styleUrls: ["./employee-list.component.scss"],
})
export class EmployeeListComponent implements OnInit {
  userForm: FormGroup;
  loading = false;
  employee: EmployeeData;
  employees: Array<EmployeeData> = [];
  employeesCopy: Array<EmployeeData> = [];
  totalItems = 0;
  pageSize = 10;
  lowValue: number = 0;
  highValue: number = 10;
  roles: Array<EmployeeRole>;
  imageUrl: string;
  serviceErrors: string;
  User = this.authService.loggedInUserInfo();
  displayedColumns: string[] = [
    "employeeIdPhotoUrl",
    "employeeId",
    "employeeName",
    "roleName",
    "branchName",
    "Department",
    "employeePhone1",
    "action",
  ];
  @ViewChild("exportTable") element: ElementRef;

  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private toaster: ToastrService,
    private authService: AuthService,
    private adminService: AdminService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    const resolvedData: EmployeeResolvedData =
      this.activateRoute.snapshot.data[0]?.details;
    this.serviceErrors = this.activateRoute.snapshot.data[0]?.error;
    if (resolvedData) {
      this.employeesCopy = this.employees = noRecords(resolvedData.employees)
        ? []
        : resolvedData.employees.map(
            (employee): EmployeeData => ({
              ...employee,
              employeePhotoUrl:
                employee.employeePhotoUrl
                  .toLowerCase()
                  .indexOf("firebasestorage") !== -1
                  ? employee.employeePhotoUrl
                  : "",
            })
          );
      this.roles = noRecords(resolvedData.roles) ? [] : resolvedData.roles;
      this.totalItems = this.employees.length;
    } else showToasterMessage(this.toaster, "warning", this.serviceErrors);
  }

  getValue(event): void {
    const results = searchItems(
      event.target.value,
      this.employees,
      "employees"
    );
    this.employees = results.length ? results : this.employeesCopy;
    this.totalItems = this.employees.length;
  }

  openEditRoleModal(employee: EmployeeData): void {
    const dialogRef = this.dialog.open(EmployeeRoleEditorComponent, {
      minWidth: "30%",
      data: { employee, roles: this.roles },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.employeesCopy = this.employees = this.employees.map(
          (employee) => ({
            ...employee,
            roleId:
              employee.employeeId === result.employeeId
                ? result.roleId
                : employee.roleId,
            roleName:
              employee.employeeId === result.employeeId
                ? result.roleName
                : employee.roleName,
          })
        );
      }
    });
  }

  openDetailsModal(employee: EmployeeData): void {
    const dialogRef = this.dialog.open(EmployeeDetailsComponent, {
      minWidth: "30%",
      data: { employee, isModal: true },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.employeesCopy = this.employees = this.employees.map(
          (employee) => ({
            ...employee,
            roleId:
              employee.employeeId === result.employeeId
                ? result.roleId
                : employee.roleId,
            roleName:
              employee.employeeId === result.employeeId
                ? result.roleName
                : employee.roleName,
          })
        );
      }
    });
  }

  public getPaginatorData(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    return event;
  }

  pageSizeOptions() {
    return [5, 10, 20, 30, 40, 50, this.employees?.length].filter(
      (n) => n <= this.employees?.length
    );
  }

  // export to excel
  exportToExcel(): any {
    // this.exportService.exportExcel(this.user, "employees");
  }

  deactivateEmployee(employeeId: number): any {
    this.adminService.deactivateEmployee(employeeId).subscribe(
      () => {
        this.employeesCopy = this.employees = this.employees.filter(
          (employee) => employee.employeeId !== employeeId
        );
        showToasterMessage(
          this.toaster,
          "success",
          "Employee was deactivated successfully"
        );
      },
      (error: string) => {
        showToasterMessage(this.toaster, "warning", error);
      }
    );
  }
}
