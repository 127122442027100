<div class="mx-auto">
  <h4 class="text-info text-center text-uppercase font-weight-bold pt-1 pb-0 px-3">
    Edit Email
  </h4>
  <div class="my-4">
    <form (ngSubmit)="postEditEmail()" [formGroup]="userForm">
      <div class="form-group text-primary">
        <label [for]="formField" [ngClass]="fval[formField].valid ? 'text-info' : ''"
        >Enter New Email<i *ngIf="fval[formField].valid" class="material-icons"
        >check_circle</i
        ></label
        >
        <div class="input-block mb-0">
          <input
            [formControlName]="formField"
            [id]="formField"
            [ngClass]="{ 'is-invalid':(fval[formField].touched || fval[formField].dirty) && fval[formField].errors}"
            autocomplete="off"
            class="form-control shadow"
            placeholder="Enter Email"
            required
            type="text"
          >
          <i
            class="start fas fa-envelope text-primary mx-auto"
          ></i>
        </div>
        <small
          *ngIf="
          (fval[formField].touched || fval[formField].dirty) &&
          fval[formField].errors?.required
        "
          class="text-danger col"
        >
          Your email address is required
        </small>
        <small
          *ngIf="
          (fval[formField].touched || fval[formField].dirty) &&
          fval[formField].errors?.validEmail
        "
          class="text-danger col"
        >
          Please enter a valid email address
        </small>
      </div>
      <div class="d-flex my-2 justify-content-between flex-row">
        <button
          [disabled]="!userForm.valid || loading"
          class="btn btn-primary px-3 px-xl-5 shadow"
          type="submit"
        >
          Save
        </button>
        <button
          (click)="emitCloseModal()"
          [disabled]="loading"
          class="btn btn-danger px-3 px-xl-5 shadow mr-1"
          type="button"
        >
          Cancel
        </button>
      </div>
    </form>
  </div>
</div>
