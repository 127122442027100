<div class="mx-auto">
  <h4 class="text-info text-uppercase text-center font-weight-bold pt-1 pb-0 px-3">
    Edit Profile
  </h4>
  <div class="my-4">
    <form (ngSubmit)="saveProfile()" [formGroup]="profileForm">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group text-secondary">
            <label [ngClass]="pval.employeeName.valid ? 'text-info' : ''" for="employeeName">Name</label>
            <div class="input-block mb-0">
              <i class="start fas fa-user text-secondary mx-auto" style="size: 50px">
              </i>
              <input
                [ngClass]="{
                          'is-invalid':
                            (pval.employeeName.touched ||
                              pval.employeeName.dirty) &&
                            pval.employeeName.errors
                        }"
                autocomplete="off"
                class="form-control shadow"
                formControlName="employeeName"
                id="employeeName"
                placeholder="Type your full name"
                type="text"
              />
            </div>
            <div *ngIf="
                        (pval.employeeName.touched || pval.employeeName.dirty) &&
                        pval.employeeName.errors?.required
                      " class="text-danger col">
              Your name is required
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group text-secondary">
            <label for="employeeDateOfBirth">Date Of Birth</label>

            <div class="input-block mb-0">
              <i class="start fas fa-calendar-alt text-primary mx-auto" style="size: 50px">
              </i>
              <input
                [bsConfig]="{
                          containerClass: 'theme-blue',
                          dateInputFormat: 'MM/DD/YYYY',
                          isAnimated: true
                        }"
                [ngClass]="{
                          'is-invalid':
                            (pval.employeeDateOfBirth.touched ||
                              pval.employeeDateOfBirth.dirty) &&
                            pval.employeeDateOfBirth.errors
                        }"
                autocomplete="off"
                bsDatepicker
                class="form-control shadow"
                formControlName="employeeDateOfBirth"
                id="employeeDateOfBirth"
                placeholder="Click to select Date Of Birth"
                placement="bottom"
                type="text"
              />
              <div *ngIf="
                        (pval.employeeDateOfBirth.touched || pval.employeeDateOfBirth.dirty) &&
                        pval.employeeDateOfBirth.errors?.required
                      " class="text-danger col">
                Your Date of Birth is required
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group text-secondary">
            <label [ngClass]="pval.employeePhotoUrl.valid ? 'text-info' : ''" for="employeePhotoUrl">Employee
              Photo
            </label>
            <div class="input-block mb-0">
              <i class="start fas fa-image text-secondary mx-auto" style="size: 50px">
              </i>
              <input
                (change)="onFileSelected($event, 'photoUrl')"
                [ngClass]="{
                          'is-invalid':
                            (pval.employeePhotoUrl.touched ||
                              pval.employeePhotoUrl.dirty) &&
                            pval.employeePhotoUrl.errors
                        }"
                class="form-control shadow"
                formControlName="employeePhotoUrl"
                id="employeePhotoUrl"
                src="image/*"
                type="file"
              />
            </div>
            <div *ngIf="
                        (pval.employeePhotoUrl.touched || pval.employeePhotoUrl.dirty) &&
                        pval.employeePhotoUrl.errors?.required
                      " class="text-danger col">
              Your photo is required
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group text-secondary">
            <label [ngClass]="pval.employeeIdPhotoUrl.valid ? 'text-info' : ''" for="employeeIdPhotoUrl">ID
              Photo
            </label>
            <div class="input-block mb-0">
              <i class="start fas fa-image text-secondary mx-auto" style="size: 50px">
              </i>
              <input
                (change)="onFileSelected($event, 'idPhotoUrl')"
                [ngClass]="{
                          'is-invalid':
                            (pval.employeeIdPhotoUrl.touched ||
                              pval.employeeIdPhotoUrl.dirty) &&
                            pval.employeeIdPhotoUrl.errors
                        }"
                class="form-control shadow"
                formControlName="employeeIdPhotoUrl"
                id="employeeIdPhotoUrl"
                src="image/*"
                type="file"
              />
            </div>
            <div *ngIf="
                        (pval.employeeIdPhotoUrl.touched || pval.employeeIdPhotoUrl.dirty) &&
                        pval.employeeIdPhotoUrl.errors?.required
                      " class="text-danger col">
              Your ID photo is required
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group text-primary">
            <label for="employeeGender">Gender</label>
            <div class="input-block mb-0">
              <select
                [ngClass]="{
                'is-invalid':
                  (pval.employeeGender.touched || pval.employeeGender.dirty) &&
                  pval.employeeGender.errors
              }"
                autocomplete="off"
                class="form-control shadow"
                formControlName="employeeGender"
                id="employeeGender"
                required
                type="text"
              >
                <option
                  class="place-holder-select"
                  disabled
                  hidden
                  selected
                  value=""
                >
                  Select an ID Type
                </option>
                <option *ngFor="let type of gender" [value]="type">
                  {{ type }}
                </option>
              </select>
              <div *ngIf="
                        (pval.employeeGender.touched || pval.employeeGender.dirty) &&
                        pval.employeeGender.errors?.required
                      " class="text-danger col">
                Your Gender is required
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group text-secondary">
            <label [ngClass]="pval.employmentNumber.valid ? 'text-info' : ''" for="employmentNumber">
              Employee Number
            </label>
            <div class="input-block mb-0">
              <i class="start fas fa-tag text-secondary mx-auto" style="size: 50px"></i>
              <input
                [ngClass]="{
                          'is-invalid':
                            (pval.employmentNumber.touched ||
                              pval.employmentNumber.dirty) &&
                            pval.employmentNumber.errors
                        }"
                autocomplete="off"
                class="form-control shadow"
                formControlName="employmentNumber"
                id="employmentNumber"
                placeholder="Your Employee ID"
                type="text"/>
            </div>
            <div *ngIf="
                        (pval.employmentNumber.touched ||
                          pval.employmentNumber.dirty) &&
                        pval.employmentNumber.errors?.required
                      " class="text-danger col">
              The employment number is required
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group text-primary">
            <label for="jobTitle">Job Title</label>
            <div class="input-block mb-0">
              <input
                [ngClass]="{
                          'is-invalid':
                            (pval.jobTitle.touched ||
                              pval.jobTitle.dirty) &&
                            pval.jobTitle.errors
                        }"
                autocomplete="off"
                class="form-control shadow"
                formControlName="jobTitle"
                id="jobTitle"
                type="text"
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group text-primary">
            <label for="department">Department</label>
            <div class="input-block mb-0">
              <input
                [ngClass]="{
                          'is-invalid':
                            (pval.department.touched ||
                              pval.department.dirty) &&
                            pval.department.errors
                        }"
                class="form-control shadow"
                formControlName="department"
                id="department"
                type="text"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group text-primary">
            <label for="employeeIdType">ID Type</label>
            <div class="input-block mb-0">
              <select
                [ngClass]="{
                'is-invalid':
                  (pval.employeeIdType.touched || pval.employeeIdType.dirty) &&
                  pval.employeeIdType.errors
              }"
                autocomplete="off"
                class="form-control shadow"
                formControlName="employeeIdType"
                id="employeeIdType"
                required
                type="text"
              >
                <option
                  class="place-holder-select"
                  disabled
                  hidden
                  selected
                  value=""
                >
                  Select an ID Type
                </option>
                <option *ngFor="let type of idTypes" [value]="type.id">
                  {{ type.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group text-secondary">
            <label [ngClass]="pval.employeeIdNumber.valid ? 'text-info' : ''" for="employeeIdNumber">ID Number</label>
            <div class="input-block mb-0">
              <i class="start fas fa-tag text-secondary mx-auto" style="size: 50px"></i>
              <input
                [ngClass]="{
                          'is-invalid':
                            (pval.employeeIdNumber.touched ||
                              pval.employeeIdNumber.dirty) &&
                            pval.employeeIdNumber.errors
                        }"
                autocomplete="off"
                class="form-control shadow"
                formControlName="employeeIdNumber"
                id="employeeIdNumber"
                placeholder="Type your ID number"
                type="text"
              />
            </div>
            <div *ngIf="
                        (pval.employeeIdNumber.touched ||
                          pval.employeeIdNumber.dirty) &&
                        pval.employeeIdNumber.errors?.required
                      " class="text-danger col">
              The ID number is required
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group text-secondary">
            <label [ngClass]="pval.employeePhone1.valid ? 'text-info' : ''" for="employeePhone1">Phone contact 1</label>
            <div class="input-block mb-0">
              <i class="start fas fa-phone text-secondary mx-auto" style="size: 50px"></i>
              <input [ngClass]="{
                          'is-invalid':
                            (pval.employeePhone1.touched ||
                              pval.employeePhone1.dirty) &&
                            pval.employeePhone1.errors
                        }" autocomplete="off" class="form-control shadow"
                     formControlName="employeePhone1"
                     id="employeePhone1" placeholder="e.g. 07XXXXXXXX" type="text"/>
            </div>
            <div *ngIf="
                        (pval.employeePhone1.touched ||
                          pval.employeePhone1.dirty) &&
                        pval.employeePhone1.errors?.required
                      " class="text-danger col">
              Your phone contact is required
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group text-secondary">
            <label [ngClass]="pval.employeePhone2.valid ? 'text-info' : ''" for="employeePhone2">Phone contact 2</label>
            <div class="input-block mb-0">
              <i class="start fas fa-phone text-secondary mx-auto" style="size: 50px"></i>
              <input [ngClass]="{
                          'is-invalid':
                            (pval.employeePhone2.touched ||
                              pval.employeePhone2.dirty) &&
                            pval.employeePhone2.errors
                        }" autocomplete="off" class="form-control shadow"
                     formControlName="employeePhone2"
                     id="employeePhone2" placeholder="e.g. 07XXXXXXXX (optional)" type="text"/>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group text-secondary">
            <label for="employeeRecruitmentDate">Recruitment Date</label>

            <div class="input-block mb-0">
              <i class="start fas fa-calendar-alt text-primary mx-auto" style="size: 50px">
              </i>
              <input
                [bsConfig]="{
                          dateInputFormat: 'MM/DD/YYYY',
                          isAnimated: true
                        }"
                [ngClass]="{
                          'is-invalid':
                            (pval.employeeRecruitmentDate.touched ||
                              pval.employeeRecruitmentDate.dirty) &&
                            pval.employeeRecruitmentDate.errors
                        }"
                autocomplete="off"
                bsDatepicker
                class="form-control shadow"
                formControlName="employeeRecruitmentDate"
                id="employeeRecruitmentDate"
                placeholder="Click to select Date"
                placement="bottom"
                type="text"
              />
              <div *ngIf="
                        (pval.employeeRecruitmentDate.touched || pval.employeeRecruitmentDate.dirty) &&
                        pval.employeeRecruitmentDate.errors?.required
                      " class="text-danger col">
                Your recruitment Date is required
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group text-secondary">
            <label [ngClass]="pval.employeeHomeAreaDetails.valid ? 'text-info' : ''" for="employeeHomeAreaDetails">
              Home Address
            </label>
            <div class="input-block mb-0">
              <i class="start fas fa-envelope text-secondary mx-auto" style="size: 50px"></i>
              <input
                [ngClass]="{
                          'is-invalid':
                            (pval.employeeHomeAreaDetails.touched ||
                              pval.employeeHomeAreaDetails.dirty) &&
                            pval.employeeHomeAreaDetails.errors
                        }"
                autocomplete="off"
                class="form-control shadow"
                formControlName="employeeHomeAreaDetails"
                id="employeeHomeAreaDetails"
                placeholder="Type your home address"
                type="text"
              />
            </div>
            <div *ngIf="
                        (pval.employeeHomeAreaDetails.touched ||
                          pval.employeeHomeAreaDetails.dirty) &&
                        pval.employeeHomeAreaDetails.errors?.required
                      " class="text-danger col">
              Your home address is required
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex my-1 pt-0 pb-2 pt-md-2 pt-xl-1 mx-auto mx-md-4 px-md-5">
        <button
          [disabled]="!profileForm.valid || profileForm.pristine || loading"
          class="btn btn-primary mx-auto px-4 px-md-5 shadow"
          type="submit"
        >
          <i class="fas fa-save mx-auto" style="size: 50px"></i>
          Save
        </button>
        <button
          (click)="cancel()"
          [disabled]="loading"
          class="btn btn-danger mx-auto px-4 px-md-5 shadow"
          type="button"
        >
          <i class="fas fa-times mx-auto" style="size: 50px"></i>
          Cancel
        </button>
      </div>
    </form>
  </div>
</div>
