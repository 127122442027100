import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { AuthService } from "src/app/modules/auth/services/auth.service";
import { CustomValidator } from "src/app/modules/shared/validators/custom-validator";
import { ToastrService } from "ngx-toastr";
import {
  formatFormFields,
  showToasterMessage,
} from "../../../shared/utils/functions";
import { API_URLS } from "../../../shared/utils/constants";
import { Branch } from "src/app/modules/shared/models/user-auth";

export interface Department {
  roleName: string;
  roleId: number;
}

@Component({
  selector: "app-register-employee",
  templateUrl: "./register-employee.component.html",
  styleUrls: ["./register-employee.component.scss"],
})
export class RegisterEmployeeComponent implements OnInit {
  loading = false;
  fieldType: boolean;
  userForm: FormGroup;
  branches: Array<Branch>;
  REGISTER_ENDPOINT = API_URLS.AUTH.EMPLOYEE.REGISTER;

  constructor(
    private authService: AuthService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private _formBuilder: FormBuilder
  ) {}

  get fval(): any {
    return this.userForm.controls;
  }

  ngOnInit(): any {
    this.userForm = this.createFormGroup();
    this.getBranches();
  }

  createFormGroup(): FormGroup {
    return new FormGroup(
      {
        employeeName: this._formBuilder.control(
          "",
          Validators.compose([
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(128),
            // 4. check whether the entered password has a lower-case letter
            CustomValidator.patternValidator(/[a-zA-Z]/, {
              hasCharacters: true,
            }),
          ])
        ),
        employeePhone1: this._formBuilder.control(
          "",
          Validators.compose([
            Validators.required,
            CustomValidator.patternValidator(
              /^(([0])([1-9])([0-9])([0-9])([0-9])([0-9])([0-9])([0-9])([0-9])([0-9]))$/,
              { hasNumber: true }
            ),
          ])
        ),
        employeeEmail: this._formBuilder.control(
          "",
          Validators.compose([
            Validators.required,
            CustomValidator.patternValidator(/\S+@\S+\.\S+/, {
              validEmail: true,
            }),
          ])
        ),
        password: this._formBuilder.control(
          "",
          Validators.compose([
            // 1. Password Field is Required

            Validators.required,

            // 2. check whether the entered password has a number
            //CustomValidatorInitialCompanySetup.patternValidator(/^(([1-9])([1-9])([1-9])([0-9]))$/, { hasNumber: true }),
            // 6. Has a minimum length of 8 characters
            Validators.minLength(8),
            //Validators.maxLength(4),
            // 3. check whether the entered password has upper case letter
            CustomValidator.patternValidator(/[A-Z]/, {
              hasCapitalCase: true,
            }),
            // 4. check whether the entered password has a lower-case letter
            CustomValidator.patternValidator(/[a-z]/, {
              hasSmallCase: true,
            }),
            // 5. check whether the entered password has a special character
            CustomValidator.patternValidator(/[!@#$%^&*_+-='"|>?]/, {
              hasSpecialCharacters: true,
            }),
          ])
        ),
        confirmPassword: this._formBuilder.control(
          "",
          Validators.compose([Validators.required])
        ),
        employeeBranchId: this._formBuilder.control("", Validators.required),
      },
      { validators: CustomValidator.passwordMatchValidator }
    );
  }

  toggleFieldType(): void {
    this.fieldType = !this.fieldType;
  }

  onSubmit(): any {
    this.loading = true;
    if (!this.userForm.valid) return;
    const data = formatFormFields(this.userForm.value);
    data.employeePassword = data.password;
    delete data.password;
    delete data.confirmPassword;
    this.authService.registerHandler(data, this.REGISTER_ENDPOINT).subscribe(
      () => {
        this.loading = false;
        showToasterMessage(
          this.toaster,
          "success",
          "Please first verify your email and then proceed to login",
          "Registration was Successful"
        );
        this.userForm.reset();
        this.router.navigate(["auth/employee-login"]).then();
      },

      (error: string) => {
        this.loading = false;
        showToasterMessage(
          this.toaster,
          "warning",
          error,
          "Registration Failed!"
        );
      }
    );
  }

  getBranches() {
    this.authService.getAuthBranches().subscribe((data: any) => {
      this.branches = data;
    });
  }
}
