import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { BsModalService } from "ngx-bootstrap/modal";
import { ActivatedRoute, Router } from "@angular/router";
import {
  EmployeeData,
  EmployeeRole,
} from "src/app/modules/shared/models/employee";
import {
  noRecords,
  searchItems,
  showToasterMessage,
} from "../../../../shared/utils/functions";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../../../../auth/services/auth.service";
import { AdminService } from "../../../services/admin.service";
import { EmployeeResolvedData } from "../../../resolvers/all-employees.resolver";
import { EmployeeDetailsComponent } from "../../../../shared/components/employee-details/employee-details.component";
import { PageEvent } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { EmployeeRoleEditorComponent } from "../employee-role-editor/employee-role-editor.component";

@Component({
  selector: "app-approve-users",
  templateUrl: "./approve-employee.component.html",
  styleUrls: ["./approve-employee.component.scss"],
})
export class ApproveEmployeeComponent implements OnInit {
  loading = false;
  employee: EmployeeData;
  employees: Array<EmployeeData> = [];
  employeesCopy: Array<EmployeeData> = [];
  roles: Array<EmployeeRole>;
  imageUrl: string;
  serviceErrors: string;
  User = this.authService.loggedInUserInfo();
  totalItems = 0;
  pageSize = 10;
  lowValue: number = 0;
  highValue: number = 10;
  displayedColumns: string[] = [
    "employeeIdPhotoUrl",
    "employeeId",
    "employeeName",
    "roleName",
    "branchName",
    "Department",
    "employeePhone1",
    "action",
  ];
  @ViewChild("exportTable") element: ElementRef;

  constructor(
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private toaster: ToastrService,
    private authService: AuthService,
    private adminService: AdminService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    const resolvedData: EmployeeResolvedData =
      this.activateRoute.snapshot.data[0]?.details;
    this.serviceErrors = this.activateRoute.snapshot.data[0]?.error;
    if (resolvedData) {
      this.employeesCopy = this.employees = noRecords(resolvedData.employees)
        ? []
        : resolvedData.employees.map(
            (employee): EmployeeData => ({
              ...employee,
              employeePhotoUrl:
                employee.employeePhotoUrl
                  .toLowerCase()
                  .indexOf("firebasestorage") !== -1
                  ? employee.employeePhotoUrl
                  : "",
            })
          );
      this.roles = noRecords(resolvedData.roles) ? [] : resolvedData.roles;
      this.totalItems = this.employees.length;
    } else showToasterMessage(this.toaster, "warning", this.serviceErrors);
  }

  getValue(event): void {
    const results = searchItems(
      event.target.value,
      this.employees,
      "employees"
    );
    this.employees = results.length ? results : this.employeesCopy;
    this.totalItems = this.employees.length;
  }

  exportToExcel(): void {
    // this.exportService.exportExcel(this.filteredUsers, "registeredusers");
  }

  openEditRoleModal(employee: EmployeeData): void {
    this.employee = employee;
    const dialogRef = this.dialog.open(EmployeeRoleEditorComponent, {
      minWidth: "30%",
      data: { employee, roles: this.roles, isApproval: true },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.approveEmployee) {
        this.approveEmployee({
          employeeId: this.employee.employeeId,
          roleId: result.roleId,
          employeeIdApprover: this.User.employeeId,
        });
      } else this.rejectEmployee();
    });
  }

  openDetailsModal(employee: EmployeeData): void {
    const dialogRef = this.dialog.open(EmployeeDetailsComponent, {
      minWidth: "30%",
      data: { employee, isModal: true },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.employeesCopy = this.employees = this.employees.map(
          (employee) => ({
            ...employee,
            roleId:
              employee.employeeId === result.employeeId
                ? result.roleId
                : employee.roleId,
            roleName:
              employee.employeeId === result.employeeId
                ? result.roleName
                : employee.roleName,
          })
        );
      }
    });
  }

  public getPaginatorData(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    return event;
  }

  pageSizeOptions() {
    return [5, 10, 20, 30, 40, 50, this.employees?.length].filter(
      (n) => n <= this.employees?.length
    );
  }

  approveEmployee(data: {
    employeeId: number;
    roleId: number;
    employeeIdApprover: number;
  }): void {
    this.adminService.approveEmployee(data).subscribe(
      () => {
        this.employeesCopy = this.employees = this.employees.filter(
          (user) => user.employeeId !== data.employeeId
        );
        showToasterMessage(
          this.toaster,
          "success",
          "User was assigned successfully"
        );
      },
      (error: string) => {
        showToasterMessage(this.toaster, "warning", error);
      }
    );
  }

  rejectEmployee(): void {
    this.adminService
      .rejectEmployee({ employeeId: this.employee.employeeId })
      .subscribe(
        () => {
          this.employeesCopy = this.employees = this.employees.filter(
            (user) => user.employeeId !== this.employee.employeeId
          );
          showToasterMessage(
            this.toaster,
            "success",
            "user was rejected successfully"
          );
        },
        (error: string) => {
          showToasterMessage(this.toaster, "warning", error);
        }
      );
  }
}
