<app-auth-nav></app-auth-nav>
<header [ngStyle]="{'background-image': getUrl()}" class="masthead">
  <div class="container h-100">
    <div
      class="
        welcome h-100 d-flex flex-column justify-content-start justify-content-md-center
        justify-content-lg-center align-items-center
      "
    >
      <p class="text-white font-weight-bold mt-0 pt-0">
        We are the Brand Leaders In Printing, Corporate Branding, Commercial
        Printing and Large-format Material
      </p>
      <p class="text-primo">
        <strong> "Enhancing Your Brand"</strong>
      </p>
      <a class="btn btn-primary btn-sm js-scroll-trigger w-25" routerLink="#">
        Learn More&nbsp;
        <i class="fas fa-arrow-right"></i>
      </a>
      <hr class="divider my-6">
    </div>
  </div>
  <!-- Footer -->
  <div class="container">
    <div class="small text-center text-warning mt-sm-5 pb-sm-2">Copyright &copy; 2021 - Brimasmedia Ltd</div>
  </div>
</header>

