<app-auth-nav></app-auth-nav>
<div class="d-flex flex-column justify-content-between align-items-center" style="height: 100vh">
  <div class="mt-5"></div>
  <div class="card mt-3">
    <h2 class="d-flex flex-row justify-content-center mt-3">
      <i class="fas fa-sign-in-alt text-info mx-auto"></i>
    </h2>
    <h3 class="auth-head card-title text-info d-flex justify-content-center">
      <span>Client Sign Up</span>
    </h3>
    <div class="card-body py-2 mb-0">
      <form (ngSubmit)="onSubmit()" [formGroup]="userForm">
        <div class="form-group text-primary">
          <label [ngClass]="fval.customerName.valid ? 'text-info' : ''"
                 for="customerName"
          >Name<i *ngIf="fval.customerName.valid" class="material-icons"
          >check_circle</i
          ></label
          >
          <div class="input-block mb-0">
            <input
              [ngClass]="{
                'is-invalid':
                  (fval.customerName.touched || fval.customerName.dirty) &&
                  fval.customerName.errors
              }"
              autocomplete="off"
              class="form-control shadow" formControlName="customerName"
              id="customerName"
              placeholder="Enter client name"
              required type="text"
            >
            <i class="start fas fa-user text-primary mx-auto">
            </i>
          </div>
          <small
            *ngIf="
              (fval.customerName.touched || fval.customerName.dirty) &&
              fval.customerName.errors?.required
            "
            class="text-danger col"
          >
            Your name is required
          </small>
        </div>
        <div class="form-group text-primary">
          <label [ngClass]="fval.customerPhone1.valid ? 'text-info' : ''"
                 for="customerPhone1"
          >Phone number<i
            *ngIf="fval.customerPhone1.valid"
            class="material-icons"
          >check_circle</i
          ></label
          >
          <div class="input-block mb-0">
            <input
              [ngClass]="{
                'is-invalid':
                  (fval.customerPhone1.touched ||
                    fval.customerPhone1.dirty) &&
                  fval.customerPhone1.errors
              }"
              autocomplete="off"
              class="form-control shadow"
              formControlName="customerPhone1"
              id="customerPhone1"
              placeholder="Enter phone number"
              required type="text"
            >
            <i
              class="start fas fa-phone text-primary mx-auto"
            ></i>
            <small
              *ngIf="
                (fval.customerPhone1.touched ||
                fval.customerPhone1.dirty) &&
                fval.customerPhone1.errors?.required
                "
              class="text-danger col"
            >
              Your phone number is required
            </small>
          </div>
        </div>
        <div class="form-group text-primary">
          <label [ngClass]="fval.customerEmail.valid ? 'text-info' : ''" for="customerEmail"
          >Email<i *ngIf="fval.customerEmail.valid" class="material-icons"
          >check_circle</i
          ></label
          >
          <div class="input-block mb-0">
            <input
              [ngClass]="{ 'is-invalid':(fval.customerEmail.touched || fval.customerEmail.dirty) && fval.customerEmail.errors}"
              autocomplete="off"
              class="form-control shadow"
              formControlName="customerEmail"
              id="customerEmail"
              placeholder="Enter Email"
              required
              type="text"
            >
            <i
              class="start fas fa-envelope text-primary mx-auto"
            ></i>
          </div>
          <small
            *ngIf="
              (fval.customerEmail.touched || fval.customerEmail.dirty) &&
              fval.customerEmail.errors?.required
            "
            class="text-danger col"
          >
            Your email address is required
          </small>
          <small
            *ngIf="
              (fval.customerEmail.touched || fval.customerEmail.dirty) &&
              fval.customerEmail.errors?.validEmail
            "
            class="text-danger col"
          >
            Please enter a valid email address
          </small>
        </div>
        <div class="form-group text-secondary">
          <label
            [ngClass]="fval.customerPhotoUrl.valid ? 'text-info' : ''"
            for="customerPhotoUrl"
          >Logo (Optional)</label>
          <div class="input-block mb-0">
            <i
              class="start fas fa-image text-secondary mx-auto"
              style="size: 50px"
            >
            </i>
            <input
              (change)="onFileSelected($event)"
              [appDisableControl]="!fval.customerEmail.valid"
              [ngClass]="{
                        'is-invalid':
                          (fval.customerPhotoUrl.touched ||
                            fval.customerPhotoUrl.dirty) &&
                          fval.customerPhotoUrl.errors
                      }"
              class="form-control shadow"
              formControlName="customerPhotoUrl"
              id="customerPhotoUrl"
              src="image/*"
              type="file"
            >
          </div>
        </div>
        <div class="form-group text-primary">
          <label [ngClass]="fval.customerBranchId.valid ? 'text-info' : ''">
            <i *ngIf="fval.customerBranchId.valid" class="material-icons">check_circle</i>
            Branch
          </label>
          <div class="input-block mb-0">
            <select
              [ngClass]="{
              'is-invalid':
                (fval.customerBranchId.touched || fval.customerBranchId.dirty) && fval.customerBranchId.errors
            }"
              autocomplete="off"
              class="form-control shadow"
              formControlName="customerBranchId"
              required type="text"
            >
              <option class="place-holder-select" disabled hidden selected value="">Select a branch</option>
              <option *ngFor="let branch of branches" [value]="branch.branchId">{{branch.branchName}}</option>
            </select>
          </div>
          <small
            *ngIf="
              (fval.customerBranchId.touched || fval.customerBranchId.dirty) && fval.customerBranchId.errors?.required
            "
            class="text-danger col"
          >
            The branch name is required
          </small>
        </div>
        <div class="form-group text-primary">
          <label
            [ngClass]="fval.password.valid ? 'text-info' : ''"
            for="password"
          >Password<i *ngIf="fval.password.valid" class="material-icons"
          >check_circle</i
          ></label
          >

          <div class="input-block mb-0">
            <i
              class="start fas fa-lock text-primary mx-auto"
              style="size: 50px"
            ></i>
            <input
              [ngClass]="{
                'is-invalid':
                  (fval.password.touched || fval.password.dirty) &&
                  fval.password.errors
              }"
              [type]="fieldType ? 'text' : 'password'"
              autocomplete="off"
              class="form-control shadow"
              formControlName="password"
              id="password"
              placeholder="Enter Password"
            />
            <i
              (click)="toggleFieldType()"
              [ngClass]="{
                  'fa-eye-slash': !fieldType,
                  'fa-eye': fieldType
                }"
              class="end fas"
            ></i>
          </div>
          <div>
            <small
              *ngIf="
              (fval.password.touched || fval.password.dirty) &&
              fval.password.errors?.required
            "
              class="text-danger col"
            >
              Your Password is Required
            </small>
          </div>
          <div>
            <small
              *ngIf="
              (fval.password.touched || fval.password.dirty) &&
              fval.password.errors?.minlength
            "
              class="text-danger col"
            >
              Password should have a minimum of 8 characters
            </small>
          </div>
          <div>
            <small
              *ngIf="
              (fval.password.touched || fval.password.dirty) &&
              fval.password.errors?.hasCapitalCase
            "
              class="text-danger col"
            >
              Password should contain a capital letter
            </small>
          </div>
          <div>
            <small
              *ngIf="
              (fval.password.touched || fval.password.dirty) &&
              fval.password.errors?.hasSmallCase
            "
              class="text-danger col"
            >
              Password should contain a small letter
            </small>
          </div>
          <div>
            <small
              *ngIf="
              (fval.password.touched || fval.password.dirty) &&
              fval.password.errors?.hasSpecialCharacters
            "
              class="text-danger col"
            >
              Password should contain a special character
            </small>
          </div>
        </div>
        <div class="form-group text-primary">
          <label [ngClass]="fval.confirmPassword.valid ? 'text-info' : ''"
                 for="confirmPassword"
          >Confirm Password<i
            *ngIf="fval.confirmPassword.valid"
            class="material-icons"
          >check_circle</i
          ></label
          >
          <div class="input-block mb-0">
            <i
              class="start fas fa-lock text-primary mx-auto"
            ></i>
            <input
              [ngClass]="{
                'is-invalid':
                  (fval.confirmPassword.touched ||
                    fval.confirmPassword.dirty) &&
                  fval.confirmPassword.errors
              }"
              [type]="fieldType ? 'text' : 'password'"
              autocomplete="off"
              class="form-control shadow"
              formControlName="confirmPassword"
              id="confirmPassword"
              placeholder="Confirm Password" required
            >
            <i
              (click)="toggleFieldType()"
              [ngClass]="{
                    'fa-eye-slash': !fieldType,
                    'fa-eye': fieldType
                  }"
              class="end fas"
            ></i>
          </div>

          <small
            *ngIf="fval.confirmPassword.errors?.NoPasswordMatch"
            class="text-danger col"
          >
            Passwords do not match
          </small>
          <small
            *ngIf="
              fval.confirmPassword.errors?.required &&
              (fval.confirmPassword.touched || fval.confirmPassword.dirty)
            "
            class="text-danger col"
          >
            Please confirm your password
          </small>
        </div>
        <div class="d-flex flex-row justify-content-between mb-4">
          <button
            [disabled]="!userForm.valid"
            class="btn btn-primary col-5 shadow"
            type="submit"
          >
            REGISTER
          </button>
          <button
            class="btn btn-danger col-5 shadow"
            routerLink="/auth/customer-login"
            type="button"
          >
            LOGIN
          </button>
        </div>
      </form>
      <div class="col-12 d-flex justify-content-start mt-0 pt-0">
        <div class="form-group">
          Already signed up?
          <a class="text-info" routerLink="/auth/customer-login">
            Login
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="small text-center text-info mb-5">
    Copyright &copy; {{2022}} - Brimasmedia Ltd
  </div>
</div>


