import { Component, OnInit } from "@angular/core";
import { MenuItem } from "../../shared/layout/left-panel/left-panel.component";

@Component({
  selector: "app-customer-index",
  templateUrl: "./customer-index.component.html",
  styleUrls: ["./customer-index.component.scss"],
})
export class CustomerIndexComponent implements OnInit {
  menuList: MenuItem[];
  moduleName: string;
  userName: string;
  imageUrl: string = "../../../../assets/avatar3.jpg";
  constructor() {}

  ngOnInit(): void {
    this.moduleName = "CuSTOMER";
    this.menuList = [
      {
        name: this.userName,
        icon: "far fa-user-circle",
        url: "/customer/none",
      },

      {
        name: "customer Dashboard",
        icon: "far fa-clone",
        url: "/customer/dashboard",
        // badge: '2',
        // badgeBg: 'bg-success',
      },

      {
        name: "Add Tokens To Stock",
        icon: "far fa-calendar-alt text-danger",
        url: "/customer/addtokens",
        // badge: '3',
        // badgeBg: 'bg-danger',
      },
      {
        name: "Sell Tokens",
        icon: "far fa-user-circle text-success",
        url: "/customer/selltokens",
        // badge: '3',
        // badgeBg: 'bg-danger',
      },
      {
        name: "Transfer Tokens To Savings",
        icon: "far fa-user-circle text-info",
        url: "/customer/transfertokenstosavingsbox",
        // badge: '3',
        // badgeBg: 'bg-danger',
      },
      {
        name: "Withdraw Tokens",
        icon: "far fa-clone text-warning",
        url: "/customer/withdrawTokens",
        // badge: '2',
        // badgeBg: 'bg-success',
      },
      {
        name: "Update Profile",
        icon: "far fa-user text-danger",
        url: "/customer/profile",
        // badge: '3',
        // badgeBg: 'bg-danger',
      },
      {
        name: "Notifications",
        icon: "fas fa-envelope text-info",
        url: "/customer/notifications",
        badge: "8",
        badgeBg: "bg-danger",
      },
    ];
  }
}
