<div class="mx-auto">
  <h4 class="text-info text-uppercase text-center font-weight-bold pt-1 pb-0 px-3">
    Change Password
  </h4>
  <div class="my-4">
    <form (ngSubmit)="changePassword()" [formGroup]="userForm" autocomplete="off">
      <div class="form-group text-primary">
        <label [ngClass]="fval.code.valid ? 'text-info' : ''" for="code">
          Check your email for a password reset code
          <i *ngIf="fval.code.valid" class="material-icons">check_circle</i>
        </label>
        <div class="input-group mb-0 d-flex flex-row">
          <input
            [ngClass]="{
                'is-invalid':
                  (fval.code.touched || fval.code.dirty) && fval.code.errors
              }"
            autocomplete="off"
            class="form-control"
            formControlName="code"
            id="code"
            placeholder="Enter the code sent to your email"
            type="text"
          />
          <button
            (click)="getPasswordResetCode()"
            class="btn btn-outline-info ml-4"
            mat-button
            style="background-color: #0babe0; color: white"
            tooltip="Resend Code"
          >
            Resend
          </button>
        </div>
        <div
          *ngIf="
              (fval.code.touched || fval.code.dirty) &&
              fval.code.errors?.required
            "
          class="text-danger col"
        >
          The code is required!
        </div>
        <div
          *ngIf="
            (fval.code.touched || fval.code.dirty) &&
            fval.code.invalid
          "
          [ngClass]="
            fval.code.errors?.required ||
            fval.code.errors?.minlength ||
            fval.code.errors?.maxlength
              ? 'text-danger'
              : 'text-info'
          "
          class="col"
        >
          The code should have exactly five digits!
        </div>
        <div
          *ngIf="
            (fval.code.touched || fval.code.dirty) &&
            fval.code.invalid
          "
          [ngClass]="
            fval.code.hasError('required') ||
            fval.code.hasError('hasNumber')
              ? 'text-danger'
              : 'text-info'
          "
          class="col"
        >
          Only digits are allowed!
        </div>
      </div>
      <div class="form-group text-primary">
        <label
          [ngClass]="fval.password.valid ? 'text-info' : ''"
          for="password"
        >
          New Password
          <i *ngIf="fval.password.valid" class="material-icons">check_circle</i>
        </label>
        <div class="input-block mb-0">
          <i
            class="start fas fa-lock text-primary mx-auto"
            style="size: 50px;"
          ></i>
          <input
            [ngClass]="{
              'is-invalid':
                (fval.password.touched || fval.password.dirty) &&
                fval.password.errors
            }"
            [type]="fieldType ? 'text' : 'password'"
            autocomplete="off"
            class="form-control shadow"
            formControlName="password"
            id="password"
            placeholder="Enter New Password"
          >
          <i
            (click)="toggleFieldType()"
            [ngClass]="{
                  'fa-eye-slash': !fieldType,
                  'fa-eye': fieldType
                }"
            class="end fas"
          ></i>
        </div>
        <div
          *ngIf="
            (fval.password.touched || fval.password.dirty) &&
            fval.password.errors?.required
          "
          class="text-danger col"
        >
          New Password is required
        </div>
        <div
          *ngIf="
            (fval.password.touched || fval.password.dirty)&&fval.password.errors?.hasNumber
          "
          class="text-danger col"
        >
          Password should contain a number and an uppercase letter
        </div>
      </div>
      <div class="form-group text-primary">
        <label
          [ngClass]="fval.confirmPassword.valid ? 'text-info' : ''"
          for="confirmPassword"
        >
          Confirm Password
          <i *ngIf="fval.confirmPassword.valid" class="material-icons">check_circle</i>
        </label>
        <div class="input-block mb-0">
          <i
            class="start fas fa-lock text-primary mx-auto"
            style="size: 50px"
          ></i>
          <input
            [ngClass]="{
                'is-invalid':
                  (fval.confirmPassword.touched ||
                    fval.confirmPassword.dirty) &&
                  fval.confirmPassword.errors
              }"
            [type]="fieldType ? 'text' : 'password'"
            autocomplete="off"
            class="form-control shadow"
            formControlName="confirmPassword"
            id="confirmPassword"
            placeholder="Confirm Password"
          />
          <i
            (click)="toggleFieldType()"
            [ngClass]="{
                    'fa-eye-slash': !fieldType,
                    'fa-eye': fieldType
                  }"
            class="end fas"
          ></i>
        </div>
        <div
          *ngIf="
              (fval.confirmPassword.touched || fval.confirmPassword.dirty) &&
              fval.confirmPassword.errors?.required
            "
          class="text-danger col"
        >
          Please Confirm Password
        </div>
        <div
          *ngIf="fval.confirmPassword.errors?.NoPasswordMatch"
          class="text-danger col"
        >
          Passwords do not match
        </div>
      </div>
      <div class="d-flex my-0 py-2 mx-auto">
        <button
          [disabled]="!userForm.valid"
          class="btn btn-primary col-5 mx-auto shadow"
          type="submit"
        >
          Save
        </button>

        <button
          (click)="cancel()"
          [disabled]="loading"
          class="btn btn-danger col-5 mx-auto shadow"
          type="button"
        >
          Cancel
        </button>
      </div>
    </form>
  </div>
</div>
