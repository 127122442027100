import { Component, Input, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { AuthService } from "src/app/modules/auth/services/auth.service";
import { LayoutService } from "src/app/modules/shared/layout/services/layout.service";
import {
  checkUserType,
  showToasterMessage,
} from "../../../shared/utils/functions";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-header",
  templateUrl: "./header-production.component.html",
  styleUrls: ["./header-production.component.scss"],
})
export class HeaderProductionComponent implements OnInit {
  @Input() navLayout: string;
  @Input() defaultNavbar: string;
  @Input() toggleNavbar: string;
  @Input() toggleStatus: boolean;
  @Input() navbarEffect: string;
  @Input() deviceType: string;
  @Input() headerColorTheme: string;
  @Input() leftHeaderColorTheme: string;
  @Input() navbarColorTheme: string;
  @Input() activeNavColorTheme: string;
  @Input() headerHeight: number;
  @Input() collapsedLeftHeader: boolean;

  user = "../../../assets/img/man.svg";
  userName: string;
  serviceErrors: any;

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private layoutService: LayoutService,
    private authService: AuthService,
    private toaster: ToastrService
  ) {}

  ngOnInit() {}

  changeTheToggleStatus() {
    this.layoutService.getToggleStatus();
  }

  logoutUser() {
    setTimeout(() => {
      if (checkUserType(this.authService.loggedInUserInfo()) === "employee") {
        this.router.navigate(["auth/employee-login"]).then();
        this.authService.logoutUser();
      } else if (
        checkUserType(this.authService.loggedInUserInfo()) === "customer"
      ) {
        this.router.navigate(["auth/customer-login"]).then();
        this.authService.logoutUser();
      } else if (
        checkUserType(this.authService.loggedInUserInfo()) === "supplier"
      ) {
        this.router.navigate(["auth/supplier-login"]).then();
        this.authService.logoutUser();
      }
      showToasterMessage(this.toaster, "success", "Logout Successfully!!");
    }, 1000);
  }
}
