<app-auth-nav *ngIf="showNavBar"></app-auth-nav>
<div class="d-flex flex-column justify-content-between align-items-center" style="height: 100vh">
  <div class="card">
    <h3 class="auth-head card-title text-info d-flex justify-content-center">
      <span>Change Password</span>
    </h3>
    <div class="card-body py-2 mb-0">
      <form (ngSubmit)="changePassword()" [formGroup]="userForm" autocomplete="off">
        <div class="form-group text-primary">
          <label [ngClass]="fval.code.valid ? 'text-info' : ''" for="code">
            Reset Code
            <i *ngIf="fval.code.valid" class="material-icons">check_circle</i>
          </label>
          <div class="input-group mb-0">
            <input
              [ngClass]="{
                'is-invalid':
                  (fval.code.touched || fval.code.dirty) && fval.code.errors
              }"
              autocomplete="off"
              class="form-control shadow col-8"
              formControlName="code"
              id="code"
              placeholder="Enter the code sent to your email"
              type="text"
            />
            <button
              (click)="getPasswordResetCode()"
              class="btn btn-outline-info col-3 ml-4"
              mat-button
              style="background-color: #0babe0; color: white"
            >
              Resend
            </button>
          </div>
          <div
            *ngIf="
              (fval.code.touched || fval.code.dirty) &&
              fval.code.errors?.required
            "
            class="text-danger col"
          >
            The code is required!
          </div>
          <div
            *ngIf="
            (fval.code.touched || fval.code.dirty) &&
            fval.code.invalid
          "
            [ngClass]="
            fval.code.errors?.required ||
            fval.code.errors?.minlength ||
            fval.code.errors?.maxlength
              ? 'text-danger'
              : 'text-info'
          "
            class="col"
          >
            The code should have exactly five digits!
          </div>
          <div
            *ngIf="
            (fval.code.touched || fval.code.dirty) &&
            fval.code.invalid
          "
            [ngClass]="
            fval.code.hasError('required') ||
            fval.code.hasError('hasNumber')
              ? 'text-danger'
              : 'text-info'
          "
            class="col"
          >
            Only digits are allowed!
          </div>
        </div>
        <div class="form-group text-primary">
          <label
            [ngClass]="fval.password.valid ? 'text-info' : ''"
            for="password"
          >
            Password
            <i *ngIf="fval.password.valid" class="material-icons">check_circle</i>
          </label>

          <div class="input-block mb-0">
            <i
              class="start fas fa-lock text-primary mx-auto"
              style="size: 50px"
            ></i>
            <input
              [autocomplete]="false"
              [ngClass]="{
                'is-invalid':
                  (fval.password.touched || fval.password.dirty) &&
                  fval.password.errors
              }"
              [type]="fieldType ? 'text' : 'password'"
              autocomplete="Enter Password"
              class="form-control shadow"
              formControlName="password"
              id="password"
              placeholder="Enter Password"
            />
            <i
              (click)="toggleFieldType()"
              [ngClass]="{
                  'fa-eye-slash': !fieldType,
                  'fa-eye': fieldType
                }"
              class="end fas"
            ></i>
          </div>
          <small
            *ngIf="
              (fval.password.touched || fval.password.dirty) &&
              fval.password.errors?.required
            "
            class="text-danger col"
          >
            Your Password is Required
          </small>
          <small
            *ngIf="
              (fval.password.touched || fval.password.dirty) &&
              fval.password.errors?.minlength
            "
            class="text-danger col"
          >
            Password should have a minimum of 8 characters
          </small>
          <small
            *ngIf="
              (fval.password.touched || fval.password.dirty) &&
              fval.password.errors?.hasCapitalCase
            "
            class="text-danger col"
          >
            Password should contain a capital letter
          </small>
          <small
            *ngIf="
              (fval.password.touched || fval.password.dirty) &&
              fval.password.errors?.hasSmallCase
            "
            class="text-danger col"
          >
            Password should contain a small letter
          </small>
          <small
            *ngIf="
              (fval.password.touched || fval.password.dirty) &&
              fval.password.errors?.hasSpecialCharacters
            "
            class="text-danger col"
          >
            Password should contain a special character
          </small>
        </div>
        <div class="form-group text-primary">
          <label [ngClass]="fval.confirmPassword.valid ? 'text-info' : ''" for="confirmPassword">
            Confirm Password
            <i *ngIf="fval.confirmPassword.valid" class="material-icons">
              check_circle
            </i>
          </label>
          <div class="input-block mb-0">
            <i
              class="start fas fa-lock text-primary mx-auto"
            ></i>
            <input
              [ngClass]="{
                  'is-invalid':
                    (fval.confirmPassword.touched ||
                      fval.confirmPassword.dirty) &&
                    fval.confirmPassword.errors
                }"
              [type]="fieldType ? 'text' : 'password'"
              autocomplete="off"
              class="form-control shadow"
              formControlName="confirmPassword"
              id="confirmPassword"
              placeholder="Confirm Password"
              required
            >
            <i
              (click)="toggleFieldType()"
              [ngClass]="{
                      'fa-eye-slash': !fieldType,
                      'fa-eye': fieldType
                    }"
              class="end fas"
            ></i>
          </div>

          <small
            *ngIf="fval.confirmPassword.errors?.NoPasswordMatch"
            class="text-danger col"
          >
            Passwords do not match
          </small>
          <small
            *ngIf="
                fval.confirmPassword.errors?.required &&
                (fval.confirmPassword.touched || fval.confirmPassword.dirty)
              "
            class="text-danger col"
          >
            Please confirm your password
          </small>
        </div>
        <div class="d-flex my-0 py-2 mx-auto">
          <button
            [disabled]="!userForm.valid || loading"
            class="btn btn-primary col-5 mx-auto shadow"
            type="submit"
          >
            Save
          </button>

          <button
            [routerLink]="LOGIN_PATH"
            class="btn btn-danger col-5 mx-auto shadow"
            type="button"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="small text-center text-info mb-5">
    Copyright &copy; {{2022}} - Brimasmedia Ltd
  </div>
</div>

