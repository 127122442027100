
            <!-- /.sidebar-menu -->
          <!-- </div> -->
          <!-- /.sidebar -->
        <!-- </aside> -->

        <!-- Content Wrapper. Contains page content -->
        <!-- <div class="content-wrapper"> -->
          <!-- Content Header (Page header) -->
          <section class="content-header">
            <div class="container-fluid">
              <div class="row mb-2 text-info">
                <div class="col-sm-6">
                  <h1>Invoice</h1>
                </div>
                <div class="col-sm-6 text-info">
                  <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Invoice</li>
                  </ol>
                </div>
              </div>
            </div><!-- /.container-fluid -->
          </section>

          <section class="content">
            <div class="container-fluid">
              <div class="row text-info">
                <div class="col-12">
                  <!-- <div class="callout callout-info">
                    <h5><i class="fas fa-info"></i> Note:</h5>
                    This page has been enhanced for printing. Click the print button at the bottom of the invoice to test.
                  </div> -->


                  <!-- Main content -->
                  <div class="invoice p-3 mb-3">
                    <!-- title row -->
                    <div class="row">
                      <div class="col-12">
                        <h4>
                                <strong  class="text-info">Brimas Media Ltd</strong>  <i class="text-info float-right"><img src='../../../../../../assets/blimassLead.jpg'></i>
                          <!-- <small class="float-right">Invoice Date: 28/11/2019</small> -->
                        </h4>
                      </div>
                      <!-- /.col -->
                    </div>
                    <!-- info row -->
                    <div class="row invoice-info">
                      <div class="col-sm-4 invoice-col">
                        <address>

                          P.o Box 30399 Kampala Uganda<br>
                          Conrad Plaza<br>
                          Phone:+256 756 342343<br>
                          Sales Person responsible: ANITA
                        </address>
                      </div>
                      <!-- /.col -->
                      <div class="col-sm-4 invoice-col">
                        Customer:
                        <address>
                          <strong>Stat Solutions Reseach Network Ltd</strong><br>
                          P.o Box 28886 Kampala<br>
                          Mukwano Mall R34<br>
                          Contact Person:Bazirake Augustine <br>
                          Contact Number: 0782231039
                        </address>
                      </div>
                      <!-- /.col -->
                      <div class="col-sm-4 invoice-col">
                        <b>Date: 28/11/2019</b><br>

                        <b>Invoice No #:</b> 4025<br>
                        <b>Customer Id:</b> 155<br>
                        <b>Valid Until:</b> 15/12/2019
                      </div>
                      <!-- /.col -->
                    </div>
                    <!-- /.row -->

                    <!-- Table row -->
                    <div class="row">
                      <div class="col-12 table-responsive">
                        <table class="table table-striped">
                          <thead>
                          <tr>
                            <th>#</th>
                            <th>Description</th>
                            <th>Total Qnty(Units)</th>
                            <th>Unit Price(UGX)</th>
                            <th>Amount(UGX)</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr>
                            <td>1</td>
                            <td>System Development</td>
                            <td>3</td>
                            <td>800,000</td>
                            <td>2,400,000</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>Training Users and Manual</td>
                            <td>2</td>
                            <td>500,000</td>
                            <td>1,000,000</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>Buying server for cloud hosting</td>
                            <td>2</td>
                            <td>1,000,000</td>
                            <td>2,000,000</td>
                          </tr>

                          </tbody>
                        </table>
                      </div>
                      <!-- /.col -->
                    </div>
                    <!-- /.row -->

                    <div class="row">
                      <!-- accepted payments column -->
                      <div class="col-6">
                        <p class="lead">Terms And Conditions:</p>
                        <!-- <img src="../../dist/img/credit/visa.png" alt="Visa">
                        <img src="../../dist/img/credit/mastercard.png" alt="Mastercard">
                        <img src="../../dist/img/credit/american-express.png" alt="American Express">
                        <img src="../../dist/img/credit/paypal2.png" alt="Paypal">
       -->
                        <p class="text-muted well well-sm shadow-none" style="margin-top: 10px;">
                          1.This invoice will be invalid after the due date

                        </p>
                      </div>
                      <!-- /.col -->
                      <div class="col-6">
                        <!-- <p class="lead">Amount Due 02/12/2019</p> -->

                        <div class="table-responsive">
                          <table class="table">
                            <tr>
                              <th style="width:50%">Subtotal:</th>
                              <td>4,400,000</td>
                            </tr>
                            <tr>
                              <th>Tax (10%)</th>
                              <td>440,000</td>
                            </tr>
                            <!-- <tr>
                              <th>Shipping:</th>
                              <td>$5.80</td>
                            </tr> -->
                            <tr>
                              <th>Total:</th>
                              <td>4,840,000</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <!-- /.col -->
                    </div>
                    <!-- /.row -->
                    <div class="row">
                            <div class="col-12">

                                <p>
                                   <span>Bank Details: DTB Bank, Account Number:005867800;UBA BANK, Account Number:.........</span> <br/>
                                   <span>For further information please contact Anita Arigye</span> <br/>
                                   <strong>
                                        Thank you for your business!!
                                   </strong>
                                </p>


                            </div>
                    </div>
                    <!-- this row will not appear when printing -->
                    <div class="row no-print">
                      <div class="col-12">
                        <a href="invoice-print.html" target="_blank" class="btn btn-default"><i class="fas fa-print"></i> Print</a>
                        <button type="button" class="btn btn-success float-right"><i class="far fa-credit-card"></i> Submit
                          Payment
                        </button>
                        <button type="button" class="btn btn-primary float-right" style="margin-right: 5px;">
                          <i class="fas fa-download"></i> Generate PDF
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- /.invoice -->
                </div><!-- /.col -->
              </div><!-- /.row -->
            </div><!-- /.container-fluid -->
          </section>
          <!-- /.content -->
        <!-- </div> -->
