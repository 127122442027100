import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-auth",
  templateUrl: "./auth.component.html",
  styleUrls: ["./auth.component.scss"],
})
export class AuthComponent implements OnInit {
  imageUrl = "../assets/img/brimas_logo.svg";
  isCollapsed: boolean;

  toggleClass: string;

  constructor() {}

  ngOnInit() {
    this.toggleArial();
  }
  getUrl() {
    return "url('./assets/img/1.jpg')";
  }

  toggleArial() {
    this.isCollapsed = !this.isCollapsed;
    this.toggleTheClass(this.isCollapsed);
  }

  toggleTheClass(theToggler: boolean) {
    if (theToggler) this.toggleClass = "collapse navbar-collapse";
    else this.toggleClass = "navbar-collapse";
  }
}
