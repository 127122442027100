import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AdministrationService } from "../../../administration/services/administration.service";
import { showToasterMessage } from "../../utils/functions";
import { ToastrService } from "ngx-toastr";

export interface CustomerDetail {
  DateApproved: string | null;
  DateCreated: string | null;
  DateUpdateApproved: string | null;
  DateUpdated: string | null;
  TimeApproved: string | null;
  TimeCreated: string | null;
  TimeUpdateApproved: string | null;
  TimeUpdated: string | null;
  branchId: number;
  branchName: string | null;
  customerApprovedBy: string | null;
  customerCreatedBy: string | null;
  customerEmail: string | null;
  customerEmailVerify: number;
  customerEmailVerifyDecoded: string | null;
  customerId: number;
  customerName: string | null;
  customerPhone1: string | null;
  customerPhone2: string | null;
  customerSector: string | null;
  customerSectorDetails: string | null;
  customerStatus: number;
  customerStatusDecoded: string | null;
  customerType: number;
  customerTypeDecoded: string | null;
  customerUpdatedApprovalBy: string | null;
  customerUpdatedBy: string | null;
  relationshipManagerContact: string | null;
  relationshipManagerEmail: string | null;
  relationshipManagerId: number;
  relationshipManagerName: string | null;
  customerPhotoUrl?: string;
}

@Component({
  selector: "app-customer-details",
  templateUrl: "./customer-details.component.html",
  styleUrls: ["./customer-details.component.scss"],
})
export class CustomerDetailsComponent implements OnInit {
  @Input() customerDetail: CustomerDetail;
  @Output() emitEditing = new EventEmitter<any>();
  @Output() emitShippingAddress = new EventEmitter<any>();
  @Output() zoomImage = new EventEmitter<string>();
  imageUrl: string;

  constructor(
    private toaster: ToastrService,
    private administrationService: AdministrationService
  ) {}

  ngOnInit(): void {
    const customerPhotoUrl = this.customerDetail.customerPhotoUrl;
    this.imageUrl = customerPhotoUrl ? customerPhotoUrl : "assets/img/man.svg";
  }

  emitEditAction() {
    this.emitEditing.emit(this.customerDetail);
  }

  emitOpenBankingDetailsModal() {}

  emitOpenShippingAddressModal() {
    this.administrationService
      .getShippingAddress(this.customerDetail.customerId, "CUSTOMER")
      .subscribe(
        (res) => {
          this.emitShippingAddress.emit(res);
        },
        (err) => {
          showToasterMessage(this.toaster, "warning", err);
        }
      );
  }

  emitOpenContactPersonModal() {}

  emitPhotoUrl() {
    this.zoomImage.emit(this.imageUrl);
  }
}
