<div class="card mt-0 mb-3 pt-0 shadow mx-0 mx-lg-3">
  <div class="card-title mt-0">
    <h4
      class="text-info text-right text-uppercase font-weight-bold pt-1 pb-0 px-3"
    >
      chart of accounts
    </h4>
  </div>
  <div class="card-body pt-0 pb-1 mx-0 px-2">
    <div class="row">
      <!--Top Pagination-->
      <div class="col-5 pt-0 px-0 px-md-1 d-flex justify-content-start">
        <!--        <pagination-controls-->
        <!--          (pageBoundsCorrection)="pageChanged($event)"-->
        <!--          (pageChange)="pageChanged($event)"-->
        <!--          autoHide="true"-->
        <!--          directionLinks="true"-->
        <!--          maxSize="9"-->
        <!--          nextLabel="Next"-->
        <!--          previousLabel="Previous"-->
        <!--          responsive="true"-->
        <!--          screenReaderCurrentLabel="Page"-->
        <!--          screenReaderPageLabel="page"-->
        <!--          screenReaderPaginationLabel="Pagination"-->
        <!--        >-->
        <!--        </pagination-controls>-->
      </div>
      <div class="col-7">
        <div class="mt-1 mb-2 mb-md-3 mx-0 mx-md-3 pull-right">
          <input
            (input)="getValue($event)"
            autocomplete="on"
            class="form-control shadow"
            id="search"
            name=""
            placeholder="Search"
            type="text"
          />
        </div>
      </div>
    </div>
    <div class="col-12">
      <button class="btn btn-danger align-self-start shadow">Delete All</button>
    </div>
    <!-- record container-->
    <div class="invest-card mt-3">
      <div class="item-container-1 mt-1">
        <div class="list-card card mt-1 py-1 mb-0 mx-2 px-1 px-md-3 shadow">
          <div class="list-card-body card-body py-1">
            <div class="break-text col-12 mx-0 px-0">
              <div class="row">
                <div class="col-6">
                  <div class="font-weight-bold text-red">
                    <span class=""></span>
                  </div>
                  <div class="text-primary">
                    <span class="">Account Number</span>
                  </div>
                </div>
                <div class="col-6">
                  <div
                    class="d-flex justify-content-end font-weight-bold text-red"
                  >
                    <span class=""> </span>
                  </div>
                  <div class="d-flex justify-content-end text-primary">
                    <span class="">Account Name</span>
                  </div>
                </div>
              </div>
              <hr class="liner"/>
              <div class="row mx-0 px-0">
                <div class="col-5">
                  <div class="">
                    <a (click)="openModal1(moredetails)" class="text-info">
                      View Details
                    </a>
                  </div>
                </div>
                <div class="col-7">
                  <div class="d-flex justify-content-end">
                    <button class="btn btn-outline-info mx-2 shadow">
                      <i class="fas fa-edit"></i> Edit
                    </button>
                    <button class="btn btn-danger shadow">
                      <i class="fas fa-trash"></i> Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Desktop view-->

    <div class="row">
      <div class="col-12 px-2 px-md-3">
        <div class="table-col col-12 py-2">
          <div class="state-table table-responsive pb-0">
            <!--table-->
            <table class="table table-hover table-borderless table-sm">
              <thead class="border-line-1 text-dark">
              <tr class="mx-2">
                <th scope="col">#</th>
                <th scope="col">Account ID</th>
                <th scope="col">Account Name</th>
                <th scope="col">Account No.</th>
                <th scope="col">Account Status</th>
                <th scope="col">Contra Status</th>
                <th scope="col">
                  <a class="btn-secondary px-2 pb-1 font-weight-normal"
                  >More Info</a
                  >
                </th>
                <th scope="col">Action</th>
              </tr>
              </thead>
              <tbody class="text-primary">
              <tr class="border-line-2 mx-2">
                <td>1</td>
                <td>777</td>
                <td>hhh</td>
                <td>uuu</td>
                <td>jkk</td>
                <td>ooo</td>
                <td><a class="btn-secondary px-2 pb-1">More Info</a></td>
                <td>
                  <div ngbDropdown>
                    <button
                      aria-expanded="false"
                      aria-haspopup="true"
                      class="btn btn-outline-primary"
                      ngbDropdownToggle
                      placement="left"
                      tooltip="Options"
                    >
                      <i class="fas fa-ellipsis-v text-info"></i>
                    </button>
                    <div
                      aria-labelledby="dropdownMenuButton"
                      ngbDropdownMenu="dropdownMenuButton"
                    >
                      <button
                        class="text-primo-2 pointerCursor"
                        ngbDropdownItem
                      >
                        <i class="fas fa-edit"></i>
                        Edit
                      </button>
                      <button
                        class="text-danger pointerCursor"
                        ngbDropdownItem
                      >
                        <i class="fas fa-times"></i>
                        Delete
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="border-line-2 mx-2">
                <td>2</td>
                <td>777</td>
                <td>hhh</td>
                <td>uuu</td>
                <td>jkk</td>
                <td>ooo</td>
                <td><a class="btn-secondary px-2 pb-1">More Info</a></td>
                <td>
                  <div ngbDropdown>
                    <button
                      aria-expanded="false"
                      aria-haspopup="true"
                      class="btn btn-outline-primary"
                      id="dropdownMenuButton"
                      ngbDropdownToggle
                      placement="left"
                      tooltip="Options"
                    >
                      <i class="fas fa-ellipsis-v text-info"></i>
                    </button>
                    <div
                      aria-labelledby="dropdownMenuButton"
                      ngbDropdownMenu="dropdownMenuButton"
                    >
                      <button
                        class="text-primo-2 pointerCursor"
                        ngbDropdownItem
                      >
                        <i class="fas fa-edit"></i>
                        Edit
                      </button>
                      <button
                        class="text-danger pointerCursor"
                        ngbDropdownItem
                      >
                        <i class="fas fa-times"></i>
                        Delete
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!--Bottom Pagination-->
    <div class="row">
      <div class="col-12 pt-0 px-0 px-md-1 d-flex justify-content-center">
        <!--        <pagination-controls-->
        <!--          (pageBoundsCorrection)="pageChanged($event)"-->
        <!--          (pageChange)="pageChanged($event)"-->
        <!--          autoHide="true"-->
        <!--          directionLinks="true"-->
        <!--          maxSize="9"-->
        <!--          nextLabel="Next"-->
        <!--          previousLabel="Previous"-->
        <!--          responsive="true"-->
        <!--          screenReaderCurrentLabel="Page"-->
        <!--          screenReaderPageLabel="page"-->
        <!--          screenReaderPaginationLabel="Pagination"-->
        <!--        >-->
        <!--        </pagination-controls>-->
      </div>
    </div>

    <!-- Position Column -->
    <!-- Name Column -->
    <!-- Weight Column -->
    <!-- Symbol Column -->
    <!-- <div class="mat-elevation-z0 w-100">
      <table [dataSource]="dataSource" class="w-100" mat-table>
        <ng-container matColumnDef="position">
          <th *matHeaderCellDef mat-header-cell>No.</th>
          <td *matCellDef="let element" mat-cell>{{ element.position }}</td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th *matHeaderCellDef mat-header-cell>Name</th>
          <td *matCellDef="let element" mat-cell>{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="weight">
          <th *matHeaderCellDef mat-header-cell>Weight</th>
          <td *matCellDef="let element" mat-cell>{{ element.weight }}</td>
        </ng-container>

        <ng-container matColumnDef="symbol">
          <th *matHeaderCellDef mat-header-cell>Symbol</th>
          <td *matCellDef="let element" mat-cell>{{ element.symbol }}</td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
      </table>

      <mat-paginator
        [pageSizeOptions]="[5, 10, 20]"
        aria-label="Select page of periodic elements"
        showFirstLastButtons
      >
      </mat-paginator>
    </div>
  </div> -->

    <ng-template #moredetails>
      <div class="modal-header bg-info">
        <h4 class="modal-title text-center text-white">Account Details</h4>
        <button
          (click)="modalRef.hide()"
          aria-
          class="pull-right close"
          data-dismiss="modal"
          label="Close"
          type="button"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="list-card-body card-body my-0 py-0 mx-0 px-0">
          <div class="break-text col-12">
            <div class="row">
              <div class="col-6 col-md-4 mx-0 px-0 px-md-1">
                <div class="font-weight-bold text-red">
                  <span class="font-weight-bold">sfsfsfsfsfsfs</span>
                </div>
                <div class="text-primary">
                  <span class="">Account ID</span>
                </div>
              </div>
              <div class="col-6 col-md-4 mx-0 px-0">
                <div class="d-flex justify-content-end font-weight-bold text-red">
                  <span class=""></span>
                </div>
                <div class="d-flex justify-content-end text-primary">
                  <span class="">Account Name</span>
                </div>
              </div>
              <div class="col-6 col-md-4 mx-0 px-0">
                <div
                  class="d-flex justify-content-md-end font-weight-bold text-red"
                >
                  <span class=""></span>
                </div>
                <div class="d-flex justify-content-md-end text-primary">
                  <span class="">Account Number</span>
                </div>
              </div>
              <div class="col-6 col-md-4 mx-0 px-0">
                <div class="font-weight-bold text-red">
                  <span class=""></span>
                </div>
                <div class="text-primary">
                  <span class="">Account Status</span>
                </div>
              </div>
              <div class="col-6 col-md-4 mx-0 px-0">
                <div class="d-flex justify-content-end font-weight-bold text-red">
                  <span class=""></span>
                </div>
                <div class="d-flex justify-content-end text-primary">
                  <span class="">Contra Status</span>
                </div>
              </div>
              <div class="col-6 col-md-4 mx-0 px-0">
                <div
                  class="d-flex justify-content-md-end font-weight-bold text-red"
                >
                  <span class=""></span>
                </div>
                <div class="d-flex justify-content-md-end text-primary">
                  <span class="">Branch Name</span>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-6 col-md-4 mx-0 px-0">
                <div class="font-weight-bold text-red">
                  <span class=""></span>
                </div>
                <div class="text-primary">
                  <span class="">Account Header</span>
                </div>
              </div>
              <div class="col-6 col-md-4 mx-0 px-0">
                <div class="d-flex justify-content-end font-weight-bold text-red">
                  <span class=""></span>
                </div>
                <div class="d-flex justify-content-end text-primary">
                  <span class="">Major Account Header</span>
                </div>
              </div>
              <div class="col-6 col-md-4 mx-0 px-0">
                <div
                  class="d-flex justify-content-md-end font-weight-bold text-red"
                >
                  <span class=""></span>
                </div>
                <div class="d-flex justify-content-md-end text-primary">
                  <span class="">Account Type</span>
                </div>
              </div>
              <div class="col-6 col-md-4 mx-0 px-0">
                <div class="font-weight-bold text-red">
                  <span class=""></span>
                </div>
                <div class="text-primary">
                  <span class="">Foundational Account ID</span>
                </div>
              </div>
              <div class="col-6 col-md-4 mx-0 px-0">
                <div class="d-flex justify-content-end font-weight-bold text-red">
                  <span class=""></span>
                </div>
                <div class="d-flex justify-content-end text-primary">
                  <span class="">Account Category</span>
                </div>
              </div>
              <div class="col-6 col-md-4 mx-0 px-0">
                <div
                  class="d-flex justify-content-md-end font-weight-bold text-red"
                >
                  <span class=""></span>
                </div>
                <div class="d-flex justify-content-md-end text-primary">
                  <span class="">Account Subcategory</span>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-6 col-md-4 mx-0 px-0">
                <div class="font-weight-bold text-red">
                  <span class=""></span>
                </div>
                <div class="text-primary">
                  <span class="">Currency used</span>
                </div>
              </div>
              <div class="col-6 col-md-4 mx-0 px-0">
                <div class="d-flex justify-content-end font-weight-bold text-red">
                  <span class=""></span>
                </div>
                <div class="d-flex justify-content-end text-primary">
                  <span class="">Currency used Category</span>
                </div>
              </div>
              <div class="col-6 col-md-4 mx-0 px-0">
                <div
                  class="d-flex justify-content-md-end font-weight-bold text-red"
                >
                  <span class=""></span>
                </div>
                <div class="d-flex justify-content-md-end text-primary">
                  <span class="">Currency Name</span>
                </div>
              </div>
              <div class="col-6 col-md-4 mx-0 px-0">
                <div class="font-weight-bold text-red">
                  <span class=""></span>
                </div>
                <div class="text-primary">
                  <span class="">Country of Origin</span>
                </div>
              </div>
              <div class="col-6 col-md-4 mx-0 px-0">
                <div class="d-flex justify-content-end font-weight-bold text-red">
                  <span class=""></span>
                </div>
                <div class="d-flex justify-content-end text-primary">
                  <span class="">Currency symbol</span>
                </div>
              </div>
              <div class="col-6 col-md-4 mx-0 px-0">
                <div
                  class="d-flex justify-content-md-end font-weight-bold text-red"
                >
                  <span class=""></span>
                </div>
                <div class="d-flex justify-content-md-end text-primary">
                  <span class="">GL Code Format</span>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-6 col-md-7 mx-0 px-0">
                <div class="font-weight-bold text-red">
                  <span class=""></span>
                </div>
                <div class="text-primary">
                  <span class="">Created By</span>
                </div>
              </div>
              <div class="col-6 col-md-5 mx-0 px-0">
                <div class="d-flex justify-content-end font-weight-bold text-red">
                  <span class=""></span>
                </div>
                <div class="d-flex justify-content-end text-primary">
                  <span class="">Date created</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
