import { Component, HostListener, Input, OnInit } from "@angular/core";
import { LayoutService } from "../services/layout.service";

export interface MenuItem {
  name: string;
  icon: string;
  url: string;
  badge?: string;
  badgeBg?: string;
  subMenu?: MenuItem[];
}

@Component({
  selector: "app-left-panel",
  templateUrl: "./left-panel.component.html",
  styleUrls: ["./left-panel.component.scss"],
})
export class LeftPanelComponent implements OnInit {
  asideBarHeight: number;
  @Input() navLayout: string;
  @Input() defaultNavbar: string;
  @Input() toggleNavbar: string;
  @Input() toggleStatus: boolean;
  @Input() navbarEffect: string;
  @Input() deviceType: string;
  @Input() headerColorTheme: string;
  @Input() navbarColorTheme: string;
  @Input() activeNavColorTheme: string;
  @Input() menuList: MenuItem[];
  imageUrl = "../../../../assets/avatar3.jpg";
  title: any;
  selected: any;
  userName: any;

  constructor(private layoutService: LayoutService) {}

  isActive(item) {
    return this.selected === item;
  }

  onItemSelect(item) {
    this.selected = this.selected === item ? item : item;
  }

  onSubItemSelect(item) {
    event.stopPropagation();
    this.selected = this.selected === item ? item : item;
  }

  @HostListener("window:resize", ["$event"])
  // onResizeHeight(event: any)
  onResizeHeight() {
    this.asideBarHeight = window.innerHeight;
  }

  ngOnInit() {
    this.layoutService.setAsideBarHeightCast.subscribe(
      (setSidebarHeight) => (this.asideBarHeight = setSidebarHeight)
    );
    this.title = "Navigation";
  }
}
