<div [attr.device-type]="setDeviceType" [attr.theme-layout]="themeLayout" class="flux-container">
  <div (click)="changeTheToggleStatus()"
       *ngIf="setVerticalNavbarEffect === 'overlay' && setDefaultNavbar === 'offcanvas' && setToggleStatus === false"
       class="overlay-box"></div>
  <div (click)="changeTheToggleStatus()"
       *ngIf="setNavLayout === 'horizontal' && setDeviceType === 'mobile' && setToggleStatus === false"
       class="overlay-box"></div>
  <div class="flux-wrapper">
    <app-header
      [activeNavColorTheme]="setActiveNavColorTheme" [collapsedLeftHeader]="setCollapsedLeftHeader" [defaultNavbar]="setDefaultNavbar"
      [deviceType]="setDeviceType"
      [headerColorTheme]="setHeaderColorTheme" [headerHeight]="setHeaderHeight" [leftHeaderColorTheme]="setLeftHeaderColorTheme"
      [moduleName]="moduleName" [navLayout]="setNavLayout"
      [navbarColorTheme]="setNavbarColorTheme" [navbarEffect]="setVerticalNavbarEffect"
      [toggleNavbar]="setToggleNavbar" [toggleStatus]="setToggleStatus"
    ></app-header>

    <app-left-panel
      [activeNavColorTheme]="setActiveNavColorTheme" [defaultNavbar]="setDefaultNavbar" [deviceType]="setDeviceType"
      [headerColorTheme]="setHeaderColorTheme"
      [menuList]="menuList" [navLayout]="setNavLayout" [navbarColorTheme]="setNavbarColorTheme"
      [navbarEffect]="setVerticalNavbarEffect" [toggleNavbar]="setToggleNavbar"
      [toggleStatus]="setToggleStatus"
    ></app-left-panel>

    <app-content-section [activeNavColorTheme]="setActiveNavColorTheme" [defaultNavbar]="setDefaultNavbar" [deviceType]="setDeviceType"
                         [headerColorTheme]="setHeaderColorTheme" [navLayout]="setNavLayout"
                         [navbarColorTheme]="setNavbarColorTheme"
                         [navbarEffect]="setVerticalNavbarEffect" [toggleNavbar]="setToggleNavbar"
                         [toggleStatus]="setToggleStatus"
    ></app-content-section>
  </div>
</div>
