import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { NotificationsComponent } from "./components/notifications/notifications.component";
import { UsersComponent } from "./components/employees/users.component";
import { BranchSetupComponent } from "./components/branch-setup/branch-setup.component";
import { ReportComponent } from "./components/report/report.component";
import { ApproveEmployeeComponent } from "./components/employees/approve-employee/approve-employee.component";
import { ViewLoggedinComponent } from "./components/employees/view-loggedin/view-loggedin.component";
import { CompanySetupComponent } from "./components/company-setup/company-setup.component";
import { ApprovalsSetupComponent } from "./components/approvals-setup/approvals-setup.component";
import { ProcessSetupComponent } from "./components/process-setup/process-setup.component";
import { EmployeeListComponent } from "./components/employees/employee-list/employee-list.component";
import { CompanyProfileResolver } from "./resolvers/company-profile.resolver";
import { ApprovalsResolver } from "./resolvers/approvals.resolver";
import { AllEmployeesResolver } from "./resolvers/all-employees.resolver";
import { LoggedInEmployeesResolver } from "./resolvers/logged-in-employees.resolver";
import { DashboardResolver } from "./resolvers/dasboard.resolver";
import { DeactivatedEmployeeComponent } from "./components/employees/deactivated-employee/deactivated-employee.component";
import { DeactivatedUsersResolver } from "./resolvers/deactivated-users.resolver";
import { ProfileComponent } from "../shared/components/profile/profile.component";
import { EditProcessComponent } from "./components/process-setup/edit-process/edit-process.component";
import { CreateProcessComponent } from "./components/process-setup/create-process/create-process.component";
import { ViewProcessComponent } from "./components/process-setup/view-process/view-process.component";
import { AllBranchesResolver } from "./resolvers/all-branches.resolver";
import { EmployeeProfileResolver } from "../shared/resolvers/employee-profile";
import { AdminIndexComponent } from "./components/admin-index/admin-index.component";
import { EmployeesForApprovalResolver } from "./resolvers/employees-for-approval.resolver";

const routes: Routes = [
  {
    path: "",
    component: AdminIndexComponent,
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: "dashboard",
      },
      {
        path: "dashboard",
        component: DashboardComponent,
        resolve: [DashboardResolver],
      },
      {
        path: "approval-setup",
        component: ApprovalsSetupComponent,
        resolve: [ApprovalsResolver],
      },
      {
        path: "branch-setup",
        resolve: [AllBranchesResolver],
        component: BranchSetupComponent,
      },
      {
        path: "process-setup",
        component: ProcessSetupComponent,
        children: [
          {
            path: "",
            pathMatch: "full",
            redirectTo: "list",
          },
          {
            path: "create",
            component: CreateProcessComponent,
          },
          {
            path: "edit",
            component: EditProcessComponent,
          },
          {
            path: "list",
            component: ViewProcessComponent,
          },
        ],
      },
      {
        path: "employees",
        component: UsersComponent,
        children: [
          {
            path: "",
            pathMatch: "full",
            redirectTo: "all",
          },
          {
            path: "logged-in",
            component: ViewLoggedinComponent,
            resolve: [LoggedInEmployeesResolver],
          },
          {
            path: "approve",
            component: ApproveEmployeeComponent,
            resolve: [EmployeesForApprovalResolver],
          },
          {
            path: "all",
            component: EmployeeListComponent,
            resolve: [AllEmployeesResolver],
          },
          {
            path: "deactivated",
            component: DeactivatedEmployeeComponent,
            resolve: [DeactivatedUsersResolver],
          },
        ],
      },
      {
        path: "company-setup",
        component: CompanySetupComponent,
        resolve: [CompanyProfileResolver],
      },
      {
        path: "profile",
        component: ProfileComponent,
        resolve: [EmployeeProfileResolver],
      },
      {
        path: "reports",
        component: ReportComponent,
      },
      {
        path: "notifications",
        component: NotificationsComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
