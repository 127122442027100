import { Component, OnInit } from "@angular/core";
import { MenuItem } from "../../shared/layout/left-panel/left-panel.component";

@Component({
  selector: "app-management-index",
  templateUrl: "./management-index.component.html",
  styleUrls: ["./management-index.component.scss"],
})
export class ManagementIndexComponent implements OnInit {
  menuList: MenuItem[];
  moduleName: string;
  constructor() {}

  ngOnInit(): void {
    this.moduleName = "MANAGEMENT";
    this.menuList = [
      {
        name: "Dashboard",
        icon: "fas fa-tachometer-alt",
        url: "/management/dashboard",
      },
      {
        name: "Approvals",
        icon: "fas fa-clipboard-list",
        url: "/management/approvals",
      },
      {
        name: "Reports",
        icon: "fas fa-chart-line",
        url: "/management/reports",
      },
      {
        name: "Profile",
        icon: "fas fa-user-cog",
        url: "/management/profile",
      },
      {
        name: "Notifications",
        icon: "fas fa-envelope",
        url: "/management/notification",
        badge: "8",
        badgeBg: "bg-warning",
      },
    ];
  }
}
