import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-procurement-dashboard",
  templateUrl: "./procurement-dashboard.component.html",
  styleUrls: ["./procurement-dashboard.component.sass"],
})
export class ProcurementDashboardComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
