import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CreateCompany, ItemsApproval } from "../../shared/models/admin";
import { API_URLS, COMPANY_TOKEN } from "../../shared/utils/constants";
import { CreateBranch } from "src/app/modules/admin/components/branch-setup/branch-setup.component";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AdminService {
  public company = new BehaviorSubject(
    JSON.parse(localStorage.getItem(COMPANY_TOKEN))
  );

  constructor(private http: HttpClient) {}

  createCompany(postData: CreateCompany): any {
    return this.http.post(API_URLS.ADMIN.SETUP_COMPANY, postData);
  }

  getCompany(): any {
    return this.http.get(API_URLS.ADMIN.GET_COMPANY);
  }

  createEditBranch(postData: CreateBranch, method, endPoint): any {
    return this.http[method](API_URLS.ADMIN[endPoint], postData);
  }

  createBranch(data) {
    return this.http.get(API_URLS.ADMIN.GET_COMPANY);
  }

  getAllBranches(): any {
    return this.http.get(API_URLS.ADMIN.GET_ALL_BRANCHES);
  }

  getCreateApprovals(): any {
    return this.http.get(API_URLS.ADMIN.GET_CREATE_APPROVED);
  }

  getUpdateApprovals(): any {
    return this.http.get(API_URLS.ADMIN.GET_UPDATE_APPROVED);
  }

  postApprovalStatus(method: string, postData: ItemsApproval) {
    if (method === "createApproval")
      return this.http.put(API_URLS.ADMIN.SET_CREATE_APPROVED, postData);
    return this.http.put(API_URLS.ADMIN.SET_UPDATE_APPROVED, postData);
  }

  getAllEmployees(): any {
    return this.http.get(API_URLS.USERS.EMPLOYEE.GET_ALL_ACTIVE);
  }

  getLoggedInEmployees(): any {
    return this.http.get(API_URLS.USERS.EMPLOYEE.GET_LOGGED_IN);
  }

  getEmployeesForApproval(): any {
    return this.http.get(API_URLS.USERS.EMPLOYEE.GET_FOR_APPROVAL);
  }

  approveEmployee(postData: {
    employeeId: number;
    roleId: number;
    employeeIdApprover: number;
  }): any {
    return this.http.put(API_URLS.USERS.EMPLOYEE.APPROVE, postData);
  }

  rejectEmployee(postData: { employeeId: number }): any {
    return this.http.put(API_URLS.USERS.EMPLOYEE.REJECT, postData);
  }

  getDeactivatedEmployees(): any {
    return this.http.get(API_URLS.USERS.EMPLOYEE.GET_DEACTIVATED);
  }

  activateEmployee(employeeId: number): any {
    return this.http.put(
      API_URLS.USERS.EMPLOYEE.ACTIVATE + `?employeeId=${employeeId}`,
      {}
    );
  }

  deactivateEmployee(employeeId: number): any {
    return this.http.put(
      API_URLS.USERS.EMPLOYEE.DEACTIVATE + `?employeeId=${employeeId}`,
      {}
    );
  }

  logoutEmployee(employeeId: number): any {
    return this.http.put(
      API_URLS.USERS.EMPLOYEE.LOGOUT + `?employeeId=${employeeId}`,
      {}
    );
  }
}
