<div class="container col-xl-12 mx-0 px-0 px-md-2 px-xl-5 h-100">
  <div *ngIf="!companyCreated" class="row my-5 py-0 py-md-5">
    <div class="col-12 my-5 py-5 d-flex flex-column justify-content-center">
      <h4 class="text-primary text-center font-weight-bold py-1">
        You have no company set up!
      </h4>
      <h4 class="text-primary text-center font-weight-bold py-1">
        Please set up a company to explore more
      </h4>
      <button
        class="btn btn-primary my-4 mt-lg-5 align-self-center shadow"
        routerLink="/admin/company-setup"
        type="button"
      >
        Create Company
      </button>
    </div>
  </div>
  <div *ngIf="companyCreated" class="row d-flex flex-wrap justify-content-around my-3 pb-2 pb-xl-3 mx-auto mx-md-2 w-100">
    <div class="col-12 col-lg-6 col-xl-8 mx-md-0 px-md-1 align-self-stretch mx-auto">
      <div
        class="big-card-1 bg-white shadow pt-3 pb-4 px-4 mb-md-2 align-self-stretch mx-auto"
      >
        <a routerLink="/admin/company-setup">
          <h4 class="text-info text-center text-uppercase">Company details</h4>
        </a>
        <div
          class="record-2 row d-flex align-items-center px-0 mb-0 mb-md-5 mb-lg-2 text-primary"
        >
          <div
            class="col-12 col-lg-4 d-flex justify-content-center mx-lg-0 px-lg-0 my-2"
          >
            <img
              [src]="company.companyLogoUrl || noLogo"
              alt="company-logo"
              class="rounded-circle border"
              data-holder-rendered="true"
              height="100"
              width="100"
            />
          </div>
          <div class="col-6 col-md-4 px-2">
            <div class="font-weight-bold">
              <span class="">{{ company.companyName }}</span>
            </div>
            <div class="text-primary">
              <span class="">Name</span>
            </div>
          </div>
          <div class="col-6 col-md-4 px-2">
            <div class="d-flex justify-content-end font-weight-bold">
              <span class="">{{ company.companyEmail1 }}</span>
            </div>
            <div class="d-flex justify-content-end text-primary">
              <span class="">Email</span>
            </div>
          </div>

          <div class="col-6 col-md-4 px-2">
            <div
              class="d-flex justify-content-md-end justify-content-lg-start font-weight-bold"
            >
              <span class="">{{ company.companyPhoneContact1 }}</span>
            </div>
            <div
              class="d-flex justify-content-md-end justify-content-lg-start text-primary"
            >
              <span class="">Phone contact</span>
            </div>
          </div>
          <div
            *ngIf="company.companyEmail2 !== company.companyEmail1"
            class="col-6 col-md-4 px-2"
          >
            <!--display second email only if given-->
            <div
              class="d-flex justify-content-end justify-content-md-start justify-content-lg-end font-weight-bold"
            >
              <span class="">{{ company.companyEmail2 }}</span>
            </div>
            <div
              class="d-flex justify-content-end justify-content-md-start justify-content-lg-end text-primary"
            >
              <span class="">Alternative Email</span>
            </div>
          </div>
          <div
            *ngIf="
              company.companyPhoneContact2 !== company.companyPhoneContact1
            "
            class="col-6 col-md-4 px-2"
          >
            <!--display second phone contact only if given-->
            <div
              class="d-flex justify-content-start justify-content-md-end font-weight-bold"
            >
              <span class="">{{ company.companyPhoneContact2 }}</span>
            </div>
            <div
              class="d-flex justify-content-start justify-content-md-end text-primary"
            >
              <span class="">Alternative Contact</span>
            </div>
          </div>

          <div class="col-6 col-md-4 px-2">
            <div
              class="d-flex justify-content-end justify-content-lg-start font-weight-bold"
            >
              <span class="">{{ company.companyOfficeFloor }}</span>
            </div>
            <div
              class="d-flex justify-content-end justify-content-lg-start text-primary"
            >
              <span class="">Office floor</span>
            </div>
          </div>

          <div class="col-6 col-md-4 px-2">
            <div
              class="d-flex justify-content-start justify-content-lg-end font-weight-bold"
            >
              <span class="">{{ company.companyStreetBuilding }}</span>
            </div>
            <div
              class="d-flex justify-content-start justify-content-lg-end text-primary"
            >
              <span class="">Building</span>
            </div>
          </div>

          <div class="col-6 col-md-4 px-2">
            <div class="d-flex justify-content-end font-weight-bold">
              <span class="">{{ company.companyPlotNumber }}</span>
            </div>
            <div class="d-flex justify-content-end text-primary">
              <span class="">Plot No.</span>
            </div>
          </div>

          <div class="col-6 col-md-4 px-2">
            <div
              class="d-flex justify-content-start justify-content-md-end justify-content-lg-start font-weight-bold"
            >
              <span class="">{{ company.companyStreetName }}</span>
            </div>
            <div
              class="d-flex justify-content-start justify-content-md-end justify-content-lg-start text-primary"
            >
              <span class="">Street name</span>
            </div>
          </div>

          <div class="col-6 col-md-4 px-2">
            <div
              class="d-flex justify-content-end justify-content-md-start justify-content-lg-end font-weight-bold"
            >
              <span class="">{{ company.companyBoxNumber }}</span>
            </div>
            <div
              class="d-flex justify-content-end justify-content-md-start justify-content-lg-end text-primary"
            >
              <span class="">Box No.</span>
            </div>
          </div>

          <div class="col-6 col-md-4 px-2">
            <div
              class="d-flex justify-content-start justify-content-md-end font-weight-bold"
            >
              <span class="">{{ company.companyCityLocation }}</span>
            </div>
            <div
              class="d-flex justify-content-start justify-content-md-end text-primary"
            >
              <span class="">City</span>
            </div>
          </div>

          <div class="col-6 col-md-4 px-2">
            <div
              class="d-flex justify-content-end justify-content-lg-start font-weight-bold"
            >
              <span class="">{{ company.companyRegionLocation }}</span>
            </div>
            <div
              class="d-flex justify-content-end justify-content-lg-start text-primary"
            >
              <span class="">Region</span>
            </div>
          </div>
          <div class="col-6 col-md-4 px-2">
            <div
              class="d-flex justify-content-start justify-content-xl-end font-weight-bold"
            >
              <span class="">{{ company.companyCountryLocation }}</span>
            </div>
            <div
              class="d-flex justify-content-start justify-content-xl-end text-primary"
            >
              <span class="">Country</span>
            </div>
          </div>
        </div>
      </div>
    </div>
      <div
        *ngIf="branches.length" class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-1 align-self-stretch mx-auto">
        <div
          class="big-card-2 bg-white shadow my-2 mt-xl-0 py-3 px-2 align-self-stretch mx-auto"
        >
          <a routerLink="/admin/branch-setup">
            <h4 class="text-info text-center text-uppercase mb-1">
              Company Projects
            </h4>
          </a>
          <div class="records mt-0 pt-1 mx-0 px-0">
            <a routerLink="/admin/branch-setup">
              <div
                *ngFor="let branch of branches; let i = index"
                class="card my-2 p-2 shadow text-primary"
              >
                <div
                  class="record-1 d-flex flex-row justify-content-between pl-2 pr-1"
                >
                  <div class="d-flex flex-column justify-content-center">
                    <div class="font-weight-bold">
                      <span class="">{{ branch.branchName }}</span>
                    </div>
                    <div class="">
                      <span class="">Project Name</span>
                    </div>
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <div class="d-flex justify-content-end font-weight-bold">
                      <span class="">{{
                        branch.branchType === 1100 ? "MAIN" : "OTHERS"
                      }}</span>
                    </div>
                    <div class="d-flex justify-content-end">
                      <span class="">Project Type</span>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="d-flex justify-content-start px-3">
            <a class="text-info" routerLink="/admin/branch-setup">
              <i class="fas fa-chevron-right"></i> More projects
            </a>
          </div>
        </div>
      </div>
      <div
        *ngIf="approvals.length"
        class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-1"
      >
        <div
          class="big-card-2 bg-white shadow my-2 py-3 pb-xl-4 px-2 align-self-stretch mx-auto"
        >
          <a routerLink="/admin/approval-setup">
            <h4 class="text-info text-center text-uppercase mb-1">
              Approval Items
            </h4>
          </a>
          <div class="records mt-0 pt-1 mx-0 px-0">
            <a routerLink="/admin/approval-setup">
              <div
                *ngFor="let item of approvals; let i = index"
                class="card my-2 p-2 shadow text-primary"
              >
                <div
                  class="record-1 d-flex flex-row justify-content-between pl-2 pr-1"
                >
                  <div class="d-flex flex-column justify-content-center">
                    <div class="font-weight-bold">
                      <span class="">{{ item.itemName }}</span>
                    </div>
                    <div class="">
                      <span class="">Item Name</span>
                    </div>
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <div class="d-flex justify-content-end font-weight-bold">
                      <span class="">{{
                        item.approvalStatus === 0
                          ? "NO APPROVAL"
                          : "TO BE APPROVED"
                      }}</span>
                    </div>
                    <div class="d-flex justify-content-end">
                      <span class="">Status</span>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="d-flex justify-content-start px-3 pb-xl-4">
            <a class="text-info" routerLink="/admin/approval-setup">
              <i class="fas fa-chevron-right"></i> More items to approve
            </a>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl-8 mx-md-0 px-md-1">
        <div
          *ngIf="loggedIn.length"
          class="big-card-3 bg-white shadow my-2 py-3 px-2 align-self-stretch mx-auto mx-md-0 px-md-1"
        >
          <a routerLink="/admin/employees/logged-in">
            <h4 class="text-info text-center text-uppercase mb-1">
              Logged-In Employees
            </h4>
          </a>
          <div class="records mt-0 pt-1 mx-0 px-0 px-md-1">
            <a routerLink="/admin/employees/logged-in">
              <div
                *ngFor="let employee of loggedIn; let i = index"
                class="card my-2 p-2 shadow text-primary"
              >
                <div
                  class="record-1 row d-flex align-items-center px-0 mx-0 mb-1"
                >
                  <div class="col-3 col-md-2 col-xl-1 d-flex justify-content-center">
                    <img
                      [src]="employee.employeePhotoUrl || 'assets/img/man.svg'"
                      alt="employee photo"
                      class="img-circle rounded-circle mx-auto"
                      height="50"
                      width="50"
                    />
                  </div>
                  <div class="col-9 col-md-5 col-xl-3">
                    <div class="font-weight-bold">
                      <span class="">{{ employee.employeeName }}</span>
                    </div>
                    <div class="">
                      <span class="">Name</span>
                    </div>
                  </div>
                  <div class="col-12 col-md-5 col-xl-4 pt-2 pt-md-0">
                    <div class="d-flex justify-content-start justify-content-md-end font-weight-bold">
                      <span class="">{{ employee.employeeEmail }}</span>
                    </div>
                    <div class="d-flex justify-content-start justify-content-md-end">
                      <span class="">Email</span>
                    </div>
                  </div>
                  <div class="col-6 col-xl-2 pt-2 pt-xl-0">
                    <div
                      class="d-flex justify-content-start justify-content-xl-end font-weight-bold"
                    >
                      <span class="">{{ employee.loginDate }}</span>
                    </div>
                    <div
                      class="d-flex justify-content-start justify-content-xl-end"
                    >
                      <span class="">Login Date</span>
                    </div>
                  </div>
                  <div class="col-6 col-xl-2 pt-2 pt-xl-0">
                    <div class="d-flex justify-content-end font-weight-bold">
                      <span class="">{{ employee.loginTime }}</span>
                    </div>
                    <div class="d-flex justify-content-end">
                      <span class="">Login Time</span>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="d-flex justify-content-start px-3">
            <a class="text-info" routerLink="/admin/employees/logged-in">
              <i class="fas fa-chevron-right"></i> More Logged-in employees
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
