import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { NotificationsComponent } from "./components/notifications/notifications.component";
import { RfqApprovalComponent } from "./components/rfq-approval/rfq-approval.component";
import { ReviewRfqComponent } from "./components/rfq-approval/review-rfq.component";
import { ReportsComponent } from "./components/reports/reports.component";
import { ManageCustomerApprovalComponent } from "./components/approvals/manage-customer-approval/manage-customer-approval.component";
import { ApprovalsComponent } from "./components/approvals/approvals.component";
import { ManageCustomerSectorComponent } from "./components/approvals/manage-customer-sector/manage-customer-sector.component";
import { ManageSupplierApprovalComponent } from "./components/approvals/manage-supplier-approval/manage-supplier-approval.component";
import { ManagementIndexComponent } from "./management-index/management-index.component";
import { ProfileComponent } from "../shared/components/profile/profile.component";
import { EmployeeProfileResolver } from "../shared/resolvers/employee-profile";

const routes: Routes = [
  {
    path: "",
    component: ManagementIndexComponent,
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: "dashboard",
      },

      {
        path: "dashboard",
        component: DashboardComponent,
      },
      {
        path: "approvals",
        component: ApprovalsComponent,
        children: [
          {
            path: "",
            pathMatch: "full",
            redirectTo: "customer-approvals",
          },
          {
            path: "customer-sector",
            component: ManageCustomerSectorComponent,
          },
          {
            path: "customer-approvals",
            component: ManageCustomerApprovalComponent,
          },
          {
            path: "supplier-approvals",
            component: ManageSupplierApprovalComponent,
          },
        ],
      },
      {
        path: "rfq-approval",
        component: RfqApprovalComponent,
      },
      {
        path: "rfq-approval/edit-rfq",
        component: ReviewRfqComponent,
      },
      {
        path: "reports",
        component: ReportsComponent,
      },
      {
        path: "profile",
        component: ProfileComponent,
        resolve: [EmployeeProfileResolver],
      },
      {
        path: "notifications",
        component: NotificationsComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ManagementRoutingModule {}
