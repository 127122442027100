import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-auth-nav",
  templateUrl: "./auth-nav.component.html",
  styleUrls: ["./auth-nav.component.scss"],
})
export class AuthNavComponent implements OnInit {
  imageUrl = "../assets/img/brimas_logo.svg";
  isCollapsed: boolean;

  toggleClass: string;

  constructor() {}

  ngOnInit() {
    this.toggleArial();
  }
  getUrl() {
    return "url('./assets/img/1.jpg')";
  }

  toggleArial() {
    this.isCollapsed = !this.isCollapsed;
    this.toggletheClass(this.isCollapsed);
  }

  toggletheClass(theTogler: boolean) {
    if (theTogler) {
      this.toggleClass = "collapse navbar-collapse";

      // console.log(this.toggleClass);
    } else {
      this.toggleClass = "navbar-collapse";
      // console.log(this.toggleClass);
    }
  }
}
