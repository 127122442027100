import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-items-verification",
  templateUrl: "./items-verification.component.html",
  styleUrls: ["./items-verification.component.sass"],
})
export class ItemsVerificationComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
