<div class="d-flex flex-column">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="title-card shadow mt-2 mb-0 pt-2 pb-1 px-4 px-md-5">
          <div class="row d-flex justify-content-between">
            <div class="col-12 pt-0">
              <h3 class="text-info text-uppercase font-weight-bold pull-right">
                reports
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between align-self-center">
      <div class="card shadow my-2 pt-1 pb-2 d-flex flex-column px-2 px-xl-3 col-xl-12">
      <form [formGroup]="userForm">
        <div class="d-flex my-0 py-1 mx-auto px-auto">
          <div class="col-12 mx-0 px-0 px-sm-2 px-xl-3">
            <div class="row">
              <div class="col-12 col-sm-6 col-lg-3">
                <!--select the kind of report depending on the filter set -->
                <label for="position">Report Type</label>
                <div class="input-block mb-0">
                      <i class="start fas fa-file text-primary" style="size: 50px">
                      </i>
                  <select
                    formControlName="report_type"
                    class="form-control shadow"
                    autocomplete="off"
                    placeholder="Specify Report Type"
                    (change)="getReport(userForm.controls.report_type.value)"
                  >
                    <option selected>Specify Type</option>
                    <option
                      *ngFor="let reports of reportTypes"
                      [ngValue]="reports.reportType"
                    >
                      {{ reports.reportType }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-lg-3">
                <!--select the kind of report depending on the filter set -->
                <label for="position">Branch</label>
                <div class="input-block mb-0">
                  <select
                    formControlName="report_type"
                    class="form-control shadow"
                    autocomplete="off"
                    placeholder="Specify Report Type"
                    (change)="getReport(userForm.controls.report_type.value)"
                  >
                    <option selected>Specify Branch</option>
                    <option
                      *ngFor="let reports of reportTypes"
                      [ngValue]="reports.reportType"
                    >
                      {{ reports.reportType }}
                    </option>
                  </select>
                </div>
              </div>
                            <div class="col-7 col-sm-6 col-lg-3">
                <!--rangedate-->
                <label for="range_date">Select Date </label>

                <div class="input-block mb-0">
                      <i
                        class="start fas fa-calendar-alt text-primary mx-auto"
                        style="size: 50px"
                      >
                      </i>
                  <input
                    id="range_date"
                    formControlName="range_date"
                    type="text"
                    class="form-control shadow"
                    placeholder="Select date range"
                    autocomplete="off"
                    bsDaterangepicker
                    [bsConfig]="{
                      containerClass: 'theme-blue',
                      dateInputFormat: 'MM/DD/YYYY',
                      isAnimated: true
                    }"
                    placement="bottom"
                  />
                </div>
              </div>
              <div class="col-2 col-sm-3 col-lg-1 mx-0 pt-1 px-0">
                <button
                  class="btn btn-primary mt-4 mx-0 py-1 py-sm-2 px-1 px-sm-2 shadow"
                  type="submit"
                  (click)="fetchReport(userForm.controls.range_date.value)"
                  [disabled]="!userForm.valid"
                >
                  <i class="fas fa-search"></i> Search
                </button>
              </div>
              <div class="col-2 col-sm-3 col-md-2 mx-0 px-0 pt-0 d-flex justify-content-end">
                <div ngbDropdown class="d-inline-block mt-4 mx-0 px-0 px-sm-3 px-lg-2">
                  <button
                  class="btn btn-outline-info mt-1 mb-2 ml-2 mr-0 mx-lg-2 px-1 px-sm-2 shadow"
                  tooltip="Export to Excel"
                  (click)="exportToExcel()"
                >
                  <i class="fas fa-file-export"></i> Export
                </button>
                  <div
                    ngbDropdownMenu
                    aria-labelledby="dropdownBasic1"
                    class="dropdown-menu"
                  >
                    <button ngbDropdownItem>Excel</button>
                    <button ngbDropdownItem>Pdf</button>
                    <button ngbDropdownItem>CSV</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
</div>
    </div>
  </div>
</div>

<!--Reports-->
<div class="container">

  <!--comprehensive report-->
      <div class="row">
      <div class="col-12">
        <div
          class="title-card shadow mt-2 mb-0 pt-2 pb-1 px-4 px-md-5"
        >
          <div class="row d-flex justify-content-between">
            <div class="col-12 pt-0">
              <h4 class="text-info text-uppercase font-weight-bold pull-right">
                {{type}}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>

  <div class="col-12 py-2 mb-0 mx-0 px-0">
        <!--record container-->
            <!-- <div
              class="invest-card card mt-1 py-2 mb-2 mx-auto px-2 shadow"
              *ngIf="loading && !filteredLoans.length"
            >
                <div class="col-12 my-5 py-5 justify-content-between">
                  <h4 class="text-center text-info">There is no {{type}} yet
                  </h4>
                </div>
              </div> -->
            <div
              class="invest-card card mt-1 py-2 mb-2 mx-auto px-2 shadow"
              *ngIf="!filteredLoans.length && !loading"
            >
                <div class="col-12 my-5 py-5 justify-content-between">
                  <h4 class="text-center text-info">No Records Found</h4>
                </div>
              </div>
            <div
              class="invest-card card mt-1 py-2 mb-2 mx-auto px-2 shadow"
              *ngIf="filteredLoans.length"
            >
          <div
            class="iteration"
          >
            <div class="item-container-1 mt-1">
              <div
                class="list-card card mt-1 pt-2 mb-0 mx-auto px-0 pb-1 shadow"
                *ngFor="
                  let loans of filteredLoans;
                  let i = index
                "
                >
                <div class="list-card-body card-body my-0 py-0 mx-0 px-md-0">
                  <div class="row my-0 py-0 mx-md-0 px-md-0">
                    <div class="col-12">
                      <div class="row mx-md-0 px-md-0">
                        <div class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0">
                          <div class="row">
                            <div
                              class="col-5 col-md-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                            >
                              <span class="">ID No.:</span>
                            </div>
                            <div
                              class="col-7 col-md-8 col-lg-9 d-flex justify-content-start font-weight-bold"
                            >
                              <span class="itemCreated">
                                {{ loans.customerIdNumber }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0">
                          <div class="row">
                            <div
                              class="col-5 col-md-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                            >
                              <span class="">ID Type:</span>
                            </div>
                            <div
                              class="col-7 col-md-8 col-lg-9 d-flex justify-content-start font-weight-bold"
                            >
                              <span class="itemCreated">
                                {{ loans.customerIdType }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0">
                          <div class="row">
                            <div
                              class="col-5 col-md-4 col-lg-2 d-flex justify-content-start mx-md-0 px-md-0"
                            >
                              <span class="">Name:</span>
                            </div>
                            <div
                              class="col-7 col-md-8 col-lg-10 d-flex justify-content-start font-weight-bold mx-md-0 px-md-0"
                            >
                              <span class="itemCreated">
                                {{ loans.customerName }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0">
                          <div class="row">
                            <div
                              class="col-5 col-md-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                            >
                              <span class="">Phone No.:</span>
                            </div>
                            <div
                              class="col-7 col-md-8 col-lg-9 d-flex justify-content-start font-weight-bold"
                            >
                              <span class="itemCreated">
                                {{ loans.customerPhone1 }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0">
                          <div class="row">
                            <div
                              class="col-5 col-md-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                            >
                              <span class="">Type:</span>
                            </div>
                            <div
                              class="col-7 col-md-8 col-lg-9 d-flex justify-content-start font-weight-bold"
                            >
                              <span class="itemCreated">
                                {{ loans.loanThresholdType }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0">
                          <div class="row">
                            <div
                              class="col-5 col-md-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                            >
                              <span class="">Product:</span>
                            </div>
                            <div
                              class="col-7 col-md-8 col-lg-9 d-flex justify-content-start font-weight-bold mx-md-0 px-md-0"
                            >
                              <span class="itemCreated">
                                {{ loans.loanThresholdProduct }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0">
                          <div class="row">
                            <div
                              class="col-5 col-md-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                            >
                              <span class="">Tenure:</span>
                            </div>
                            <div
                              class="col-7 col-md-8 col-lg-9 d-flex justify-content-start font-weight-bold"
                            >
                              <span class="itemCreated">
                                {{ loans.loanTenure }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0">
                          <div class="row">
                            <div
                              class="col-5 col-md-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                            >
                              <span class="">Amount:</span>
                            </div>
                            <div
                              class="col-7 col-md-8 col-lg-9 d-flex justify-content-start font-weight-bold"
                            >
                              <span class="itemCreated">
                                {{ loans.loanAmount | number }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0">
                          <div class="row">
                            <div
                              class="col-5 col-md-4 d-flex justify-content-start mx-md-0 px-md-0"
                            >
                              <span class="">OriginatingBranch:</span>
                            </div>
                            <div
                              class="col-7 col-md-8 d-flex justify-content-start font-weight-bold mx-md-0 px-md-0"
                            >
                              <span class="itemCreated">
                                {{ loans.loanOriginatingBranch }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0">
                          <div class="row">
                            <div
                              class="col-5 col-md-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                            >
                              <span class="">Mov't Stage:</span>
                            </div>
                            <div
                              class="col-7 col-md-8 col-lg-9 d-flex justify-content-start font-weight-bold"
                            >
                              <span class="itemCreated">
                              {{loans.movementStage}}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                        class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0"
                      >
                        <div class="row">
                          <div
                            class="col-5 col-md-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                          >
                            <span class="">Mov't Status:</span>
                          </div>
                          <div
                            class="col-7 col-md-8 col-lg-9 d-flex justify-content-start font-weight-bold mx-md-0 px-md-0"
                          >
                            <span class="itemCreated">
                              {{ loans.movementStatus === 1 ? 'CREATED' :
                              loans.movementStatus === 2 ? 'FOWARDED' :
                              loans.movementStatus === 3 ? 'RECEIVED' :
                              loans.movementStatus === 4 ? 'DISBURSED' : 'DEFFERED' }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                          class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0"
                        >
                          <div class="row">
                            <div
                              class="col-5 col-md-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                            >
                              <span class="">Trasaction Status:</span>
                            </div>
                            <div
                              class="col-7 col-md-8 col-lg-9 d-flex justify-content-start font-weight-bold mx-md-0 px-md-0"
                            >
                              <span class="itemCreated">
                                {{ loans.transactionStatus === 0 ? 'NONE' :
                                loans.transactionStatus === 1 ? 'APPROVED' :
                                loans.transactionStatus === 2 ? 'DEFERED' :
                                loans.transactionStatus === 3 ? 'REJECETED' : 'RECTIFIED' }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0">
                          <div class="row">
                            <div
                              class="col-5 col-md-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                            >
                              <span class="">BranchMovedTo:</span>
                            </div>
                            <div
                              class="col-7 col-md-8 col-lg-9 d-flex justify-content-start font-weight-bold"
                            >
                              <span class="itemCreated">
                                {{ loans.branchMovedToNow }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0">
                          <div class="row">
                            <div
                              class="col-5 col-md-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                            >
                              <span class="">Moved by:</span>
                            </div>
                            <div
                              class="col-7 col-md-8 col-lg-9 d-flex justify-content-start font-weight-bold"
                            >
                              <span class="itemCreated">
                                {{ !loans.movedBy ? "NONE" : loans.movedBy }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0">
                          <div class="row">
                            <div
                              class="col-5 col-md-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                            >
                              <span class="">Mov't Date:</span>
                            </div>
                            <div
                              class="col-7 col-md-8 col-lg-9 d-flex justify-content-start font-weight-bold"
                            >
                              <span class="itemCreated">
                                {{ loans.movementDate }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0">
                          <div class="row">
                            <div
                              class="col-5 col-md-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                            >
                              <span class="">Mov't Time:</span>
                            </div>
                            <div
                              class="col-7 col-md-8 col-lg-9 d-flex justify-content-start font-weight-bold"
                            >
                              <span class="itemCreated">
                                {{ loans.movementTime }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0">
                          <div class="row">
                            <div
                              class="col-5 col-md-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                            >
                              <span class="">AgeSinceMov't:</span>
                            </div>
                            <div
                              class="col-7 col-md-8 col-lg-9 d-flex justify-content-start font-weight-bold"
                            >
                              <span class="itemCreated">
                                {{ loans.ageSinceMovement }} HOURS
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0">
                          <div class="row">
                            <div
                              class="col-5 col-md-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                            >
                              <span class="">CreationDate:</span>
                            </div>
                            <div
                              class="col-7 col-md-8 col-lg-9 d-flex justify-content-start font-weight-bold"
                            >
                              <span class="itemCreated">
                                {{ loans.loanCreationDate }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0">
                          <div class="row">
                            <div
                              class="col-5 col-md-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                            >
                              <span class="">CreationTime:</span>
                            </div>
                            <div
                              class="col-7 col-md-8 col-lg-9 d-flex justify-content-start font-weight-bold"
                            >
                              <span class="itemCreated">
                                {{ loans.loanCreationTime }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-xl-4 mx-md-0 px-md-0">
                          <div class="row">
                            <div
                              class="col-5 col-md-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                            >
                              <span class="">Total Age:</span>
                            </div>
                            <div
                              class="col-7 col-md-8 col-lg-9 d-flex justify-content-start font-weight-bold"
                            >
                              <span class="itemCreated">
                                {{ loans.ageSinceCreation }} DAYS
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
           <!-- <pagination-controls
          id="my_pages"
          (pageChange)="pageChanged($event)"
          (pageBoundsCorrection)="pageChanged($event)"
          directionLinks="true"
        >
        </pagination-controls> -->
        </div>
        <!--/ end of record container-->
  </div>
</div>
