import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-front-desk-notifications",
  templateUrl: "./front-desk-notifications.component.html",
  styleUrls: ["./front-desk-notifications.component.sass"],
})
export class FrontDeskNotificationsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
