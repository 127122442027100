<div class="d-flex flex-column">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="title-card shadow mt-2 mb-0 pt-2 pb-0 px-4 px-md-5 mx-lg-3">
          <div class="row d-flex justify-content-between">
            <div class="col-12 pt-0">
              <h3 class="text-info text-uppercase font-weight-bold pull-right">
                accounts
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="col-12 col-md-7 col-lg-8 mx-0 mx-lg-3 pt-0 px-4 px-md-3 d-flex justify-content-start"
      >
        <a class="text-info mb-1" routerLink="list">
          <button class="btn btn-primary my-2 ml-1 mx-sm-1 shadow">Back</button>
        </a>
        <a
          class="text-info mb-1"
          routerLink="c_o_accounts"
          routerLinkActive="active"
        >
          <button class="btn btn-outline-info my-2 ml-1 mx-sm-1 shadow">
            Chart of Accounts
          </button>
        </a>
        <a
          class="text-info mb-1"
          routerLink="postings"
          routerLinkActive="active"
        >
          <button class="btn btn-outline-info my-2 ml-1 mx-sm-1 shadow">
            Postings
          </button>
        </a>
        <a
          class="text-info mb-1"
          routerLink="statements"
          routerLinkActive="active"
        >
          <button class="btn btn-outline-info my-2 ml-1 mx-sm-1 shadow">
            Statements
          </button>
        </a>
      </div>
    </div>
    <router-outlet> </router-outlet>
  </div>
</div>

<!-- <ng-container>
  <mat-card class="d-flex flex-column-reverse flex-md-row justify-content-md-around h3 mx-2">
    <mat-nav-list>
      <button mat-button>
        <a matLine routerLink="c_o_accounts" routerLinkActive="active">Chart of accounts</a>
      </button>
      <button mat-button>
        <a matLine routerLink="postings" routerLinkActive="active">Posting</a>
      </button>
      <button mat-button>
        <a matLine routerLink="statements" routerLinkActive="active">Statements</a>
      </button>
    </mat-nav-list>
    <h2 class="font-weight-bolder" routerLinkActive="active">Accounts</h2>
  </mat-card>
  <mat-card class="m-2">
    <router-outlet></router-outlet>
  </mat-card>
</ng-container> -->
