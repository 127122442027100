<!--suppress ALL -->
<div class="card mt-0 mb-3 pt-0 shadow mx-0 mx-md-1 mx-lg-3">
  <div class="card-title">
    <div class="d-flex flex-row my-4">
      <h4 class="text-info text-right text-uppercase font-weight-bold pt-1 pb-0 px-3">
        Shipping Address
      </h4>
      <button
        (click)="closeEditor.emit()"
        class="btn btn-primary px-4 px-xl-5 mx-auto shadow ml-auto"
        type="submit"
      >
        Close
      </button>
    </div>
  </div>
  <div class="card-body d-flex flex-column justify-content-center pt-1 mx-2 px-1 px-sm-2">
    <div class="d-flex flex-row justify-content-center px-xl-0">
      <div *ngIf="!address.length || isEditing" class="form-card mt-1 mb-3 py-3 px-3 mx-md-5 mx-xl-0 shadow">
        <form (ngSubmit)="createUpdateShippingAddress()" [formGroup]="userForm" class="container">
          <div class="row row-cols-2">
            <div class="form-group text-primary">
              <label [for]="BOX_NUMBER" [ngClass]="userForm.controls[BOX_NUMBER].valid ? 'text-info' : ''">
                BOX NUMBER
                <i *ngIf="userForm.controls[BOX_NUMBER].valid" class="material-icons">
                  check_circle
                </i>
              </label>
              <div class="input-block mb-0">
                <input
                  [formControlName]="BOX_NUMBER"
                  [id]="BOX_NUMBER"
                  [ngClass]="{
                          'is-invalid':
                            (userForm.controls[BOX_NUMBER].touched || userForm.controls[BOX_NUMBER].dirty) &&
                            userForm.controls[BOX_NUMBER].errors
                        }"
                  autocomplete="off"
                  class="form-control px-2 shadow"
                  placeholder="Enter  Box Number"
                  required
                  type="text"
                />
              </div>
              <small
                *ngIf="
                        (userForm.controls[BOX_NUMBER].touched || userForm.controls[BOX_NUMBER].dirty) &&
                        userForm.controls[BOX_NUMBER].errors?.required
                      "
                class="text-danger col"
              >
                The box number is required
              </small>
            </div>
            <div class="form-group text-primary">
              <label [for]="CITY_LOCATION" [ngClass]="userForm.controls[CITY_LOCATION].valid ? 'text-info' : ''">
                CITY LOCATION
                <i *ngIf="userForm.controls[CITY_LOCATION].valid" class="material-icons">
                  check_circle
                </i>
              </label>
              <div class="input-block mb-0">
                <input
                  [formControlName]="CITY_LOCATION"
                  [id]="CITY_LOCATION"
                  [ngClass]="{
                          'is-invalid':
                            (userForm.controls[CITY_LOCATION].touched || userForm.controls[CITY_LOCATION].dirty) &&
                            userForm.controls[CITY_LOCATION].errors
                        }"
                  autocomplete="off"
                  class="form-control px-2 shadow"
                  placeholder="Enter  city location"
                  required
                  type="text"
                />
              </div>
              <small
                *ngIf="
                        (userForm.controls[CITY_LOCATION].touched || userForm.controls[CITY_LOCATION].dirty) &&
                        userForm.controls[CITY_LOCATION].errors?.required
                      "
                class="text-danger col"
              >
                The city location is required
              </small>
            </div>
          </div>
          <div class="row row-cols-2">
            <div class="form-group text-primary">
              <label [for]="COUNTRY_LOCATION" [ngClass]="userForm.controls[COUNTRY_LOCATION].valid ? 'text-info' : ''">
                COUNTRY LOCATION
                <i *ngIf="userForm.controls[COUNTRY_LOCATION].valid" class="material-icons">
                  check_circle
                </i>
              </label>
              <div class="input-block mb-0">
                <input
                  [formControlName]="COUNTRY_LOCATION"
                  [id]="COUNTRY_LOCATION"
                  [ngClass]="{
                          'is-invalid':
                            (userForm.controls[COUNTRY_LOCATION].touched || userForm.controls[COUNTRY_LOCATION].dirty) &&
                            userForm.controls[COUNTRY_LOCATION].errors
                        }"
                  autocomplete="off"
                  class="form-control px-2 shadow"
                  placeholder="Enter  country location"
                  required
                  type="text"
                />
              </div>
              <small
                *ngIf="
                        (userForm.controls[COUNTRY_LOCATION].touched || userForm.controls[COUNTRY_LOCATION].dirty) &&
                        userForm.controls[COUNTRY_LOCATION].errors?.required
                      "
                class="text-danger col"
              >
                The country location is required
              </small>
            </div>
            <div class="form-group text-primary">
              <label [for]="REGION_LOCATION" [ngClass]="userForm.controls[REGION_LOCATION].valid ? 'text-info' : ''">
                REGION LOCATION
                <i *ngIf="userForm.controls[REGION_LOCATION].valid" class="material-icons">
                  check_circle
                </i>
              </label>
              <div class="input-block mb-0">
                <input
                  [formControlName]="REGION_LOCATION"
                  [id]="REGION_LOCATION"
                  [ngClass]="{
                          'is-invalid':
                            (userForm.controls[REGION_LOCATION].touched || userForm.controls[REGION_LOCATION].dirty) &&
                            userForm.controls[REGION_LOCATION].errors
                        }"
                  autocomplete="off"
                  class="form-control px-2 shadow"
                  placeholder="Enter  region location"
                  required
                  type="text"
                />
              </div>
              <small
                *ngIf="
                        (userForm.controls[REGION_LOCATION].touched || userForm.controls[REGION_LOCATION].dirty) &&
                        userForm.controls[REGION_LOCATION].errors?.required
                      "
                class="text-danger col"
              >
                The region location is required
              </small>
            </div>
          </div>
          <div class="row row-cols-2">
            <div class="form-group text-primary">
              <label [for]="OFFICE_FLOOR" [ngClass]="userForm.controls[OFFICE_FLOOR].valid ? 'text-info' : ''">
                OFFICE FLOOR
                <i *ngIf="userForm.controls[OFFICE_FLOOR].valid" class="material-icons">
                  check_circle
                </i>
              </label>
              <div class="input-block mb-0">
                <input
                  [formControlName]="OFFICE_FLOOR"
                  [id]="OFFICE_FLOOR"
                  [ngClass]="{
                          'is-invalid':
                            (userForm.controls[OFFICE_FLOOR].touched || userForm.controls[OFFICE_FLOOR].dirty) &&
                            userForm.controls[OFFICE_FLOOR].errors
                        }"
                  autocomplete="off"
                  class="form-control px-2 shadow"
                  placeholder="Enter  office floor"
                  required
                  type="text"
                />
              </div>
              <small
                *ngIf="
                        (userForm.controls[OFFICE_FLOOR].touched || userForm.controls[OFFICE_FLOOR].dirty) &&
                        userForm.controls[OFFICE_FLOOR].errors?.required
                      "
                class="text-danger col"
              >
                The office floor is required
              </small>
            </div>
            <div class="form-group text-primary">
              <label [for]="PLOT_NUMBER" [ngClass]="userForm.controls[PLOT_NUMBER].valid ? 'text-info' : ''">
                PLOT NUMBER
                <i *ngIf="userForm.controls[PLOT_NUMBER].valid" class="material-icons">
                  check_circle
                </i>
              </label>
              <div class="input-block mb-0">
                <input
                  [formControlName]="PLOT_NUMBER"
                  [id]="PLOT_NUMBER"
                  [ngClass]="{
                          'is-invalid':
                            (userForm.controls[PLOT_NUMBER].touched || userForm.controls[PLOT_NUMBER].dirty) &&
                            userForm.controls[PLOT_NUMBER].errors
                        }"
                  autocomplete="off"
                  class="form-control px-2 shadow"
                  placeholder="Enter  Plot Number"
                  required
                  type="text"
                />
              </div>
              <small
                *ngIf="
                        (userForm.controls[PLOT_NUMBER].touched || userForm.controls[PLOT_NUMBER].dirty) &&
                        userForm.controls[PLOT_NUMBER].errors?.required
                      "
                class="text-danger col"
              >
                The Plot number is required
              </small>
            </div>
          </div>
          <div class="row row-cols-2">
            <div class="form-group text-primary">
              <label [for]="STREET_NAME" [ngClass]="userForm.controls[STREET_NAME].valid ? 'text-info' : ''">
                STREET NAME
                <i *ngIf="userForm.controls[STREET_NAME].valid" class="material-icons">
                  check_circle
                </i>
              </label>
              <div class="input-block mb-0">
                <input
                  [formControlName]="STREET_NAME"
                  [id]="STREET_NAME"
                  [ngClass]="{
                          'is-invalid':
                            (userForm.controls[STREET_NAME].touched || userForm.controls[STREET_NAME].dirty) &&
                            userForm.controls[STREET_NAME].errors
                        }"
                  autocomplete="off"
                  class="form-control px-2 shadow"
                  placeholder="Enter  Street Name"
                  required
                  type="text"
                />
              </div>
              <small
                *ngIf="
                        (userForm.controls[STREET_NAME].touched || userForm.controls[STREET_NAME].dirty) &&
                        userForm.controls[STREET_NAME].errors?.required
                      "
                class="text-danger col"
              >
                The Street Name is required
              </small>
            </div>
            <div class="form-group text-primary">
              <label [for]="STREET_BUILDING" [ngClass]="userForm.controls[STREET_BUILDING].valid ? 'text-info' : ''">
                STREET BUILDING
                <i *ngIf="userForm.controls[STREET_BUILDING].valid" class="material-icons">
                  check_circle
                </i>
              </label>
              <div class="input-block mb-0">
                <input
                  [formControlName]="STREET_BUILDING"
                  [id]="STREET_BUILDING"
                  [ngClass]="{
                          'is-invalid':
                            (userForm.controls[STREET_BUILDING].touched || userForm.controls[STREET_BUILDING].dirty) &&
                            userForm.controls[STREET_BUILDING].errors
                        }"
                  autocomplete="off"
                  class="form-control px-2 shadow"
                  placeholder="Enter  Street Building"
                  required
                  type="text"
                />
              </div>
              <small
                *ngIf="
                        (userForm.controls[STREET_BUILDING].touched || userForm.controls[STREET_BUILDING].dirty) &&
                        userForm.controls[STREET_BUILDING].errors?.required
                      "
                class="text-danger col"
              >
                The Street Building is required
              </small>
            </div>
          </div>
          <div class="row row-cols-2">
            <div class="form-group text-primary">
              <label [for]="EMAIL" [ngClass]="userForm.controls[EMAIL].valid ? 'text-info' : ''">
                EMAIL
                <i *ngIf="userForm.controls[EMAIL].valid" class="material-icons">check_circle</i>
              </label>
              <div class="input-block mb-0">
                <input
                  [formControlName]="EMAIL"
                  [id]="EMAIL"
                  [ngClass]="{ 'is-invalid':(userForm.controls[EMAIL].touched || userForm.controls[EMAIL].dirty) && userForm.controls[EMAIL].errors}"
                  autocomplete="off"
                  class="form-control shadow"
                  placeholder="Enter Email"
                  required
                  type="text"
                >
                <i
                  class="start fas fa-envelope text-primary mx-auto"
                ></i>
              </div>
              <small
                *ngIf="
          (userForm.controls[EMAIL].touched || userForm.controls[EMAIL].dirty) &&
          userForm.controls[EMAIL].errors?.required
        "
                class="text-danger col"
              >
                Email address is required
              </small>
              <small
                *ngIf="
          (userForm.controls[EMAIL].touched || userForm.controls[EMAIL].dirty) &&
          userForm.controls[EMAIL].errors?.validEmail
        "
                class="text-danger col"
              >
                Please enter a valid email address
              </small>
            </div>
            <div class="form-group text-primary">
              <label [for]="EMAIL2">EMAIL 2 (OPTIONAL)</label>
              <div class="input-block mb-0">
                <input
                  [formControlName]="EMAIL2"
                  [id]="EMAIL2"
                  [ngClass]="{ 'is-invalid':(userForm.controls[EMAIL2].touched || userForm.controls[EMAIL2].dirty) && userForm.controls[EMAIL2].errors}"
                  autocomplete="off"
                  class="form-control shadow"
                  placeholder="Enter Email"
                  type="text"
                >
                <i
                  class="start fas fa-envelope text-primary mx-auto"
                ></i>
              </div>
              <small
                *ngIf="
          (userForm.controls[EMAIL2].touched || userForm.controls[EMAIL2].dirty) &&
          userForm.controls[EMAIL2].errors?.validEmail
        "
                class="text-danger col"
              >
                Please enter a valid email address
              </small>
            </div>
          </div>
          <div class="row row-cols-2">
            <div class="form-group text-primary">
              <label
                [for]="PHONE_CONTACT"
                [ngClass]="userForm.controls[PHONE_CONTACT].valid ? 'text-info' : ''"
              >PHONE CONTACT<i
                *ngIf="userForm.controls[PHONE_CONTACT].valid"
                class="material-icons"
              >check_circle</i
              ></label
              >
              <div class="input-block mb-0">
                <input
                  [formControlName]="PHONE_CONTACT"
                  [id]="PHONE_CONTACT"
                  [ngClass]="{
                          'is-invalid':
                            (userForm.controls[PHONE_CONTACT].touched || userForm.controls[PHONE_CONTACT].dirty) &&
                            userForm.controls[PHONE_CONTACT].errors
                        }"
                  autocomplete="off"
                  class="form-control shadow"
                  placeholder="e.g.07XXXXXXXX"
                  required
                  type="text"
                />
                <i class="start fas fa-phone text-primary mx-auto"></i>
              </div>
              <small
                *ngIf="
                        (userForm.controls[PHONE_CONTACT].touched || userForm.controls[PHONE_CONTACT].dirty) &&
                        userForm.controls[PHONE_CONTACT].errors?.required
                      "
                class="text-danger col"
              >
                phone contact is required
              </small>
            </div>
            <div class="form-group text-primary">
              <label [for]="PHONE_CONTACT2">PHONE CONTACT 2 (OPTIONAL)</label>
              <div class="input-block mb-0">
                <input
                  [formControlName]="PHONE_CONTACT2"
                  [id]="PHONE_CONTACT2"
                  [ngClass]="{
                          'is-invalid':
                            (userForm.controls[PHONE_CONTACT2].touched || userForm.controls[PHONE_CONTACT2].dirty) &&
                            userForm.controls[PHONE_CONTACT2].errors
                        }"
                  autocomplete="off"
                  class="form-control shadow"
                  placeholder="e.g.07XXXXXXXX(Optional)"
                  type="text"
                />
                <i class="start fas fa-phone text-primary mx-auto"></i>
              </div>
            </div>
          </div>
          <div class="d-flex mt-1 mb-2 pt-0 pb-2 mx-5 mx-xl-4">
            <button
              [disabled]="!userForm.valid || loading"
              class="btn btn-primary px-4 px-xl-5 mx-auto shadow"
              type="submit"
            >
              {{ isEditing ? 'Save' : 'Create'}}
            </button>
            <button
              (click)="isEditing = false"
              class="btn btn-danger px-4 px-xl-5 mx-auto shadow"
              type="button"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
      <div *ngIf="address.length && !isEditing">
        <div class="d-flex flex-row justify-content-end">
          <button
            (click)="setIsEditing()"
            class="btn btn-primary px-4 px-xl-5 mx-auto shadow"
            type="submit"
          >
            Edit
          </button>
          <button
            (click)="deleteShippingAddress"
            class="btn btn-primary px-4 px-xl-5 mx-auto shadow bg-danger"
            type="submit"
          >
            Delete
          </button>
        </div>
        <div class="container mt-4">
          <div class="row row-cols-2">
            <div>BOX NUMBER: {{address[0][BOX_NUMBER]}}</div>
            <div>CITY LOCATION: {{address[0][CITY_LOCATION]}}</div>
          </div>
          <div class="row row-cols-2">
            <div>COUNTRY LOCATION: {{address[0][COUNTRY_LOCATION]}}</div>
            <div>REGION LOCATION: {{address[0][REGION_LOCATION]}}</div>
          </div>
          <div class="row row-cols-2">
            <div>OFFICE FLOOR: {{address[0][OFFICE_FLOOR]}}</div>
            <div>PLOT NUMBER: {{address[0][PLOT_NUMBER]}}</div>
          </div>
          <div class="row row-cols-2">
            <div>STREET BUILDING: {{address[0][STREET_BUILDING]}}</div>
            <div>STREET NAME: {{address[0][STREET_NAME]}}</div>
          </div>
          <div class="row row-cols-2">
            <div>EMAIL: {{address[0][EMAIL]}}</div>
            <div>EMAIL 2: {{address[0][EMAIL2]}}</div>
          </div>
          <div class="row row-cols-2">
            <div>PHONE CONTACT: {{address[0][PHONE_CONTACT]}}</div>
            <div>PHONE CONTACT 2: {{address[0][PHONE_CONTACT2]}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

