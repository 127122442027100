<header class="flux-header clearfix" [attr.nav-layout]="navLayout"
  [attr.navbar-type]="toggleStatus ? defaultNavbar : toggleNavbar " [attr.effect-type]="navbarEffect"
  [attr.header-theme]="headerColorTheme" [attr.collapsed-left-header]="collapsedLeftHeader"
  [attr.device-type]="deviceType">

  <div class="flux-header-wrapper clearfix">
    <div class="flux-brand clearfix" [attr.left-header-theme]="leftHeaderColorTheme">
      <span class="flux-brand-logo"><img src="../../../../../assets/img/brimas_logo.svg" height="60" width="50"></span>
      <span class="flux-brand-text">BRIMASLEAD</span>
    </div>
    <div class="flux-header-navbar px-1">
      <div class="flux-left-header clearfix">
        <div class="sidebarToggle clearfix" (click)="changeTheToggleStatus()"><i class="fas fa-bars"></i></div>
      </div>
    <span class="dashHead text-info flux-left-header">FRONT DESK</span>
      <div class="flux-right-header mt-2 mx-0 p-0">
        <ul class="align-items-center mt-0 mx-0 px-0">
          <li>
<span class="d-none d-lg-inline mr-0 text-gray-600 small">{{
                userName
              }}</span
              ><img
                class="border rounded-circle img-profile"
                style="width: 30px; height: 30px"
                [src]="user"
              />
          </li>
          <li><a (click)="logoutUser()" tooltip="Logout"
            placement="bottom"><i class="fas fa-power-off"></i></a></li>
          <!-- <ngx-spinner
          class="bg-warning bg-success"
          size="medium"
          type="ball-scale-multiple"
        >
          <p class="text-light">Loading...</p>
        </ngx-spinner> -->
        </ul>
      </div>
    </div>
  </div>
</header>

