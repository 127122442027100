<app-auth-nav></app-auth-nav>
<div class="d-flex flex-column justify-content-between align-items-center" style="height: 100vh">
  <div class="mt-5"></div>
  <div class="card mt-5">
    <h2 class="d-flex flex-row justify-content-center mt-3">
      <i class="fas fa-sign-in-alt text-info mx-auto"></i>
    </h2>
    <h3 class="auth-head card-title text-info d-flex justify-content-center">
      <span>Supplier Sign Up</span>
    </h3>
    <div class="card-body py-2 mb-0">
      <form (ngSubmit)="onSubmit()" [formGroup]="userForm">
        <div class="form-group text-primary">
          <label [ngClass]="fval.supplierName.valid ? 'text-info' : ''"
                 for="supplierName"
          >Name<i *ngIf="fval.supplierName.valid" class="material-icons"
          >check_circle</i
          ></label
          >
          <div class="input-block mb-0">
            <input
              [ngClass]="{
                'is-invalid':
                  (fval.supplierName.touched || fval.supplierName.dirty) &&
                  fval.supplierName.errors
              }"
              autocomplete="off"
              class="form-control shadow" formControlName="supplierName"
              id="supplierName"
              placeholder="Enter client name"
              required type="text"
            >
            <i class="start fas fa-user text-primary mx-auto">
            </i>
          </div>
          <small
            *ngIf="
              (fval.supplierName.touched || fval.supplierName.dirty) &&
              fval.supplierName.errors?.required
            "
            class="text-danger col"
          >
            Your name is required
          </small>
        </div>
        <div class="form-group text-primary">
          <label [ngClass]="fval.supplierPhone1.valid ? 'text-info' : ''"
                 for="supplierPhone1"
          >Phone number<i
            *ngIf="fval.supplierPhone1.valid"
            class="material-icons"
          >check_circle</i
          ></label
          >
          <div class="input-block mb-0">
            <input
              [ngClass]="{
                'is-invalid':
                  (fval.supplierPhone1.touched ||
                    fval.supplierPhone1.dirty) &&
                  fval.supplierPhone1.errors
              }"
              autocomplete="off"
              class="form-control shadow"
              formControlName="supplierPhone1"
              id="supplierPhone1"
              placeholder="Enter phone number"
              required type="text"
            >
            <i
              class="start fas fa-phone text-primary mx-auto"
            ></i>
            <small
              *ngIf="
                (fval.supplierPhone1.touched ||
                fval.supplierPhone1.dirty) &&
                fval.supplierPhone1.errors?.required
                "
              class="text-danger col"
            >
              Your phone number is required
            </small>
            <!-- <small
            class="text-secondary col"
            >
            The phone number should be equal to 10 "digits"
          </small> -->
          </div>
        </div>
        <div class="form-group text-primary">
          <label [ngClass]="fval.supplierEmail.valid ? 'text-info' : ''" for="supplierEmail"
          >Email<i *ngIf="fval.supplierEmail.valid" class="material-icons"
          >check_circle</i
          ></label
          >
          <div class="input-block mb-0">
            <input
              [ngClass]="{
                'is-invalid':
                  (fval.supplierEmail.touched || fval.supplierEmail.dirty) && fval.supplierEmail.errors
              }"
              autocomplete="off"
              class="form-control shadow"
              formControlName="supplierEmail"
              id="supplierEmail"
              placeholder="Enter email"
              required type="text"
            >
            <i
              class="start fas fa-envelope text-primary mx-auto"
            ></i>
          </div>
          <small
            *ngIf="
              (fval.supplierEmail.touched || fval.supplierEmail.dirty) &&
              fval.supplierEmail.errors?.required
            "
            class="text-danger col"
          >
            Your email address is required
          </small>
          <small
            *ngIf="
              (fval.supplierEmail.touched || fval.supplierEmail.dirty) &&
              fval.supplierEmail.errors?.validEmail
            "
            class="text-danger col"
          >
            Please enter a valid email address
          </small>
        </div>
        <div class="form-group text-secondary">
          <label
            [ngClass]="fval.supplierPhotoUrl.valid ? 'text-info' : ''"
            for="supplierPhotoUrl"
          >Logo (Optional)</label>
          <div class="input-block mb-0">
            <input
              (change)="onFileSelected($event)"
              [appDisableControl]="!fval.supplierEmail.valid"
              [ngClass]="{
                        'is-invalid':
                          (fval.supplierPhotoUrl.touched ||
                            fval.supplierPhotoUrl.dirty) &&
                          fval.supplierPhotoUrl.errors
                      }"
              class="form-control shadow"
              formControlName="supplierPhotoUrl"
              id="supplierPhotoUrl"
              src="image/*"
              type="file"
            >
          </div>
        </div>
        <div class="form-group text-primary">
          <label
            [ngClass]="fval.password.valid ? 'text-info' : ''"
            for="password"
          >Password<i *ngIf="fval.password.valid" class="material-icons"
          >check_circle</i
          ></label
          >

          <div class="input-block mb-0">
            <i
              class="start fas fa-lock text-primary mx-auto"
              style="size: 50px"
            ></i>
            <input
              [ngClass]="{
                'is-invalid':
                  (fval.password.touched || fval.password.dirty) &&
                  fval.password.errors
              }"
              [type]="fieldType ? 'text' : 'password'"
              autocomplete="off"
              class="form-control shadow"
              formControlName="password"
              id="password"
              placeholder="Enter Password"
            />
            <i
              (click)="toggleFieldType()"
              [ngClass]="{
                  'fa-eye-slash': !fieldType,
                  'fa-eye': fieldType
                }"
              class="end fas"
            ></i>
          </div>
          <div>
            <small
              *ngIf="
              (fval.password.touched || fval.password.dirty) &&
              fval.password.errors?.required
            "
              class="text-danger col"
            >
              Your Password is Required
            </small>
          </div>
          <div>
            <small
              *ngIf="
              (fval.password.touched || fval.password.dirty) &&
              fval.password.errors?.minlength
            "
              class="text-danger col"
            >
              Password should have a minimum of 8 characters
            </small>
          </div>
          <div>
            <small
              *ngIf="
              (fval.password.touched || fval.password.dirty) &&
              fval.password.errors?.hasCapitalCase
            "
              class="text-danger col"
            >
              Password should contain a capital letter
            </small>
          </div>
          <div>
            <small
              *ngIf="
              (fval.password.touched || fval.password.dirty) &&
              fval.password.errors?.hasSmallCase
            "
              class="text-danger col"
            >
              Password should contain a small letter
            </small>
          </div>
          <div>
            <small
              *ngIf="
              (fval.password.touched || fval.password.dirty) &&
              fval.password.errors?.hasSpecialCharacters
            "
              class="text-danger col"
            >
              Password should contain a special character
            </small>
          </div>
        </div>
        <div class="form-group text-primary">
          <label [ngClass]="fval.confirmPassword.valid ? 'text-info' : ''"
                 for="confirmPassword"
          >Confirm Password<i
            *ngIf="fval.confirmPassword.valid"
            class="material-icons"
          >check_circle</i
          ></label
          >
          <div class="input-block mb-0">
            <i
              class="start fas fa-lock text-primary mx-auto"
            ></i>
            <input
              [ngClass]="{
                'is-invalid':
                  (fval.confirmPassword.touched ||
                    fval.confirmPassword.dirty) &&
                  fval.confirmPassword.errors
              }"
              [type]="fieldType ? 'text' : 'password'"
              autocomplete="off"
              class="form-control shadow"
              formControlName="confirmPassword"
              id="confirmPassword"
              placeholder="Confirm Password" required
            >
            <i
              (click)="toggleFieldType()"
              [ngClass]="{
                    'fa-eye-slash': !fieldType,
                    'fa-eye': fieldType
                  }"
              class="end fas"
            ></i>
          </div>

          <small
            *ngIf="fval.confirmPassword.errors?.NoPasswordMatch"
            class="text-danger col"
          >
            Passwords do not match
          </small>
          <small
            *ngIf="
              fval.confirmPassword.errors?.required &&
              (fval.confirmPassword.touched || fval.confirmPassword.dirty)
            "
            class="text-danger col"
          >
            Please confirm your password
          </small>
        </div>
        <div class="d-flex flex-row justify-content-between mb-4">
          <button
            [disabled]="!userForm.valid"
            class="btn btn-primary col-5 shadow"
            type="submit"
          >
            REGISTER
          </button>

          <button
            class="btn btn-primary col-5 shadow"
            routerLink="/auth/supplier-login"
            type="button"
          >
            LOGIN
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="small text-center text-info mb-5">
    Copyright &copy; {{2022}} - Brimasmedia Ltd
  </div>
</div>


