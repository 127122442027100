import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
// import { ToastrService } from 'ngx-toastr';
// import { AlertService } from "ngx-alerts";
// import { DbServiceService } from 'src/app/shared/services/firestore-db/DbServiceService';
// import { UserData } from 'src/app/shared/models/user-profile/user-data';
// import { switchMap, tap } from 'rxjs/operators';
// import { AuthUser } from 'src/app/shared/models/user-profile/auth-user';
// import { AngularFireAuth } from '@angular/fire/auth';
// import { RfqSerialNumberService } from 'src/app/shared/services/front-desk-services/rfq-serial-number.service';

// export interface AccessRights {
//   accessCategory: string;
//   accessType: string;
//   createdAt: firebase.firestore.FieldValue;
//   updatedAt: firebase.firestore.FieldValue;
// }
@Component({
  selector: "app-process-setup",
  templateUrl: "./process-setup.component.html",
  styleUrls: ["./process-setup.component.scss"],
})
export class ProcessSetupComponent implements OnInit {
  userForm: FormGroup;

  constructor() // private toastr: ToastrService, // private router: Router, // private spinner: NgxSpinnerService, // private authService: AuthService,
  // private db: DbServiceService,
  // private afa: AngularFireAuth
  {}

  get fval(): any {
    return this.userForm.controls;
  }

  ngOnInit() {
    this.userForm = this.createFormGroup();
  }

  createFormGroup() {
    return new FormGroup({
      processName: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      processType: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
    });
  }
}
