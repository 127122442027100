<nav class="navbar navbar-expand-lg navbar-light fixed-top my-0 px-0 py-0" id="mainNav">
  <div class="container">
    <a class="navbar-brand logo logo-ico-widht-text js-scroll-trigger mx-auto pr-5" href=""><img class=""
                                                                                                 [src]="imageUrl" alt="Brimas Media" />
    </a>

    <button class="navbar-toggler navbar-toggler-right text-warning" type="button" data-toggle="collapse"
            data-target="#navbarResponsive" aria-controls="navbarResponsive"
            aria-label="Toggle navigation" [attr.aria-expanded]="isCollapsed" (click)="toggleArial()" >

      <span class="navbar-toggler-icon text-warning"></span>
    </button>
    <div [ngClass]="toggleClass" class="collapse navbar-collapse col-offset-lg-3 col-lg-9" id="navbarResponsive">
      <ul class="navbar-nav justify-content-end align-self-right">
        <li class="nav-item">
          <a class="nav-link js-scroll-trigger" routerLink=""><i class="fas fa-home" (click)="toggleArial()"></i>&nbsp;Home</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropbtn js-scroll-trigger" routerLink="about"><i class="fas fa-info-circle" (click)="toggleArial()"></i>&nbsp;About</a>
          <div class="dropdown-content">
            <a routerLink="about/whoWeAre" (click)="toggleArial()"><i class="fas fa-info-circle"></i>&nbsp;Who we are</a>
            <a routerLink="our-clients" (click)="toggleArial()"><i class="fas fa-business-time"></i>&nbsp;Our Clients</a>
            <a routerLink="gallery" (click)="toggleArial()"><i class="fas fa-photo-video"></i>&nbsp;Gallery</a>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropbtn js-scroll-trigger" routerLink="services" (click)="toggleArial()"><i class="fas fa-tasks"></i>&nbsp;Services</a>
          <div class="dropdown-content">
            <a routerLink="./services/largeFormatPrint" (click)="toggleArial()"><i class="fas fa-print"></i>&nbsp;Large-format printing</a>
            <a routerLink="./services/commercialPrint" (click)="toggleArial()"><i class="fas fa-box-open"></i>&nbsp;Commercial printing</a>
            <a routerLink="./services/corporateGifts" (click)="toggleArial()"><i class="fas fa-gifts"></i>&nbsp;Corporate gifts</a>
            <a routerLink="./services/promotionItems" (click)="toggleArial()"><i class="fas fa-parachute-box"></i>&nbsp;Promotional items</a>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropbtn js-scroll-trigger" routerLink="registeruser" (click)="toggleArial()">
            <i class="fas fa-registered"></i>&nbsp;Login </a>
          <div class="dropdown-content">
            <a routerLink="/auth/customer-login"><i class="fas fa-user-friends" (click)="toggleArial()"></i>&nbsp;Login Customer</a>
            <a routerLink="/auth/supplier-login"><i class="fas fa-people-carry" (click)="toggleArial()"></i>&nbsp;Login Supplier</a>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropbtn js-scroll-trigger" (click)="toggleArial()">
            <i class="fas fa-registered"></i>&nbsp;Register </a>
          <div class="dropdown-content">
            <a routerLink="/auth/register-customer"><i class="fas fa-user-friends" (click)="toggleArial()"></i>&nbsp;Register Customer</a>
            <a routerLink="/auth/register-supplier"><i class="fas fa-people-carry" (click)="toggleArial()"></i>&nbsp;Register Supplier</a>
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link js-scroll-trigger" routerLink="/contact"><i class="fas fa-envelope"></i>&nbsp;Contact</a>
        </li>
      </ul>
    </div>
  </div>
</nav>

