<div class="d-flex flex-column">
  <div class="container mt-2 mb-0 mb-md-1 mb-xl-2 px-0">
    <div class="row mx-0 px-0">
        <div
          class="thunder-col-1 col-6 col-md-4 col-xl-2 mx-0 mb-1 mb-lg-2"
        >
        <div class="sm-card-1 card shadow mx-0 py-0">
          <div class="card-body py-2 py-md-3">
            <div class="row">
              <div class="col mx-0 px-0 mx-lg-1">
                <div class="text-uppercase text-danger font-weight-bold mb-1">
                  <span class="sm-card-title-2">rfqs</span>
                </div>
                <div class="row">
                  <div class="col-auto">
                    <div class="text-secondary font-weight-bold h5 mb-0 pb-0">
                      <span class="pl-2 pl-md-3 pl-xl-2 pb-0">11</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-auto align-self-baseline">
                <i class="fas fa-list text-info"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div
          class="thunder-col-2 col-6 col-md-4 col-xl-3 mx-0 mb-1 mb-lg-2"
        >
        <div class="sm-card-2 card shadow mx-0 mx-md-2 py-0">
          <div class="card-body py-2 py-md-3">
            <div class="row">
              <div class="col mx-0 px-0 mx-lg-1">
                <div class="text-uppercase text-info font-weight-bold mb-1">
                  <span class="sm-card-title-2">procurement</span>
                </div>
                <div class="row">
                  <div class="col-auto">
                    <div class="text-secondary font-weight-bold h5 mb-0 pb-0">
                      <span class="pl-1 pl-md-3 pl-xl-2 pb-0">37,700,000</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-auto align-self-baseline mx-0 px-1 px-xl-3">
                <i class="fas fa-warehouse text-danger"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

        <div
          class="thunder-col-3 col-6 col-md-4 col-xl-2 mx-0 mb-0 mb-lg-2"
        >
        <div class="sm-card-3 card shadow mx-0 py-0">
          <div class="card-body py-2 py-md-3">
            <div class="row">
              <div class="col mx-0 px-0 mx-lg-1">
                <div class="text-uppercase text-danger font-weight-bold mb-1">
                  <span class="sm-card-title-3">bids</span>
                </div>
                <div class="row">
                  <div class="col-auto">
                    <div class="text-secondary font-weight-bold h5 mb-0 pb-0">
                      <span class="pl-2 pl-md-3 pl-xl-2 pb-0">5</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-auto align-self-baseline">
                <i class="fas fa-file text-info"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div
          class="thunder-col-4 col-6 col-xl-2 mx-0 mb-1 mb-lg-2"
        >
        <div class="sm-card-4 card shadow mx-md-3 mx-xl-2 py-0">
          <div class="card-body py-2 py-md-3">
            <div class="row">
              <div class="col mx-0 px-0 mx-lg-2">
                <div class="text-uppercase text-info font-weight-bold mb-1">
                  <span class="sm-card-title-4">sms left</span>
                </div>
                <div class="row">
                  <div class="col-auto">
                    <div class="text-secondary font-weight-bold h5 mb-0 pb-0">
                      <span class="pl-2 pl-md-3 pl-xl-2 pb-0">40</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-auto align-self-baseline">
                <i class="fas fa-sms text-danger"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div
          class="thunder-col-5 col-8 col-md-6 col-xl-3 mx-5 mx-md-0 mb-2"
        >
        <div class="sm-card-5 card shadow mx-md-0 py-0">
          <div class="card-body py-2 py-md-3">
            <div class="row">
              <div class="col mx-0 px-0 mx-lg-1">
                <div class="text-uppercase text-danger font-weight-bold mb-1">
                  <span class="sm-card-title-5">debt management</span>
                </div>
                <div class="row">
                  <div class="col-auto">
                    <div class="text-secondary font-weight-bold h5 mb-0 pb-0">
                      <span class="pl-2 pl-md-3 pl-xl-2 pb-0">34,300,000</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-auto align-self-baseline">
                <i class="fas fa-balance-scale text-info"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container mb-xl-3">
    <div class="row mt-0 pt-0">
      <div class="col-12 col-xl-6">
        <div class="tickets-card2 card2 card shadow mx-0 mb-2 pb-3">
          <div
            class="card-header d-flex justify-content-between align-items-center pt-1 pb-2"
          >
            <h5
              class="text-info text-uppercase pull-right font-weight-bold m-0"
            >
              <hr class="top mt-1 mb-0" />
              request for quotation (rfq)
              <hr class="bottom my-0" />
            </h5>
          </div>
          <div class="card-body mx-0 px-1 px-md-1 mb-4 pt-1 pb-4 pb-md-1">
            <!--record container-->
            <!--to be displayed if no rfqs to work on-->
            <!-- <div class="invest-card card my-2 py-2 mx-auto px-2 shadow-lg">
              <div class="col-12 my-5 py-5 justify-content-between">
                <h4 class="text-center text-info">No Appointments made yet</h4>
              </div>
            </div> -->
            <div
              class="invest-card card mt-1 py-2 mb-2 mx-0 mx-md-1 px-2 shadow-lg"
            >
              <div class="iteration">
                <div class="item-container-1 mt-1">
                  <div
                    class="list-card card mt-1 pt-2 mb-0 mx-auto px-0 pb-1 shadow-lg"
                    *ngFor="let rfq of rfqTable; let i = index"
                  >
                    <div
                      class="list-card-body card-body my-0 py-0 mx-0 px-4 px-md-3"
                    >
                      <div class="row my-0 pt-md-0 pb-md-1 mx-md-0 px-md-0">
                        <div
                          class="col-1 px-0 mx-md-0 d-flex justify-content-start"
                        >
                          <span class="">{{ i + 1 }}</span>
                        </div>
                        <div class="col-11 mx-0 px-0">
                          <div class="row mx-md-0 px-md-0">
                            <div class="col-12 col-md-6 col-xl-5 mx-md-0 px-md-0">
                              <div class="row">
                                <div
                                  class="col-5 col-md-4 col-lg-3 col-xl-5 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Serial No:</span>
                                </div>
                                <div
                                  class="col-7 col-md-8 col-lg-9 col-xl-7 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated">{{
                                    rfq.rfqId
                                  }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 col-md-6 col-xl-7 mx-md-0 px-md-0">
                              <div class="row">
                                <div
                                  class="col-3 col-lg-2 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Client:</span>
                                </div>
                                <div
                                  class="col-9 col-lg-10 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="">{{ rfq.clientName }}</span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-6 col-lg-4 col-xl-6 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-5 col-md-4 col-xl-4 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Source:</span>
                                </div>
                                <div
                                  class="col-7 col-md-8 col-xl-8 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"
                                    >{{ rfq.rfqSource }}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-6 col-lg-4 col-xl-6 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-5 col-md-4 col-lg-5 col-xl-4 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">No Of Items:</span>
                                </div>
                                <div
                                  class="col-7 col-md-8 col-lg-7 col-xl-8 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"
                                    >{{ rfq.noOfItems }}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-6 col-lg-4 col-xl-6 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-5 col-md-4 col-lg-3 col-xl-4 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Amount:</span>
                                </div>
                                <div
                                  class="col-7 col-md-8 col-lg-9 col-xl-8 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"
                                    >{{ rfq.totalAmount }}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <!-- <div
                              class="col-12 col-md-6 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Department:</span>
                                </div>
                                <div
                                  class="col-8 col-lg-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"
                                    >{{ rfq.department }}
                                  </span>
                                </div>
                              </div>
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--/ end of record container-->
            <div class="d-flex justify-content-start mx-2 mx-md-4">
              <a class="text-decoration-none text-info pointerCursor" (click)="openModal(rfqs)">Expand View >></a>
            </div>
          </div>
        </div>
      </div>
        <div class="col-12 col-xl-6">
          <div class="tickets-card2 card2 card shadow mx-0 mb-2">
            <div
              class="card-header d-flex justify-content-between align-items-center pt-1 pb-2"
            >
              <h5
                class="text-info text-uppercase pull-right font-weight-bold m-0"
              >
                <hr class="top mt-1 mb-0" />
                bids
                <hr class="bottom my-0" />
              </h5>
            </div>

                      <div class="card-body mx-0 px-1 px-md-1 mb-4 pt-1 pb-4 pb-md-1">
            <!--record container-->
            <!--to be displayed if no rfqs to work on-->
            <!-- <div class="invest-card card my-2 py-2 mx-auto px-2 shadow-lg">
              <div class="col-12 my-5 py-5 justify-content-between">
                <h4 class="text-center text-info">No Appointments made yet</h4>
              </div>
            </div> -->
            <div
              class="invest-card card mt-1 py-2 mb-2 mx-0 mx-md-1 px-2 shadow-lg"
            >
              <div class="iteration">
                <div class="item-container-1 mt-1">
                  <div
                    class="list-card card mt-1 pt-2 mb-0 mx-auto px-0 pb-1 shadow-lg"
                    *ngFor="let rfq of rfqTable; let i = index"
                  >
                    <div
                      class="list-card-body card-body my-0 py-0 mx-0 px-4 px-md-3"
                    >
                      <div class="row my-0 pt-md-0 pb-md-1 mx-md-0 px-md-0">
                        <div
                          class="col-1 px-0 mx-md-0 d-flex justify-content-start"
                        >
                          <span class="">{{ i + 1 }}</span>
                        </div>
                        <div class="col-11 mx-0 px-0">
                          <div class="row mx-md-0 px-md-0">
                            <div class="col-12 col-md-6 col-xl-5 mx-md-0 px-md-0">
                              <div class="row">
                                <div
                                  class="col-5 col-md-4 col-lg-3 col-xl-5 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Serial No:</span>
                                </div>
                                <div
                                  class="col-7 col-md-8 col-lg-9 col-xl-7 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated">{{
                                    rfq.rfqId
                                  }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 col-md-6 col-xl-7 mx-md-0 px-md-0">
                              <div class="row">
                                <div
                                  class="col-3 col-lg-2 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Client:</span>
                                </div>
                                <div
                                  class="col-9 col-lg-10 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="">{{ rfq.clientName }}</span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-6 col-lg-4 col-xl-6 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-5 col-md-4 col-xl-4 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Source:</span>
                                </div>
                                <div
                                  class="col-7 col-md-8 col-xl-8 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"
                                    >{{ rfq.rfqSource }}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-6 col-lg-4 col-xl-6 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-5 col-md-4 col-lg-5 col-xl-4 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">No Of Items:</span>
                                </div>
                                <div
                                  class="col-7 col-md-8 col-lg-7 col-xl-8 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"
                                    >{{ rfq.noOfItems }}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-6 col-lg-4 col-xl-6 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-5 col-md-4 col-lg-3 col-xl-4 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Amount:</span>
                                </div>
                                <div
                                  class="col-7 col-md-8 col-lg-9 col-xl-8 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"
                                    >{{ rfq.totalAmount }}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <!-- <div
                              class="col-12 col-md-6 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Department:</span>
                                </div>
                                <div
                                  class="col-8 col-lg-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"
                                    >{{ rfq.department }}
                                  </span>
                                </div>
                              </div>
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--/ end of record container-->
            <div class="d-flex justify-content-start mx-2 mx-md-4">
              <a class="text-decoration-none text-info pointerCursor" (click)="openModal(bids)">Expand View >></a>
            </div>
          </div>

          </div>
        </div>
      </div>
                    <div class="row">
        <div class="col-12 col-xl-6">
          <div class="tickets-card2 card2 card shadow mx-0 mb-2">
            <div
              class="card-header d-flex justify-content-between align-items-center pt-1 pb-2"
            >
              <h5
                class="text-info text-uppercase pull-right font-weight-bold m-0"
              >
                <hr class="top mt-1 mb-0" />
                debt management
                <hr class="bottom my-0" />
              </h5>
            </div>
                      <div class="card-body mx-0 px-1 px-md-1 mb-4 pt-1 pb-4 pb-md-1">
            <!--record container-->
            <!--to be displayed if no rfqs to work on-->
            <!-- <div class="invest-card card my-2 py-2 mx-auto px-2 shadow-lg">
              <div class="col-12 my-5 py-5 justify-content-between">
                <h4 class="text-center text-info">No Appointments made yet</h4>
              </div>
            </div> -->
            <div
              class="invest-card card mt-1 py-2 mb-2 mx-0 mx-md-1 px-2 shadow-lg"
            >
              <div class="iteration">
                <div class="item-container-1 mt-1">
                  <div
                    class="list-card card mt-1 pt-2 mb-0 mx-auto px-0 pb-1 shadow-lg"
                    *ngFor="let rfq of rfqTable; let i = index"
                  >
                    <div
                      class="list-card-body card-body my-0 py-0 mx-0 px-4 px-md-3"
                    >
                      <div class="row my-0 pt-md-0 pb-md-1 mx-md-0 px-md-0">
                        <div
                          class="col-1 px-0 mx-md-0 d-flex justify-content-start"
                        >
                          <span class="">{{ i + 1 }}</span>
                        </div>
                        <div class="col-11 mx-0 px-0">
                          <div class="row mx-md-0 px-md-0">
                            <div class="col-12 col-md-6 col-xl-5 mx-md-0 px-md-0">
                              <div class="row">
                                <div
                                  class="col-5 col-md-4 col-lg-3 col-xl-5 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Serial No:</span>
                                </div>
                                <div
                                  class="col-7 col-md-8 col-lg-9 col-xl-7 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated">{{
                                    rfq.rfqId
                                  }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 col-md-6 col-xl-7 mx-md-0 px-md-0">
                              <div class="row">
                                <div
                                  class="col-3 col-lg-2 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Client:</span>
                                </div>
                                <div
                                  class="col-9 col-lg-10 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="">{{ rfq.clientName }}</span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-6 col-lg-4 col-xl-6 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-5 col-md-4 col-xl-4 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Source:</span>
                                </div>
                                <div
                                  class="col-7 col-md-8 col-xl-8 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"
                                    >{{ rfq.rfqSource }}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-6 col-lg-4 col-xl-6 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-5 col-md-4 col-lg-5 col-xl-4 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">No Of Items:</span>
                                </div>
                                <div
                                  class="col-7 col-md-8 col-lg-7 col-xl-8 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"
                                    >{{ rfq.noOfItems }}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-6 col-lg-4 col-xl-6 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-5 col-md-4 col-lg-3 col-xl-4 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Amount:</span>
                                </div>
                                <div
                                  class="col-7 col-md-8 col-lg-9 col-xl-8 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"
                                    >{{ rfq.totalAmount }}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <!-- <div
                              class="col-12 col-md-6 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Department:</span>
                                </div>
                                <div
                                  class="col-8 col-lg-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"
                                    >{{ rfq.department }}
                                  </span>
                                </div>
                              </div>
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--/ end of record container-->
            <div class="d-flex justify-content-start mx-2 mx-md-4">
              <a class="text-decoration-none text-info pointerCursor" (click)="openModal(bids)">Expand View >></a>
            </div>
          </div>
          </div>
        </div>
        <div class="col-12 col-xl-6">
          <div class="tickets-card2 card2 card shadow mx-0 mb-2">
            <div
              class="card-header d-flex justify-content-between align-items-center pt-1 pb-2"
            >
              <h5
                class="text-info text-uppercase pull-right font-weight-bold m-0"
              >
                <hr class="top mt-1 mb-0" />
                procurement
                <hr class="bottom my-0" />
              </h5>
            </div>
                      <div class="card-body mx-0 px-1 px-md-1 mb-4 pt-1 pb-4 pb-md-1">
            <!--record container-->
            <!--to be displayed if no rfqs to work on-->
            <!-- <div class="invest-card card my-2 py-2 mx-auto px-2 shadow-lg">
              <div class="col-12 my-5 py-5 justify-content-between">
                <h4 class="text-center text-info">No Appointments made yet</h4>
              </div>
            </div> -->
            <div
              class="invest-card card mt-1 py-2 mb-2 mx-0 mx-md-1 px-2 shadow-lg"
            >
              <div class="iteration">
                <div class="item-container-1 mt-1">
                  <div
                    class="list-card card mt-1 pt-2 mb-0 mx-auto px-0 pb-1 shadow-lg"
                    *ngFor="let rfq of rfqTable; let i = index"
                  >
                    <div
                      class="list-card-body card-body my-0 py-0 mx-0 px-4 px-md-3"
                    >
                      <div class="row my-0 pt-md-0 pb-md-1 mx-md-0 px-md-0">
                        <div
                          class="col-1 px-0 mx-md-0 d-flex justify-content-start"
                        >
                          <span class="">{{ i + 1 }}</span>
                        </div>
                        <div class="col-11 mx-0 px-0">
                          <div class="row mx-md-0 px-md-0">
                            <div class="col-12 col-md-6 col-xl-5 mx-md-0 px-md-0">
                              <div class="row">
                                <div
                                  class="col-5 col-md-4 col-lg-3 col-xl-5 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Serial No:</span>
                                </div>
                                <div
                                  class="col-7 col-md-8 col-lg-9 col-xl-7 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated">{{
                                    rfq.rfqId
                                  }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 col-md-6 col-xl-7 mx-md-0 px-md-0">
                              <div class="row">
                                <div
                                  class="col-3 col-lg-2 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Client:</span>
                                </div>
                                <div
                                  class="col-9 col-lg-10 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="">{{ rfq.clientName }}</span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-6 col-lg-4 col-xl-6 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-5 col-md-4 col-xl-4 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Source:</span>
                                </div>
                                <div
                                  class="col-7 col-md-8 col-xl-8 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"
                                    >{{ rfq.rfqSource }}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-6 col-lg-4 col-xl-6 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-5 col-md-4 col-lg-5 col-xl-4 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">No Of Items:</span>
                                </div>
                                <div
                                  class="col-7 col-md-8 col-lg-7 col-xl-8 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"
                                    >{{ rfq.noOfItems }}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-6 col-lg-4 col-xl-6 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-5 col-md-4 col-lg-3 col-xl-4 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Amount:</span>
                                </div>
                                <div
                                  class="col-7 col-md-8 col-lg-9 col-xl-8 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"
                                    >{{ rfq.totalAmount }}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <!-- <div
                              class="col-12 col-md-6 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Department:</span>
                                </div>
                                <div
                                  class="col-8 col-lg-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"
                                    >{{ rfq.department }}
                                  </span>
                                </div>
                              </div>
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--/ end of record container-->
            <div class="d-flex justify-content-start mx-2 mx-md-4">
              <a class="text-decoration-none text-info pointerCursor" (click)="openModal(bids)">Expand View >></a>
            </div>
          </div>
          </div>
        </div>
        </div>
    </div>
  </div>
      <ng-template #rfqs>
        <div class="modal-header bg-info text-white close">
          <h4 class="modal-title">List Of RFQs</h4>
          <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="modalRef.hide()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body mt-2 mb-0 py-0">
          <!-- <div class="form-group">
            <input
              type="text"
              class="form-control col-md-5"
              [(ngModel)]="userFilter.clientName"
              placeholder="Enter Client Phone Number"
            />
          </div> -->
          <div class="table-responsive my-0 py-0">
            <!--record container-->
            <!--to be displayed if no rfqs to work on-->
            <!-- <div class="invest-card card my-2 py-2 mx-auto px-2 shadow-lg">
              <div class="col-12 my-5 py-5 justify-content-between">
                <h4 class="text-center text-info">No Appointments made yet</h4>
              </div>
            </div> -->
            <div
              class="invest-card-1 card mt-1 py-2 mb-2 mb-xl-1 mx-0 mx-md-1 px-2 shadow-lg"
            >
              <div class="iteration">
                <div class="item-container-1 mt-1">
                  <div
                    class="list-card card mt-1 pt-2 mb-0 mx-auto px-0 pb-1 shadow-lg"
                    *ngFor="let rfq of rfqTable; let i = index"
                  >
                    <div
                      class="list-card-body card-body my-0 py-0 mx-0 px-4 px-md-3"
                    >
                      <div class="row my-0 pt-md-0 pb-md-1 mx-md-0 px-md-0">
                        <div
                          class="col-1 px-0 mx-md-0 d-flex justify-content-start"
                        >
                          <span class="">{{ i + 1 }}</span>
                        </div>
                        <div class="col-11 mx-0 px-0">
                          <div class="row mx-md-0 px-md-0">
                            <div class="col-12 col-md-6 mx-md-0 px-md-0">
                              <div class="row">
                                <div
                                  class="col-5 col-md-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Serial No:</span>
                                </div>
                                <div
                                  class="col-7 col-md-8 col-lg-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated">{{
                                    rfq.rfqId
                                  }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 col-md-6 mx-md-0 px-md-0">
                              <div class="row">
                                <div
                                  class="col-3 col-lg-2 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Client:</span>
                                </div>
                                <div
                                  class="col-9 col-lg-10 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="">{{ rfq.clientName }}</span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-6 col-lg-4 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-5 col-md-4 col-xl-3 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Source:</span>
                                </div>
                                <div
                                  class="col-7 col-md-8 col-xl-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"
                                    >{{ rfq.rfqSource }}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-6 col-lg-4 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-5 col-md-4 col-lg-5 col-xl-4 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">No Of Items:</span>
                                </div>
                                <div
                                  class="col-7 col-md-8 col-lg-7 col-xl-8 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"
                                    >{{ rfq.noOfItems }}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-6 col-lg-4 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-5 col-md-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Amount:</span>
                                </div>
                                <div
                                  class="col-7 col-md-8 col-lg-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"
                                    >{{ rfq.totalAmount }}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <!-- <div
                              class="col-12 col-md-6 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Department:</span>
                                </div>
                                <div
                                  class="col-8 col-lg-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"
                                    >{{ rfq.department }}
                                  </span>
                                </div>
                              </div>
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--/ end of record container-->
            <div class="card-tools mx-2 mb-5 mb-md-3 mb-xl-4 pt-0 pt-md-3 px-auto">
              <ul
                class="pagination pagination-sm text-info align-items-center"
              >
                <li class="page-item">
                  <a href="#" class="page-link">&laquo;</a>
                </li>
                <li class="page-item">
                  <a href="#" class="page-link">1</a>
                </li>
                <li class="page-item">
                  <a href="#" class="page-link">2</a>
                </li>
                <li class="page-item">
                  <a href="#" class="page-link">3</a>
                </li>
                <li class="page-item">
                  <a href="#" class="page-link">&raquo;</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template #bids>
        <div class="modal-header bg-info text-white close">
          <h4 class="modal-title">List Of Bids</h4>
          <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="modalRef.hide()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body mt-2 mb-0 py-0">
          <!-- <div class="form-group">
            <input
              type="text"
              class="form-control col-md-5"
              [(ngModel)]="userFilter.clientName"
              placeholder="Enter Client Phone Number"
            />
          </div> -->
          <div class="table-responsive my-0 py-0">
            <!--record container-->
            <!--to be displayed if no rfqs to work on-->
            <!-- <div class="invest-card card my-2 py-2 mx-auto px-2 shadow-lg">
              <div class="col-12 my-5 py-5 justify-content-between">
                <h4 class="text-center text-info">No Appointments made yet</h4>
              </div>
            </div> -->
            <div
              class="invest-card-1 card mt-1 py-2 mb-2 mb-xl-1 mx-0 mx-md-1 px-2 shadow-lg"
            >
              <div class="iteration">
                <div class="item-container-1 mt-1">
                  <div
                    class="list-card card mt-1 pt-2 mb-0 mx-auto px-0 pb-1 shadow-lg"
                    *ngFor="let rfq of rfqTable; let i = index"
                  >
                    <div
                      class="list-card-body card-body my-0 py-0 mx-0 px-4 px-md-3"
                    >
                      <div class="row my-0 pt-md-0 pb-md-1 mx-md-0 px-md-0">
                        <div
                          class="col-1 px-0 mx-md-0 d-flex justify-content-start"
                        >
                          <span class="">{{ i + 1 }}</span>
                        </div>
                        <div class="col-11 mx-0 px-0">
                          <div class="row mx-md-0 px-md-0">
                            <div class="col-12 col-md-6 mx-md-0 px-md-0">
                              <div class="row">
                                <div
                                  class="col-5 col-md-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Serial No:</span>
                                </div>
                                <div
                                  class="col-7 col-md-8 col-lg-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated">{{
                                    rfq.rfqId
                                  }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 col-md-6 mx-md-0 px-md-0">
                              <div class="row">
                                <div
                                  class="col-3 col-lg-2 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Client:</span>
                                </div>
                                <div
                                  class="col-9 col-lg-10 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="">{{ rfq.clientName }}</span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-6 col-lg-4 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-5 col-md-4 col-xl-3 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Source:</span>
                                </div>
                                <div
                                  class="col-7 col-md-8 col-xl-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"
                                    >{{ rfq.rfqSource }}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-6 col-lg-4 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-5 col-md-4 col-lg-5 col-xl-4 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">No Of Items:</span>
                                </div>
                                <div
                                  class="col-7 col-md-8 col-lg-7 col-xl-8 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"
                                    >{{ rfq.noOfItems }}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-6 col-lg-4 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-5 col-md-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Amount:</span>
                                </div>
                                <div
                                  class="col-7 col-md-8 col-lg-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"
                                    >{{ rfq.totalAmount }}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <!-- <div
                              class="col-12 col-md-6 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Department:</span>
                                </div>
                                <div
                                  class="col-8 col-lg-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"
                                    >{{ rfq.department }}
                                  </span>
                                </div>
                              </div>
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--/ end of record container-->
            <div class="card-tools mx-2 mb-5 mb-md-3 mb-xl-4 pt-0 pt-md-3 px-auto">
              <ul
                class="pagination pagination-sm text-info align-items-center"
              >
                <li class="page-item">
                  <a href="#" class="page-link">&laquo;</a>
                </li>
                <li class="page-item">
                  <a href="#" class="page-link">1</a>
                </li>
                <li class="page-item">
                  <a href="#" class="page-link">2</a>
                </li>
                <li class="page-item">
                  <a href="#" class="page-link">3</a>
                </li>
                <li class="page-item">
                  <a href="#" class="page-link">&raquo;</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </ng-template>

