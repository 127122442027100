import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BinaryDataServiceService } from "./binary-data-service.service";
import { AuthService } from "../../auth/services/auth.service";
import { LoaderService } from "./loader.service";
import { InterceptorService } from "./interceptors/request-interceptor.service";

@NgModule({
  declarations: [],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [],
})
export class ServicesModule {
  static forRoot(): ModuleWithProviders<ServicesModule> {
    return {
      ngModule: ServicesModule,
      providers: [
        BinaryDataServiceService,
        AuthService,
        LoaderService,
        InterceptorService,
      ],
    };
  }
}
