import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AuthRoutingModule } from "./auth-routing.module";
import { AuthComponent } from "./auth.component";
import { LoginComponent } from "./login/login.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { AlertModule } from "ngx-bootstrap/alert";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";

import { ReactiveFormsModule } from "@angular/forms";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { RegisterEmployeeComponent } from "./registration/register-employee/register-employee.component";
import { RegisterCustomerComponent } from "./registration/register-customer/register-customer.component";
import { RegisterSupplierComponent } from "./registration/register-supplier/register-supplier.component";
import { AuthNavComponent } from "./auth-nav/auth-nav.component";
import { HomeComponent } from "./home/home.component";
import { SharedModule } from "../shared/shared.module";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
  declarations: [
    AuthComponent,
    HomeComponent,
    AuthNavComponent,
    LoginComponent,
    RegisterEmployeeComponent,
    RegisterCustomerComponent,
    RegisterSupplierComponent,
    ChangePasswordComponent,
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    NgxSpinnerModule,
    AlertModule.forRoot(),
    BsDatepickerModule,
    ReactiveFormsModule,
    SharedModule,
    MatButtonModule,
  ],
})
export class AuthModule {}
