import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, finalize, switchMap, take } from "rxjs/operators";
import { AuthService } from "../../../auth/services/auth.service";
import { LoaderService } from "../loader.service";

@Injectable({
  providedIn: "root",
})
export class InterceptorService implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private router: Router,
    private authService: AuthService,
    private loaderService: LoaderService
  ) {}

  private static addToken(request: HttpRequest<any>, token: string): any {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private static setContentType(request: HttpRequest<any>) {
    return request.clone({
      setHeaders: {
        "Content-Type": "application/json",
      },
    });
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.loaderService.isLoading.next(true);
    if (
      request.method === "POST" ||
      request.method === "PUT" ||
      request.method === "PATCH"
    ) {
      request = InterceptorService.setContentType(request);
    }
    if (this.authService.getJwtToken()) {
      request = InterceptorService.addToken(
        request,
        this.authService.getJwtToken()
      );
    }
    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401 && this.authService.isLoggedIn()) {
            return this.handle401Error(request, next);
          } else if (error.status === 500) {
            this.loaderService.isLoading.next(false);
            return throwError(
              error?.error?.error?.message || "Something went wrong"
            );
          } else if (error.status === 0) {
            this.loaderService.isLoading.next(false);
            return throwError(
              "This seems to be a network problem, please check your connection"
            );
          } else if (error.status === 301) {
            this.loaderService.isLoading.next(false);
            return throwError(error?.error);
          } else {
            this.loaderService.isLoading.next(false);
            return throwError(
              error?.error?.error?.message || "Something went wrong"
            );
          }
        }
      }),
      finalize(() => {
        this.loaderService.isLoading.next(false);
      })
    ) as Observable<HttpEvent<any>>;
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): any {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.accessToken);
          return next.handle(
            InterceptorService.addToken(request, token.accessToken)
          );
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((accessToken) => {
          return next.handle(InterceptorService.addToken(request, accessToken));
        })
      );
    }
  }
}
