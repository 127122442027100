import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-major-headers",
  templateUrl: "./major-headers.component.html",
  styleUrls: ["./major-headers.component.sass"],
})
export class MajorHeadersComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
