<div
  class="container col-xl-12 mx-0 px-0 mx-sm-auto mx-md-0 px-md-2 px-xl-5 h-100"
>
  <div class="d-flex flex-column">
    <h4 class="text-info text-uppercase text-center my-3">Projects</h4>
    <div class="big-card bg-white mb-4 mx-2 py-3 pb-md-0">
      <div class="d-flex flex-column flex-md-row justify-content-between">
        <div class="row px-3">
          <div class="col-8 my-2">
            <input
              (input)="getValue($event)"
              class="form-control shadow px-2 px-md-3"
              placeholder="Search"
              style="width: inherit"
              type="text"
            />
          </div>
          <div class="col-4 my-2 mx-md-0 px-md-0">
            <button
              (click)="openBranchDialog(false)"
              class="btn btn-outline-info shadow py-2 px-1 pull-right"
              tooltip="Create Project"
            >
              Create Project
            </button>
          </div>
        </div>
        <div
          class="d-flex flex-row justify-content-center justify-content-md-end"
        >
          <mat-paginator
            (page)="getPaginatorData($event)"
            [length]="branches?.length"
            [pageSizeOptions]="pageSizeOptions()"
            [pageSize]="pageSize"
            showFirstLastButtons="false"
          >
          </mat-paginator>
        </div>
      </div>

      <!--Desktop view-->
      <div class="row d-none d-md-block">
        <div class="col-12 px-2 px-md-3">
          <div class="table-col table-responsive col-12">
            <!--table-->
            <table class="table table-hover table-borderless table-sm w-100">
              <thead class="border-line-1 text-dark">
              <tr class="mx-2 mx-md-3">
                <th class="col-4 col-md-2" scope="col">Project ID</th>
                <th scope="col">Project Type</th>
                <th scope="col">Project Name</th>
                <th scope="col">Action</th>
              </tr>
              </thead>
              <tbody class="text-primary">
              <tr
                *ngFor="
                    let element of branches | slice: lowValue:highValue;
                    let i = index
                  "
                class="border-line-2 mx-2 mx-md-3"
              >
                <td class="col-4 col-md-2">{{ element.branchId }}</td>
                <td>
                  {{ element.branchType === 1100 ? "MAIN" : "OTHERS" }}
                </td>
                <td>{{ element.branchName }}</td>
                <td>
                  <button
                    (click)="openBranchDialog(true, element)"
                    class="btn btn-outline-info my-1 shadow"
                  >
                    <i class="fas fa-edit"></i> Edit
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- <div class="mat-elevation-0 w-100 d-none d-md-block h-100">
          <table
            [dataSource]="branches | slice: lowValue:highValue"
            class="w-100"
            mat-table
          >
            <ng-container matColumnDef="id">
              <th *matHeaderCellDef mat-header-cell><h4>Branch ID</h4></th>
              <td *matCellDef="let element" mat-cell>{{ element.branchId }}</td>
            </ng-container>
            <ng-container matColumnDef="type">
              <th *matHeaderCellDef mat-header-cell><h4>Type</h4></th>
              <td *matCellDef="let element" mat-cell>
                {{ element.branchType === 1100 ? "HEADOFFICE" : "BRANCH" }}
              </td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th *matHeaderCellDef mat-header-cell><h4>Name</h4></th>
              <td *matCellDef="let element" mat-cell>
                {{ element.branchName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th *matHeaderCellDef mat-header-cell><h4>Action</h4></th>
              <td *matCellDef="let element" mat-cell>
                <button
                  (click)="openBranchDialog(true, element)"
                  class="btn btn-outline-info"
                  mat-raised-button
                >
                  <i class="fas fa-edit"></i>
                  Edit
                </button>
              </td>
            </ng-container>
            <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
            <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
          </table>
        </div> -->
      <div class="records mt-2 pt-1 mx-0 px-2">
        <div
          *ngFor="
            let branch of branches | slice: lowValue:highValue;
            let i = index
          "
          class="card my-2 p-2 shadow text-primary d-block d-md-none"
        >
          <div class="card-body my-0 py-0">
            <div
              class="record-1 row d-flex flex-row justify-content-between pl-1 pr-0"
            >
              <div class="col-6 col-md-4 px-2">
                <div class="font-weight-bold">
                  <span class="">{{ branch.branchId }}</span>
                </div>
                <div class="">
                  <span class="">Project ID</span>
                </div>
              </div>
              <div class="col-6 col-md-4 px-2">
                <div class="d-flex justify-content-end font-weight-bold">
                  <span class="">{{
                    branch.branchType === 1100 ? "MAIN" : "OTHERS"
                    }}</span>
                </div>
                <div class="d-flex justify-content-end">
                  <span class="">Project Type</span>
                </div>
              </div>
              <div class="col-8 col-md-4 px-2">
                <div
                  class="d-flex justify-content-start justify-content-md-end font-weight-bold"
                >
                  <span class="itemCreated"> {{ branch.branchName }}</span>
                </div>
                <div
                  class="d-flex justify-content-start justify-content-md-end"
                >
                  <span class="">Project Name</span>
                </div>
              </div>
              <div class="col-4 col-md-1">
                <div class="d-flex justify-content-end mt-1 mx-lg-2">
                  <button
                    (click)="openBranchDialog(true, branch)"
                    class="btn btn-outline-info px-2 shadow"
                  >
                    <i class="fas fa-edit"></i>
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
