import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Tokens } from "../../shared/models/tokens";
import { catchError, map, mapTo, tap } from "rxjs/operators";
import { JwtHelperService } from "@auth0/angular-jwt";
import {
  ACCESS_TOKEN,
  API_URLS,
  REFRESH_TOKEN,
} from "../../shared/utils/constants";
import {
  ChangePasswordCredential,
  LoginCredentials,
  PasswordCodeCredential,
  RegisterUser,
} from "../../shared/models/user-auth";
import { checkUserType, routeToLogins } from "../../shared/utils/functions";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  pleaseLogin = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private router: Router,
    private jwtHelper: JwtHelperService
  ) {}

  getAuthBranches(): Observable<any> {
    return this.http.get<any[]>(API_URLS.AUTH.EMPLOYEE.GET_BRANCHES);
  }

  getEmployeeRoles(): any {
    return this.http.get(API_URLS.AUTH.EMPLOYEE.GET_EMPLOYEE_ROLE);
  }

  loginHandler(postData: LoginCredentials, endPoint: string): any {
    return this.http.post<any>(endPoint, postData).pipe(
      tap((tokens) => this.storeTokens(tokens)),
      mapTo(true)
    );
  }

  registerHandler(postData: RegisterUser, endPoint): any {
    return this.http.post<any>(endPoint, postData).pipe(
      map((res: string) => res),
      tap()
    );
  }

  passwordChangeCodeHandler(postData: PasswordCodeCredential, endPoint): any {
    return this.http.post<any>(endPoint, postData);
  }

  changePasswordHandler(
    postData: ChangePasswordCredential,
    endPoint: string
  ): any {
    return this.http.post<any>(endPoint, postData);
  }

  logoutUser(): Observable<any> {
    localStorage.clear();
    return;
  }

  isLoggedIn(): boolean {
    return !!this.getJwtToken();
  }

  loggedInUserInfo(): any {
    return this.jwtHelper.decodeToken(this.getJwtToken());
  }

  getJwtToken(): any {
    return localStorage.getItem(ACCESS_TOKEN);
  }

  refreshToken(): any {
    const exp = this.jwtHelper.decodeToken(this.getRefreshToken()).exp;
    const bool = Date.now() < exp * 1000;
    if (bool) {
      return this.http
        .post<any>(API_URLS.AUTH.EMPLOYEE.REFRESH_TOKEN, {
          refreshToken: this.getRefreshToken(),
        })
        .pipe(
          tap((tokens: Tokens) => {
            this.storeTokens(tokens);
          }),
          catchError((error) => {
            console.log(error);
            if (error instanceof HttpErrorResponse) {
              this.pleaseLogin.next(true);
              return this.logoutUser();
              const user = checkUserType(this.loggedInUserInfo());
              routeToLogins(user, this.router);
            }
          })
        );
    } else {
      this.pleaseLogin.next(true);
      this.logoutUser();
      const user = checkUserType(this.loggedInUserInfo());
      routeToLogins(user, this.router);
    }
  }

  getRefreshToken(): any {
    return localStorage.getItem(REFRESH_TOKEN);
  }

  storeTokens(tokens: Tokens): any {
    localStorage.setItem(ACCESS_TOKEN, tokens.accessToken);
    localStorage.setItem(REFRESH_TOKEN, tokens.refreshToken);
  }
}
