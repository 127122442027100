import { Component, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ApprovalItem } from "src/app/modules/shared/models/admin";
import { AdminService } from "src/app/modules/admin/services/admin.service";
import {
  searchItems,
  showToasterMessage,
} from "../../../shared/utils/functions";
import { PageEvent } from "@angular/material/paginator";

@Component({
  selector: "app-approvals-setup",
  templateUrl: "./approvals-setup.component.html",
  styleUrls: ["./approvals-setup.component.scss"],
})
export class ApprovalsSetupComponent implements OnInit {
  approvalForm: FormGroup;
  posted = false;
  errored: boolean;
  serviceErrors: string;
  approvals: Array<ApprovalItem>;
  approvalsCopy: Array<ApprovalItem>;
  loading = false;
  totalItems: number;
  pageSize = 10;
  lowValue: number = 0;
  highValue: number = 10;
  @ViewChild("paginator", { static: true }) paginator;

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private adminService: AdminService,
    private fb: FormBuilder,
    private activateRoute: ActivatedRoute
  ) {}

  get fval(): any {
    return this.approvalForm.controls;
  }

  get approvalItem(): any {
    return this.fb.group({
      approvalStatus: this.fb.control({ value: "" }),
      itemName: this.fb.control({ value: "" }),
      itemRequiringApprovalId: this.fb.control({ value: "" }),
    });
  }

  ngOnInit(): void {
    const resolvedData: { approvals: Array<ApprovalItem> } =
      this.activateRoute.snapshot.data[0]?.details;
    this.serviceErrors = this.activateRoute.snapshot.data[0]?.error;
    if (resolvedData) {
      this.approvalsCopy = this.approvals = resolvedData.approvals;
      this.approvalForm = this.createFormGroup();
      this.initialiseForm();
    } else {
      showToasterMessage(this.toaster, "warning", this.serviceErrors);
    }
  }

  createFormGroup(): any {
    return this.fb.group({
      approvalItems: this.fb.array([this.approvalItem]),
    });
  }

  addItem(): any {
    (this.fval.approvalItems as FormArray).push(this.approvalItem);
  }

  removeItem(index: number): any {
    (this.fval.approvalItems as FormArray).removeAt(index);
  }

  initialiseForm(): void {
    let n: number;
    this.approvals.forEach((item, i) => {
      this.fval.approvalItems.controls[i].controls.itemName.setValue(
        item.itemName
      );
      this.fval.approvalItems.controls[
        i
      ].controls.itemRequiringApprovalId.setValue(item.itemRequiringApprovalId);
      this.fval.approvalItems.controls[i].controls.approvalStatus.setValue(
        item.approvalStatus
      );
      this.addItem();
      n = i + 1;
    });
    this.removeItem(n);
  }

  resetForm(): void {
    this.approvalForm.reset();
  }

  saveStatus(index: any): any {
    if (!this.fval.approvalItems["controls"][index].pristine || this.loading) {
      if (this.fval.approvalItems.controls[index].valid) {
        this.loading = true;
        const itemName =
          this.fval.approvalItems.controls[index].controls.itemName.value;
        let postingMethod = "createApproval";
        const data = {
          itemRequiringApprovalId:
            this.fval.approvalItems.controls[index].controls
              .itemRequiringApprovalId.value,
          approvalStatus: Number(
            this.fval.approvalItems.controls[index].controls.approvalStatus
              .value
          ),
        };
        if (itemName.toLowerCase().indexOf("updating") !== -1)
          postingMethod = "updateApproval";
        this.adminService.postApprovalStatus(postingMethod, data).subscribe(
          (res) => {
            this.loading = false;
            showToasterMessage(
              this.toaster,
              "success",
              "Approval status was set successfully",
              itemName
            );
          },
          (error) => {
            this.loading = false;
            showToasterMessage(this.toaster, "warning", error);
          }
        );
      } else return;
    } else return;
  }

  public getPaginatorData(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    return event;
  }

  pageSizeOptions() {
    return [
      5,
      10,
      20,
      30,
      40,
      50,
      this.fval.approvalItems.controls?.length,
    ].filter((n) => n <= this.fval.approvalItems.controls?.length);
  }

  getValue(event): void {
    const results = searchItems(
      event.target.value,
      this.approvals,
      "approvedItemsAdmin"
    );
    console.log(results);
    this.approvals = results.length ? results : this.approvalsCopy;
    this.totalItems = this.approvals.length;
    this.approvalForm = this.createFormGroup();
    this.initialiseForm();
  }
}
