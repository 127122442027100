import { Component, OnInit } from "@angular/core";
import { EmployeeData, EmployeeNextOfKin } from "../../models/employee";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../../../auth/services/auth.service";
import { AdminService } from "../../../admin/services/admin.service";
import { noRecords, showToasterMessage } from "../../utils/functions";
import * as moment from "moment";
import { MatDialog } from "@angular/material/dialog";
import { ProfileEditorComponent } from "./profile-editor/profile-editor.component";
import { NextOfKinEditorComponent } from "./next-of-kin-editor/next-of-kin-editor.component";
import { ProfilesService } from "../../services/profiles.service";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { EditUserEmailComponent } from "../edit-user-email/edit-user-email.component";
import { API_URLS, USER_TOKEN } from "../../utils/constants";

@Component({
  selector: "app-admin-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  loading = false;
  profile: EmployeeData;
  nextOfKins: EmployeeNextOfKin[];
  User = this.authService.loggedInUserInfo();
  serviceErrors: any;
  fieldType: any;
  totalItems: number;
  pageSize = 1;
  currentPage = 1;
  gender = ["MALE", "FEMALE"];
  idTypes = [
    { name: "NATIONAL ID", id: 1 },
    { name: "VILAGE ID", id: 2 },
    { name: "PASSPORT", id: 3 },
  ];

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private toaster: ToastrService,
    private authService: AuthService,
    private adminService: AdminService,
    private activateRoute: ActivatedRoute,
    private profilesService: ProfilesService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    const resolvedData = this.activateRoute.snapshot.data[0]?.details;
    this.serviceErrors = this.activateRoute.snapshot.data[0]?.error;
    if (resolvedData) {
      this.profile = resolvedData.profile[0];
      this.nextOfKins = noRecords(resolvedData.nextOfKins)
        ? []
        : resolvedData.nextOfKins;
    } else showToasterMessage(this.toaster, "warning", this.serviceErrors);
  }

  showIdType(idValue: number): string {
    return this.idTypes.filter((item) => item.id === idValue)[0]?.name || " ";
  }

  pageChanged(event): any {
    this.currentPage = event;
  }

  showDate(date: any): any {
    return moment(date).format("YYYY-MM-DD");
  }

  openProfileDialog(): void {
    const dialogRef = this.dialog.open(ProfileEditorComponent, {
      minWidth: "50%",
      maxHeight: "90vh",
      data: this.profile,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.profile = result;
      localStorage.setItem(USER_TOKEN, JSON.stringify(this.profile));
      this.profilesService.userProfile.next(
        JSON.parse(localStorage.getItem(USER_TOKEN))
      );
    });
  }

  openNextOfKinDialog(kin?: EmployeeNextOfKin): void {
    const dialogRef = this.dialog.open(NextOfKinEditorComponent, {
      minWidth: "50%",
      maxHeight: "90vh",
      data: { isEditing: !!kin?.employeeNextOfKinId, nextOfKin: kin || null },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.nextOfKins = result;
    });
  }

  openChangePasswordDialog(): void {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      minWidth: "30%",
      maxHeight: "90vh",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.nextOfKins = result;
    });
  }

  openEditEmailDialog(): void {
    const dialogRef = this.dialog.open(EditUserEmailComponent, {
      minWidth: "30%",
      maxHeight: "90vh",
      data: {
        formField: "employeeEmail",
        isDialog: true,
        userId: this.User.employeeId,
        userIdNaming: "employeeId",
        endPoint: API_URLS.USERS.EMPLOYEE.EDIT_EMAIL,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        setTimeout(() => {
          this.authService.logoutUser();
          this.router.navigate(["auth/employee-login"]).then();
        }, 500);
      }
    });
  }

  deleteNextOfKin(nextOfKin: EmployeeNextOfKin): void {
    console.log(nextOfKin);
    this.profilesService
      .deleteNextOfKinEmployee(nextOfKin.employeeNextOfKinId)
      .subscribe(
        (res) => {
          this.loading = false;
          this.nextOfKins = this.nextOfKins.filter(
            (kin) => kin.employeeNextOfKinId !== nextOfKin.employeeNextOfKinId
          );
        },
        (error) => {
          this.loading = false;
          showToasterMessage(this.toaster, "warning", error);
        }
      );
  }
}
