<div class="big-card bg-white mx-auto mt-0 mb-4 pt-2 pb-0">
  <h4 class="text-info text-uppercase text-center my-2">Deactivated employees</h4>
  <div class="d-flex flex-column flex-md-row justify-content-between">
    <div class="row px-3 px-md-4">
      <div class="col-12 my-2">
        <input
          (input)="getValue($event)"
          class="form-control shadow px-2 px-md-3"
          placeholder="Search"
          style="width: inherit"
          type="text"
        />
      </div>
    </div>
    <div
      class="d-flex flex-row justify-content-center justify-content-md-end px-md-5"
    >
      <mat-paginator
        (page)="getPaginatorData($event)"
        [length]="employees?.length"
        [pageSizeOptions]="pageSizeOptions()"
        [pageSize]="pageSize"
        showFirstLastButtons="false"
      >
      </mat-paginator>
    </div>
  </div>
  <div class="pt-2 pb-3 px-1">
    <div
      *ngIf="!employees.length"
      class="col-12 my-5 py-5 justify-content-between"
    >
      <h4 class="text-center text-primary">No deactivated employees</h4>
    </div>


    <!--Desktop view-->
    <div *ngIf="employees.length && !loading" class="row d-none d-md-block w-100 h-100">
      <div class="col-12">
        <div class="table-col table-responsive px-2 px-lg-3">
          <!--table-->
          <table class="table table-hover table-borderless table-sm w-100">
            <thead class="border-line-1 text-dark">
            <tr class="mx-1">
              <th class="" scope="col">Photo</th>
              <th class="col-1" scope="col">ID</th>
              <th scope="col">Name</th>
              <th scope="col">Role</th>
              <th scope="col">Project</th>
              <th scope="col">Department</th>
              <th class="col-2" scope="col">Phone</th>
              <th scope="col">Action</th>
            </tr>
            </thead>
            <tbody class="text-primary">
            <tr
              *ngFor="
                  let element of employees | slice: lowValue:highValue;
                  let i = index
                "
              class="border-line-2 mx-1 pt-5"
            >
              <td class="">
                <img
                  [src]="
                      element.employeeIdPhotoUrl !== 'MissingIdPhotoUrl.com'
                        ? element.employeePhotoUrl
                        : 'assets/img/man.svg' || 'assets/img/man.svg'
                    "
                  alt="Employee Photo"
                  class="rounded-circle border m-auto m-md-0 align-self-center"
                  height="50"
                  width="50"
                />
              </td>
              <td class="col-1">
                {{ element.employeeId }}
              </td>
              <td>
                {{ element.employeeName.toUpperCase() }}
              </td>
              <td class="">
                {{ element.roleName | removeDashes }}
              </td>
              <td class="">
                {{ element.branchName }}
              </td>
              <td class="">
                {{ element.department }}
              </td>
              <td class="col-2">
                {{ element.employeePhone1 }}
              </td>
              <td>
                <button
                  [matMenuTriggerFor]="menu"
                  class="btn btn-outline-info"
                  mat-raised-button
                >
                  Options
                </button>
                <mat-menu #menu="matMenu">
                  <button (click)="activateEmployee(element.employeeId)" mat-menu-item>
                    <i class="fas fa-user-cog"></i>
                    Activate
                  </button>
                  <button (click)="openDetailsModal(element)" mat-menu-item>
                    <i class="fas fa-eye"></i>
                    View Details
                  </button>
                </mat-menu>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>


    <!-- <div *ngIf="employees.length" class="mat-elevation-0 w-100 d-none d-md-block h-100">
        <table [dataSource]="(employees | slice: lowValue : highValue)" class="w-100" mat-table>
          <ng-container matColumnDef="employeeIdPhotoUrl">
            <th *matHeaderCellDef mat-header-cell><h4>Photo</h4></th>
            <td *matCellDef="let element" class="py-1" mat-cell>
              <img
                [src]="element.employeeIdPhotoUrl !== 'MissingIdPhotoUrl.com' ?
                  element.employeePhotoUrl : 'assets/img/man.svg' || 'assets/img/man.svg'
                "
                alt="Employee Photo"
                class="rounded-circle border m-auto m-md-0 align-self-center"
                height="60"
                width="60"
              />
            </td>
          </ng-container>
          <ng-container matColumnDef="employeeId">
            <th *matHeaderCellDef mat-header-cell><h4>ID</h4></th>
            <td *matCellDef="let element" mat-cell> {{element.employeeId}} </td>
          </ng-container>
          <ng-container matColumnDef="employeeName">
            <th *matHeaderCellDef mat-header-cell><h4>Name</h4></th>
            <td *matCellDef="let element" mat-cell>{{element.employeeName.toUpperCase()}}</td>
          </ng-container>
          <ng-container matColumnDef="roleName">
            <th *matHeaderCellDef mat-header-cell><h4>Role</h4></th>
            <td *matCellDef="let element" mat-cell> {{element.roleName | removeDashes}} </td>
          </ng-container>
          <ng-container matColumnDef="branchName">
            <th *matHeaderCellDef mat-header-cell><h4>Branch</h4></th>
            <td *matCellDef="let element" mat-cell> {{element.branchName}} </td>
          </ng-container>
          <ng-container matColumnDef="Department">
            <th *matHeaderCellDef mat-header-cell><h4>Department</h4></th>
            <td *matCellDef="let element" mat-cell> {{element.department}} </td>
          </ng-container>
          <ng-container matColumnDef="employeePhone1">
            <th *matHeaderCellDef mat-header-cell><h4>Phone</h4></th>
            <td *matCellDef="let element" mat-cell> {{element.employeePhone1}} </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th *matHeaderCellDef mat-header-cell><h4>Action</h4></th>
            <td *matCellDef="let element" mat-cell>
              <button [matMenuTriggerFor]="menu" class="btn btn-outline-info" mat-raised-button>Options</button>
              <mat-menu #menu="matMenu">
                <button (click)="activateEmployee(element.employeeId)" mat-menu-item>
                  <i class="fas fa-edit"></i>
                  Activate
                </button>
                <button (click)="openDetailsModal(element)" mat-menu-item>
                  <i class="fas fa-eye"></i>
                  View Details
                </button>
              </mat-menu>
            </td>
          </ng-container>
          <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
          <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
        </table>
      </div> -->


    <!--Mobile View-->
    <div class="records my-0 pt-0 mx-0 px-2">
      <div
        *ngFor="
            let employee of employees | slice: lowValue:highValue;
            let i = index
          "
        [hidden]="!employees.length"
        class="card mt-0 mb-2 p-2 shadow text-primary d-block d-md-none"
      >
        <app-employee-details
          (emitBtn1)="activateEmployee($event.employeeId)"
          [employeeDetail]="employee"
          [isModal]="false"
          btnValue1="Activate"
          showButtons="true"
        >
        </app-employee-details>
      </div>
    </div>
  </div>
</div>

