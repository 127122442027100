import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import {
  EmployeeData,
  EmployeeToken,
  EpmloyeeProfile,
} from "src/app/modules/shared/models/employee";
import { AdminService } from "src/app/modules/admin/services/admin.service";
import { AuthService } from "src/app/modules/auth/services/auth.service";
import { formatFormFields, showToasterMessage } from "../../../utils/functions";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { ProfilesService } from "../../../services/profiles.service";
import { AngularFireStorage } from "@angular/fire/storage";
import { CustomValidator } from "../../../validators/custom-validator";
import { finalize } from "rxjs/operators";
import { LoaderService } from "../../../services/loader.service";

@Component({
  selector: "app-edit-branch",
  templateUrl: "./profile-editor.component.html",
  styleUrls: ["./profile-editor.component.scss"],
})
export class ProfileEditorComponent implements OnInit {
  loading = false;
  User: EmployeeToken = this.authService.loggedInUserInfo();
  profileForm: FormGroup;
  profile: EmployeeData;
  nextOfKins: Array<any>;
  serviceErrors: any;
  fieldType: any;
  totalItems: number;
  pageSize = 1;
  currentPage = 1;
  gender = ["MALE", "FEMALE"];
  idTypes = [
    { name: "NATIONAL ID", id: 1 },
    { name: "VILLAGE ID", id: 2 },
    { name: "PASSPORT", id: 3 },
  ];
  photoUrl: string;
  idPhotoUrl: string;

  constructor(
    private router: Router,
    private toaster: ToastrService,
    private authService: AuthService,
    private adminService: AdminService,
    public dialogRef: MatDialogRef<ProfileEditorComponent>,
    private spinner: NgxSpinnerService,
    private profilesService: ProfilesService,
    private activateRoute: ActivatedRoute,
    private storage: AngularFireStorage,
    private loaderService: LoaderService,
    @Inject(MAT_DIALOG_DATA)
    public data: EmployeeData
  ) {}

  get pval(): any {
    return this.profileForm.controls;
  }

  ngOnInit(): void {
    this.profileForm = this.createFormGroup();
    this.profile = this.data;
    this.setProfileValues(this.profile);
  }

  createFormGroup(): any {
    return new FormGroup({
      employeeName: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      employeePhone1: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          CustomValidator.patternValidator(
            /^(([0-9])([0-9])([0-9])([0-9])([0-9])([0-9])([0-9])([0-9])([0-9])([0-9]))$/,
            { hasNumber: true }
          ),
        ])
      ),
      employeePhone2: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          CustomValidator.patternValidator(
            /^(([0-9])([0-9])([0-9])([0-9])([0-9])([0-9])([0-9])([0-9])([0-9])([0-9]))$/,
            { hasNumber: true }
          ),
        ])
      ),
      employeeIdType: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      employeeIdPhotoUrl: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      employeeIdNumber: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      employeeGender: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      employeePhotoUrl: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      employeeHomeAreaDetails: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      employeeRecruitmentDate: new FormControl(""),
      employeeDateOfBirth: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      employmentNumber: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      jobTitle: new FormControl("", Validators.compose([Validators.required])),
      department: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
    });
  }

  toggleFieldType(): any {
    this.fieldType = !this.fieldType;
  }

  setProfileValues(profile: EmployeeData): void {
    this.pval.employeeName.setValue(profile.employeeName);
    this.pval.employeePhone1.setValue(profile.employeePhone1);
    this.pval.employeePhone2.setValue(profile.employeePhone2);
    this.pval.employeeIdType.setValue(profile.employeeIdType);
    this.pval.employeeIdNumber.setValue(profile.employeeIdNumber);
    this.pval.employeeGender.setValue(profile.employeeGender);
    this.pval.employeeHomeAreaDetails.setValue(profile.employeeHomeAreaDetails);
    this.pval.employeeDateOfBirth.setValue(
      new Date(profile.employeeDateOfBirth)
    );
    this.pval.employmentNumber.setValue(profile.employmentNumber);
    this.pval.jobTitle.setValue(profile.jobTitle);
    this.pval.department.setValue(profile.department);
    this.pval.employeeRecruitmentDate.setValue(
      new Date(profile.employeeRecruitmentDate)
    );
    if (profile.employeePhotoUrl) {
      this.photoUrl = this.profile.employeePhotoUrl;
      this.pval.employeePhotoUrl.setValidators([]);
    }
    if (profile.employeeIdPhotoUrl) {
      this.idPhotoUrl = this.profile.employeeIdPhotoUrl;
      this.pval.employeeIdPhotoUrl.setValidators([]);
    }
  }

  onFileSelected(event, propertyUrl): void {
    const file = event.target.files[0];
    const filePath = `Users/Employees/${this.User.employeeId + propertyUrl}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          let downloadURL = fileRef.getDownloadURL();
          downloadURL.subscribe((url) => {
            if (url) {
              this[propertyUrl] = url;
            }
          });
        })
      )
      .subscribe();
  }

  cancel(): void {
    this.profileForm.reset();
    this.dialogRef.close();
  }

  saveProfile(): void {
    if (!this.profileForm.valid) return;
    this.loaderService.isLoading.next(true);
    this.loading = true;
    setTimeout(() => {
      const data: EpmloyeeProfile = {
        ...formatFormFields(this.profileForm.value),
        employeeIdNumber: this.profileForm.controls.employeeIdNumber.value,
        employeeId: this.User.employeeId,
        employeePhotoUrl: this.photoUrl,
        employeeIdPhotoUrl: this.idPhotoUrl,
      };

      this.profilesService.postEditEmployeeProfile(data).subscribe(
        async (res) => {
          this.loading = false;
          this.profileForm.reset();
          this.dialogRef.close({ ...this.profile, ...data });
          showToasterMessage(
            this.toaster,
            "success",
            "Profile was updated successfully"
          );
        },
        (error) => {
          this.loading = false;
          showToasterMessage(this.toaster, "warning", error);
        }
      );
    }, 4000);
  }
}
