<div class="mx-auto">
  <div class="">
    <h4 class="text-center text-info text-uppercase py-0 px-3">
      Edit Project
    </h4>
  </div>
  <div class="mx-auto w-75 my-3">
    <form (ngSubmit)="createEditBranch()" [formGroup]="userForm">
      <div class="form-group text-primary">
        <label
          [ngClass]="fval.branchType.valid ? 'text-info' : ''"
          for="branchType"
        >Project Type<i *ngIf="fval.branchType.valid" class="material-icons"
        >check_circle</i
        ></label
        >
        <div class="input-block mb-0">
          <select
            [ngClass]="{
                'is-invalid':
                  (fval.branchType.touched || fval.branchType.dirty) &&
                  fval.branchType.errors
              }"
            autocomplete="off"
            class="form-control shadow"
            formControlName="branchType"
            id="branchType"
            required
            type="text"
          >
            <option
              class="place-holder-select"
              disabled
              hidden
              selected
              value=""
            >
              Select a Type
            </option>
            <option *ngFor="let type of branchTypes" [value]="type.id">
              {{ type.name }}
            </option>
          </select>
        </div>
        <small
          *ngIf="
              (fval.branchType.touched || fval.branchType.dirty) &&
              fval.branchType.errors?.required
            "
          class="text-danger col"
        >
          The project type is required
        </small>
      </div>
      <div class="form-group text-primary">
        <label
          [ngClass]="fval.branchName.valid ? 'text-info' : ''"
          for="branchName"
        >Project Name<i *ngIf="fval.branchName.valid" class="material-icons"
        >check_circle</i
        ></label
        >
        <div class="input-block mb-0">
          <input
            [ngClass]="{
                'is-invalid':
                  (fval.branchName.touched || fval.branchName.dirty) &&
                  fval.branchName.errors
              }"
            autocomplete="off"
            class="form-control shadow"
            formControlName="branchName"
            id="branchName"
            placeholder="Enter branch name"
            required
            type="text"
          />
          <i class="start fas fa-building text-primary mx-auto"></i>
        </div>
        <small
          *ngIf="
              (fval.branchName.touched || fval.branchName.dirty) &&
              fval.branchName.errors?.required
            "
          class="text-danger col"
        >
          The project name is required
        </small>
      </div>
      <div class="d-flex mt-1 mb-2 pt-0 pb-2 mx-auto">
        <button
          [disabled]="!userForm.valid || loading"
          class="btn btn-primary col-5 mx-auto shadow"
          type="submit"
        >
          Save
        </button>
        <button (click)="closeEditorDialog()" class="btn btn-danger col-5 mx-auto shadow" type="button">
          Cancel
        </button>
      </div>
    </form>
  </div>
</div>
