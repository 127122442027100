<div class="d-flex flex-row">
  <div class="container">
    <div class="components row mt-2 pt-0">
      <div class="col-12 col-xl-6">
        <div class="tickets-card2 card2 card shadow mx-0 mx-md-1 mb-2">
          <div
            class="card-header d-flex justify-content-between align-items-center pt-1 pb-2"
          >
            <h5
              class="text-info text-uppercase pull-right font-weight-bold m-0"
            >
              <hr class="top mt-1 mb-0" />
              APPOINTMENTS
              <hr class="bottom my-0" />
            </h5>
          </div>
          <div class="card-body mx-0 px-1 pt-1 pb-0">
            <!--record container-->
            <!--to be displayed if no rfqs to work on-->
            <!-- <div class="invest-card card my-2 py-2 mx-auto px-2 shadow-lg">
              <div class="col-12 my-5 py-5 justify-content-between">
                <h4 class="text-center text-info">No Appointments made yet</h4>
              </div>
            </div> -->
            <div class="invest-card card mt-1 pt-2 pb-0 mb-2 mx-1 px-2 shadow-lg">
              <div class="iteration">
                <div class="item-container-1 mt-1">
                  <div
                    class="list-card card mt-1 pt-2 mb-0 mx-auto px-0 pb-1 shadow-lg"
                  >
                    <div class="list-card-body card-body my-0 py-0 mx-0 px-3">
                      <div class="row my-0 pt-md-0 pb-md-1 mx-md-0 px-md-0">
                        <div
                          class="col-1 px-0 mx-md-0 d-flex justify-content-start"
                        >
                          <div class="dropdown">
                            <button
                              class="btn btn-outline-primary dropdown-toggle"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              tooltip="Options"
                            >
                              <i class="fas fa-ellipsis-v fa-2x text-info"></i>
                            </button>
                            <div
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a class="dropdown-item text-primo pointerCursor"
                                ><i class="fas fa-edit"></i>
                                Edit
                              </a>
                              <a class="dropdown-item text-primo pointerCursor"
                                ><i class="fas fa-save"></i>
                                Save
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="col-11 mx-lg-0 px-2 px-md-0">
                          <div class="row mx-md-0 px-md-0">
                            <div
                              class="col-12 col-md-6 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-3 col-md-2 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Name:</span>
                                </div>
                                <div
                                  class="col-9 col-md-10 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated">Tumwesigye </span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-6 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-3 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Office:</span>
                                </div>
                                <div
                                  class="col-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="">Finance</span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-6 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-4 col-md-3 col-lg-2 col-xl-3 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Reason:</span>
                                </div>
                                <div
                                  class="col-8 col-md-9 col-lg-10 col-xl-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"
                                    >Payment follow up
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-6 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-3 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Time:</span>
                                </div>
                                <div
                                  class="col-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"
                                    >Wed,11/05/2020, 09:00hrs
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-tools pt-2 px-auto">
                <ul class="pagination pagination-sm text-info align-items-center">
                  <li class="page-item">
                    <a href="#" class="page-link">&laquo;</a>
                  </li>
                  <li class="page-item">
                    <a href="#" class="page-link">1</a>
                  </li>
                  <li class="page-item">
                    <a href="#" class="page-link">2</a>
                  </li>
                  <li class="page-item">
                    <a href="#" class="page-link">3</a>
                  </li>
                  <li class="page-item">
                    <a href="#" class="page-link">&raquo;</a>
                  </li>
                </ul>
              </div>
            </div>
            <!--/ end of record container-->
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <div class="tickets-card2 card2 card shadow mx-0 mx-md-1 mb-2">
          <div
            class="card-header d-flex justify-content-between align-items-center pt-1 pb-2"
          >
            <h5
              class="text-info text-uppercase pull-right font-weight-bold m-0"
            >
              <hr class="top mt-1 mb-0" />
              created clients
              <hr class="bottom mt-0 mb-0" />
            </h5>
          </div>
          <div class="card-body mx-0 px-1 pt-1 pb-0">

                        <!--record container-->
            <!--to be displayed if no rfqs to work on-->
            <!-- <div class="invest-card card my-2 py-2 mx-auto px-2 shadow-lg">
              <div class="col-12 my-5 py-5 justify-content-between">
                <h4 class="text-center text-info">No Appointments made yet</h4>
              </div>
            </div> -->
            <div class="invest-card card mt-1 pt-2 pb-0 mb-2 mx-1 px-2 shadow-lg">
              <div class="iteration">
                <div class="item-container-1 mt-1">
                  <div
                    class="list-card card mt-1 pt-2 mb-0 mx-auto px-1 pb-1 shadow-lg"
                  >
                    <div class="list-card-body card-body my-0 py-0 mx-0 px-4 px-md-3">
                      <div class="row my-0 pt-md-0 pb-md-1 mx-md-0 px-md-0">
                        <div
                          class="col-1 px-0 mx-md-0 d-flex justify-content-start"
                        >
                                  <span class="">1</span>
                        </div>
                        <div class="col-11 mx-0 px-0">
                          <div class="row mx-md-0 px-md-0">
                            <div
                              class="col-12 col-md-6 col-xl-5 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-3 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">ID:</span>
                                </div>
                                <div
                                  class="col-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated">bmc0322225</span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-6 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-3 col-md-2 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Client:</span>
                                </div>
                                <div
                                  class="col-9 col-md-10 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="">Rcihard ssebabbiligila kakwezi</span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-6 col-xl-5 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-4 col-md-3 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Phone:</span>
                                </div>
                                <div
                                  class="col-8 col-md-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"
                                    >0755555555
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-6 col-xl-7 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-3 col-md-2 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Email:</span>
                                </div>
                                <div
                                  class="col-9 col-xl-10 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"
                                    >richard.ssebabikakwe@gmail.com
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-tools pt-2 px-auto">
                <ul class="pagination pagination-sm text-info align-items-center">
                  <li class="page-item">
                    <a href="#" class="page-link">&laquo;</a>
                  </li>
                  <li class="page-item">
                    <a href="#" class="page-link">1</a>
                  </li>
                  <li class="page-item">
                    <a href="#" class="page-link">2</a>
                  </li>
                  <li class="page-item">
                    <a href="#" class="page-link">3</a>
                  </li>
                  <li class="page-item">
                    <a href="#" class="page-link">&raquo;</a>
                  </li>
                </ul>
              </div>
            </div>
            <!--/ end of record container-->
          </div>
        </div>
      </div>
    </div>
      <div class="row mt-2 pt-0">
        <div class="col-12">
          <div class="tickets-card2 card1 card shadow mx-0 mx-md-1 mb-4">
            <div
              class="card-header d-flex justify-content-between align-items-center pt-1 pb-2"
            >
              <h5 class="text-info text-uppercase font-weight-bold m-0">
                <hr class="top mt-1 mb-0" />
                rfqs prepared
                <hr class="bottom mt-0 mb-0" />
              </h5>
            </div>
          <div class="card-body mx-0 px-1 pt-1 pb-0">

                        <!--record container-->
            <!--to be displayed if no rfqs to work on-->
            <!-- <div class="invest-card card my-2 py-2 mx-auto px-2 shadow-lg">
              <div class="col-12 my-5 py-5 justify-content-between">
                <h4 class="text-center text-info">No Appointments made yet</h4>
              </div>
            </div> -->
            <div class="invest-card card mt-1 pt-2 pb-0 mb-3 mx-1 px-2 shadow-lg">
              <div class="iteration">
                <div class="item-container-1 mt-1">
                  <div
                    class="list-card card mt-1 pt-2 mb-0 mx-auto px-0 pb-1 shadow-lg" *ngFor="let rfq of rfqTable; let i = index"
                  >
                    <div class="list-card-body card-body my-0 py-0 mx-0 px-4 px-md-3">
                      <div class="row my-0 pt-md-0 pb-md-1 mx-md-0 px-md-0">
                        <div
                          class="col-1 px-0 mx-md-0 d-flex justify-content-start"
                        >
                                  <span class="">{{ i + 1 }}</span>
                        </div>
                        <div class="col-11 mx-0 px-0">
                          <div class="row mx-md-0 px-md-0">
                            <div
                              class="col-12 col-md-6 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-5 col-md-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Serial No:</span>
                                </div>
                                <div
                                  class="col-7 col-md-8 col-lg-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated">{{ rfq.rfqId }}</span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-6 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-3 col-lg-2 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Client:</span>
                                </div>
                                <div
                                  class="col-9 col-lg-10 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="">{{ rfq.clientName }}</span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-6 col-lg-4 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-5 col-md-4 col-xl-3 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Source:</span>
                                </div>
                                <div
                                  class="col-7 col-md-8 col-xl-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"
                                    >{{ rfq.rfqSource }}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-6 col-lg-4 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-5 col-md-4 col-lg-5 col-xl-4 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">No Of Items:</span>
                                </div>
                                <div
                                  class="col-7 col-md-8 col-lg-7 col-xl-8 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"
                                    >{{ rfq.noOfItems }}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 col-md-6 col-lg-4 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-5 col-md-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Amount:</span>
                                </div>
                                <div
                                  class="col-7 col-md-8 col-lg-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"
                                    >{{ rfq.totalAmount }}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <!-- <div
                              class="col-12 col-md-6 mx-md-0 px-md-0"
                            >
                              <div class="row">
                                <div
                                  class="col-4 col-lg-3 d-flex justify-content-start mx-md-0 px-md-0"
                                >
                                  <span class="">Department:</span>
                                </div>
                                <div
                                  class="col-8 col-lg-9 d-flex justify-content-start font-weight-bold mx-0 px-0"
                                >
                                  <span class="itemCreated"
                                    >{{ rfq.department }}
                                  </span>
                                </div>
                              </div>
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-tools pt-2 px-auto">
                <ul class="pagination pagination-sm text-info align-items-center">
                  <li class="page-item">
                    <a href="#" class="page-link">&laquo;</a>
                  </li>
                  <li class="page-item">
                    <a href="#" class="page-link">1</a>
                  </li>
                  <li class="page-item">
                    <a href="#" class="page-link">2</a>
                  </li>
                  <li class="page-item">
                    <a href="#" class="page-link">3</a>
                  </li>
                  <li class="page-item">
                    <a href="#" class="page-link">&raquo;</a>
                  </li>
                </ul>
              </div>
            </div>
            <!--/ end of record container-->

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
