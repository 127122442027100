<div class="card-body my-0 py-2">
            <div
              class="record-1 row d-flex flex-row justify-content-between pl-1 pr-0"
            >
              <div class="col-2 col-lg-1">
      <img
        [src]="employee.employeePhotoUrl || 'assets/img/man.svg'"
        alt="Employee Photo"
        class="rounded-circle border m-auto align-self-center"
        height="60"
        width="60"
      />
    </div>
              <div class="col-10 col-md-6 col-lg-4 px-2 px-lg-4">
                <div class="d-flex justify-content-end justify-content-md-start font-weight-bold">
                  <span class="">{{ employee.employeeName }}</span>
                </div>
                <div class="d-flex justify-content-end justify-content-md-start">
                  <span class="text-nowrap">Name</span>
      </div>
    </div>
              <div class="col-6 col-md-4 col-lg-3 mt-2 mt-md-0 px-2">
                <div class="d-flex justify-content-start justify-content-md-end justify-content-lg-center font-weight-bold">
                  <span class="">{{ employee.employeeId }}</span>
                </div>
                <div class="d-flex justify-content-start justify-content-md-end justify-content-lg-center">
        <span class="text-nowrap">Employee ID</span>
      </div>
    </div>
              <div class="col-6 col-md-4 mt-2 mt-lg-0 px-2">
                <div
                  class="d-flex justify-content-end justify-content-md-start justify-content-lg-end font-weight-bold"
                >
                <span class="">{{ employee.roleName | removeDashes }}</span>
              </div>
              <div
              class="d-flex justify-content-end justify-content-md-start justify-content-lg-end"
              >
              <span class="text-nowrap">Role</span>
      </div>
    </div>
              <div class="col-6 col-md-4 mt-md-2 px-2">
                <div
                  class="d-flex justify-content-start font-weight-bold"
                >
                <span class="">{{ employee.jobTitle }}</span>
              </div>
              <div
                  class="d-flex justify-content-start"
                  >
                  <span class="text-nowrap">Job Title</span>
                </div>
    </div>
              <div class="col-6 col-md-4 mt-md-2 px-2">
                <div
                  class="d-flex justify-content-end justify-content-lg-center font-weight-bold"
                >
                <span class="">{{ employee.branchName }}</span>
              </div>
              <div
              class="d-flex justify-content-end justify-content-lg-center"
              >
              <span class="text-nowrap">Branch</span>
      </div>
    </div>
              <div class="col-6 col-md-4 mt-lg-2 px-2">
                <div
                  class="d-flex justify-content-start justify-content-lg-end font-weight-bold"
                >
                <span class="">{{ employee.department }}</span>
              </div>
              <div
              class="d-flex justify-content-start justify-content-lg-end"
              >
              <span class="text-nowrap">Department</span>
      </div>
    </div>
              <div class="col-6 col-md-4 px-2">
                <div
                  class="d-flex justify-content-end justify-content-md-start font-weight-bold"
                >
                <span class="">{{ employee.employeeEmail }}</span>
              </div>
              <div class="d-flex justify-content-end justify-content-md-start">
                  <span class="text-nowrap">Email</span>
      </div>
    </div>
              <div class="col-6 col-md-4 px-2">
                <div
                  class="d-flex justify-content-start justify-content-md-end justify-content-lg-center font-weight-bold"
                >
                <span class="">{{ employee.employeePhone1 }}</span>
              </div>
              <div
              class="d-flex justify-content-start justify-content-md-end justify-content-lg-center"
              >
              <span class="text-nowrap">Phone No. 1</span>
      </div>
    </div>
    <div
      *ngIf="employee.employeePhone1 !== employee.employeePhone2"
      class="col-6 col-md-4 px-2"
    >
                <div
                  class="d-flex justify-content-end justify-content-md-start justify-content-lg-end font-weight-bold"
                >
                <span class="">{{ employee.employeePhone2 }}</span>
              </div>
              <div
              class="d-flex justify-content-end justify-content-md-start justify-content-lg-end"
              >
              <span class="text-nowrap">Phone No. 2</span>
      </div>
    </div>
              <div class="col-6 col-md-4 px-2">
                <div
                  class="d-flex justify-content-start justify-content-md-center justify-content-lg-start font-weight-bold"
                >
                <span class="">{{ employee.employeeIdNumber }}</span>
              </div>
              <div
              class="d-flex justify-content-start justify-content-md-center justify-content-lg-start"
              >
              <span class="text-nowrap">ID Number</span>
      </div>
    </div>
              <div class="col-6 col-md-4 px-2">
                <div
                  class="d-flex justify-content-end justify-content-lg-center font-weight-bold"
                >
                <span class="">{{ employee.employeeHomeAreaDetails }}</span>
              </div>
              <div
              class="d-flex justify-content-end justify-content-lg-center"
              >
              <span class="text-nowrap">Home Area</span>
      </div>
    </div>
    <div *ngIf="showButtons || !isModal">
      <div class="d-flex flex-row justify-content-between mt-2 mx-5 px-2">
        <button
          (click)="emitButton1()"
          *ngIf="btnValue1"
          [tooltip]="btnValue1"
          class="btn btn-outline-info mt-1 mt-md-0 shadow"
        >
          <i class="fas fa-edit"></i> {{ btnValue1 }}
        </button>
        <button
          (click)="emitButton2()"
          *ngIf="btnValue2"
          [tooltip]="btnValue2"
          class="btn btn-outline-info mt-1 mt-md-0 shadow ml-1"
        >
          <i class="fas fa-user-cog"></i> {{ btnValue2 }}
        </button>
      </div>
    </div>
    <div *ngIf="data?.isModal">
      <div class="d-flex justify-content-end mt-3">
        <button
          (click)="dialogRef.close()"
          class="btn btn-primary mt-md-0 w-100"
          tooltip="close modal"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
