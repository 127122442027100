import { environment } from "../../../../environments/environment";

export const API = environment.apiUrl + "/api/";
export const ACCESS_TOKEN = "ACCESS TOKEN";
export const REFRESH_TOKEN = "REFRESH TOKEN";
export const USER_TOKEN = "USER TOKEN";
export const COMPANY_TOKEN = "USER TOKEN";

export const API_URLS = {
  AUTH: {
    EMPLOYEE: {
      GET_BRANCHES: API + "auth/branch",
      REGISTER: API + "employee/registerEmployee",
      LOGIN: API + "employee/loginemployee",
      GET_PASSWORD_CHANGE_CODE: API + "employee/postPasswordChangeCode",
      POST_CHANGE_PASSWORD: API + "employee/postChangePassword",
      REFRESH_TOKEN: API + "employee/employeeRefreshingToken",
      GET_EMPLOYEE_ROLE: API + "employee/getRole",
    },
    CUSTOMER: {
      REGISTER: API + "customer/registerCustomer",
      LOGIN: API + "customer/logincustomer",
      GET_PASSWORD_CHANGE_CODE: API + "customer/postCustomerPasswordChangeCode",
      POST_CHANGE_PASSWORD: API + "customer/postCustomerChangePassword",
      REFRESH_TOKEN: API + "customer/customerRefreshingToken",
    },
    SUPPLIER: {
      REGISTER: API + "supplier/registerSupplier",
      LOGIN: API + "supplier/loginSupplier",
      GET_PASSWORD_CHANGE_CODE: API + "supplier/postSupplierPasswordChangeCode",
      POST_CHANGE_PASSWORD: API + "supplier/postSupplierChangePassword",
      REFRESH_TOKEN: API + "supplier/supplierRefreshingToken",
    },
  },
  USERS: {
    EMPLOYEE: {
      EDIT_PROFILE: API + "employee/editemployeeDetails",
      EDIT_EMAIL: API + "employee/editemployeeEmail",
      ADD_NEXT_OF_KIN: API + "employee/addEmployeeNextOfKin",
      GET_NEXT_OF_KIN: API + "employee/getTheEmployeeNextOfKin", //?employeeId=10039
      EDIT_NEXT_OF_KIN: API + "employee/editTheEmployeeNextOfKin",
      DELETE_NEXT_OF_KIN: API + "employee/deleteTheEmployeeNextOfKin", //?employeeNextOfKinId=7000
      DEACTIVATE: API + "employee/deactivateEmployee", //?employeeId=10041
      ACTIVATE: API + "employee/activateEmployee/", //?employeeId=10041
      GET_DEACTIVATED: API + "employee/getAllDeactivatedEmployees",
      GET_ACTIVE: API + "employee/getActiveEmployee", //?employeeId=10041
      GET_ALL_ACTIVE: API + "employee/getAllActiveEmployees",
      GET_LOGGED_IN_STATUS: API + "employee/getLoggInStatus",
      GET_FOR_APPROVAL: API + "employee/getemployeesForApproval",
      APPROVE: API + "employee/approveEmployee",
      REJECT: API + "employee/rejectemployeeApproval",
      TO_VERIFY_EMAIL: API + "employee/getEmployeesRequiringEmailVerification",
      VERIFY_EMAIL: API + "employee/verifyEmail", //?employeeEmail="katumercy@gmial.com"
      GET_LOGGED_IN: API + "employee/getLoggedInemployees",
      LOGOUT: API + "employee/logOutemployee/", //?employeeId=10036
    },
    CUSTOMER: {
      CREATE: API + "customer/createCustomer",
      GET_CREATED_FOR_APPROVAL: API + "customer/getCreatedCustomersForApproval",
      APPROVE_CREATED: API + "customer/approveCreatedCustomers",
      REJECT_CREATED: API + "customer/rejectCreatedCustomerApproval",

      GET_REGISTERED_FOR_APPROVAL:
        API + "customer/getRegisteredCustomersForApproval",
      APPROVE_REGISTERED: API + "customer/approveRegisteredCustomers",
      REJECT_REGISTERED: API + "customer/rejectRegisteredCustomersApproval",

      SECTORS: {
        GET_SECTORS: API + "customer/getAllCustomerSectors",
        CREATE_SECTOR: API + "customer/createCustomerSector",
        GET_SECTORS_FOR_APPROVAL:
          API + "customer/getCustomerSectorsForApproval",
        APPROVE_SECTOR: API + "customer/approveCustomerSector",
        REJECT_SECTOR: API + "customer/rejectCustomerSector",
        GET_REJECTED: API + "customer/getAllRejectedCustomerSectors",
        UPDATE_SECTOR: API + "customer/updateCustomerSector",
        GET_UPDATED_SECTORS_FOR_APPROVAL:
          API + "customer/getUpdatedCustomerSectorsForApproval",
        APPROVE_UPDATED_SECTOR: API + "customer/approveUpdatedCustomerSector",
      },

      GET_LOGGED_IN: API + "customer/getLoggedIncustomers",
      LOGOUT: API + "customer/logOutcustomer", //?customerId=40005
      GET_LOGGED_OUT: API + "customer/getLoggedOutcustomers",
      GET_LOGGED_IN_STATUS: API + "customer/getCustomerLoggInStatus",
      GET_ALL_ACTIVE: API + "customer/getAllActiveCustomers",
      GET_ALL: API + "customer/getAllcustomers",
      GET_ACTIVE: API + "customer/getActiveCustomer", //?id=40008
      UPDATE_BY_CUSTOMER: API + "customer/updateCustomerDetailsByCustomer",
      UPDATE_BY_EMPLOYEE: API + "customer/updateCustomerDetailsByEmployee",
      GET_UPDATED_FOR_APPROVAL: API + "customer/getUpdatedCustomersForApproval",
      APPROVE_UPDATED: API + "customer/approveUpdatedCustomers",
      EDIT_EMAIL: API + "customer/editcustomerEmailByCustomer",
      EDIT_EMAIL_BY_EMPLOYEE: API + "customer/editcustomerEmailByEmployee",
      ADD_SHIPPING_ADDRESS: API + "customer/addCustomerShippingAddress",
      EDIT_SHIPPING_ADDRESS: API + "customer/editTheCustomerShippingAddress",
      DELETE_SHIPPING_ADDRESS: API + "customer/deleteTheCustomerShippinAdress", //?customerShippinAdressId=50003
      ADD_CONTACT_PERSON: API + "customer/addCustomerContactPersons",
      EDIT_CONTACT_PERSON: API + "customer/editTheCustomerContactPerson",
      DELETE_CONTACT_PERSON: (id) =>
        API +
        `customer/deleteTheCustomerContactPerson/?customerContactPersonsId=${id}`,
      GET_CONTACT_PERSONS: API + "customer/getTheCustomerContactPersons", //?customerId=40007
      GET_SHIPPING_ADDRESS: (id) =>
        API + `customer/getTheCustomerShippingAddress/?customerId=${id}`,
      DEACTIVATE: API + "customer/deactivateCustomer", //?customerId=40007
      GET_DEACTIVATED: API + "customer/getAllDeactivatedCustomers",
      ACTIVATE: API + "customer/activateCustomer", //?customerId=40007
    },
    SUPPLIER: {
      CREATE: API + "supplier/createSupplier",
      GET_REGISTERED_FOR_APPROVAL:
        API + "supplier/getRegisteredSuppliersForApproval",
      GET_CREATED_FOR_APPROVAL: API + "supplier/getCreatedSuppliersForApproval",
      APPROVE_REGISTERED: API + "supplier/approveRegisteredSuppliers",
      APPROVE_CREATED: API + "supplier/approveCreatedSuppliers",
      REJECT_REGISTERED: API + "supplier/rejectRegisteredSuppliersApproval",
      REJECT_CREATED: API + "supplier/rejectCreatedSupplierApproval",
      GET_LOGGED_IN: API + "supplier/getLoggedInsuppliers",

      LOGOUT: API + "supplier/logOutsupplier", //?supplierId=40005
      GET_LOGGED_OUT: API + "supplier/getLoggedOutsuppliers",
      GET_LOGGED_IN_STATUS: API + "supplier/getSupplierLoggInStatus",

      GET_ALL_ACTIVE: API + "supplier/getAllActiveSuppliers",
      GET_ALL: API + "supplier/getAllsuppliers",
      GET_ACTIVE: API + "supplier/getActiveSupplier", //?id=40021

      UPDATE: API + "supplier/updateSupplierDetailsBySupplier",
      UPDATE_BY_EMPLOYEE: API + "supplier/updateSupplierDetailsByEmployee",
      GET_UPDATED_FOR_APPROVAL: API + "supplier/getUpdatedSuppliersForApproval",
      APPROVE_UPDATED: API + "supplier/approveUpdatedSuppliers",

      EDIT_SUPPLIER_EMAIL: API + "supplier/editsupplierEmailBySupplier",
      EDIT_SUPPLIER_EMAIL_BY_EMPLOYEE:
        API + "supplier/editsupplierEmailByEmployee",
      ADD_SHIPPING_ADDRESS: API + "customer/addSupplierShippingAddress",
      EDIT_SHIPPING_ADDRESS: API + "customer/editTheSupplierShippingAddress",
      DELETE_SHIPPING_ADDRESS: API + "customer/deleteTheSupplierShippinAdress", //?suppliershippingadressId=50003
      ADD_CONTACT_PERSON: API + "customer/addSupplierContactPersons",
      EDIT_CONTACT_PERSON: API + "customer/editTheSupplierContactPerson",
      DELETE_CONTACT_PERSON: API + "customer/deleteTheSupplierContactPerson", //?supplierContactPersonsId=60006
      GET_CONTACT_PERSONS: API + "customer/getTheSupplierContactPersons", //?supplierId=40007
      GET_SHIPPING_ADDRESS: (id) =>
        API + `customer/getTheSupplierShippingAddress/?supplierId=${id}`,
      DEACTIVATE: API + "customer/deactivateSupplier", //?supplierId=40007
      ACTIVATE: API + "customer/activateSupplier", //?supplierId=40007
      GET_DEACTIVATED: API + "customer/getAllDeactivatedSuppliers",
    },
  },
  ADMIN: {
    GET_CREATE_APPROVED:
      API + "systemAdminUser/getItemsRequiringApprovalCreate",
    GET_UPDATE_APPROVED:
      API + "systemAdminUser/getItemsRequiringApprovalUpdate",
    SET_CREATE_APPROVED:
      API + "systemAdminUser/updateItemsRequiringApprovalCreate",
    SET_UPDATE_APPROVED:
      API + "systemAdminUser/updateItemsRequiringApprovalUpdate",
    SETUP_COMPANY: API + "systemAdminUser/setUpCompany",
    GET_COMPANY: API + "systemAdminUser/getTheCompanyDetails",
    CREATE_BRANCH: API + "systemAdminUser/createBranch",
    GET_ALL_BRANCHES: API + "systemAdminUser/getAllBranches",
    GET_BRANCH: API + "systemAdminUser/getAllBranches",
    UPDATE_BRANCH: API + "systemAdminUser/updateBranch",
  },
  ACCOUNTS: {
    GET_ALL_CURRENCIES: API + "accounts/getAllCurrencies",
    CREATE_CURRENCY_USED: API + "accounts/createCurrenciesUsed",
    GET_CURRENCY_USED_FOR_APPROVAL: API + "accounts/getCurrencyUsedForApproval",
    PUT_APPROVED_CURRENCY: API + "accounts/approveCurrencyUsed",
    PUT_REJECT_CURRENCY_USED: API + "accounts/rejectCurrencyUsedApproval",
    GET_CURRENCIES_USED: API + "accounts/getCurrencyUsed",
    PUT_DELETE_CURRENCY: (currencyUsedId) =>
      API + `accounts/deleteCurrencyUsed?currencyUsedId=${currencyUsedId}`,

    GET_FOUNDATION_ACCOUNT_DETAILS:
      API + "accounts/getFoundationalAccountsDetails",
    POST_LOWER_BOUND_OF_PARTITION_RANGE:
      API + "accounts/lowerBoundOfPartitionRange",
    CREATE_MAJOR_ACCOUNT: API + "createMajorAccountHeader",
    GET_MAJOR_ACCOUNT_HEADERS: API + "accounts/getMajorAccountHeaders",
    UPDATE_MAJOR_ACCOUNT_HEADER: API + "accounts/updateMajorAccountHeaders",
  },
};
