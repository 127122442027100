import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-view-process",
  templateUrl: "./view-process.component.html",
  styleUrls: ["./view-process.component.scss"],
})
export class ViewProcessComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
