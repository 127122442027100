import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, of } from "rxjs";
import { AdminService } from "../services/admin.service";
import { catchError, map } from "rxjs/operators";
import { AuthService } from "../../auth/services/auth.service";
import { EmployeeData, EmployeeRole } from "../../shared/models/employee";

export interface EmployeeResolvedData {
  users: Array<EmployeeData>;
  roles: Array<EmployeeRole>;
}

@Injectable({
  providedIn: "root",
})
export class AllBranchesResolver implements Resolve<Observable<any>> {
  constructor(
    private adminService: AdminService,
    private authService: AuthService
  ) {}

  resolve(): Observable<any> {
    return this.adminService.getAllBranches().pipe(
      map((details) => ({ details })),
      catchError((error) => {
        return of({ details: null, error: error });
      })
    );
  }
}
