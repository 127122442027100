import { EmployeeData, EmployeeToken } from "../models/employee";
import { ToastrService } from "ngx-toastr";
import * as moment from "moment";
import { Branch } from "../models/user-auth";
import { CustomerSector } from "../models/customer";
import { ApprovalItem } from "../models/admin";

export const checkUserType = (userDetails: EmployeeToken | any) => {
  if (!userDetails) return "none";
  if (userDetails?.employeeEmail) return "employee";
  if (userDetails?.customerEmail) return "customer";
  if (userDetails?.supplierEmail) return "supplier";
};

export const showToasterMessage = (
  toaster: ToastrService,
  type,
  message: string,
  title = ""
) => {
  type === "success" &&
    toaster.success(message, title, {
      timeOut: 6000,
      positionClass: "toast-top-right",
    });
  type === "warning" &&
    toaster.warning(message, title, {
      timeOut: 6000,
      positionClass: "toast-top-right",
    });
};

export const log = (...values: any[]) => {
  values.forEach((value) => console.log(value));
};

export const formatFormFields = (formValue: Record<string, any>) => {
  const res: any = {};
  Object.entries(formValue).forEach(([k, v]) => {
    if (typeof v === "object") res[k] = formatFormFields(v);
    else if (typeof v === "number") res[k] = v;
    else if (typeof v === "string") {
      v = v.trim();
      if (k?.toLowerCase().indexOf("email") !== -1) res[k] = v;
      else if (k?.toLowerCase().indexOf("password") !== -1) res[k] = v;
      else if (k?.toLowerCase().indexOf("url") !== -1) res[k] = v;
      else if (k?.toLowerCase().indexOf("certificate") !== -1) res[k] = v;
      else if (k?.toLowerCase().indexOf("type") !== -1) res[k] = Number(v);
      else if (k?.toLowerCase().indexOf("compliant") !== -1) res[k] = Number(v);
      else if (k?.toLowerCase().indexOf("id") !== -1) res[k] = Number(v);
      else if (k?.toLowerCase().indexOf("date") !== -1)
        res[k] = moment(v).format("YYYY-MM-DD");
      else res[k] = v.toUpperCase();
    }
  });
  return res;
};

export const searchItems = (
  searchVal: string,
  items: any,
  itemType: string
) => {
  if (!searchVal) return [];
  if (itemType === "employees") {
    return items.filter(
      (item: EmployeeData) =>
        item.employeeId
          ?.toString()
          .toLowerCase()
          .indexOf(searchVal.toLowerCase()) !== -1 ||
        item.employeeName?.toLowerCase().indexOf(searchVal.toLowerCase()) !==
          -1 ||
        item.employeeEmail?.toLowerCase().indexOf(searchVal.toLowerCase()) !==
          -1 ||
        item.roleName?.toLowerCase().indexOf(searchVal.toLowerCase()) !== -1 ||
        item.jobTitle?.toLowerCase().indexOf(searchVal.toLowerCase()) !== -1
    );
  }
  if (itemType === "branches") {
    return items.filter(
      (item: Branch) =>
        item.branchId
          ?.toString()
          .toLowerCase()
          .indexOf(searchVal.toLowerCase()) !== -1 ||
        item.branchName?.toLowerCase().indexOf(searchVal.toLowerCase()) !==
          -1 ||
        (item.branchType === 1100 ? "HEADOFFICE" : "BRANCH")
          .toLowerCase()
          .indexOf(searchVal.toLowerCase()) !== -1
    );
  }
  if (itemType === "approvedItemsAdmin") {
    return items.filter(
      (item: ApprovalItem) =>
        item.itemName?.toLowerCase().indexOf(searchVal.toLowerCase()) !== -1
    );
  }
  if (itemType === "customer-sectors") {
    return items.filter(
      (item: CustomerSector) =>
        item.customerSectorId
          ?.toString()
          .toLowerCase()
          .indexOf(searchVal.toLowerCase()) !== -1 ||
        item.customerSectorName
          ?.toLowerCase()
          .indexOf(searchVal.toLowerCase()) !== -1 ||
        item.customerSectorDescription
          ?.toLowerCase()
          .indexOf(searchVal.toLowerCase()) !== -1
    );
  }
};
export const noRecords = (data: any): any => {
  if (data.length === 1)
    return data[0]?.theMessage?.toUpperCase() === "NO RECORDS FOUND";
};

interface CheckTokensArgs {
  userDetails: EmployeeToken | any;
  whichUser: string;
  router: any;
  DASHBOARD_ROUTE;
}

export const routeToDashboard = ({
  router,
  whichUser,
  userDetails,
  DASHBOARD_ROUTE,
}: CheckTokensArgs) => {
  if (whichUser === "supplier") {
    if (userDetails.supplierStatus === 2)
      router.navigate([DASHBOARD_ROUTE]).then();
  } else if (whichUser === "customer") {
    if (userDetails.customerStatus === 2)
      router.navigate([DASHBOARD_ROUTE]).then();
  } else {
    if (userDetails.employeeStatus === 2) {
      if (userDetails.roleId === 100) router.navigate(["/admin"]).then();
      if (userDetails.roleId === 200) router.navigate(["/admin"]).then();
      // router.navigate(["/front-desk"]).then();
      if (userDetails.roleId === 300) router.navigate(["/admin"]).then();
      // router.navigate(["/administration"]).then();
      if (userDetails.roleId === 400) router.navigate(["/admin"]).then();
      // router.navigate(["/finance"]).then();
      if (userDetails.roleId === 500) router.navigate(["/admin"]).then();
      // router.navigate(["/creative-and-design"]).then();
      if (userDetails.roleId === 600)
        router.navigate(["/quality-assurance"]).then();
      if (userDetails.roleId === 700) router.navigate(["/admin"]).then();
      // router.navigate(["/production"]).then();
      if (userDetails.roleId === 800) router.navigate(["/admin"]).then();
      // router.navigate(["/sales-market"]).then();
      if (userDetails.roleId === 900) router.navigate(["/admin"]).then();
      // router.navigate(["/transport-and-logistics"]).then();
      if (userDetails.roleId === 1000) router.navigate(["/admin"]).then();
      // router.navigate(["/management"]).then();
    }
  }
};

export const routeToLogins = (user: string, router: any) => {
  if (user === "employee") router.navigate(["auth/employee-login"]).then();
  if (user === "customer") router.navigate(["auth/customer-login"]).then();
  if (user === "supplier") router.navigate(["auth/supplier-login"]).then();
  if (user === "none") router.navigate(["auth/"]).then();
};
