<div class="d-flex flex-column">
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-lg-12">
        <div class="tickets-card2 card shadow-lg mt-2 mb-2 pt-0 pb-1">
          <div class="row">
            <div class="col-4 col-lg-5 pt-3 pl-4">
              <a
                class="btn btn-info btn-sm"
                role="button"
                routerLink="authpage/register-client"
                ><i class="fas fa-envelope text-white"></i>&nbsp;Email</a
              >
            </div>
            <div class="col-8 col-offset-lg-5 col-lg-7 pt-2 pl-4">
              <h5 class="text-info font-weight-bold pl-2">
                <hr class="top mt-1 mb-0" />
                LEAVE MANAGEMENT
                <hr class="bottom mt-1 mb-0" />
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="peek-cards row">
      <div class="rfq-col col-6 col-md-3 col-lg-3 mb-4">
        <div class="rfqs-card card shadow py-0">
          <div class="card-body">
            <div class="row align-items-center no-gutters">
              <div class="col mx-0">
                <div
                  class="text-uppercase text-info font-weight-bold text-xs mb-1"
                >
                  <span>TOTAL</span>
                </div>
                <div class="row">
                  <div class="col-auto">
                    <div class="text-secondary font-weight-bold h5 mb-0 pb-0">
                      <span class="pl-2 pb-0">{{
                        annualLeaveDays.totalDays
                      }}</span>
                      <span class="h6 text-monospace" style="font-size: 11px"
                        >&nbsp;DAYS</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-hourglass fa-2x text-danger"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="orders-col col-6 col-md-3 col-lg-3 mb-4">
        <div class="orders-card card shadow py-0">
          <div class="card-body ">
            <div class="row align-items-center no-gutters">
              <div class="col mr-0">
                <div
                  class="text-uppercase text-danger font-weight-bold text-xs mb-1"
                >
                  <span>TAKEN</span>
                </div>
                <div class="row">
                  <div class="col-auto">
                    <div class="text-secondary font-weight-bold h5 mb-0 pb-0">
                      <span class="pl-2 pb-0">{{
                        annualLeaveDays.takenDays
                      }}</span>
                      <span class="h6 text-monospace" style="font-size: 11px"
                        >&nbsp;DAYS</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-hourglass-start fa-2x text-info"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rfq-col col-6 col-md-3 col-lg-3 mb-4">
        <div class="rfqs-card card shadow py-0">
          <div class="card-body">
            <div class="row align-items-center no-gutters">
              <div class="col mx-0">
                <div
                  class="text-uppercase text-info font-weight-bold text-xs mb-1"
                >
                  <span>BOOKED</span>
                </div>
                <div class="row">
                  <div class="col-auto">
                    <div class="text-secondary font-weight-bold h5 mb-0 pb-0">
                      <span class="pl-2 pb-0">{{
                        annualLeaveDays.bookedDays
                      }}</span>
                      <span class="h6 text-monospace" style="font-size: 11px"
                        >&nbsp;DAYS</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-hourglass-half fa-2x text-danger"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="orders-col col-6 col-md-3 col-lg-3 mb-4">
        <div class="orders-card card shadow py-0">
          <div class="card-body">
            <div class="row align-items-center no-gutters">
              <div class="col mx-0">
                <div
                  class="text-uppercase text-danger font-weight-bold text-xs mb-1"
                >
                  <span>REMAINING</span>
                </div>
                <div class="row">
                  <div class="col-auto">
                    <div class="text-secondary font-weight-bold h5 mb-0 pb-0">
                      <span class="pl-2 pb-0">{{
                        annualLeaveDays.totalDays -
                          annualLeaveDays.takenDays -
                          annualLeaveDays.bookedDays
                      }}</span>
                      <span class="h6 text-monospace" style="font-size: 11px"
                        >&nbsp;DAYS</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-hourglass-half fa-2x text-info"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between align-self-center">
      <div
        class="form-card card shadow mt-2 mb-4 pt-2 d-flex flex-column col-offset-lg-6"
      >
        <div class="card-body p-3">
          <form
            [formGroup]="personalFormGroup"
            (ngSubmit)="form1()"
            #formone="ngForm"
          >
            <fieldset
              name="leaveRequest"
              class="border border-secondary px-4 py-4 mx-2 mt-2 mb-4"
            >
              <legend class="text-info pl-2">Leave Request</legend>
              <div class="row">
                <div class="col-xs-12 col-md-6">
                  <mat-form-field appearance="fill">
                    <mat-label for="userName">Name</mat-label>
                    <mat-icon matPrefix>person_outline</mat-icon>
                    <input
                      matInput
                      formControlName="userName"
                      placeholder="Enter your name"
                    />
                    <mat-error
                      *ngIf="
                        this.personalFormGroup
                          .get('userName')
                          .hasError('required')
                      "
                    >
                      Name is required
                    </mat-error>
                    <mat-error
                      *ngIf="
                        !this.personalFormGroup
                          .get('userName')
                          .hasError('required') &&
                        this.personalFormGroup
                          .get('userName')
                          .hasError('minLength')
                      "
                    >
                      Name should not be less than two characters
                    </mat-error>
                    <mat-error
                      *ngIf="
                        !this.personalFormGroup
                          .get('userName')
                          .hasError('required') &&
                        this.personalFormGroup
                          .get('userName')
                          .hasError('maxLength')
                      "
                    >
                      Name should have a maximum of 128 characters
                    </mat-error>
                    <mat-error
                      *ngIf="
                        !this.personalFormGroup
                          .get('userName')
                          .hasError('required') &&
                        this.personalFormGroup
                          .get('userName')
                          .hasError('hasCharacters')
                      "
                    >
                      Name should not include digits
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field appearance="fill">
                    <mat-label>Employee ID No.</mat-label>
                    <mat-icon matPrefix>supervised_user_circle</mat-icon>
                    <input
                      matInput
                      formControlName="employeeId"
                      placeholder="Enter employee ID number"
                    />
                    <mat-error
                      *ngIf="
                        this.personalFormGroup
                          .get('employeeId')
                          .hasError('required')
                      "
                      >Employee ID number is required
                    </mat-error>
                    <mat-error
                      *ngIf="
                        !this.personalFormGroup
                          .get('employeeId')
                          .hasError('required') &&
                        this.personalFormGroup
                          .get('employeeId')
                          .hasError('employeeIdCheck')
                      "
                      >Employee ID is invalid
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12 col-md-6">
                  <mat-form-field appearance="fill">
                    <mat-label>Job title</mat-label>
                    <mat-icon matPrefix>turned_in_not</mat-icon>
                    <input
                      matInput
                      formControlName="jobTitle"
                      placeholder="Enter job title"
                    />
                    <mat-error
                      *ngIf="
                        this.personalFormGroup
                          .get('jobTitle')
                          .hasError('required')
                      "
                      >Job title is required
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field appearance="fill">
                    <mat-label>Department</mat-label>
                    <mat-icon matPrefix>work_outline</mat-icon>
                    <mat-select
                      formControlName="department"
                      placeholder="Select department"
                    >
                      <mat-option
                        *ngFor="let dept of departments"
                        [value]="dept.value"
                      >
                        {{ dept.viewValue }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        this.personalFormGroup
                          .get('department')
                          .hasError('required')
                      "
                      >Department is required
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12 col-md-6">
                  <mat-form-field appearance="fill">
                    <mat-label>Supervisor Name</mat-label>
                    <mat-icon matPrefix>supervisor_account</mat-icon>
                    <input
                      matInput
                      formControlName="supervisor"
                      placeholder="Enter supervisor"
                    />
                    <mat-error
                      *ngIf="
                        this.personalFormGroup
                          .get('supervisor')
                          .hasError('required')
                      "
                      >Supervisor is required
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field appearance="fill">
                    <mat-label>Supervisor Title</mat-label>
                    <mat-icon matPrefix>turned_in_not</mat-icon>
                    <mat-select
                      formControlName="supervisorTitle"
                      placeholder="Select supervisor title"
                    >
                      <mat-option
                        *ngFor="let superTitle of supervisorTitles"
                        [value]="superTitle.value"
                      >
                        {{ superTitle.viewValue }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        this.personalFormGroup
                          .get('supervisorTitle')
                          .hasError('required')
                      "
                      >Supervisor title is required
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12 col-md-6">
                  <mat-form-field appearance="fill">
                    <mat-label>Phone number</mat-label>
                    <mat-icon matPrefix>phone_android</mat-icon>
                    <input
                      matInput
                      formControlName="phoneNumber"
                      placeholder="Enter phone number"
                    />
                    <mat-error
                      *ngIf="
                        this.personalFormGroup
                          .get('phoneNumber')
                          .hasError('required')
                      "
                      >Phone number is required
                    </mat-error>
                    <mat-error
                      *ngIf="
                        !this.personalFormGroup
                          .get('phoneNumber')
                          .hasError('required') &&
                        this.personalFormGroup
                          .get('phoneNumber')
                          .hasError('hasNumber')
                      "
                      >Phone number is invalid
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field appearance="fill">
                    <mat-label>Alternative Phone number</mat-label>
                    <mat-icon matPrefix>phone_android</mat-icon>
                    <input
                      matInput
                      formControlName="phoneNumber2"
                      placeholder="Enter phone number"
                    />
                    <!-- <mat-error
                          *ngIf="
                            this.personalFormGroup
                              .get('phoneNumber2')
                              .hasError('required')
                          "
                          >Phone number is required
                        </mat-error> -->
                    <mat-error
                      *ngIf="
                        !this.personalFormGroup
                          .get('phoneNumber2')
                          .hasError('required') &&
                        this.personalFormGroup
                          .get('phoneNumber2')
                          .hasError('hasNumber')
                      "
                      >Phone number is invalid
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12 col-md-6">
                  <mat-form-field appearance="fill">
                    <mat-label>Address</mat-label>
                    <mat-icon matPrefix>my_location</mat-icon>
                    <input
                      matInput
                      formControlName="address"
                      placeholder="Enter your address"
                    />
                    <mat-error
                      *ngIf="
                        this.personalFormGroup
                          .get('address')
                          .hasError('required')
                      "
                      >Address is required
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field appearance="fill">
                    <mat-label>Email</mat-label>
                    <mat-icon matPrefix>mail</mat-icon>
                    <input
                      matInput
                      formControlName="email"
                      placeholder="Enter your email address"
                    />
                    <mat-error
                      *ngIf="
                        this.personalFormGroup.get('email').hasError('email')
                      "
                      >Email is required
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12 col-md-4">
                  <mat-form-field appearance="fill">
                    <mat-label>Type of Leave</mat-label>
                    <mat-icon matPrefix>work_off</mat-icon>
                    <mat-select
                      formControlName="leaveType"
                      placeholder="Select type of leave"
                    >
                      <mat-option
                        *ngFor="let leave of leaveTypes; let i = index"
                        [value]="leave.value"
                        (click)="filterLeave(leave.value)"
                      >
                        {{ leave.viewValue }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        this.personalFormGroup
                          .get('leaveType')
                          .hasError('required')
                      "
                      >Type of leave is required
                    </mat-error>
                  </mat-form-field>
                  <div class="row" *ngIf="showLeaveDays">
                    <div class="col-xs-3 col-md-3 mx-0 px-0">
                      <mat-form-field appearance="fill">
                        <mat-label>Total</mat-label>
                        <input
                          matInput
                          formControlName="totalDays"
                          style="width: 100% !important"
                          [value]="annualLeaveDays.totalDays"
                          disabled
                        />
                        <mat-error
                          *ngIf="
                            this.personalFormGroup
                              .get('takenDays')
                              .hasError('required')
                          "
                          >Taken days are required
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-xs-3 col-md-3 mx-0 px-0">
                      <mat-form-field appearance="fill">
                        <mat-label>Taken</mat-label>
                        <input
                          matInput
                          formControlName="takenDays"
                          style="width: 100% !important"
                          [value]="annualLeaveDays.takenDays"
                          disabled
                        />
                        <mat-error
                          *ngIf="
                            this.personalFormGroup
                              .get('takenDays')
                              .hasError('required')
                          "
                          >Taken days are required
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-xs-3 col-md-3 mx-0 px-0">
                      <mat-form-field appearance="fill">
                        <mat-label>Requested</mat-label>
                        <input
                          matInput
                          formControlName="bookedDays"
                          style="width: 100% !important"
                          [value]="personalFormGroup.controls.bookedDays.value"
                        />
                        <mat-error
                          *ngIf="
                            this.personalFormGroup
                              .get('bookedDays')
                              .hasError('required')
                          "
                          >Requested days are required
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-xs-3 col-md-3 mx-0 px-0">
                      <mat-form-field appearance="fill">
                        <mat-label>Remaining</mat-label>
                        <input
                          matInput
                          formControlName="remainingDays"
                          style="width: 100% !important"
                          [value]="
                            annualLeaveDays.totalDays -
                            annualLeaveDays.takenDays -
                            personalFormGroup.controls.bookedDays.value
                          "
                        />
                        <mat-error
                          *ngIf="
                            this.personalFormGroup
                              .get('remainingDays')
                              .hasError('required')
                          "
                          >Remaining days are required
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-4">
                  <mat-form-field appearance="fill">
                    <mat-label>Start Date</mat-label>
                    <mat-icon matPrefix>hourglass_empty</mat-icon>
                    <input
                      matInput
                      formControlName="startDate"
                      [matDatepicker]="picker"
                      placeholder="Enter start date"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error
                      *ngIf="
                        this.personalFormGroup
                          .get('startDate')
                          .hasError('required')
                      "
                      >start date is required
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-4">
                  <mat-form-field appearance="fill">
                    <mat-label>End Date</mat-label>
                    <mat-icon matPrefix>hourglass_full</mat-icon>
                    <input
                      matInput
                      formControlName="endDate"
                      [matDatepicker]="picker2"
                      placeholder="Enter end date"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker2"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                    <mat-error
                      *ngIf="
                        this.personalFormGroup
                          .get('endDate')
                          .hasError('required')
                      "
                      >End date is required
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="d-flex mt-2 pt-1 pb-2 mx-auto">
                <button
                  type="button"
                  class="btn btn-secondary col-5 mx-auto"
                  (click)="formone.reset()"
                >
                  Reset
                </button>
                <button
                  class="btn btn-info col-5 mx-auto"
                  type="button"
                  (click)="formone.ngSubmit.emit()"
                >
                  Submit
                </button>
                <ngx-spinner
                  class="bg-warning bg-success"
                  size="medium"
                  type="ball-scale-multiple"
                >
                  <p class="text-light">Loading...</p>
                </ngx-spinner>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
    <!-- <footer class="main-footer"> -->
    <div class="container mt-3 pb-3">
      <div class="col-xs-12 col-lg-12">
        <div class="small text-center text-danger">
          Copyright &copy; 2020 - Brimasmedia Ltd
        </div>
      </div>
    </div>
    <!-- </footer> -->
  </div>
</div>
