import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-admin-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.scss"],
})
export class NotificationsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
