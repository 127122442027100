<header
  [attr.collapsed-left-header]="collapsedLeftHeader"
  [attr.device-type]="deviceType"
  [attr.effect-type]="navbarEffect"
  [attr.header-theme]="headerColorTheme"
  [attr.nav-layout]="navLayout"
  [attr.navbar-type]="toggleStatus ? defaultNavbar : toggleNavbar"
  class="flux-header clearfix"
>
  <div class="flux-header-wrapper clearfix">
    <div [attr.left-header-theme]="leftHeaderColorTheme" class="flux-brand clearfix">
      <span class="flux-brand-logo"><img height="35" src="../../../assets/img/brimas_logo.svg" width="35"></span>
      <span class="flux-brand-text">BRIMASLEAD</span>
    </div>
    <div class="flux-header-navbar px-1">
      <div class="flux-left-header clearfix">
        <div (click)="changeTheToggleStatus()" class="sidebarToggle clearfix"><i class="fas fa-bars"></i></div>
      </div>
      <span class="dashHead text-info flux-left-header">TRANSPORT & LOGISTICS</span>
      <div class="flux-right-header mt-2 mx-0 p-0">
        <ul class="align-items-center mt-0 mx-0 px-0">
          <li>
            <span class="d-none d-lg-inline mr-0 text-gray-600 small">{{userName}}</span>
            <img
              [src]="user"
              class="border rounded-circle img-profile"
              style="width: 30px; height: 30px"
            />
          </li>
          <li>
            <a (click)="logoutUser()" placement="bottom" tooltip="Logout">
              <i class="fas fa-power-off"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</header>

