import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-invoice-delivery",
  templateUrl: "./invoice-delivery.component.html",
  styleUrls: ["./invoice-delivery.component.sass"],
})
export class InvoiceDeliveryComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
