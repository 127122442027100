<div class="d-flex flex-column">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div
          class="tickets-card2 card shadow-lg mt-2 mb-0 pt-2 pb-1 px-4 px-md-5"
        >
          <div class="row d-flex justify-content-center">
            <div class="col-12 pt-0">
              <h4 class="text-info text-uppercase font-weight-bold pull-right">
                <hr class="top mt-1 mb-0" />
                my profile
                <hr class="bottom mt-0 mb-0" />
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div
          class="tickets-card2 card shadow-lg mt-2 mb-0 pt-2 pb-1 pb-xl-5 px-4 px-md-5 px-xl-3"
        >
          <div class="row">
            <div class="col-12 col-xl-3">
              <div
                class="update-img d-flex mt-3 mt-lg-4 mx-5 px-5 mx-lg-1 px-lg-0 justify-content-between"
              >
                <img
                  src="../../../../../assets/img/man.svg"
                  alt="Photo not found!"
                  class="img-fluid img-rounded"
                />
              </div>
              <div
                class="d-flex mt-3 mx-4 px-4 mx-lg-3 px-lg-4 align-items-center"
              >
                <button
                  class="update-button btn btn-info mx-4"
                  (click)="update(upload)"
                >
                  Update Photo
                </button>
              </div>
              <ng-template #upload let-modal>
                <div class="modal-header bg-info">
                  <h4 class="modal-title text-white-75" id="modal-basic-title">
                    Update Photo
                  </h4>
                  <button
                    type="button"
                    class="close"
                    aria-label="Close"
                    (click)="closeModal()"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="input-group mb-3">
                    <div class="form-group">
                      <input
                        type="file"
                        (change)="onFileSelected($event)"
                        id="resetFile"
                        class="form-control"
                        accept="image/*"
                      />
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-info"
                    (click)="updateProfile()"
                    [disabled]="userPhotoUrl === ''"
                  >
                    OK
                  </button>
                  <button
                    type="button"
                    class="btn btn-info"
                    (click)="closeModal()"
                  >
                    Cancel
                  </button>
                </div>
              </ng-template>
              <!-- <ngx-alerts></ngx-alerts> -->
              <!--update info avatar popup-->
            </div>
            <div class="col-12 col-xl-9">
              <div class="mx-2 my-2 mx-md-3 my-md-4">
                <!--display the profile info in the form, that should be disabled-->
                <form [formGroup]="userForm" (ngSubmit)="save()">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="form-group text-primary">
                        <label
                          for="full_name"
                          [ngClass]="fval.full_name.valid ? 'text-info' : ''"
                          ><i
                            *ngIf="fval.full_name.valid"
                            class="material-icons"
                            >check_circle</i
                          >Full Name</label
                        >
                        <div class="input-group mb-0">
                          <div class="input-group-prepend">
                            <div class="input-group-text">
                              <i
                                class="fas fa-user text-primary mx-auto"
                                style="size: 50px"
                              >
                              </i>
                            </div>
                          </div>
                          <input
                            id="full_name"
                            formControlName="full_name"
                            type="text"
                            class="form-control"
                            [ngClass]="{
                              'is-invalid':
                                (fval.full_name.touched ||
                                  fval.full_name.dirty) &&
                                fval.full_name.errors
                            }"
                            placeholder="Enter full name"
                            autocomplete="off"
                          />
                        </div>
                        <div
                          class="text-danger col"
                          *ngIf="
                            (fval.full_name.touched || fval.full_name.dirty) &&
                            fval.full_name.errors?.required
                          "
                        >
                          The full name is required
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group text-primary">
                        <label
                          for="user_contact_number1"
                          [ngClass]="
                            fval.user_contact_number1.valid ? 'text-info' : ''
                          "
                          >Phone contact<i
                            *ngIf="fval.user_contact_number1.valid"
                            class="material-icons"
                            >check_circle</i
                          ></label
                        >
                        <div class="input-group mb-0">
                          <div class="input-group-prepend">
                            <div class="input-group-text">
                              <i
                                class="fas fa-phone text-primary mx-auto"
                                style="size: 50px"
                              ></i>
                            </div>
                          </div>
                          <input
                            id="user_contact_number1"
                            formControlName="user_contact_number1"
                            type="text"
                            class="form-control"
                            [ngClass]="{
                              'is-invalid':
                                (fval.user_contact_number1.touched ||
                                  fval.user_contact_number1.dirty) &&
                                fval.user_contact_number1.errors
                            }"
                            placeholder="e.g. 07XXXXXXXX"
                            autocomplete="off"
                          />
                        </div>
                        <div
                          class="text-danger col"
                          *ngIf="
                            (fval.user_contact_number1.touched ||
                              fval.user_contact_number1.dirty) &&
                            fval.user_contact_number1.errors?.required
                          "
                        >
                          The phone number is required
                        </div>
                        <div
                          class="text-danger col"
                          *ngIf="
                            (fval.user_contact_number1.touched ||
                              fval.user_contact_number1.dirty) &&
                            fval.user_contact_number1.errors?.hasNumber
                          "
                        >
                          The phone number should be equal to 10 "digits"
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="form-group text-primary">
                        <label
                          for="user_contact_number2"
                          [ngClass]="
                            fval.user_contact_number2.valid ? 'text-info' : ''
                          "
                          >Phone contact 2<i
                            *ngIf="fval.user_contact_number2.valid"
                            class="material-icons"
                            >check_circle</i
                          ></label
                        >
                        <div class="input-group mb-0">
                          <div class="input-group-prepend">
                            <div class="input-group-text">
                              <i
                                class="fas fa-phone text-primary mx-auto"
                                style="size: 50px"
                              ></i>
                            </div>
                          </div>
                          <input
                            id="user_contact_number2"
                            formControlName="user_contact_number2"
                            type="text"
                            class="form-control"
                            [ngClass]="{
                              'is-invalid':
                                (fval.user_contact_number2.touched ||
                                  fval.user_contact_number2.dirty) &&
                                fval.user_contact_number2.errors
                            }"
                            placeholder="e.g. 07XXXXXXXX (Optional)"
                            autocomplete="off"
                          />
                        </div>
                        <div
                          class="text-danger col"
                          *ngIf="
                            (fval.user_contact_number2.touched ||
                              fval.user_contact_number2.dirty) &&
                            fval.user_contact_number2.errors?.hasNumber
                          "
                        >
                          The phone number should be equal to 10 "digits"
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group text-primary">
                        <label
                          for="email2"
                          [ngClass]="fval.email2.valid ? 'text-info' : ''"
                          >Email
                          <i *ngIf="fval.email2.valid" class="material-icons"
                            >check_circle</i
                          ></label
                        >
                        <div class="input-group mb-0">
                          <div class="input-group-prepend">
                            <div class="input-group-text">
                              <i
                                class="fas fa-envelope text-primary mx-auto"
                                style="size: 50px"
                              ></i>
                            </div>
                          </div>
                          <input
                            id="email2"
                            formControlName="email2"
                            type="text"
                            class="form-control"
                            [ngClass]="{
                              'is-invalid':
                                (fval.email2.touched || fval.email2.dirty) &&
                                fval.email2.errors
                            }"
                            placeholder="Enter email address"
                            autocomplete="off"
                          />
                        </div>
                        <div
                          class="text-danger col"
                          *ngIf="
                            (fval.email2.touched || fval.email2.dirty) &&
                            fval.email2.errors?.validEmail
                          "
                        >
                          Please enter valid email address
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex my-1 my-md-2 py-2 mx-auto">
                    <button
                      type="submit"
                      class="btn btn-primary col-4 col-sm-3 mx-auto"
                      [disabled]="!userForm.valid"
                    >
                      Save
                    </button>

                    <ngx-spinner
                      bdColor="#175b6f86"
                      size="medium"
                      color="#27ceaa"
                      type="ball-clip-rotate-multiple"
                    >
                      <!--p class="text-light justify-content-between align-items-center mt-4 pt-4 mt-md-4 pt-md-2">Please wait...</p-->
                    </ngx-spinner>

                    <label class="col" class="text-danger">
                      <!-- <i class="material-icons">Cancel</i> -->

                      <!-- <ngx-alerts></ngx-alerts> -->
                    </label>

                    <label class="col" class="text-info">
                      <!-- <i class="material-icons">check_circle</i> -->
                      <!-- <ngx-alerts></ngx-alerts> -->
                    </label>

                    <button
                      type="button"
                      class="btn btn-danger col-4 col-sm-3 mx-auto"
                      (click)="cancel()"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary col-4 col-sm-3 mx-auto"
                      (click)="enableEdit()"
                    >
                      Edit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
