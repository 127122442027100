import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-procurement-notification",
  templateUrl: "./procurement-notification.component.html",
  styleUrls: ["./procurement-notification.component.sass"],
})
export class ProcurementNotificationComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
