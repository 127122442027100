import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ProfilesService } from "../../services/profiles.service";
import { showToasterMessage } from "../../utils/functions";
import { CustomValidator } from "../../validators/custom-validator";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-edit-user-email",
  templateUrl: "./edit-user-email.component.html",
  styleUrls: ["./edit-user-email.component.sass"],
})
export class EditUserEmailComponent implements OnInit {
  userForm: FormGroup;
  loading = false;
  @Input() formField: string;
  @Input() isEmployeeEditing: boolean;
  @Input() employeeId: number | string;
  @Input() userId: string | number;
  @Input() userIdNaming: string;
  @Input() endPoint: string;
  @Output() closeModal = new EventEmitter<string>();

  constructor(
    private router: Router,
    private toaster: ToastrService,
    private profilesService: ProfilesService,
    public dialogRef: MatDialogRef<EditUserEmailComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      formField: string;
      isDialog: boolean;
      userId: number;
      userIdNaming: string;
      endPoint: string;
    }
  ) {}

  get fval(): any {
    return this.userForm.controls;
  }

  ngOnInit() {
    if (this.data.isDialog) {
      this.formField = this.data.formField;
      this.endPoint = this.data.endPoint;
      this.userIdNaming = this.data.userIdNaming;
      this.userId = this.data.userId;
    }
    this.userForm = this.createFormGroup();
  }

  createFormGroup(): FormGroup {
    return new FormGroup({
      [this.formField]: new FormControl(
        "",
        Validators.compose([
          CustomValidator.patternValidator(/\S+@\S+\.\S+/, {
            validEmail: true,
          }),
        ])
      ),
    });
  }

  cancel(): void {
    this.userForm.reset();
    this.dialogRef.close(false);
  }

  emitCloseModal() {
    this.cancel();
    this.closeModal.emit("closeEmailModal");
  }

  postEditEmail(): void {
    if (!this.userForm.valid) return;
    this.loading = true;
    const data = {
      [this.userIdNaming]: this.userId,
      ...this.userForm.value,
    };

    if (this.isEmployeeEditing) data.employeeId = this.employeeId;
    this.profilesService.postUpdateUserEmail(data, this.endPoint).subscribe(
      (res) => {
        this.loading = false;
        this.userForm.reset();
        this.dialogRef.close(true);
        showToasterMessage(this.toaster, "success", res);
      },
      (error) => {
        this.loading = false;
        showToasterMessage(this.toaster, "warning", error);
      }
    );
  }
}
