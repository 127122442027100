import { FilterPipeModule } from "ngx-filter-pipe";
import { TagInputModule } from "ngx-chips";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"; // this is needed!
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FrontDeskLayoutComponent } from "./front-desk-layout/front-desk-layout.component";
import { FrontDeskRoutingModule } from "./front-desk-routing.module";
import { FrontDeskDashboardComponent } from "./front-desk-dashboard/front-desk-dashboard.component";
import { FrontDeskHeaderComponent } from "./front-desk-panels/front-desk-header/front-desk-header.component";
import { FrontDeskLeftPanelComponent } from "./front-desk-panels/front-desk-left-panel/front-desk-left-panel.component";
import { FrontDeskRightPanelComponent } from "./front-desk-panels/front-desk-right-panel/front-desk-right-panel.component";
import { FrontDeskComponent } from "./front-desk.component";
import { RequestForQuoteComponent } from "./front-desk-dashboard/request-for-quote/request-for-quote.component";
import { LeaveManagementComponent } from "./front-desk-dashboard/employee-service/leave-management/leave-management.component";
// eslint-disable-next-line max-len
import { PerformanceManagementComponent } from "./front-desk-dashboard/employee-service/performance-management/performance-management.component";
import { PayrollModuleComponent } from "./front-desk-dashboard/employee-service/payroll-module/payroll-module.component";
// eslint-disable-next-line max-len
import { HireComponent } from "./front-desk-dashboard/employee-service/hire/hire.component";
import { AlertModule } from "ngx-bootstrap/alert";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatStepperModule } from "@angular/material/stepper";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatNativeDateModule, MatRippleModule } from "@angular/material/core";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSliderModule } from "@angular/material/slider";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTreeModule } from "@angular/material/tree";
import { NgxSpinnerModule } from "ngx-spinner";
import { RfqDetailsComponent } from "./front-desk-dashboard/request-for-quote/rfq-details/rfq-details.component";
import { ModalModule } from "ngx-bootstrap/modal";
import { AddRfqItemsComponent } from "./front-desk-dashboard/request-for-quote/add-rfq-items/add-rfq-items.component";
// import { DocPipe } from '../shared/pipes/doc.pipe';
import { DbDatePipe } from "../shared/pipes/db-date.pipe";
// import { FieldPathGenPipe } from '../shared/pipes/field-path-gen.pipe';
import { TimevaluePipe } from "../shared/pipes/timevalue.pipe";
import { CreateCatalogItemComponent } from "./front-desk-dashboard/create-catalog-item/create-catalog-item.component";
import { AgGridModule } from "ag-grid-angular";
// import { TabsModule } from 'ngx-bootstrap/modal';
import { CreateClientsComponent } from "./front-desk-dashboard/create-clients/create-clients.component";
import { PhoneRfqsComponent } from "./front-desk-dashboard/phone-rfqs/phone-rfqs.component";
import { MakeAppointmentComponent } from "./front-desk-dashboard/make-appointment/make-appointment.component";
import { FileArchiveComponent } from "./front-desk-dashboard/file-archive/file-archive.component";
import { FrontDeskNotificationsComponent } from "./front-desk-dashboard/front-desk-notifications/front-desk-notifications.component";
import { FrontDeskReportsComponent } from "./front-desk-dashboard/front-desk-reports/front-desk-reports.component";
import { FrontDeskProfileComponent } from "./front-desk-dashboard/front-desk-profile/front-desk-profile.component";
import { BsDatepickerModule, DatepickerModule } from "ngx-bootstrap/datepicker";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TabsModule } from "ngx-bootstrap/tabs";
import { StockDetailsComponent } from "./front-desk-dashboard/purchases/stock-details/stock-details.component";

@NgModule({
  declarations: [
    FrontDeskLayoutComponent,
    FrontDeskDashboardComponent,
    FrontDeskHeaderComponent,
    FrontDeskLeftPanelComponent,
    FrontDeskRightPanelComponent,
    FrontDeskComponent,
    RequestForQuoteComponent,
    LeaveManagementComponent,
    PerformanceManagementComponent,
    PayrollModuleComponent,
    // HireComponent,
    RfqDetailsComponent,
    AddRfqItemsComponent,
    // DocPipe,
    DbDatePipe,
    // FieldPathGenPipe,
    TimevaluePipe,
    CreateCatalogItemComponent,
    CreateClientsComponent,
    PhoneRfqsComponent,
    MakeAppointmentComponent,
    FileArchiveComponent,
    FrontDeskNotificationsComponent,
    FrontDeskReportsComponent,
    FrontDeskProfileComponent,
    StockDetailsComponent,
  ],
  imports: [
    TagInputModule,
    BrowserAnimationsModule,
    CommonModule,
    FrontDeskRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    NgxSpinnerModule,
    DatepickerModule,
    BsDatepickerModule,
    NgbModule,
    AlertModule.forRoot(),
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatRippleModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    AgGridModule.withComponents([]),
    TabsModule.forRoot(),
    FilterPipeModule,
  ],
  exports: [
    FrontDeskLayoutComponent,
    FrontDeskDashboardComponent,
    FrontDeskHeaderComponent,
    FrontDeskLeftPanelComponent,
    FrontDeskRightPanelComponent,
    FrontDeskComponent,
  ],
})
export class FrontDeskModule {}
