import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pay-service-providers',
  templateUrl: './pay-service-providers.component.html',
  styleUrls: ['./pay-service-providers.component.sass']
})
export class PayServiceProvidersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
