// app-loader.component.ts
import { Component, OnInit } from "@angular/core";
import { LoaderService } from "../../services/loader.service";

@Component({
  selector: "app-loader",
  templateUrl: "./app-loader.component.html",
  styleUrls: ["./app-loader.component.scss"],
})
export class AppLoaderComponent implements OnInit {
  loading: boolean;
  spinner = "../../../../assets/img/spinner-b.gif";

  constructor(private loaderService: LoaderService) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }

  ngOnInit() {}
}
