import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CustomerRoutingModule } from "./customer-routing.module";
import { SharedModule } from "../shared/shared.module";
import { ProfileComponent } from "./components/profile/profile.component";
import { ReactiveFormsModule } from "@angular/forms";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { NotificationsComponent } from "./components/notifications/notifications.component";
import { ClientDetailsComponent } from "./components/dashboard/client-details/client-details.component";
import { CustomerIndexComponent } from "./customer-index/customer-index.component";

@NgModule({
  declarations: [
    CustomerIndexComponent,
    DashboardComponent,
    ProfileComponent,
    NotificationsComponent,
    ClientDetailsComponent,
  ],
  imports: [
    CommonModule,
    CustomerRoutingModule,
    SharedModule,
    ReactiveFormsModule,
  ],
})
export class CustomerModule {}
