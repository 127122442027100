import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, of } from "rxjs";
import { AdminService } from "../services/admin.service";
import { catchError, map } from "rxjs/operators";
import { Company } from "../../shared/models/admin";

@Injectable({
  providedIn: "root",
})
export class CompanyProfileResolver implements Resolve<Observable<any>> {
  constructor(private adminService: AdminService) {}

  resolve(): Observable<any> {
    return this.adminService.getCompany().pipe(
      map((details: Company) => ({ details })),
      catchError((error) => {
        return of({ details: null, error: error });
      })
    );
  }
}
