<div class="jumbotron-fluid">
<div class="d-flex flex-column">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="title-card shadow mt-2 mb-0 pt-2 pb-0 px-4 px-md-5 mx-lg-3">
          <div class="row d-flex justify-content-between">
            <div class="col-12 pt-0">
              <h3 class="text-info text-uppercase font-weight-bold pull-right">
                approvals
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="col-12 mx-1 mx-lg-3 py-2 px-md-3"
      >
        <a class="text-info mb-1" routerLink="customer-approvals">
          <button
            class="btn btn-outline-info ml-1 mx-sm-1 px-1 px-md-2 shadow"
            tooltip="Customer Approval"
          >
            <i class="fas fa-users"></i> Customer
          </button>
        </a>
        <a class="text-info mb-1" routerLink="customer-sector">
          <button
            class="btn btn-outline-info ml-1 mx-sm-1 px-1 px-md-2 shadow"
            tooltip="Customer Sector"
          >
            <i class="fas fa-anchor"></i>  Customer Sector
          </button>
        </a>
        <a class="text-info mb-1" routerLink="supplier-approvals">
          <button
            class="btn btn-outline-info my-1 ml-1 mx-sm-1 px-1 px-md-2 shadow"
            tooltip="Supplier Approval"
          >
            <i class="fas fa-truck"></i> Supplier
          </button>
        </a>
        <a class="text-info mb-1" routerLink="#">
          <button
            class="btn btn-outline-info ml-1 mx-sm-1 px-1 px-md-2 shadow"
            tooltip="RFQ Approval"
          >
            <i class="fas fa-clipboard"></i> RFQ
          </button>
        </a>
        <a class="text-info mb-1" routerLink="#">
          <button
            class="btn btn-outline-info my-1 ml-1 mx-sm-1 px-1 px-md-2 shadow"
            tooltip="Bid Approval"
          >
            <i class="fas fa-clipboard"></i> Bid
          </button>
        </a>
        <a class="text-info mb-1" routerLink="#">
          <button
            class="btn btn-outline-info my-1 ml-1 mx-sm-1 px-1 px-md-2 shadow"
            tooltip="Customer Sectors"
          >
            <i class="fas fa-user"></i> Employee
          </button>
        </a>
        <a class="text-info mb-1" routerLink="#">
          <button
            class="btn btn-outline-info my-1 ml-1 mx-sm-1 px-1 px-md-2 shadow"
            tooltip="View Customers"
          >
            <i class="fas fa-eye"></i> Procurement
          </button>
        </a>
      </div>
    </div>
  </div>
</div>
</div>
    <router-outlet></router-outlet>
