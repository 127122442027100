<div class="row my-0 py-0">
  <div class="col-2 col-md-1 mx-0 px-1 px-lg-2 px-xl-3">
    <img
      class="img-circle mt-2 mt-xl-1 mb-1 pb-0"
      [src]="supplierDetail.supplierPhotoUrl || 'assets/img/man.svg'"
    />
    <label class="pic-font d-flex justify-content-center">Photo</label>
    <!-- (click)="openModal(template, itm.customerPhotoUrl)" -->
    <img
    *ngIf="supplierDetail.supplierCertificateOfRegistration"
      class="img-circle-1 my-1 pb-0"
      [src]="supplierDetail.supplierCertificateOfRegistration"
    />
    <label class="pic-font d-flex justify-content-center">Certificate</label>
    <!-- (click)="openModal(template, itm.gaurantorPhotoUrl)" alt="none" -->
  </div>
  <div class="break-text col-9 col-md-10">
    <div class="row mx-0 px-0">
      <div class="col-12 col-md-6 col-xl-4">
        <div class="row">
          <div class="col-5 d-flex justify-content-start mx-0 px-0">
            <span class="">Supplier ID:</span>
          </div>
          <div class="col-7 d-flex justify-content-start font-weight-bold mx-0 px-0 mx-lg-auto px-lg-2">
            <span class="itemCreated">{{supplierDetail.supplierId}} </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <div class="row">
          <div class="col-4 d-flex justify-content-start mx-0 px-0">
            <span class="">Supplier:</span>
          </div>
          <div class="col-8 d-flex justify-content-start font-weight-bold mx-0 px-0">
            <span class="itemCreated">{{supplierDetail.supplierName}} </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <div class="row">
          <div class="col-6 d-flex justify-content-start mx-0 px-0">
            <span class="">Supplier Type:</span>
          </div>
          <div class="col-6 d-flex justify-content-start font-weight-bold mx-0 px-0">
            <span class="itemCreated">{{supplierDetail.supplierTypeDecoded}}</span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <div class="row">
          <div class="col-7 col-sm-6 d-flex justify-content-start mx-0 px-0">
            <span class="">Engagement Type:</span>
          </div>
          <div class="col-5 col-sm-6 d-flex justify-content-start font-weight-bold mx-0 px-0">
            <span class="itemCreated">{{supplierDetail.supplierEngagementTypeDecoded}} </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <div class="row">
          <div class="col-6 d-flex justify-content-start mx-0 px-0">
            <span class="">TIN:</span>
          </div>
          <div class="col-6 d-flex justify-content-start font-weight-bold mx-0 px-0">
            <span class="itemCreated">{{supplierDetail.supplierTinNumber}}</span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <div class="row">
          <div class="col-6 d-flex justify-content-start mx-0 px-0">
            <span class="">VAT Compliant:</span>
          </div>
          <div class="col-6 d-flex justify-content-start font-weight-bold mx-0 px-0">
            <span class="itemCreated">{{supplierDetail.supplierVatCompliantDecoded}}</span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <div class="row">
          <div class="col-7 col-sm-6 d-flex justify-content-start mx-0 px-0">
            <span class="">Phone Contact 1:</span>
          </div>
          <div class="col-5 col-sm-6 d-flex justify-content-start font-weight-bold mx-0 px-0">
            <span class="itemCreated">{{supplierDetail.supplierPhone1}} </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4" *ngIf="supplierDetail.supplierPhone2">
        <div class="row">
          <div class="col-7 col-sm-6 d-flex justify-content-start mx-0 px-0">
            <span class="">Phone Contact 2:</span>
          </div>
          <div class="col-5 col-sm-6 d-flex justify-content-start font-weight-bold mx-0 px-0">
            <span class="itemCreated">{{supplierDetail.supplierPhone2}} </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <div class="row">
          <div class="col-4 d-flex justify-content-start mx-0 px-0">
            <span class="">Email:</span>
          </div>
          <div class="col-8 d-flex justify-content-start font-weight-bold mx-0 px-0">
            <span class="itemCreated">{{supplierDetail.supplierEmail}} </span>
          </div>
        </div>
      </div>
    </div>
    <hr class="length mt-2 mb-1" />
    <div class="row d-flex justify-content-center px-3 px-md-4">
      <div class="col-6 col-md-4">
        <div class="row">
          <div class="col mx-0 px-0 px-md-4">
            <span class="">
              <a
                class="text-decoration-none text-info pointerCursor"
                (click)="emitOpenContactPersonModal()"
              >Contact Person</a>
            </span>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-4">
        <div class="row">
          <div class="col mx-0 px-0 px-md-4">
            <span class="">
              <a
                class="text-decoration-none text-info pointerCursor"
                (click)="emitOpenBankingDetailsModal()"
              >Banking Details</a>
            </span>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-4">
        <div class="row">
          <div class="col mx-0 px-0 px-md-4">
            <span class="">
              <a
                class="text-decoration-none text-info pointerCursor"
                (click)="emitOpenShippingAddressModal()"
              >
                Shipping Address</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-3 col-md-1">
    <div class="row">
      <div class="d-flex justify-content-end mx-1 mx-md-0 px-0">
        <a
          class="text-info mb-1"
          tooltip="Edit Customer"
          (click)="emitEditAction()"
        >
          <button class="btn btn-outline-info mt-2 mb-1 mb-md-2 shadow">
            <i class="fas fa-edit"></i>
            Edit
          </button>
        </a>
      </div>
    </div>
  </div>
</div>
