import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { EmployeeToken } from "src/app/modules/shared/models/employee";
import { AdminService } from "src/app/modules/admin/services/admin.service";
import { AuthService } from "src/app/modules/auth/services/auth.service";
import {
  searchItems,
  showToasterMessage,
} from "../../../shared/utils/functions";
import { PageEvent } from "@angular/material/paginator";
import { Branch } from "../../../shared/models/user-auth";
import { NgxSpinnerService } from "ngx-spinner";
import { MatDialog } from "@angular/material/dialog";
import { BranchEditorComponent } from "./branch-editor/branch-editor.component";

export interface CreateBranch {
  branchId?: number;
  branchName: string;
  branchType: number;
  employeeId: number;
}

@Component({
  selector: "app-branch-setup",
  templateUrl: "./branch-setup.component.html",
  styleUrls: ["./branch-setup.component.scss"],
})
export class BranchSetupComponent implements OnInit {
  loading = false;
  branchTypes = [
    { id: 1100, name: "HEADOFFICE" },
    { id: 1200, name: "BRANCH" },
  ];
  User: EmployeeToken = this.authService.loggedInUserInfo();
  branches: Array<Branch> = [];
  branchesCopy: Array<Branch>;
  serviceErrors: "";
  totalItems: number;
  pageSize = 15;
  lowValue: number = 0;
  highValue: number = 15;
  displayedColumns: string[] = ["id", "type", "name", "action"];

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private toaster: ToastrService,
    private authService: AuthService,
    private adminService: AdminService,
    private activateRoute: ActivatedRoute,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.branchesCopy = this.branches =
      this.activateRoute.snapshot.data[0]?.details;
    this.totalItems = this.branches.length;
    this.serviceErrors = this.activateRoute.snapshot.data[0]?.error;
    if (!this.branches) {
      showToasterMessage(this.toaster, "warning", this.serviceErrors);
    }
  }

  openBranchDialog(isEditing: boolean, branch?: Branch): void {
    const dialogRef = this.dialog.open(BranchEditorComponent, {
      minWidth: "30%",
      data: {
        isEditing,
        branch,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.editing) {
        this.branchesCopy = this.branches = this.branches.map((branch) => {
          if (branch.branchId === result.branches[0].branchId)
            return result.branches[0];
          return branch;
        });
      } else {
        if (result.branches.length)
          this.branchesCopy = this.branches = result.branches;
      }
    });
  }

  public getPaginatorData(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    return event;
  }

  pageSizeOptions() {
    return [5, 10, 20, 30, 40, 50, this.branches?.length].filter(
      (n) => n <= this.branches?.length
    );
  }

  getValue(event): void {
    const results = searchItems(event.target.value, this.branches, "branches");
    this.branches = results.length ? results : this.branchesCopy;
    this.totalItems = this.branches.length;
  }
}
