import {Component, OnInit} from "@angular/core";
import {MenuItem} from "../../../shared/layout/left-panel/left-panel.component";
import {ProfilesService} from "../../../shared/services/profiles.service";
import {AuthService} from "../../../auth/services/auth.service";
import {showToasterMessage} from "../../../shared/utils/functions";
import {ToastrService} from "ngx-toastr";
import {COMPANY_TOKEN, USER_TOKEN} from "../../../shared/utils/constants";
import {AdminService} from "../../services/admin.service";
import {Router} from "@angular/router";

@Component({
  selector: "app-admin-index",
  templateUrl: "./admin-index.component.html",
  styleUrls: ["./admin-index.component.scss"],
})
export class AdminIndexComponent implements OnInit {
  menuList: MenuItem[] = [
    {
      name: "Dashboard",
      icon: "fas fa-tachometer-alt",
      url: "/admin/dashboard",
    },
    {
      name: "Company Setup",
      icon: "fas fa-anchor",
      url: "/admin/company-setup",
    },
    {
      name: "Projects",
      icon: "fas fa-building",
      url: "/admin/branch-setup",
    },
    {
      name: "Approvals Setup",
      icon: "fas fa-toolbox",
      url: "/admin/approval-setup",
    },
    // {
    //   name: "Processes",
    //   icon: "fas fa-tools",
    //   url: "/admin/process-setup",
    // },
    {
      name: "Employees",
      icon: "fas fa-users",
      url: "/admin/employees",
    },
    // {
    //   name: "Reports",
    //   icon: "fas fa-chart-line",
    //   url: "/admin/reports",
    // },
    {
      name: "Profile",
      icon: "fas fa-user-cog",
      url: "/admin/profile",
    },
  ];
  moduleName = "ADMIN";

  constructor(
    private profilesService: ProfilesService,
    private adminService: AdminService,
    private authService: AuthService,
    private toaster: ToastrService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      this.profilesService
        .getActiveEmployee(this.authService.loggedInUserInfo().employeeId)
        .subscribe(
          (res) => {
            localStorage.setItem(USER_TOKEN, JSON.stringify(res[0]));
            this.profilesService.userProfile.next(
              JSON.parse(localStorage.getItem(USER_TOKEN))
            );
          },
          (error) => {
            showToasterMessage(this.toaster, "warning", error);
          }
        );
      this.adminService.getCompany().subscribe(
        (res) => {
          localStorage.setItem(COMPANY_TOKEN, JSON.stringify(res[0]));
          this.adminService.company.next(
            JSON.parse(localStorage.getItem(COMPANY_TOKEN))
          );
        },
        (error) => {
          showToasterMessage(this.toaster, "warning", error);
        }
      );
    } else this.router.navigate(["/auth/employee-login"]).then();
  }
}
