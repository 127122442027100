import { Component, Inject, OnInit } from "@angular/core";
import { showToasterMessage } from "../../../../shared/utils/functions";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../../../../auth/services/auth.service";
import { AdminService } from "../../../services/admin.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
  EmployeeData,
  EmployeeRole,
  EmployeeToken,
} from "../../../../shared/models/employee";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-employee-role-editor",
  templateUrl: "./employee-role-editor.component.html",
  styleUrls: ["./employee-role-editor.component.scss"],
})
export class EmployeeRoleEditorComponent implements OnInit {
  userForm: FormGroup;
  loading: boolean = false;
  User: EmployeeToken = this.authService.loggedInUserInfo();

  constructor(
    private toaster: ToastrService,
    private fb: FormBuilder,
    private authService: AuthService,
    private adminService: AdminService,
    public dialogRef: MatDialogRef<EmployeeRoleEditorComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      employee: EmployeeData;
      roles: Array<EmployeeRole>;
      isApproval?: boolean;
    }
  ) {}

  ngOnInit(): void {
    this.userForm = this.createFormGroup();
    this.userForm.controls.employeeName.setValue(
      this.data.employee.employeeName
    );
    this.userForm.controls.role.setValue(this.data.employee.roleId);
    this.userForm.controls.employeeName.disable();
  }

  createFormGroup(): any {
    return new FormGroup({
      employeeName: this.fb.control(
        "",
        Validators.compose([Validators.required])
      ),
      role: this.fb.control("", Validators.compose([Validators.required])),
    });
  }

  closeEditorDialog(approveEmployee?: boolean): void {
    this.dialogRef.close({
      approveEmployee,
      roleId: this.userForm.controls.role.value,
    });
  }

  updateEmployeeRole(): void {
    this.loading = true;
    const data = {
      employeeId: this.data.employee.employeeId,
      roleId: this.userForm.controls.role.value,
      employeeIdApprover: this.User.employeeId,
    };

    const newRoleName = this.data.roles.filter(
      (role) => role.roleId === data.roleId
    )[0].roleName;
    this.adminService.approveEmployee(data).subscribe(
      () => {
        this.loading = false;
        this.dialogRef.close({ ...data, roleName: newRoleName });
        this.userForm.reset();
        showToasterMessage(
          this.toaster,
          "success",
          "Role was updated successfully"
        );
      },
      (error: string) => {
        this.loading = false;
        showToasterMessage(this.toaster, "warning", error);
      }
    );
  }
}
