  <div class="row my-0 py-0">
    <div class="col-2 col-md-1 mx-0 px-1 px-lg-2">
      <img
        class="img-circle mt-2 mb-1 pb-0"
        [src]="customerDetail.customerPhotoUrl || 'assets/img/man.svg'"
        alt="Customer photo"
      />
    </div>
    <div class="break-text col-10 mx-0 px-0">
      <div class="row mx-0 px-0">
        <div class="col-12 col-md-6 col-xl-4">
          <div class="row">
            <div class="col-5 d-flex justify-content-start mx-0 px-0">
              <span class="">Customer ID:</span>
            </div>
            <div
              class="col-7 d-flex justify-content-start font-weight-bold mx-0 px-0 mx-lg-auto px-lg-2"
            >
              <span class="itemCreated">{{customerDetail.customerId}}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="row">
            <div class="col-4 d-flex justify-content-start mx-0 px-0">
              <span class="">Customer:</span>
            </div>
            <div
              class="col-8 d-flex justify-content-start font-weight-bold mx-0 px-0"
            >
              <span class="itemCreated">{{customerDetail.customerName}}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="row">
            <div class="col-6 d-flex justify-content-start mx-0 px-0">
              <span class="">Customer Type:</span>
            </div>
            <div
              class="col-6 d-flex justify-content-start font-weight-bold mx-0 px-0"
            >
              <span class="itemCreated">{{customerDetail.customerTypeDecoded}}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="row">
            <div class="col-6 d-flex justify-content-start mx-0 px-0">
              <span class="">Customer Sector:</span>
            </div>
            <div
              class="col-6 d-flex justify-content-start font-weight-bold mx-0 px-0"
            >
              <span class="itemCreated">{{customerDetail.customerSectorDetails}}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="row">
            <div class="col-6 d-flex justify-content-start mx-0 px-0">
              <span class="">Phone Contact 1:</span>
            </div>
            <div
              class="col-6 d-flex justify-content-start font-weight-bold mx-0 px-0"
            >
              <span class="itemCreated">{{customerDetail.customerPhone1}}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="row">
            <div class="col-6 d-flex justify-content-start mx-0 px-0">
              <span class="">Phone Contact 2:</span>
            </div>
            <div
              class="col-6 d-flex justify-content-start font-weight-bold mx-0 px-0"
            >
              <span class="itemCreated">{{customerDetail.customerPhone2}} </span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="row">
            <div class="col-4 d-flex justify-content-start mx-0 px-0">
              <span class="">Email:</span>
            </div>
            <div
              class="col-8 d-flex justify-content-start font-weight-bold mx-0 px-0"
            >
              <span class="itemCreated">{{customerDetail.customerEmail}} </span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="row">
            <div class="col-5 d-flex justify-content-start mx-0 px-0">
              <span class="">Branch:</span>
            </div>
            <div
              class="col-7 d-flex justify-content-start font-weight-bold mx-0 px-0"
            >
              <span class="itemCreated">{{customerDetail.branchName}}</span>
            </div>
          </div>
        </div>
      </div>
      <hr class="length mt-2 mb-1"/>
      <div class="row d-flex justify-content-center px-3 px-md-4">
        <div class="col-6 col-md-4">
          <div class="row">
            <div class="col mx-0 px-0 px-md-4">
                        <span class="">
                          <a
                            class="text-decoration-none text-info pointerCursor"
                            (click)="emitOpenContactPersonModal()"
                          >Contact Person</a
                          >
                        </span>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-4">
          <div class="row">
            <div class="col mx-0 px-0 px-md-4">
                        <span class="">
                          <a
                            class="text-decoration-none text-info pointerCursor"
                            (click)="emitOpenBankingDetailsModal()"
                          >Banking Details</a
                          >
                        </span>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-4">
          <div class="row">
            <div class="col mx-0 px-0 px-md-4">
              <span class="">
                <a
                          class="text-decoration-none text-info pointerCursor"
                            (click)="emitOpenShippingAddressModal()"
                          >
                            Shipping Address</a
                          >
                        </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3 col-md-1">
      <div class="row">
        <div class="d-flex justify-content-end mx-1 mx-md-0 px-0">
          <a
            class="text-info mb-1"
            tooltip="Edit Customer"
            (click)="emitEditAction()"
          >
            <button class="btn btn-outline-info mt-2 mb-1 mb-md-2 shadow">
              <i class="fas fa-edit"></i>
              Edit
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
