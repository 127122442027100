import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { EmployeeToken } from "src/app/modules/shared/models/employee";
import { Branch } from "src/app/modules/shared/models/user-auth";
import { AdminService } from "src/app/modules/admin/services/admin.service";
import { AuthService } from "src/app/modules/auth/services/auth.service";
import {
  formatFormFields,
  showToasterMessage,
} from "../../../../shared/utils/functions";
import { CreateBranch } from "../branch-setup.component";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-edit-branch",
  templateUrl: "./branch-editor.component.html",
  styleUrls: ["./branch-editor.component.scss"],
})
export class BranchEditorComponent implements OnInit {
  userForm: FormGroup;
  loading = false;
  branchTypes = [
    { id: 1100, name: "MAIN" },
    { id: 1200, name: "OTHERS" },
  ];
  User: EmployeeToken = this.authService.loggedInUserInfo();

  constructor(
    private router: Router,
    private toaster: ToastrService,
    private authService: AuthService,
    private adminService: AdminService,
    public dialogRef: MatDialogRef<BranchEditorComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { isEditing: boolean; branch?: Branch }
  ) {}

  get fval(): any {
    return this.userForm.controls;
  }

  ngOnInit() {
    this.userForm = this.createFormGroup();
    if (this.data.isEditing) {
      this.fval.branchName.setValue(this.data.branch.branchName);
      this.fval.branchType.setValue(this.data.branch.branchType);
    }
  }

  createFormGroup() {
    return new FormGroup({
      branchName: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      branchType: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
    });
  }

  createEditBranch(): void {
    if (!this.userForm.valid) return;
    this.loading = true;
    const data: CreateBranch = {
      ...formatFormFields(this.userForm.value),
      employeeId: this.User.employeeId,
    };

    if (this.data.isEditing) data.branchId = this.data.branch.branchId;

    this.adminService
      .createEditBranch(
        data,
        this.data.isEditing ? "put" : "post",
        this.data.isEditing ? "UPDATE_BRANCH" : "CREATE_BRANCH"
      )
      .subscribe(
        () => {
          this.loading = false;
          this.userForm.reset();
          showToasterMessage(
            this.toaster,
            "success",
            data.branchName +
              `was ${this.data.isEditing ? "edited" : "created"} successfully`
          );
          if (!this.data.isEditing) {
            this.adminService.getAllBranches().subscribe(
              (res) => {
                this.dialogRef.close({
                  editing: false,
                  branches: [...res].reverse(),
                });
              },
              (error) => {
                this.loading = false;
                this.dialogRef.close({ editing: false, branches: [] });
                showToasterMessage(this.toaster, "warning", error);
              }
            );
          } else this.dialogRef.close({ editing: true, branches: [data] });
        },
        (error) => {
          this.loading = false;
          showToasterMessage(this.toaster, "warning", error);
        }
      );
  }

  closeEditorDialog(): void {
    this.dialogRef.close();
  }
}
