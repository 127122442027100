import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LayoutService } from "./layout/services/layout.service";
import { MenudropdownDirective } from "./directives/menudropdown.directive";
import { DropzoneDirective } from "./directives/dropzone.directive";
import { AppLoaderComponent } from "./components/app-loader/app-loader.component";
import { ServicesModule } from "./services/services.module";
import { EmployeeDetailsComponent } from "./components/employee-details/employee-details.component";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { RemoveDashesPipe } from "./pipes/remove-dashes.pipe";
import { CustomerDetailsComponent } from "./components/customer-details/customer-details.component";
import { SupplierDetailsComponent } from "./components/supplier-details/supplier-details.component";
import { ShippingAddressComponent } from "./components/shipping-address/shipping-address.component";
import { ContactPersonComponent } from "./components/contact-person/contact-person.component";
import { BankDetailsComponent } from "./components/bank-details/bank-details.component";
import { EditUserEmailComponent } from "./components/edit-user-email/edit-user-email.component";
import { PagesCoreComponent } from "./layout/pages-core/pages-core.component";
import { HeaderComponent } from "./layout/header/header.component";
import { LeftPanelComponent } from "./layout/left-panel/left-panel.component";
import { ContentSectionComponent } from "./layout/content-section/content-section.component";
import { RouterModule } from "@angular/router";
import { BsDatepickerModule, DatepickerModule } from "ngx-bootstrap/datepicker";
import { ProfileEditorComponent } from "./components/profile/profile-editor/profile-editor.component";
import { NextOfKinEditorComponent } from "./components/profile/next-of-kin-editor/next-of-kin-editor.component";
import { MatDialogModule } from "@angular/material/dialog";
import { appDisableControlDirective } from "./directives/disableControlDirective.directive";

@NgModule({
  declarations: [
    PagesCoreComponent,
    HeaderComponent,
    LeftPanelComponent,
    ContentSectionComponent,
    MenudropdownDirective,
    DropzoneDirective,
    AppLoaderComponent,
    EmployeeDetailsComponent,
    RemoveDashesPipe,
    appDisableControlDirective,
    CustomerDetailsComponent,
    SupplierDetailsComponent,
    ShippingAddressComponent,
    ContactPersonComponent,
    BankDetailsComponent,
    EditUserEmailComponent,
    ProfileEditorComponent,
    NextOfKinEditorComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MatDialogModule,
    ServicesModule,
    TooltipModule.forRoot(),
    DatepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
  ],
  exports: [
    PagesCoreComponent,
    MenudropdownDirective,
    ServicesModule,
    AppLoaderComponent,
    EmployeeDetailsComponent,
    RemoveDashesPipe,
    appDisableControlDirective,
    CustomerDetailsComponent,
    SupplierDetailsComponent,
    EditUserEmailComponent,
    ShippingAddressComponent,
    ContactPersonComponent,
    BankDetailsComponent,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [LayoutService],
    };
  }
}
