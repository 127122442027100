<app-auth-nav *ngIf="showNavBar"></app-auth-nav>
<div class="d-flex flex-column justify-content-between align-items-center"
     style="height: 100vh; background-color: lightpink">
  <div></div>
  <div class="card">
    <h2 class="d-flex flex-row justify-content-center mt-3">
      <i class="fas fa-sign-in-alt text-info mx-auto"></i>
    </h2>
    <h3 class="auth-head card-title text-info d-flex justify-content-center">
      <span>SIGN IN</span>
    </h3>
    <div class="card-body py-2 mb-0">
      <form (ngSubmit)="login()" [formGroup]="userForm">
        <div class="form-group text-primary">
          <label
            [for]="EMAIL_FORM_FIELD_NAME"
            [ngClass]="fval[EMAIL_FORM_FIELD_NAME].valid ? 'text-info' : ''"
          >Email<i
            *ngIf="fval[EMAIL_FORM_FIELD_NAME].valid"
            class="material-icons"
          >check_circle</i
          ></label
          >
          <div class="input-block mb-0">
            <input
              [formControlName]="EMAIL_FORM_FIELD_NAME"
              [id]="EMAIL_FORM_FIELD_NAME"
              [ngClass]="{
                  'is-invalid':
                    (fval[EMAIL_FORM_FIELD_NAME].touched || fval[EMAIL_FORM_FIELD_NAME].dirty) &&
                    fval[EMAIL_FORM_FIELD_NAME].errors
                }"
              autocomplete="user@gmail.com"
              class="form-control shadow"
              placeholder="user@gmail.com"
              type="text"
            />
            <i
              class="start fas fa-phone text-primary mx-auto"
              style="size: 50px"
            >
            </i>
          </div>
          <div
            *ngIf="
                (fval[EMAIL_FORM_FIELD_NAME].touched || fval[EMAIL_FORM_FIELD_NAME].dirty) &&
                fval[EMAIL_FORM_FIELD_NAME].errors?.required
              "
            class="text-danger col"
          >
            The email is required
          </div>
          <div
            *ngIf="
                (fval[EMAIL_FORM_FIELD_NAME].touched || fval[EMAIL_FORM_FIELD_NAME].dirty) &&
                fval[EMAIL_FORM_FIELD_NAME].errors?.validEmail
              "
            class="text-danger col"
          >
            The email should be valid
          </div>
        </div>

        <div class="form-group text-primary">
          <label
            [for]="PASSWORD_FORM_FIELD_NAME"
            [ngClass]="fval[PASSWORD_FORM_FIELD_NAME].valid ? 'text-info' : ''"
          >Password<i *ngIf="fval[PASSWORD_FORM_FIELD_NAME].valid" class="material-icons"
          >check_circle</i
          ></label
          >

          <div class="input-block mb-0">
            <i
              class="start fas fa-lock text-primary mx-auto"
              style="size: 50px"
            ></i>
            <input
              [formControlName]="PASSWORD_FORM_FIELD_NAME"
              [id]="PASSWORD_FORM_FIELD_NAME"
              [ngClass]="{
                  'is-invalid':
                    (fval[PASSWORD_FORM_FIELD_NAME].touched || fval[PASSWORD_FORM_FIELD_NAME].dirty) &&
                    fval[PASSWORD_FORM_FIELD_NAME].errors
                }"
              [type]="fieldType ? 'text' : 'password'"
              autocomplete="Enter your password"
              class="form-control shadow"
              placeholder="Enter your password"
            />
            <i
              (click)="toggleFieldType()"
              [ngClass]="{
                      'fa-eye-slash': !fieldType,
                      'fa-eye': fieldType
                    }"
              class="end fas"
            ></i>
          </div>
          <div
            *ngIf="
                (fval[PASSWORD_FORM_FIELD_NAME].touched || fval[PASSWORD_FORM_FIELD_NAME].dirty) &&
                fval[PASSWORD_FORM_FIELD_NAME].errors?.required
              "
            class="text-danger col"
          >
            Password is required
          </div>
        </div>

        <div class="d-flex flex-row justify-content-between">
          <button
            [disabled]="loading || !userForm.valid"
            class="btn btn-primary col-5 shadow"
            type="submit"
          >
            LOGIN
          </button>
          <button
            [routerLink]="REGISTER_PATH"
            class="btn btn-primary col-5 shadow"
            type="button"
          >
            SIGN UP
          </button>
        </div>
        <div class="row d-flex flex-row mt-2 justify-content-end">
          <a
            [routerLink]="fval[EMAIL_FORM_FIELD_NAME].valid ? [CHANGE_PASSWORD_PATH, fval[EMAIL_FORM_FIELD_NAME].value]: ''"
            [style]="{color: fval[EMAIL_FORM_FIELD_NAME].valid ? '#0babe0' : 'gray'}"
            mat-button
          >
            Forgot Password?
          </a>
        </div>
      </form>
    </div>
  </div>
  <div class="small text-center text-info mb-5">
    Copyright &copy; {{2022}} - stat-solutions Ltd
  </div>
</div>
