import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from "src/app/modules/auth/services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { showToasterMessage } from "../../shared/utils/functions";
import { CustomValidator } from "../../shared/validators/custom-validator";
import { API_URLS } from "../../shared/utils/constants";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit {
  userEmail: string;
  loading = false;
  userForm: FormGroup;
  serviceErrors: string;
  fieldType: boolean;
  EMAIL_FIELD_NAME: string;
  PASSWORD_FORM_FIELD_NAME: string;
  LOGIN_PATH: string;
  CHANGE_PASSWORD_ENDPOINT: string;
  PASSWORD_CODE_ENDPOINT: string;
  showNavBar: boolean = false;

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private toaster: ToastrService,
    private authService: AuthService
  ) {}

  get fval(): any {
    return this.userForm?.controls;
  }

  ngOnInit() {
    this.route.url.subscribe((val) => {
      if (val[0].path === "employee-change-password") {
        this.EMAIL_FIELD_NAME = "employeeEmail";
        this.PASSWORD_FORM_FIELD_NAME = "employeePassword";
        this.LOGIN_PATH = "/auth/employee-login";
        this.PASSWORD_CODE_ENDPOINT =
          API_URLS.AUTH.EMPLOYEE.GET_PASSWORD_CHANGE_CODE;
        this.CHANGE_PASSWORD_ENDPOINT =
          API_URLS.AUTH.EMPLOYEE.POST_CHANGE_PASSWORD;
      } else if (val[0].path === "customer-change-password") {
        this.showNavBar = true;
        this.EMAIL_FIELD_NAME = "customerEmail";
        this.PASSWORD_FORM_FIELD_NAME = "customerPassword";
        this.LOGIN_PATH = "/auth/customer-login";
        this.PASSWORD_CODE_ENDPOINT =
          API_URLS.AUTH.CUSTOMER.GET_PASSWORD_CHANGE_CODE;
        this.CHANGE_PASSWORD_ENDPOINT =
          API_URLS.AUTH.CUSTOMER.POST_CHANGE_PASSWORD;
      } else if (val[0].path === "supplier-change-password") {
        this.showNavBar = true;
        this.EMAIL_FIELD_NAME = "supplierEmail";
        this.PASSWORD_FORM_FIELD_NAME = "supplierPassword";
        this.LOGIN_PATH = "/auth/supplier-login";
        this.PASSWORD_CODE_ENDPOINT =
          API_URLS.AUTH.SUPPLIER.GET_PASSWORD_CHANGE_CODE;
        this.CHANGE_PASSWORD_ENDPOINT =
          API_URLS.AUTH.SUPPLIER.POST_CHANGE_PASSWORD;
      }

      this.userForm = this.createFormGroup();

      this.route.params.subscribe((params) => {
        this.userEmail = params["userEmail"];
        if (this.userEmail) this.getPasswordResetCode();
      });
    });
  }

  createFormGroup() {
    return new FormGroup(
      {
        code: new FormControl(
          "",
          Validators.compose([
            // 1. Password Field is Required
            CustomValidator.patternValidator(
              /^(([0-9])([0-9])([0-9])([0-9])([0-9]))$/,
              {
                hasNumber: true,
              }
            ),
            Validators.required,
            Validators.minLength(5),
            Validators.maxLength(5),
          ])
        ),
        password: new FormControl(
          "",
          Validators.compose([
            // 1. Password Field is Required

            Validators.required,

            // 2. check whether the entered password has a number
            //CustomValidatorInitialCompanySetup.patternValidator(/^(([1-9])([1-9])([1-9])([0-9]))$/, { hasNumber: true }),
            // 6. Has a minimum length of 8 characters
            Validators.minLength(8),
            //Validators.maxLength(4),
            // 3. check whether the entered password has upper case letter
            CustomValidator.patternValidator(/[A-Z]/, {
              hasCapitalCase: true,
            }),
            // 4. check whether the entered password has a lower-case letter
            CustomValidator.patternValidator(/[a-z]/, {
              hasSmallCase: true,
            }),
            // 5. check whether the entered password has a special character
            CustomValidator.patternValidator(/[!@#$%^&*_+-=;':"|,.<>/?]/, {
              hasSpecialCharacters: true,
            }),
          ])
        ),
        confirmPassword: new FormControl(
          "",
          Validators.compose([
            // 1. Password Field is Required
            Validators.required,
          ])
        ),
      },
      { validators: CustomValidator.passwordMatchValidator }
    );
  }

  getPasswordResetCode(): any {
    this.authService
      .passwordChangeCodeHandler(
        { [this.EMAIL_FIELD_NAME]: this.userEmail },
        this.PASSWORD_CODE_ENDPOINT
      )
      .subscribe(
        () => {
          this.loading = false;
          showToasterMessage(
            this.toaster,
            "success",
            "Check your email for a password reset code"
          );
        },
        (error: string) => {
          this.loading = false;
          showToasterMessage(this.toaster, "warning", error);
        }
      );
  }

  // toggle visibility of password field
  toggleFieldType(): any {
    this.fieldType = !this.fieldType;
  }

  changePassword() {
    this.loading = true;
    if (!this.userForm.valid) return;
    const data = {
      [this.EMAIL_FIELD_NAME]: this.userEmail,
      theChangeCode: Number(this.fval.code.value),
      [this.PASSWORD_FORM_FIELD_NAME]: this.fval.password.value,
    };
    this.authService
      .changePasswordHandler(data, this.CHANGE_PASSWORD_ENDPOINT)
      .subscribe(
        (data: string) => {
          this.loading = false;
          showToasterMessage(
            this.toaster,
            "success",
            "Password was changed Successfully"
          );
          this.userForm.reset();
          this.router.navigate([this.LOGIN_PATH]).then();
        },
        (error: string) => {
          this.loading = false;
          showToasterMessage(
            this.toaster,
            "warning",
            error,
            "Password change failed!"
          );
        }
      );
  }
}
