    <div class="row mt-md-0 pt-0 mx-md-1 px-md-3">
      <div
        class="col-12 mt-0 pt-0 d-flex justify-content-end"
      >
        <a
          class="text-info mb-1"
          routerLink="c_o_accounts"
          routerLinkActive="active"
        >
          <button class="btn btn-outline-info my-2 ml-1 mx-sm-1 shadow">
            Accounts
          </button>
        </a>
        <a
          class="text-info mb-1"
          routerLink="c_o_accounts"
          routerLinkActive="active"
        >
          <button class="btn btn-outline-info my-2 ml-1 mx-sm-1 shadow">
            Headers
          </button>
        </a>
        <a
          class="text-info mb-1"
          routerLink="postings"
          routerLinkActive="active"
        >
          <button class="btn btn-outline-info my-2 ml-1 mx-sm-1 shadow">
            Major Headers
          </button>
        </a>
        <a
          class="text-info mb-1"
          routerLink="statements"
          routerLinkActive="active"
        >
          <button class="btn btn-outline-info my-2 ml-1 mx-sm-1 shadow">
            Currencies
          </button>
        </a>
        <a
          class="text-info mb-1"
          routerLink="statements"
          routerLinkActive="active"
        >
          <button class="btn btn-primary my-2 ml-1 mx-sm-1 align-self-end shadow">
            New
          </button>
        </a>
      </div>
    </div>
  <router-outlet></router-outlet>

    <!-- <ng-container>
  <div class="w-100 d-flex flex-column flex-md-row justify-content-md-around mb-2">
    <mat-nav-list>
      <button mat-button>
        <a matLine routerLink="c_o_accounts" routerLinkActive="active">Accounts</a>
      </button>
      <button mat-button>
        <a matLine routerLink="postings">Headers</a>
      </button>
      <button mat-button>
        <a matLine routerLink="statement">Major Headers</a>
      </button>
      <button mat-button>
        <a matLine routerLink="statement">Currencies</a>
      </button>
    </mat-nav-list>
    <button class="align-self-end p-2 font-weight-bold" mat-button>New</button>
  </div>
  <mat-divider></mat-divider>
  <router-outlet></router-outlet>
</ng-container> -->
